import { Backdrop, Box, CircularProgress, DialogTitle, FormControl, IconButton, MenuItem, Select, Tooltip } from '@mui/material'
import React from 'react'
import { GooglePdfIcon, GoogleSheetsIcon } from '../SvgIcon/SvgIcon';
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';
import { downloadExcelPDFR2aReport } from '../../config/apiUrl';
import { saveAs } from 'file-saver'; // To trigger file download
import { useSelector } from 'react-redux';

function R2aDropDownDetailsHeader(props) {

    const userInfo = useSelector((state) => state.userInfoReducer.userInfo);

    const rfqVendorCount = props.mainR2aResponse && props.mainR2aResponse.rfq_vendors
        ? props.mainR2aResponse.rfq_vendors.length
        : '-';

    const rfqItemsCount = props.auctionDetail?.items && props.auctionDetail?.items
        ? props.auctionDetail?.items.length
        : '-';

    // Generate dynamic file name
    const now = new Date();
    const day = now.toLocaleString('en-US', { weekday: 'short' });
    const month = now.toLocaleString('en-US', { month: 'short' });
    const date = now.getDate();
    const year = now.getFullYear();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    const formattedDate = `${day} ${month} ${date} ${hours}_${minutes}_${seconds} ${year}`;
    const [isLoading, setIsLoading] = React.useState(false);

    const handleExcelExport = async () => {
        setIsLoading(true);
        try {
            let data = {
                "action": "r2a_action",
                "action_code": "download_r2a_report_pdf",
                "inquiry_id": props.selctedRFQInquiryId,
                "r2a_flag": props.auctionDetail.r2a_flag,
                "qcs_no": props.auctionDetail.qcs_no,
                "auction_rfq_id": props.auctionDetail.id,
                "pdf_excel_flag": "10",
                "product_name": props.auctionDetail.category_name,
                "inquiry_date": props.mainR2aResponse.date,
                "auc_start_date": props.auctionDetail.start_date,
                "auc_end_date": props.auctionDetail.end_date,
                "rfq_inquiry_no": props.mainR2aResponse.inquiry_no,
                "auction_inquiry_no": props.auctionDetail.number,
                "rfq_vendor_count": rfqVendorCount,
                "total_vendor": props.auctionDetail.no_of_vendors,
                "rfq_item_count": rfqItemsCount,
                "total_item": props.auctionDetail.no_of_item,
                "auction_name": props.auctionDetail.name,
                "auction_category": props.auctionDetail.auction_category,
                "auction_status": props.auctionDetail.status,
                "category_id": props.auctionDetail.category.id,
                "logged_in_user_type": userInfo.type,
                "sessionID": props.sessionID,
                "fromReact": '1',
                "selected_auction_rfq_id": props.auctionDetail.id
            }
            const formData = new FormData();

            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }

            const response = await axios.post(downloadExcelPDFR2aReport, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + props.sessionID
                },
                responseType: 'blob', // Expecting a binary response for file download
            });

            let fileExtension = '.xls';
            let fileMimeType = 'application/vnd.ms-excel';
            const file = new Blob([response.data], { type: fileMimeType });
            if (response.data) {
                setIsLoading(false);
            }
            saveAs(file, `RfqToAuctionReport_${formattedDate}${fileExtension}`);


        } catch (error) {
            console.error("Error exporting to Excel", error);
            setIsLoading(false);
        }
    };

    // Function to handle the PDF export click
    const handlePdfExport = async () => {
        setIsLoading(true);
        try {
            let data = {
                "action": "r2a_action",
                "action_code": "download_r2a_report_excel",
                "inquiry_id": props.selctedRFQInquiryId,
                "r2a_flag": props.auctionDetail.r2a_flag,
                "qcs_no": props.auctionDetail.qcs_no,
                "auction_rfq_id": props.auctionDetail.id,
                "pdf_excel_flag": "20",
                "product_name": props.auctionDetail.category_name,
                "inquiry_date": props.mainR2aResponse.date,
                "auc_start_date": props.auctionDetail.start_date,
                "auc_end_date": props.auctionDetail.end_date,
                "rfq_inquiry_no": props.mainR2aResponse.inquiry_no,
                "auction_inquiry_no": props.auctionDetail.number,
                "rfq_vendor_count": rfqVendorCount,
                "total_vendor": props.auctionDetail.no_of_vendors,
                "rfq_item_count": rfqItemsCount,
                "total_item": props.auctionDetail.no_of_item,
                "auction_name": props.auctionDetail.name,
                "auction_category": props.auctionDetail.auction_category,
                "auction_status": props.auctionDetail.status,
                "category_id": props.auctionDetail.category.id,
                "logged_in_user_type": userInfo.type,
                "sessionID": props.sessionID,
                "fromReact": '1',
                "selected_auction_rfq_id": props.auctionDetail.id
            }
            const formData = new FormData();

            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }

            const response = await axios.post(downloadExcelPDFR2aReport, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + props.sessionID
                },
                responseType: 'blob', // Expecting a binary response for file download
            });

            let fileExtension = '.pdf';
            let fileMimeType = 'application/pdf';
            const file = new Blob([response.data], { type: fileMimeType });
            if (response.data) {
                setIsLoading(false);
            }
            // Trigger the download with the appropriate file name and extension
            saveAs(file, `RfqToAuctionReport_${formattedDate}${fileExtension}`);
            // const response = await axios.post(downloadExcelPDFR2aReport);


        } catch (error) {
            setIsLoading(false);
            console.error("Error exporting to PDF", error);
        }
    };

    return (
        <>
            <Box display="flex" alignItems="center">
                <DialogTitle sx={{ m: 0, padding: "16px !important" }}>
                    R2A Reports
                </DialogTitle>
                <FormControl sx={{ width: 300, pl: { sm: 1, md: 4 }, pr: 1, "@media screen and (max-width:600px)": { width: "100%" } }} size="small" >
                    <Select value={props.selectedOption} onChange={props.handleChangeR2AReportDropDown} renderValue={(selected) => (selected ? selected.name : "")}>
                        {Array.isArray(props.r2aDataGet) && props.r2aDataGet.length > 0 ? (
                            props.r2aDataGet.map((option, index) => (
                                <MenuItem key={index} value={{ name: option.number, id: option.id }}>
                                    {option.number}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem value="" disabled>
                                No options available
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
                <Box sx={{ marginLeft: "auto" }} display="flex" gap={{ xs: "2px", sm: "4px", md: "8px" }} pr={1}>
                    {(props.auctionDetail?.rfq_stage == 60 || props.auctionDetail?.rfq_stage == 70) && (
                        <>
                            <Tooltip title="Excel Download">
                                <IconButton
                                    aria-label="EXCEL"
                                    size="small"
                                    onClick={handleExcelExport}
                                >
                                    <GoogleSheetsIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="PDF Download">
                                <IconButton
                                    aria-label="PDF"
                                    size="small"
                                    onClick={handlePdfExport}
                                >
                                    <GooglePdfIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                    <Tooltip title="Close">
                        <IconButton
                            aria-label="close"
                            onClick={props.handleCloseOpenR2A}
                            // sx={{ position: "absolute", right: 8, top: 13 }}
                            size="small"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default R2aDropDownDetailsHeader
