import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import UploadOfflineAttachment from "../../Dialogs/Upload/uploadOfflineAttachment";
import { red } from "@mui/material/colors";
import { exchangeRates, parentHost } from "../../../config/apiUrl";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import LaunchIcon from "@mui/icons-material/Launch";
import ExchangeRate from "../ExchangeRateDrawer/ExchangeRate";

const NegotiationDetailsSection = (props) => {
  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const [termsAndConditionsPopUp, setTermsAndConditionsPopUp] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date()); // State to hold the current date
  const [locData, setLocalData] = useState([]);
  const [newAttachmentValue, setNewAttachmentValue] = useState(null);

  const [state, setState] = useState({
    currentTemplate: "",
    currentFieldId: "",
    // initialize other state properties here as needed
  });

  const dispatch = useDispatch();
  const [currencyExchangeRates, setCurrencyExchangeRates] = useState({});
  const generateRandom = (min, max) => {
    const timestring = new Date().getTime();
    const numstring = Math.floor(Math.random() * (max - min + 1)) + min;
    return timestring + "_" + numstring;
  };
  const [negotiationDetails, setNegotiationDetails] = useState({
    Quotation: {
      negoValidity: null,
    },
    Requote: {
      negoValidity: null,
    },
  });

  const [openExchangeRate, setOpenExchangeRate] = useState(false);

  const condition = props?.lastTransactionTypeInThread;

  const { localData, foreignData, currencyOptions } = props;
  const [IframeVisible, setIframeVisible] = React.useState(false);

  const handleExchangeRate = (event) => {
    event.stopPropagation();
    setOpenExchangeRate(true);
  };

  const CloseExchangeRate = () => {
    setOpenExchangeRate(false);
  };

  useEffect(() => {
    if (localData?.length > 0) {
      if (vendorType === "Local") {
        setLocalData(localData);
      } else {
        setLocalData(foreignData);
      }
    }
  }, [localData, foreignData]);

  useEffect(() => {
    // Event listener to handle messages from the iframe
    const handleMessage = (event) => {
      if (event.origin !== parentHost) return;

      if (event.data.excelClose) {
        setIframeVisible(false);
      }
    };
    window.addEventListener("message", handleMessage);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    const exchangeRates = currencyOptions.reduce((acc, currency) => {
      acc[currency.Currency] = currency.ExchangeRate;
      return acc;
    }, {});

    // Update the state with the transformed object
    setCurrencyExchangeRates(exchangeRates);
  }, [currencyOptions]);

  const handleChangeExchangeRates = (data) => {
    setCurrencyExchangeRates(data);
  };

  const vendorType = props?.vendorType;

  useEffect(() => {
    if (currencyExchangeRates && vendorType != "Local") {
      props.currencyExchange(currencyExchangeRates);
    }
  }, [currencyExchangeRates, vendorType]);
  // useEffect hook to update the conditionData state when props change
  useEffect(() => {
    // Check the condition provided in props and extract the corresponding data
    let data;
    switch (condition) {
      case "Quotation":
        data = negotiationDetails.Quotation;
        break;
      case "Requote":
        data = negotiationDetails.Requote;
        break;
      default:
        data = {};
    }
    props?.dataFromDetailsSection(data);
  }, [
    condition,
    negotiationDetails,
    props?.dataFromDetailsSection,
    newAttachmentValue,
  ]);

  const queryParams = new URLSearchParams(window.location.search);

  const getFieldsByCondition = (condition) => {
    switch (condition) {
      case "Quotation":
        return [
          {
            id: "1",
            name: "negoValidity",
            label: "Negotiation Validity",
            type: "date",
            isRequired: true,
            isReadOnly: false,
            formId: "datepicker",
          },
        ];
      case "Requote":
        return [
          {
            id: "1",
            name: "negoValidity",
            label: "Negotiation Validity",
            type: "date",
            isRequired: true,
            isReadOnly: false,
            formId: "datepicker",
          },
        ];
      default:
        return [];
    }
  };
  let conditionFields = getFieldsByCondition(condition);

  const handleDateChange = (condition, fieldName) => (newVal) => {
    if (!newVal) {
      setNegotiationDetails({
        ...negotiationDetails,
        [condition]: {
          ...negotiationDetails[condition],
          [fieldName]: null,
        },
      });
    } else {
      setNegotiationDetails({
        ...negotiationDetails,
        [condition]: {
          ...negotiationDetails[condition],
          [fieldName]: newVal,
        },
      });
    }
  };

  const renderField = (condition, field) => {
    const value = negotiationDetails[condition][field.name];
    const { isReadOnly, isRequired } = field;
    if (field.type === "date") {
      if (
        field.name == "technicalBidValidityDate" &&
        (value == null || value == "Invalid Date")
      ) {
        return null;
      }
      return (
        <FormControl sx={{ width: "100%" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              key={field.id}
              sx={{ width: "100%" }}
              label={field.label}
              value={value ? new Date(value) : null}
              disablePast={field.name === "negoValidity"}
              onChange={handleDateChange(condition, field.name)}
              format="dd/MM/yyyy hh:mm a"
              error={
                field.name === "negoValidity" && Boolean(props.offlineDateError)
                // &&!Boolean(value)
              }
              slotProps={{
                textField: {
                  // helperText: MaxRfqValidity != '' && `Maximum RFQ Validity ${MaxRfqValidity} IST`,
                  required: isRequired,
                  readOnly: true,
                  error:
                    field.name === "negoValidity" &&
                    Boolean(props.offlineDateError) &&
                    !Boolean(value),
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={value}
                  error={
                    field.name === "negoValidity" &&
                    Boolean(props.offlineDateError) &&
                    !Boolean(value)
                  }
                />
              )}
              InputProps={{
                readOnly: isReadOnly,
              }}
              readOnly={isReadOnly}
            />
            {Boolean(props.offlineDateError) && !Boolean(value) && (
              <FormHelperText sx={{ color: red[500] }}>
                {field.name === "negoValidity" && props.offlineDateError}
              </FormHelperText>
            )}
          </LocalizationProvider>
        </FormControl>
      );
    }
  };

  useEffect(() => {
    const getExchangeRates = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const sessionID = queryParams.get("q");

      try {
        const URL = exchangeRates(props.inquiryId, props.vendorId);
        const response = await axios.get(URL, {
          headers: {
            Authorization: `Bearer ${sessionID}`,
            "Content-Type": "application/json",
          },
        });
        if (!Array.isArray(response.data)) {
          setCurrencyExchangeRates(response.data);
        }
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    if (!props.MassNego && vendorType !== "Local") {
      getExchangeRates();
    }
  }, [vendorType]);
  return (
    <>
      <Card
        variant="outlined"
        // className="template-card local_recepients_card"
        id="NegotiationDetails"
      >
        <CardHeader
          title={props.title}
          titleTypographyProps={{
            variant: "subtitle2",
            component: "h5",
            color: "grey.900",
            textTransform: "uppercase",
          }}
          sx={{
            minHeight: "50px",
            "&.MuiCardHeader-root": {
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { xs: "start", sm: "center" },
            },
            "& .MuiCardHeader-action": {
              marginRight: 0,
              marginTop: 0,
            },
          }}
          action={
            <>
              {props.isServiceRfq && (
                <Button
                  variant="text"
                  onClick={() => {
                    setIframeVisible(true);
                  }}
                  sx={{
                    textDecoration: "underline",
                    textTransform: "capitalize",
                    p: 0,
                    "&:hover": {
                      backgroundColor: "transparent", // Set background to transparent
                    },
                  }}
                >
                  {`View ${
                    props.AlternativeNames?.service_option || "Activity"
                  } Details`}
                </Button>
              )}
            </>
          }
        />

        <Divider sx={{ marginBottom: "0" }} />
        <CardContent>
          <Grid
            container
            spacing={2}
            className="local_recepients_card_content"
            id="quote_details_card"
          >
            {conditionFields.map((field) => {
              const renderedField = renderField(`${condition}`, field);
              return (
                renderedField && (
                  <Grid item key={field.id} xl={3} lg={4} md={4} sm={6} xs={12}>
                    {renderedField}
                  </Grid>
                )
              );
            })}
            {/* {conditionFields.map((field) => (
              <Grid item key={field.id} xl={4} lg={4} md={4} sm={6} xs={12}>
                {renderField(`${condition}`, field)}
              </Grid>
            ))} */}
            {(vendorType === "International" ||
              vendorType === "Local Importer") &&
              locData?.map((cell, i) => {
                const field = props?.getField(cell);
                if (
                  field &&
                  cell.VisibilityFlag === "Y" &&
                  cell.Identifier === "Currency"
                ) {
                  return (
                    <Grid item xl={3} lg={4} md={4} sm={6} xs={12} key={i}>
                      <FormControl
                        fullWidth
                        error={props.selectedCurrencyValidator}
                      >
                        {/* <InputLabel id="currency-label">Currency *</InputLabel> */}
                        <InputLabel id="currency-label">
                          {cell.FieldLabel +
                            (cell.InstructionText
                              ? ` (${cell.InstructionText})`
                              : "")}{" "}
                        </InputLabel>
                        <Select
                          labelId="currency-label"
                          onChange={(e) => {
                            props.handleCurrencyChange(e, cell.FieldId);
                          }}
                          value={props?.selectedCurrency}
                          // label="Currency"
                          label={
                            cell.FieldLabel +
                            (cell.InstructionText
                              ? ` (${cell.InstructionText})`
                              : "") +
                            " *"
                          }
                          defaultValue={
                            props.additionalInfo[cell.FieldId]?.value
                          }
                        >
                          <MenuItem value="">Select Currency</MenuItem>
                          {currencyOptions.map((currency) => (
                            <MenuItem
                              key={currency?.CurrencyID}
                              value={currency?.Currency}
                            >
                              {currency?.Currency}
                            </MenuItem>
                          ))}
                        </Select>
                        {props.selectedCurrencyValidator && (
                          <FormHelperText>
                            {props.selectedCurrencyErrorMessage}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  );
                }
              })}
            {(vendorType === "International" ||
              vendorType === "Local Importer") && (
              <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
                <FormControl fullWidth variant="outlined">
                  <OutlinedInput
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Exchange Rate"
                          onClick={handleExchangeRate}
                          edge="end"
                          color="primary"
                        >
                          <LaunchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    value="EXCHANGE RATE"
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <ExchangeRate
        CloseExchangeRate={CloseExchangeRate}
        openExchangeRate={openExchangeRate}
        currencyExchangeRates={currencyExchangeRates}
        handleChangeExchangeRates={handleChangeExchangeRates}
      />
      <div>
        <Dialog
          open={IframeVisible}
          onClose={() => {
            setIframeVisible(false);
          }}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth={true}
          maxWidth="false"
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              height: "100%",
            },
          }}
          fullScreen
        >
          {/* Iframe content */}
          <iframe
            id="iframePost"
            title="Your Iframe Title"
            src={`${parentHost}/cgi-bin/vendx/iframe_pp.cgi?action=80&TLGUID=${
              props.lastTransactionGuidInThread
            }&sessionID=${queryParams.get("q")}`}
            width="100%"
            height="100%"
            style={{ height: "100%", border: "none" }}
          ></iframe>
        </Dialog>
      </div>
    </>
  );
};

export default NegotiationDetailsSection;
