import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import commifyWithPrecision from '../../../common/utility';
import { useDispatch, useSelector } from "react-redux";
import exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
exporting(Highcharts); // Initialize the exporting module
ExportData(Highcharts);

const chartOptions = (chartDetails, auctionDetail, index, rfqVendorsArr, selectedSortOrder, internationalNumericFormat) => {

  if (Object.keys(chartDetails).length > 0) {
    const items = Array.isArray(auctionDetail?.items) ? auctionDetail?.items : [];
    let categories = [];
    let rfqRates = [];
    let auctionRates = [];
    let totalSavings = [];
    let percentage = [];

    const sortCombinedData = (combinedData) => {
      return combinedData.sort((a, b) => {
        // If totalSaving is not 0, sort by totalSaving first
        if (a.percentage != 0 && b.percentage != 0) {
          return selectedSortOrder == "descending" ? b.percentage - a.percentage : a.percentage - b.percentage;  // Sort by totalSaving descending
        }

        // If totalSaving is 0 for both or one of them, sort by auctionRate
        if (a.percentage == 0 && b.percentage == 0) {
          return selectedSortOrder == "descending" ? b.auctionRate - a.auctionRate : a.auctionRate - b.auctionRate;  // Sort by auctionRate descending
        }

        // If one has totalSaving and the other does not, prioritize the one with totalSaving
        return a.percentage != 0 ? -1 : 1;
      })
    };


    if (index == 0) {
      // Item Graph section
      const matchedItem = items?.find(item => item.id == chartDetails.id);
      if (matchedItem) {
        const combinedData = matchedItem.r2a_data.map(data => {
          const inquiryRate = data.inquiry_rate || 0;
          const auctionRate = data.auction_rate || Infinity;

          const totalSaving = inquiryRate > 0 && auctionRate < Infinity ? inquiryRate - auctionRate : 0;
          const percentage = inquiryRate > 0 && auctionRate < Infinity
            ? (((inquiryRate - auctionRate) * 100) / inquiryRate).toFixed(2)
            : 0;

          return {
            category:
              (data.vendor_name?.trim() ||
                (() => {
                  const matchedVendor = rfqVendorsArr.find(v => v.id == data.vendor_id);
                  return matchedVendor ? matchedVendor.name : '-';
                })()) +
              (data.ms_name
                ? ` (${data.ms_name})`
                : data.slab_range_name
                  ? ` (${data.slab_range_name})`
                  : ''),
            rfqRate: inquiryRate,
            auctionRate: auctionRate == Infinity ? 0 : auctionRate,
            totalSaving,
            percentage,
          };
        });

        const sortedData = sortCombinedData(combinedData);
        categories = sortedData.map(data => data.category);
        rfqRates = sortedData.map(data => data.rfqRate);
        auctionRates = sortedData.map(data => data.auctionRate);
        totalSavings = sortedData.map(data => data.totalSaving);
        percentage = sortedData.map(data => data.percentage);
      }

    } else {
      // Vendor chart section
      const vendorChartData = items.flatMap(item =>
        item.r2a_data
          .filter(vendor => vendor.vendor_id == chartDetails.id)
          .map(vendor => {
            const inquiryRate = parseFloat(vendor.inquiry_rate) || 0;
            const auctionRate = parseFloat(vendor.auction_rate) || Infinity;

            return {

              category:
                item.name + '<br/>' + ' [' + item.code + ']' + '<br/>' +
                (vendor.ms_name ? ` (${vendor.ms_name})` : vendor.slab_range_name ? ` (${vendor.slab_range_name})` : ''),
              rfqRate: inquiryRate,
              auctionRate: auctionRate == Infinity ? 0 : auctionRate,
              totalSaving: inquiryRate > 0 && auctionRate < Infinity ? inquiryRate - auctionRate : 0,
              percentage: inquiryRate > 0 && auctionRate < Infinity ? (((inquiryRate - auctionRate) * 100) / inquiryRate).toFixed(2) : 0,
            };
          })
      );

      const sortedData = sortCombinedData(vendorChartData);
      categories = sortedData.map(data => data.category);
      rfqRates = sortedData.map(data => data.rfqRate);
      auctionRates = sortedData.map(data => data.auctionRate);
      totalSavings = sortedData.map(data => data.totalSaving);
      percentage = sortedData.map(data => data.percentage);
    }

    return {
      credits: { enabled: false },
      chart: { type: 'column', height: 350 },
      title: {
        text: `${chartDetails.name}${chartDetails.code ? ` [${chartDetails.code}]` : ''}`,
        style: { fontSize: '14px', fontWeight: '400', color: '#000' },
      },
      xAxis: {
        title: {
          text: index == 0 ? 'Vendor Name' : 'Item Name', // Conditional text
          style: {
            display: 'none' // Hide the title on the chart display
          }
        },
        categories
      },
      yAxis: { title: { text: 'Rate' }, gridLineWidth: 0 },
      plotOptions: {
        column: {
          borderRadius: 3,
          pointPadding: 0.2,
          groupPadding: 0.1,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return commifyWithPrecision(this.y, internationalNumericFormat, false);
            },
          },
        },
      },
      tooltip: {
        formatter: function () {
          if (this.series.name == 'Total Saving') {
            const percentageValue = percentage[this.point.index] || 0;
            return `Total Saving: ${commifyWithPrecision(this.y, internationalNumericFormat, false)} (${commifyWithPrecision(percentageValue, internationalNumericFormat, false)}%)`;
          }
          return `${this.series.name}: ${commifyWithPrecision(this.y, internationalNumericFormat, false)}`;
        },
      },
      series: [
        { name: 'RFQ Rate', data: rfqRates, color: '#096bef' },
        { name: 'Auction Rate', data: auctionRates, color: '#544FC5' },
        { name: 'Total Saving', data: totalSavings, color: '#22C55E' }
      ],
      // exporting: {
      //   buttons: {
      //     contextButton: {
      //       menuItems: [
      //         'viewFullscreen', 'printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG', 'separator', 'downloadCSV', 'downloadXLS'
      //       ]
      //     }
      //   }
      // },
    };
  }
};

function ColumnChart(props) {
  const feature_permission_obj = useSelector((state) => state.permissionsReducer.featurePermission);
  const international_numeric_format = feature_permission_obj?.international_numeric_format?.feature_value

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions(props.details, props.auctionDetail, props.index, props.rfqVendorsArr, props.selectedSortOrder, international_numeric_format)}
      />
    </div>
  );
}

export default function HighChartsColumn({ chartName, auctionDetail, index, rfqVendorsArr, selectedSortOrder }) {
  return (
    <ColumnChart
      details={chartName}
      auctionDetail={auctionDetail}
      index={index}
      rfqVendorsArr={rfqVendorsArr}
      selectedSortOrder={selectedSortOrder}
    />
  );
}
