import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";
import { NegoFormStyle } from "./style";
import NegotiationForm from "./negotiationForm";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ConfirmAlert from "../../components/Dialogs/ConfirmAlert.js";
import successimage from "../../assets/vendxAdminPanelAsstes/assetsnew/rfq_submit.svg";
import HelpActionButtons from "../../components/QuotationForm/HelpButton";
import GoogleTranslate from "../../components/Utils/GoogleTranslate";

function NegotiationFormLI(props) {
  const [tabValue, setTabValue] = useState(1); // Track active tab
  const [localVendorsData, setLocalVendorsData] = useState(null); // Track local vendors data
  const [internationalVendorsData, setInternationalVendorsData] =
    useState(null); // Track international vendors data
  const [SubmitMultipleNegotiaion, setSubmitMultipleNegotiaion] =
    useState(false);
  const [TermsLocal, setTermsLocal] = useState(false);
  const [TermsIntl, setTermsIntl] = useState(false);
  const [IntlErr, setIntlErr] = useState(false);
  const [LocalErr, setLocalErr] = useState(false);
  const [IsDialogShownIntl, setIsDialogShownIntl] = useState(false);
  const [IsDialogShownLocal, setIsDialogShownLocal] = useState(false);
  const [SelectedCurrency, setSelectedCurrency] = useState("");
  const [combinedSubmit, setcombinedSubmit] = useState(false);
  const [IntlSucess, setIntlSucess] = useState(false);
  const [LocalSucess, setLocalSucess] = useState(false);
  const [IntlMsg, setIntlMsg] = useState("");
  const [LocalMsg, setLocalMsg] = useState("");
  const [FilterData, setFilterData] = useState({});
  const queryParams = new URLSearchParams(window.location.search);
  const localVendors = queryParams.get("l_vendor_ids");
  const internationalVendors = queryParams.get("i_vendor_ids");

  const vendorIds = useMemo(() => localVendors, [localVendors]); // Memoize vendorIds

  const handleTabChange = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  // Only fetch data once for each vendor type
  useEffect(() => {
    if (localVendors && !localVendorsData) {
      setLocalVendorsData(localVendors); // Simulate fetching data for local vendors
    }
    if (internationalVendors && !internationalVendorsData) {
      setInternationalVendorsData(internationalVendors); // Simulate fetching data for international vendors
    }
  }, [
    localVendors,
    internationalVendors,
    localVendorsData,
    internationalVendorsData,
  ]);
  const termsConditionLocal = (tNC) => {
    setTermsLocal(tNC);
  };
  const termsConditionIntl = (tNC) => {
    setTermsIntl(tNC);
  };

  return (
    <Fragment>
      {NegoFormStyle}
      <main
        sx={{
          width: "100%",
          maxWidth: "100%",
          padding: 0,
        }}
      >
        <Box className="NegotiationTabs-Topbar">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label=""
            sx={{
              ".MuiButtonBase-root": {
                color: "var(--secondary-600)",
                minHeight: "48px",
              },
            }}
          >
            <Tab
              value={1}
              icon={LocalErr && <ErrorOutlineIcon color="error" />}
              iconPosition="start" // Positions the icon before the label
              label="Local Vendors"
            />
            <Tab
              value={2}
              icon={IntlErr && <ErrorOutlineIcon color="error" />}
              iconPosition="start" // Positions the icon before the label
              label="International Vendors"
            />
          </Tabs>

          <Box className="GoogleTranslate-Box">
            <GoogleTranslate />
          </Box>
        </Box>
        <Box className="Negotiation-Body">
          {/* Keep both forms rendered but only show the active one */}
          <Box style={{ display: tabValue === 1 ? "block" : "none" }}>
            <NegotiationForm
              VendorIDS={localVendors}
              multipleVendors={true}
              SubmitMultipleNegotiaion={SubmitMultipleNegotiaion}
              termsConditionChecked={termsConditionLocal}
              resetFlag={(flag) => {
                setSubmitMultipleNegotiaion(flag);
              }}
              errorFlag={(err) => {
                setLocalErr(err);
              }}
              openAlert={() => {
                setIsDialogShownLocal(true);
              }}
              resetCombinedSubmitFlag={(flag) => {
                setcombinedSubmit(flag);
              }}
              combinedSubmit={!LocalSucess && combinedSubmit}
              sucessMessage={(msg) => {
                setLocalMsg(msg);
              }}
              sucessFlg={() => {
                setLocalSucess(true);
              }}
              submitflg="Local"
              tabValue={1}
              filterData = {(data) => {
                setFilterData(data);
              }}
            />
          </Box>

          <Box style={{ display: tabValue === 2 ? "block" : "none" }}>
            <NegotiationForm
              VendorIDS={internationalVendors}
              multipleVendors={true}
              SubmitMultipleNegotiaion={SubmitMultipleNegotiaion}
              termsConditionChecked={termsConditionIntl}
              resetFlag={(flag) => {
                setSubmitMultipleNegotiaion(flag);
              }}
              errorFlag={(err) => {
                setIntlErr(err);
              }}
              openAlert={() => {
                setIsDialogShownIntl(true);
              }}
              selectedCurrency={(cur) => {
                setSelectedCurrency(cur);
              }}
              resetCombinedSubmitFlag={(flag) => {
                setcombinedSubmit(flag);
              }}
              combinedSubmit={!IntlSucess && combinedSubmit}
              sucessMessage={(msg) => {
                setIntlMsg(msg);
              }}
              sucessFlg={() => {
                setIntlSucess(true);
              }}
              submitflg="International"
              tabValue={2}
              filterData = {(data) => {
                setFilterData(data);
              }}
            />
          </Box>
          <Box p={2} pt={0}>
            {tabValue === 1 ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setTabValue(2)}
              >
                Next
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                className="Upload_file_btn"
                onClick={() => setSubmitMultipleNegotiaion(true)}
              >
                Submit Negotiation
              </Button>
            )}
          </Box>
        </Box>
        <HelpActionButtons />
      </main>
      <ConfirmAlert
        open={IsDialogShownIntl && IsDialogShownLocal}
        handleClose={() => {
          setIsDialogShownIntl(false);
          setIsDialogShownLocal(false);
        }}
        handleSubmit={() => {
          setcombinedSubmit(true);
          setIsDialogShownIntl(false);
          setIsDialogShownLocal(false);
        }}
        title={"Submission"}
        msg={
          (FilterData.sealed_rfq_post_nego) ? 
          (
            `Once negotiation is submitted, the RFQ will be sealed. Review all details carefully. This negotiation with an international vendor includes ${SelectedCurrency}. 
            Are you sure you want to proceed?`
          ) :
          (
            `A Negotiation with international vendor will be submitted with ${SelectedCurrency}.
            Are you sure want to submit this Negotiation?`
          ) 
      }
      />
      <Dialog
        scroll="body"
        maxWidth={"sm"}
        open={LocalSucess && IntlSucess}
        aria-labelledby="responsive-dialog-title"
        sx={(theme) => ({
          "&.MuiDialog-root .MuiPaper-root": {
            width: "500px",
            boxSizing: "border-box",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          },
        })}
      >
        <DialogContent>
          <DialogContentText>
            <div className="linkcategorydialog_main">
              <Grid container>
                <Grid
                  item
                  lg={12}
                  xl={12}
                  md={12}
                  sm={12}
                  className="text-center"
                >
                  <img src={successimage} alt="Category Link Icon" />
                  <>
                    <h3 className="success_title">Successfully</h3>
                    <p className="success_subtitle">
                      {LocalMsg}
                      <br />
                      {IntlMsg}
                    </p>
                  </>
                </Grid>
              </Grid>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            // autoFocus
            onClick={(event) => {
              setIntlSucess(false);
              setLocalSucess(false);
              const queryParams = new URLSearchParams(window.location.search);

              if (queryParams.get("supplier")) {
                window.top.location.reload();
              }
              window.parent.postMessage("Quotation_close_iframe", "*");
              event.preventDefault();
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
export default NegotiationFormLI;
