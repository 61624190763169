import React, { useEffect, useState } from "react";
import {
    Alert,
    Avatar,
    Backdrop,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Snackbar,
    Switch,
    Tooltip,
    Typography,
} from "@mui/material";
import { ResendMailDrawer } from "./ResendMailStyle";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { getVendorsDetails, reSendMailUriRfqViewForm } from "../../../config/apiUrl";

function stringAvatar(name) {
    const initials = name ? name.split(" ").map((n) => n[0]).join("") : "NA";
    return {
        sx: {
            bgcolor: "var(--primary-500)",
            fontSize: "10px",
            width: "24px",
            height: "24px",
        },
        children: initials,
    };
}

export default function ResendQuotationMail(props) {

    const [vendorDetails, setVendorDetails] = useState(""); // State to store the API response
    const [checked, setChecked] = useState([]);
    const [switchState, setSwitchState] = useState(false); // Initialize switch state to false
    const [loading, setLoading] = useState(false); // State for loader
    const [snackbar, setSnackbar] = useState(false); // State for loader
    const [modalOpen, setModalOpen] = useState(false); // State to control modal visibility
    const [modalContent, setModalContent] = useState(""); // State to store modal HTML content
    const [isSending, setIsSending] = useState(false); // New state to manage button disable

    const { rfqJsonData, buyerInfo, userInfo } = props;
    const vendor = rfqJsonData?.vendors?.[0];
    const isAllChecked = checked?.length === vendorDetails?.length;
    const isIndeterminate = checked?.length > 0 && checked?.length < vendorDetails?.length;

    // Toggle individual checkbox
    const handleToggle = (vendorId) => () => {
        const currentIndex = checked.indexOf(vendorId);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(vendorId);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    // Toggle select all checkboxes
    const handleToggleAll = () => {
        if (checked?.length === vendorDetails?.length) {
            setChecked([]); // Uncheck all if everything is checked
        } else {
            setChecked(vendorDetails.map((vendor) => vendor.id)); // Check all if not everything is checked
        }
    };

    const handleSwitchChange = (event) => {
        setSwitchState(event.target.checked); // Update switch state
    };

    const handleCloseSnackbar = () => {
        setSnackbar(false);
        handleCloseModal();
    };

    const reSendMail = async () => {
        if (isSending) return; // Prevent multiple clicks
        setIsSending(true); // Disable the button
        setLoading(true); // Show loader
        const text = props.rfqJsonData.ref_no;
        const rfqNoMatch = text.match(/(?<=RFQ-)\d+/);

        try {
            // Extract session ID from the URL
            const queryParams = new URLSearchParams(window.location.search);
            const sessionID = queryParams.get("q");
            if (!sessionID) {
                console.error("Session ID is missing. Cannot proceed with the sendMail operation.");
                return;
            }

            // Prepare payload for the request
            const payload = new URLSearchParams();

            // if (checked.length === vendorDetails?.length) {
            //     payload.append("SelectedVendorIds", "All");
            // } else {
            //     checked.forEach((vendorId) => {
            //         const vendor = vendorDetails.find((v) => v.id === vendorId);
            //         if (vendor) {
            //             payload.append("SelectedVendorIds", `${vendor.id}###${vendor.guid}`);
            //         }
            //     });
            // }


            // if (checked.length === vendorDetails?.length) {
            //     payload.append("SelectedVendorIds", "All");
            // } else {
            //     const selectedVendorIds = checked
            //         .map((vendorId) => {
            //             const vendor = vendorDetails.find((v) => v.id === vendorId);
            //             return vendor ? `${vendor.id}###${vendor.guid}` : null;
            //         })
            //         .filter(Boolean) // Remove null/undefined entries
            //         .join(","); // Join with commas

            //     payload.append("SelectedVendorIds", selectedVendorIds);
            // }

            const selectedVendorIds = vendorDetails
                .filter((vendor) => checked.includes(vendor.id)) // Include only selected vendors
                .map((vendor) => `${vendor.id}###${vendor.guid}`) // Map to the required format
                .join(","); // Join with commas

            payload.append("SelectedVendorIds", selectedVendorIds);


            payload.append("q", sessionID);
            payload.append("sessionID", sessionID);
            // payload.append("NewUISendMail", 1);
            payload.append("productid", rfqJsonData.categories?.[0].id);
            payload.append("master_type", "category");
            payload.append("prevtranguid", vendor?.transaction_thread?.[0]?.guid);
            payload.append("resend_from_qplus", "");
            payload.append("qcs_plus", 1);
            payload.append("ProductName", rfqJsonData.categories?.[0].name);
            payload.append("TranType", vendor?.transaction_thread?.[0]?.type);
            payload.append("TranTypeGuid", vendor?.transaction_thread?.[0]?.TranTypeGUID);
            payload.append("TranStatus", vendor?.transaction_thread?.[0]?.type);
            payload.append("ReverseAuction", "n");
            payload.append("SendSMSFlag", switchState ? "Y" : "N");
            payload.append("vendx_action", "resend_automail");
            payload.append("buyerid", buyerInfo?.id);
            payload.append("LinkedBusinessGrID", userInfo?.company_id);
            payload.append("RfqNo", rfqNoMatch);

            // Make the POST request
            const response = await axios.post(reSendMailUriRfqViewForm, payload, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `Bearer ${sessionID}`,
                },
            });
            if (response.status === 200) {
                setSnackbar(true);
                handleCloseModal();
            }
        } catch (error) {
            console.error("Error sending mail:", error.response?.data || error.message);
        } finally {
            setLoading(false); // Hide loader
            setIsSending(false); // Re-enable the button
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setModalContent("");
        setChecked([]);
        setSwitchState(false);
        props.CloseResendMailDrawer();
    };

    useEffect(() => {
        const fetchVendorDetails = async () => {
            try {
                const response = await axios.get(getVendorsDetails(props.inquiryId));
                setVendorDetails(response.data.vendors);
            } catch (error) {
                console.error("Error fetching vendor details:", error);
            }
        };
        // Only fetch vendor details if inquiryId is not empty
        if (props.inquiryId) {
            fetchVendorDetails();
        }
    }, [props.inquiryId]); // Add inquiryId to the dependency array

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading} // Show backdrop when loading is true
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ResendMailDrawer
                anchor="right"
                open={props.openResendMailDrawer}
                onClose={handleCloseModal}
                sx={{ zIndex: "1311" }}
                className="SidebarDrawer"
            >
                <Card>
                    <CardHeader
                        action={
                            <Tooltip title="Close">
                                <IconButton
                                    onClick={handleCloseModal}
                                    aria-label="close"
                                    size="small"
                                    sx={{ color: "var(--grey-500)" }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        title={
                            <Typography
                                variant="subtitle1"
                                fontWeight={500}
                                component="h6"
                                color={"secondary.main"}
                                lineHeight={"normal"}
                            >
                                Resend Quotation Mail
                            </Typography>
                        }
                        subheader={
                            <React.Fragment>
                                <Typography
                                    variant="body2"
                                    color={"text.primary"}
                                    component="span"
                                    whiteSpace={"nowrap"}
                                >
                                    RFQ No:
                                </Typography>
                                <Tooltip title={props?.rfqJsonData?.ref_no} placement="bottom-start">
                                    <Typography
                                        variant="body2"
                                        fontWeight={500}
                                        color={"text.primary"}
                                        component="span"
                                        noWrap
                                    >
                                        {props?.rfqJsonData?.ref_no}
                                    </Typography>
                                </Tooltip>
                            </React.Fragment>
                        }
                        sx={{
                            pt: 1,
                            pb: 1,
                            "& .MuiCardHeader-action": {
                                alignSelf: "center",
                            },
                            "& .MuiCardHeader-content": {
                                width: "calc(100% - 75px)",
                            },
                            "& .MuiCardHeader-subheader": {
                                display: "flex",
                                gap: "4px",
                            },
                            "& .MuiCardHeader-avatar": {
                                mr: 1,
                            },
                        }}
                    />
                    <Divider />
                    <CardContent>
                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={"8px"} p={2}>
                            <Box>
                                <Typography variant="body2" color={"grey.900"} lineHeight={"normal"}>
                                    Send SMS
                                </Typography>
                                <Typography variant="caption" color={"grey.500"}>
                                    Default Filter Preferences would be applied
                                </Typography>
                            </Box>
                            <Switch checked={switchState} onChange={handleSwitchChange} />
                        </Box>
                        <Divider />
                        <Box>
                            <List sx={{ pt: 0 }}>
                                {/* Select All Checkbox */}
                                <ListItem
                                    key="select-all"
                                    sx={{
                                        height: "30px",
                                        background: "var(--grey-50)",
                                        borderBottom: "1px solid var(--bluegrey-500)",
                                        overflow: "hidden",
                                        position: "sticky",
                                        top: 0,
                                        zIndex: 11,
                                    }}
                                    disablePadding
                                >
                                    <ListItemButton role={undefined} onClick={handleToggleAll} dense>
                                        <ListItemIcon sx={{ minWidth: 26 }}>
                                            <Checkbox
                                                edge="start"
                                                checked={isAllChecked}
                                                indeterminate={isIndeterminate}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ "aria-labelledby": "checkbox-list-label-select-all" }}
                                                size="small"
                                            />
                                        </ListItemIcon>
                                        <ListItemText id="checkbox-list-label-select-all" primary="Vendor(s)" />
                                    </ListItemButton>
                                </ListItem>

                                {/* Individual Checkboxes */}
                                {vendorDetails?.length > 0 ? (
                                    vendorDetails.map((vendor) => {
                                        const labelId = `checkbox-list-label-${vendor.id}`;
                                        return (
                                            <ListItem key={vendor.id} disablePadding>
                                                <ListItemButton role={undefined} onClick={handleToggle(vendor.id)} dense>
                                                    <ListItemIcon sx={{ minWidth: 26 }}>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={checked.indexOf(vendor.id) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ "aria-labelledby": labelId }}
                                                            size="small"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemAvatar sx={{ minWidth: 32 }}>
                                                        <Avatar {...stringAvatar(vendor.name || "Unknown")} />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        id={labelId}
                                                        primary={vendor.name}
                                                        secondary={vendor.code || ""}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })
                                ) : (
                                    <div>No vendors available</div>
                                )}

                            </List>
                        </Box>
                    </CardContent>
                    <Divider />
                    <CardActions sx={{ p: 2, justifyContent: "flex-end" }}>
                        <Button variant="contained" color="primary" onClick={() => reSendMail()} disabled={isSending || loading || checked?.length <= 0}>
                            {loading ? "Resending..." : "Resend"}
                        </Button>
                    </CardActions>
                </Card>
            </ResendMailDrawer>
            {/* Modal to display response content */}
            <Dialog
                open={modalOpen}
                onClose={handleCloseModal}
                maxWidth="md"
            >
                <DialogTitle>
                    Mail Sent Successfully
                    {/* <IconButton
                            aria-label="close"
                            onClick={handleCloseModal}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton> */}
                </DialogTitle>
                <DialogContent>
                    <div dangerouslySetInnerHTML={{ __html: modalContent }} />
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Box display="flex" justifyContent="center" width="100%">
                        <Button onClick={handleCloseModal} variant="contained" color="primary">
                            OK
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

            {/*Snack bar*/}

            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={3000}
                open={snackbar}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="success"
                    variant="filled"
                    sx={{
                        color: "#fff", // White font
                        backgroundColor: "#4caf50", // Default success green
                    }}
                >
                    Resend Mail sent successfully
                </Alert>
            </Snackbar>
        </>
    );
}