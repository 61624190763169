import React, { Fragment, memo, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Alert,
  Avatar,
  AvatarGroup,
  Backdrop,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { NegoFormStyle } from "./style";
import { QuotationThread } from "../../components/SvgIcon/SvgIcon";
import { RquoteLimitMsgIcon } from "../../components/SvgIcon/SvgIcon";
import DataTransferIcon from "../../assets/images/data-transfer-icon.png";
import ViewDetailsDrawer from "../../components/QuotationForm/ViewDetailsDrawer/ViewDetailsDrawer";
import QuotationThreadDrawer from "../../components/QuotationForm/QuotationThreadDrawer/QuotationThreadDrawer";
import NegotiationTemplate from "../../components/NegotiationForm/NegotiationTemplate/NegotiationTemplate";
import NegotiationTemplateView from "../../components/NegotiationForm/NegotiationTemplate/NegotiationTemplateView";
import PersonIcon from "@mui/icons-material/PersonOutlined";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import ConstructionIcon from "@mui/icons-material/ConstructionOutlined";
import NavigationIcon from "@mui/icons-material/RoomOutlined";
import ExpandLess from "@mui/icons-material/ArrowDropDown";
import ExpandMore from "@mui/icons-material/ArrowDropUp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AccountCircleIcon from "@mui/icons-material/AccountCircleOutlined";
import WorkOutline from "@mui/icons-material/WorkOutline";
import Public from "@mui/icons-material/Public";
import ErrorIcon from "@mui/icons-material/ErrorOutlineOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";

import axios from "axios";
import {
  account_all_users,
  devTemplateURi,
  feature_permission,
  getAllowMultipleSolutionOptionLabel,
  getBuyers,
  getDestinations,
  getDropDownCurrencyList,
  getRegretJustification,
  getTermsAndConditionsDetails,
  getTransactionDetails,
  getTransactionDetailsView,
  getTransactionVendors,
  get_Inquiries_Vendor_data,
  massValidation,
  quotationValidation,
  sendMailUri,
  templateURi,
  vendx_connect,
} from "../../config/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo, setVendorInfo } from "../../redux/actions/setUserInfo";
import MailIcon from "@mui/icons-material/Mail";
import dayjs from "dayjs";
import { CustomAlert, CustomSnackbar } from "../../components/Snackbar/style";
import GoogleTranslate from "../../components/Utils/GoogleTranslate";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DescriptionIcon from "@mui/icons-material/Description";
import { ResendMailIcon } from "../../components/SvgIcon/SvgIcon";
import SendMail from "../../utils/Transactions/SendMail";
import ResendMail from "../../utils/Transactions/ResendMail";
import CloseIcon from "@mui/icons-material/Close";
import { OPEN_SNACKBAR } from "../../redux/constants/type";
import { openSnackbarfunc } from "../../redux/actions/snackbarAction";
import ItemWiseVendor from "../../components/NegotiationForm/itemWiseVendor/itemWiseVendor";

const PageToolbar = styled("div")(({ theme, ...props }) => ({
  background: "#ffffff",
  position: "sticky",
  // top: "50px",
  top: props?.multipleVendors ? "50px" : "0px",
  width: "100%",
  minHeight: "54px",
  padding: "10px 20px 0 20px",
  zIndex: "99",
  [theme.breakpoints.down("md")]: {
    position: "relative",
    top: "0",
    padding: "10px 20px",
  },
}));

const PopoverDetailList = styled(List)({
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 8,
    marginTop: 0,
  },
  "& .MuiTypography-root": {
    fontSize: "12px",
    color: "var(--secondary-600)",
  },
  "& .MuiListItemText-primary": {
    color: "var(--grey-500)",
  },
  "& .MuiListItem-root": {
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: "start",
  },
  "& .MuiListItemText-root": {
    marginTop: "0",
  },
  "& .MuiListItemIcon-root svg": {
    color: "var(--secondary-600)",
  },
});

const getConfigAxios = (sessionId) => ({
  headers: { Authorization: `Bearer ${sessionId}` },
});

function NegotiationForm(props) {
  const queryParams = new URLSearchParams(window.location.search);
  const LIVendorIds = props.VendorIDS
    ? props.VendorIDS
    : queryParams.get("l_vendor_ids") || queryParams.get("i_vendor_ids");

  const dispatch = useDispatch();
  const [gst, setGst] = useState("10");
  const [referenceNo, setReferenceNo] = useState("");
  const [rfqDate, setRfqDate] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [negotiationDataLoading, setNegotiationDataLoading] = useState(true);
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryCode, setCategoryCode] = useState("");
  const [vendorType, setVendorType] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [inquiryId, setInquiryId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [mainValidationError, setMainValidationError] = useState("");
  const [itemBoxId, setItemBoxId] = useState("");
  const [itemSpecific, setItemSpecific] = useState("");
  const [newSessionID, setNewSessionID] = useState("");
  const [shipmentMark, setShipmentMark] = useState("");
  const [multipleSolutionFlag, setMultipleSolutionFlag] = useState(false);
  const [featurePermissionDetails, setFeaturePermissionDetails] = useState("");
  const [transactionApiResponse, setTransactionApiResponse] = useState("");
  const [tabValue, setTabValue] = useState("one"); //Tabs Script
  const [buyerInfo, setBuyerInfo] = useState([]);
  const [inquiryItemsId, setInquiryItemsId] = useState([]);
  const [MassItemIds, setMassItemIds] = useState([]);
  const [TransactionFilteredItems, setTransactionFilteredItems] = useState({});
  const [validityDate, setValidityDate] = useState(null);
  const [currencyDetails, setCurrencyDetails] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [showSlabRangeTable, setShowSlabRangeTable] = useState(false); // Start Slab Range Show Hide Script
  const [JsonTemplate, setJsonTemplate] = useState([]);
  const [negotiationJsonData, setNegotiationJsonData] = useState({});
  const [quotedCurrency, setQuotedCurrency] = useState("");
  const [items, setItems] = useState({});
  const [destinationDetails, setDestinationDetails] = useState({});
  const [fileSpecificationData, setFileSpecificationData] = useState({});
  const [filteredSelectedNotData, setFilteredSelectedNotData] = useState({});
  const [termsAndConditionsDetails, setTermsAndConditionsDetails] = useState(
    {}
  );
  const [lastTransactionData, setLastTransactionData] = useState(null);
  const [lastNegotiationData, setLastNegotiationData] = useState(null);
  const [lastTransactionInThread, setLastTransactionInThread] = useState({});
  const [lastTransactionInThreadCommon, setLastTransactionInThreadCommon] =
    useState({});
  const [sessionId, setSessionId] = useState(null);
  const [configAxios, setConfigAxios] = useState(null);
  const [lastTransactionTypeInThread, setLastTransactionTypeInThread] =
    useState("");
  const [lastTransactionGuidInThread, setLastTransactionGuidInThread] =
    useState("");
  const [state, setState] = useState({ clickfunction: null });
  const [JsonTemplateLoading, setJsonTemplateLoading] = useState(false);
  const [viewDetails, setViewDetails] = useState({
    // Start ViewDetails Drawer script
    right: false,
  });

  const [transactionThread, setTransactionThread] = useState({
    right: false,
  });

  const [viewTxn, setviewTxn] = useState(false);
  const [CurrentTransaction, setCurrentTransaction] = useState({});
  const [inquiryTransaction, setInquiryTransaction] = useState({});
  const [IsLastTxn, setIsLastTxn] = useState(false);
  const [multiplePlantsFlag, setMultiplePlantsFlag] = useState(false);
  const [slabFlag, setSlabFlag] = useState(false);
  const [AccountUsers, setAccountUsers] = useState({});
  const [PrevTransaction, setPrevTransaction] = useState({});
  const [industryDetails, setIndustryDetails] = useState([]);
  const [PermissionInquiry, setPermissionInquiry] = useState({});
  const setClick = (click) => {
    setState({
      clickfunction: click,
    });
  };
  const userInfo = useSelector((state) => state.userInfoReducer.userInfo);
  const accountModulePermission = useSelector(
    (state) => state.permissionsReducer.accountModulePermission
  );
  const featurePermission = useSelector(
    (state) => state.permissionsReducer.featurePermission
  );
  const companyInfo = useSelector(
    (state) => state.companyInfoReducer.companyInfo
  );
  const [headerLabels, setHeaderLabels] = useState([]);
  const [FetchedGuids, setFetchedGuids] = useState([]);
  const [copied, setCopied] = useState(false);
  const [fetchedTxn, setFetchedTxn] = useState({});
  const [transactionItemSequence, setTransactionItemSequence] = useState([]);
  const [ServiceFileName, setServiceFileName] = useState(null);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const [lastTxnInquiryState, setLastTxnInquiryState] = useState(false);
  const [AlternativeNames, setAlternativeNames] = useState({});
  const [lastTxn, setlastTxn] = useState({});
  const [MassNego, setMassNego] = useState(false);
  const [Vendors, setVendors] = useState([]);
  const [AllInquiryVendors, setAllInquiryVendors] = useState({});
  const [isToggled, setIsToggled] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSendMailDrawer, setOpenSendMailDrawer] = useState(false);
  const [openResendMailDrawer, setOpenResendMailDrawer] = useState(false);
  const [regretJustificationMessage, setRegretJustificationMessage] =
    useState("");
  const [openRegretDialogBox, setOpenRegretDialogBox] = useState(false);
  const [OfflineRegretFlag, setOfflineRegretFlag] = useState(false);
  const [TranTypeGUID, setTranTypeGUID] = useState("");
  const [ResendMailVendorDetails, setResendMailVendorDetails] = useState([]);
  const [OpenItemwiseDilogue, setOpenItemwiseDilogue] = useState(false);
  const [ViewJustification, setViewJustification] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [renderedVendorsData, setRenderedVendorsData] = useState(null);

  // Update only if localVendorsData is valid
  useEffect(() => {
    if (props.localVendorsData) {
      setRenderedVendorsData(props.localVendorsData);
    }
  }, [props.localVendorsData]);

  // Memoize the data to prevent unnecessary re-computations
  const displayedData = useMemo(() => {
    return renderedVendorsData || []; // Use previous data if no new data is provided
  }, [renderedVendorsData]);
  // useEffect(() => {
  //   if (negotiationDataLoading) {
  //     props.localData(true);
  //   }
  // }, [negotiationDataLoading]);

  const handleRegretMessage = (value) => {
    setRegretJustificationMessage(value);
  };

  const handleCloseDialogue = () => {
    setOpenItemwiseDilogue(false);
  };

  const handleSendMailDrawer = (event) => {
    event.stopPropagation();
    setOpenSendMailDrawer(true);
    setAnchorEl(null);
  };

  const CloseSendMailDrawer = () => {
    setOpenSendMailDrawer(false);
  };

  const CloseResendMailDrawer = () => {
    setOpenResendMailDrawer(false);
  };

  const handleResendMailDrawer = (event) => {
    event.stopPropagation();
    setOpenResendMailDrawer(true);
    setAnchorEl(null);
  };

  const getRegret = () => {
    axios
      .get(getRegretJustification(queryParams.get("TLGUID")), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionId}`,
        },
      })
      .then((response) => {
        if (Object.keys(response.data).length > 0) {
          setRegretJustificationMessage(response.data.Justification);
          if (response.data.tran_status == 20) {
            setOfflineRegretFlag(true);
          }
        } else {
          setRegretJustificationMessage("");
        }
      })
      .catch((error) => {
        setRegretJustificationMessage("");
        setOfflineRegretFlag(false);
        console.error("There was a problem with your axios operation:", error);
      });
  };
  const handleOpenRegretBox = () => {
    getRegret();
    setOpenRegretDialogBox(true);
    setAnchorEl(null);
  };
  const handleCloseRegretBox = () => {
    setOpenRegretDialogBox(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchSessionId = async () => {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const sessionId = queryParams.get("q");
        // Simulating asynchronous operation, replace with actual async fetch if needed
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setSessionId(sessionId);
        setNewSessionID(sessionId);
      } catch (error) {
        console.error("Error fetching sessionId:", error);
      }
    };

    fetchSessionId();
  }, []);

  useEffect(() => {
    if (sessionId) {
      const queryParams = new URLSearchParams(window.location.search);

      setConfigAxios(getConfigAxios(sessionId));

      if (queryParams.get("supplier")) {
        dispatch(setVendorInfo(sessionId));
      } else {
        dispatch(setUserInfo(sessionId));
      }
      fetchAllUsersDetails();
    }
  }, [sessionId]);

  const getTxnVendors = () => {
    axios
      .get(getTransactionVendors(inquiryId, TranTypeGUID), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionId}`,
        },
      })
      .then((response) => {
        setResendMailVendorDetails(response.data);
      })
      .catch((error) => {
        console.error("There was a problem with your axios operation:", error);
      });
  };
  useEffect(() => {
    if (viewTxn && TranTypeGUID && sessionId) {
      getTxnVendors();
    }
  }, [viewTxn, TranTypeGUID, sessionId]);
  useEffect(() => {
    if (LIVendorIds?.includes(",")) {
      setMassNego(true);
    }
  }, []);

  useEffect(() => {
    if (FetchedGuids.length > 0) {
      FetchedGuids.map((guid) => {
        fetchTransactionByGuid(guid);
      });
    }
  }, [FetchedGuids]);

  const fetchTransactionByGuid = async (guid) => {
    const url = getTransactionDetailsView(inquiryId, vendorId, guid);
    const response = await axios.get(url, configAxios);
    const transaction_thread = [...response.data.vendors[0].transaction_thread];
    const indexOfTemplate = transaction_thread
      .reverse()
      .findIndex((obj) => obj.hasOwnProperty("template"));
    let id;
    if (transaction_thread[indexOfTemplate].type === "Inquiry") {
      id = 1;
    } else if (transaction_thread[indexOfTemplate].type === "Quotation") {
      id = 3;
    } else if (transaction_thread[indexOfTemplate].type === "Requote") {
      id = 5;
    } else {
      id = 4;
    }
    setFetchedTxn((prevData) => ({
      ...prevData,
      [id]: transaction_thread[indexOfTemplate].template,
    }));
  };
  const fetchAllUsersDetails = async () => {
    try {
      const userResponse = await axios.get(account_all_users, configAxios);
      if (userResponse.status === 200) {
        const accountUserData = userResponse.data.users;
        const userIdMap = {};
        accountUserData.forEach((user) => {
          userIdMap[user.UserID] = user.UserName;
        });

        setAccountUsers(userIdMap);
      } else {
        throw new Error("Network response for user data was not ok");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const gstHandleChange = (event) => {
    setGst(event.target.value);
  };

  const handleChangeTabPosition = (event, newValue) => {
    setTabValue(newValue);
  };

  //End Tabs Script
  const ViewDetailsOpen = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setViewDetails({ ...viewDetails, [anchor]: open });
  };
  // End ViewDetails Drawer script

  // End Total Landed Cost For RFQ Drawer script
  const openCommunication = () => {
    const form = document.createElement("form");
    form.target = "_blank";
    form.action = vendx_connect;
    form.method = "post";

    const hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    hiddenInput.name = "inquiry_id";
    hiddenInput.value = inquiryId;
    const queryParams = new URLSearchParams(window.location.search);
    // const sessionID = queryParams.get("q");

    const sessionid = document.createElement("input");
    sessionid.type = "hidden";
    sessionid.name = "sessionID";
    sessionid.value = sessionId;

    const hiddenInputAction = document.createElement("input");
    hiddenInputAction.type = "hidden";
    hiddenInputAction.name = "action";
    hiddenInputAction.value = "display_communication_chain";
    form.appendChild(hiddenInput);
    form.appendChild(sessionid);

    form.appendChild(hiddenInputAction);
    document.body.appendChild(form);
    form.submit();
  };

  const TransactionThreadOpen = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setTransactionThread({ ...transactionThread, [anchor]: open });
  };

  const clickSlabTable = () => {
    setShowSlabRangeTable(!showSlabRangeTable);
  };
  // End Slab Range Show Hide Script

  // scrolling down script
  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 155; // Offset to adjust the scrolling position
    const top = sectionElement.offsetTop - offset;
    window.scrollTo({ behavior: "smooth", top });
  };
  const getUniqueGuids = (data, type) => {
    const uniqueTypes = new Set();
    const result = [];

    data.forEach((item) => {
      if (!item.template && !uniqueTypes.has(item.type) && item.type !== type) {
        uniqueTypes.add(item.type);
        result.push(item.guid);
      }
    });

    return result;
  };

  const openSnackbar = (severity, message) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    if (MassNego && itemSpecific && AllInquiryVendors && vendorId) {
      const data = vendorId.split(",").map((vendorid) => {
        return AllInquiryVendors[vendorid]?.inquiry_item_ids;
      });
      const uniqueElements = [...new Set(data.flat())];
      setMassItemIds(uniqueElements);
    }
  }, [AllInquiryVendors, vendorId, MassNego, itemSpecific]);

  useEffect(() => {
    if (MassItemIds.length > 0) {
      const filteredItems = Object.keys(items)
        .filter((itemId) => MassItemIds.includes(parseInt(itemId)))
        .map((itemId) => ({
          ...items[itemId], // Spread the original item properties
          item_id: parseInt(itemId), // Add the item_id key
        })); // Map to get the actual items

      setTransactionFilteredItems(filteredItems);
    }
  }, [items, MassItemIds]);
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const inq_id = queryParams.get("inq_id");
        setInquiryId(inq_id);
        const vendor_id = LIVendorIds;
        setVendorId(vendor_id);
        let URL = getTransactionDetails(inq_id, vendor_id);
        if (queryParams.get("TLGUID")) {
          setviewTxn(true);
          URL = getTransactionDetailsView(
            inq_id,
            vendor_id,
            queryParams.get("TLGUID")
          );
          setIsLoading(true);
          setNegotiationDataLoading(true);
          const response = await axios.get(URL, configAxios);
          setRfqDate(response.data.rfq_date);
          setTransactionItemSequence(response.data.item_sequence);
          setNegotiationJsonData(response.data);
          if (props.hasOwnProperty('filterData')) {
            props.filterData(response.data.filter_data);
          }
          setReferenceNo(response.data.ref_no);
          setCategoryId(response.data.categories[0].id);
          setCategoryName(response.data.categories[0].name);
          setCategoryCode(response.data.categories[0].code);
          setFileSpecificationData(response.data.categories[0].files);
          setCurrencyDetails(response.data.currency);
          setPermissionInquiry(response.data.permission);
          setItems(response.data.items);
          setVendorType(response.data.vendors[0].type);
          const transaction_thread = [
            ...response.data.vendors[0].transaction_thread,
          ];
          const indexOfTemplate = transaction_thread
            .reverse()
            .findIndex((obj) => obj.hasOwnProperty("template"));
          if (indexOfTemplate === 0) {
            setIsLastTxn(true);
          }
          const regretJustification =
            transaction_thread[indexOfTemplate]?.action?.regret?.justification;

          setLastTransactionInThread(
            transaction_thread[indexOfTemplate].template.itemize
          );
          setlastTxn(transaction_thread[indexOfTemplate + 1]);
          setLastTransactionTypeInThread(
            transaction_thread[indexOfTemplate].type
          );
          setShipmentMark(transaction_thread[indexOfTemplate].shipment_mark);
          setCurrentTransaction(transaction_thread[indexOfTemplate]);
          setInquiryTransaction(
            transaction_thread[transaction_thread.length - 1]
          );
          setLastTransactionData(transaction_thread[indexOfTemplate]?.template);
          setPrevTransaction(transaction_thread[indexOfTemplate + 1]);
          setIsLoading(false);
          setNegotiationDataLoading(false);
          setItemSpecific(response.data.item_specific);
          setMultiplePlantsFlag(response.data.filter_data.multi_plant);
          setMultipleSolutionFlag(response.data.filter_data.multiple_solution);
          setSlabFlag(response.data.filter_data.slab);
          setLastTransactionInThreadCommon(
            transaction_thread[indexOfTemplate].template.common
          );
          setQuotedCurrency(response.data.vendors[0].quoted_currency);
          setInquiryItemsId(response.data.vendors[0].inquiry_item_ids);
          setRegretJustificationMessage(
            regretJustification ? regretJustification : ""
          );
          setViewJustification(regretJustification ? true : false);
          setTranTypeGUID(transaction_thread[indexOfTemplate].TranTypeGUID);
        } else {
          setIsLoading(true);
          setNegotiationDataLoading(true);
          const response = await axios.get(URL, configAxios);
          const transaction_thread = [
            ...response.data.vendors[0].transaction_thread,
          ];
          const indexOfTemplate = transaction_thread.length - 1;

          if (transaction_thread[indexOfTemplate].type != "Inquiry") {
            const guids = getUniqueGuids(
              response.data.vendors[0].transaction_thread,
              transaction_thread[indexOfTemplate].type
            );
            setFetchedGuids(guids);
          } else {
            setLastTxnInquiryState(true);
          }
          const validationURL = LIVendorIds?.includes(",")
            ? massValidation(inq_id, "Negotiation", vendor_id)
            : quotationValidation(
                inq_id,
                vendor_id,
                transaction_thread[indexOfTemplate].guid,
                "Negotiation"
              );
          const res = await axios.get(validationURL, configAxios);
          setMainValidationError(res?.data?.error);
          if (res.status === 200) {
            // setServiceFileName(
            //   transaction_thread[indexOfTemplates]?.service_filename
            // );
            setTransactionApiResponse(response.status);
            setRfqDate(response.data.rfq_date);
            setTransactionItemSequence(response.data.item_sequence);
            setNegotiationJsonData(response.data);
            if (props.hasOwnProperty('filterData')) {
              props.filterData(response.data.filter_data);
            }
            setReferenceNo(response.data.ref_no);
            setValidityDate(response.data.expiry_at);
            setItemSpecific(response.data.item_specific);
            setCategoryId(response.data.categories[0].id);
            setCategoryName(response.data.categories[0].name);
            setCategoryCode(response.data.categories[0].code);
            setMultiplePlantsFlag(response.data.filter_data.multi_plant);
            setShipmentMark(transaction_thread[indexOfTemplate].shipment_mark);

            if (LIVendorIds?.includes(",")) {
              setMultipleSolutionFlag(false);
              setSlabFlag(false);
            } else {
              setMultipleSolutionFlag(
                response.data.filter_data.multiple_solution
              );
              setSlabFlag(response.data.filter_data.slab);
            }
            setFileSpecificationData(response.data.categories[0].files);
            setCurrencyDetails(response.data.currency);
            setItems(response.data.items);
            setVendorType(response.data.vendors[0].type);
            setInquiryItemsId(response.data.vendors[0].inquiry_item_ids);
            setIndustryDetails(response.data.vendors[0].industry_details);
            setPermissionInquiry(response.data.permission);
            if (indexOfTemplate === 0) {
              setIsLastTxn(true);
            }
            setLastTransactionInThread(
              transaction_thread[indexOfTemplate].template.itemize
            );
            setlastTxn(transaction_thread[indexOfTemplate]);
            setLastTransactionData(
              transaction_thread[indexOfTemplate]?.template
            );
            setLastTransactionInThreadCommon(
              transaction_thread[indexOfTemplate].template.common
            );
            setLastTransactionTypeInThread(
              transaction_thread[indexOfTemplate].type
            );
            setLastTransactionGuidInThread(
              transaction_thread[indexOfTemplate].guid
            );
            setIsLoading(false);
            setNegotiationDataLoading(false);
            setQuotedCurrency(response.data.vendors[0].quoted_currency);
          }
        }
      } catch (err) {
        console.log("Fetch Error:", err);
        setIsLoading(false);
        setNegotiationDataLoading(false);

        setMainValidationError(
          err?.response?.data[0]?.error ||
            err?.response?.data?.error ||
            err?.response?.data.message
        );
        openSnackbar(
          "error",
          err?.message ? err?.message : "Something went wrong!"
        );
        // Handle error
        // setJsonTemplate("");
      }
    };

    fetchTransactions();
  }, []);

  useEffect(() => {
    const fetchJSONTemplate = async () => {
      if (
        !negotiationJsonData ||
        !negotiationJsonData.template ||
        !negotiationJsonData.template.batch_id ||
        !negotiationJsonData.template.id
      ) {
        // Data not ready yet, do nothing
        return;
      }

      // Get Transaction Type

      // axios.defaults.headers.common["Authorization"] = `Bearer ${sessionID}`;
      const batchID = negotiationJsonData.template.batch_id;
      const templateID = negotiationJsonData.template.id;
      let templateURL;
      if (process.env.NODE_ENV === "production") {
        templateURL = templateURi(
          userInfo?.division_id,
          companyInfo?.display_name,
          templateID,
          batchID,
          4 +
            (vendorType === "Local"
              ? MassNego || ResendMailVendorDetails.length > 1
                ? "_ML"
                : "_L"
              : MassNego || ResendMailVendorDetails.length > 1
              ? "_MI"
              : "_I")
        );
      } else {
        templateURL = devTemplateURi(
          userInfo?.division_id,
          companyInfo?.display_name,
          templateID,
          batchID,
          4 +
            (vendorType === "Local"
              ? MassNego || ResendMailVendorDetails.length > 1
                ? "_ML"
                : "_L"
              : MassNego || ResendMailVendorDetails.length > 1
              ? "_MI"
              : "_I")
        );
      }
      try {
        setJsonTemplateLoading(true);
        const response = await axios.get(templateURL);

        if (
          response?.data.LOCAL.length == 0 &&
          response?.data.FOREIGN.length == 0
        ) {
          setMainValidationError(
            "Sorry ! This is an invalid transaction. The RFQ you are attempting to Negotiation is for a different country than the one you are registered for."
          );
        }
        setJsonTemplateLoading(false);
        setJsonTemplate(response.data);
        setItemBoxId(response.data.ITEMIZE[0][0].FieldId);
      } catch (err) {
        dispatch(openSnackbarfunc("error", "Template json not found!"));
        setJsonTemplateLoading(false);
      }
    };
    if (
      vendorType &&
      Object.keys(userInfo).length > 0 &&
      Object.keys(companyInfo).length > 0
    ) {
      fetchJSONTemplate();
    }
  }, [negotiationJsonData, userInfo, companyInfo, ResendMailVendorDetails]); // Adding negotiationJsonData to dependency array

  useEffect(() => {
    const fetchBuyerData = async () => {
      try {
        if (negotiationJsonData && negotiationJsonData.created_by) {
          let url;
          if (queryParams.get("supplier")) {
            url = getBuyers(negotiationJsonData?.created_by);
          } else {
            url = getBuyers(negotiationJsonData?._internal?.current_user_id);
          }
          const response = await axios.get(url, configAxios);
          setBuyerInfo(response.data);
        }
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    fetchBuyerData();
  }, [negotiationJsonData]);

  useEffect(() => {
    const getCurrencyOptions = async () => {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${sessionId}`;
        const URL = getDropDownCurrencyList;
        const response = await axios.get(URL, configAxios);
        setCurrencyOptions(response.data);
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    const queryParams = new URLSearchParams(window.location.search);
    if (!queryParams.get("TLGUID")) {
      getCurrencyOptions();
    }
  }, []);

  useEffect(() => {
    const fetchInquiryVendors = async () => {
      try {
        let url = get_Inquiries_Vendor_data(inquiryId);
        const response = await axios.get(url, configAxios);
        let filtredVendors = [];
        let allVendors = {};
        const vendors = response.data?.vendors.map((vendor) => {
          allVendors[vendor.id] = vendor;
          if (vendorId.split(",").includes(vendor.id.toString())) {
            filtredVendors.push(vendor);
          }
        });
        const uniqueData = filtredVendors.reduce((acc, current) => {
          if (!acc.some((item) => item.id == current.id)) {
            acc.push(current);
          }
          return acc;
        }, []);

        setVendors(uniqueData);
        setAllInquiryVendors(allVendors);
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    if (inquiryId) {
      fetchInquiryVendors();
    }
  }, [inquiryId, vendorId]);
  useEffect(() => {
    axios
      .get(getAllowMultipleSolutionOptionLabel, configAxios)
      .then((response) => {
        setAlternativeNames(response.data.options);
        // this.setState({
        //   label: response.data.options?.alternate_options,
        // });
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const fetchDestinationDetails = async () => {
      try {
        const url = getDestinations;
        const response = await axios.get(url, configAxios);
        setDestinationDetails(response.data);
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    fetchDestinationDetails();
  }, []);

  useEffect(() => {
    const fetchTermsAndConditionsDetails = async () => {
      try {
        const url = getTermsAndConditionsDetails(categoryId);
        const response = await axios.get(url, configAxios);
        setTermsAndConditionsDetails(response.data.data);
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    if (categoryId) {
      fetchTermsAndConditionsDetails();
    }
  }, [categoryId]);

  const filterData = () => {
    const filtered = Object.fromEntries(
      Object.entries(lastTransactionData?.itemize).filter(
        ([key, value]) => value.selected === "N"
      )
    );
    setFilteredSelectedNotData(filtered);
  };

  useEffect(() => {
    if (lastTransactionData && lastTransactionData.itemize) {
      filterData();
    }
  }, [lastTransactionData]);

  useEffect(() => {
    if (
      viewTxn == false &&
      featurePermission?.max_online_offline_requotes?.feature_value === "Y"
    ) {
      if (
        negotiationJsonData &&
        negotiationJsonData.vendors &&
        negotiationJsonData.vendors.length > 0
      ) {
        let transactionThread =
          negotiationJsonData.vendors[0].transaction_thread;
        let requoteCount = transactionThread.filter(
          (item) => item.type === "Requote" && item.r2a_flag === "N"
        ).length;
      }
    }
  }, [featurePermission, viewTxn, negotiationJsonData]);

  //Start Nego Detail popover js
  const [popperAnchorEl, setPopperAnchorEl] = React.useState(null);
  const [PopperAnchorElVendorList, setPopperAnchorElVendorList] =
    useState(null);
  const [buyerPopperAnchorEl, setBuyerPopperAnchorEl] = React.useState(null);

  const popperClick = (event) => {
    setPopperAnchorEl(popperAnchorEl ? null : event.currentTarget);
  };
  const buyerPopperClick = (event) => {
    setBuyerPopperAnchorEl(buyerPopperAnchorEl ? null : event.currentTarget);
  };

  const popperOpen = Boolean(popperAnchorEl);
  const buyerPopperOpen = Boolean(buyerPopperAnchorEl);
  const popperId = popperOpen ? "true" : undefined;
  const buyerPopperId = buyerPopperOpen ? "true" : undefined;
  const vendorPopperId = Boolean(PopperAnchorElVendorList) ? true : undefined;

  const [arrowRef, setArrowRef] = useState(null);
  const [buyerArrowRef, setBuyerArrowRef] = useState(null);
  const [vendorArrowRef, setVendorArrowRef] = useState(null);

  const [openEmailList, setOpenEmailList] = React.useState(false);
  const [openPhoneList, setOpenPhoneList] = React.useState(false);
  const EmailListClick = () => {
    setOpenEmailList(!openEmailList);
  };
  const PhoneListClick = (type) => {
    // setOpenPhoneList((prev) => ({ ...prev, [type]: !openPhoneList }));
    setOpenPhoneList((prev) => ({
      ...prev,
      [type]: !prev[type], // Toggle the value for the given type
    }));
  };
  //End Nego Detail popover js
  function stringAvatar(name = "") {
    const value = name.split(" ");
    return name ? `${value[0][0]}${value[1] ? value?.[1][0] : ""}` : "";
  }

  const handleCopy = (content) => {
    // const email = negotiationJsonData.vendors?.[0].contact_person_details?.email;
    if (content) {
      const textarea = document.createElement("textarea");
      textarea.value = content;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000); // Reset copied state after 3 seconds
    }
  };

  // Start Mobile view details section collapsible
  const [expandedDetails, setExpandedDetails] = React.useState(false);

  const isDesktopDetails = useMediaQuery("(min-width: 600px)");

  const detailsExpandClick = () => {
    setExpandedDetails((prevOpen) => !prevOpen);
  };

  const handleRemoveIframe = () => {
    window.parent.location.reload();
  };
  // End Mobile view details section collapsible
  return !mainValidationError ? (
    <Fragment>
      {NegoFormStyle}
      <Backdrop
        style={{ color: "#fff", zIndex: "10000000000" }}
        open={isLoading || JsonTemplateLoading || negotiationDataLoading}
      >
        <CircularProgress style={{ color: "#fff" }} />
      </Backdrop>
      <main
        sx={{
          width: "100%",
          maxWidth: "100%",
          padding: 0,
        }}
      >
        <PageToolbar
          multipleVendors={props.multipleVendors}
          className="datagrid-PageToolbar head-datagrid-toolbar"
        >
          <Stack direction="row" spacing="2" alignItems="center">
            <Box
              sx={{
                display: "flex",
                alignItems: { xs: "center", md: "start" },
              }}
            >
              {queryParams.get("supplier") && (
                <Tooltip title="Back">
                  <IconButton
                    aria-label="arrow"
                    size="small"
                    sx={{
                      mr: 1,
                      p: 0,
                      color: "var(--grey-900)",
                    }}
                    onClick={handleRemoveIframe} // Add onClick handler here
                  >
                    <KeyboardArrowLeftIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
              )}
              <Box
                sx={{
                  marginTop: {
                    xs: "0",
                    md: "-4px",
                  },
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  {viewTxn ? (
                    <Typography
                      variant="subtitle2"
                      color="secondary.main"
                      noWrap
                    >
                      {" "}
                      {CurrentTransaction.type &&
                        `${CurrentTransaction?.type} Details`}
                    </Typography>
                  ) : (
                    negotiationJsonData &&
                    negotiationJsonData.vendors &&
                    negotiationJsonData.vendors[0] &&
                    negotiationJsonData.vendors[0].transaction_thread && (
                      <Typography
                        variant="subtitle2"
                        color="secondary.main"
                        noWrap
                      >
                        Submit Negotiation
                      </Typography> //check condition
                    )
                  )}
                  <Divider
                    orientation="vertical"
                    sx={{ height: "12px", display: { xs: "none", md: "flex" } }}
                  />
                  <Typography
                    variant="body2"
                    color="secondary.main"
                    sx={{ display: { xs: "none", md: "flex" } }}
                  >
                    {negotiationJsonData?.ref_no}
                  </Typography>
                  {!MassNego && (
                    <Divider
                      orientation="vertical"
                      sx={{
                        height: "12px",
                        display: { xs: "none", md: "flex" },
                      }}
                    />
                  )}
                  <Box
                    sx={{
                      display: { xs: "none", md: "flex" },
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    {!MassNego && lastTxn && lastTxn?.no && (
                      <>
                        <React.Fragment key={lastTxn?.id}>
                          <Typography
                            variant="body2"
                            color="grey.500"
                            component="label"
                          >
                            {lastTxn?.no && "Last Quote No :"}
                          </Typography>
                          <Typography variant="body2" color="text.primary">
                            {lastTxn?.no}
                          </Typography>
                        </React.Fragment>
                      </>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="grey.500"
                    component="label"
                    noWrap
                  >
                    Category Name :{" "}
                  </Typography>
                  <Tooltip title={categoryName}>
                    <Typography
                      variant="body2"
                      color="text.primary"
                      sx={{ maxWidth: "220px" }}
                      noWrap
                    >
                      {categoryName}
                    </Typography>
                  </Tooltip>

                  <Divider orientation="vertical" sx={{ height: "12px" }} />

                  <Typography
                    variant="body2"
                    color="grey.500"
                    component="label"
                    noWrap
                  >
                    Category Code :{" "}
                  </Typography>
                  <Tooltip title={categoryCode}>
                    <Typography
                      variant="body2"
                      color="text.primary"
                      sx={{ maxWidth: "120px" }}
                      noWrap
                    >
                      {categoryCode}
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            </Box>

            <Box sx={{ ml: "auto !important" }}>
              <List
                sx={{
                  display: "flex",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                  gap: "4px",
                  justifyContent: "right",
                  p: 0,
                  "& .MuiListItem-root": {
                    p: 0,
                  },
                  flexWrap: { xs: "wrap", sm: "inherit" },
                  "&.MuiList-root .MuiListItem-root:not(:first-of-type)": {
                    flex: "0 0 36px",
                  },
                }}
              >
                <ListItem
                  sx={{
                    justifyContent: "right",
                  }}
                >
                  {!props.multipleVendors && (
                    <Box className="GoogleTranslate-Box">
                      <GoogleTranslate />
                    </Box>
                  )}
                </ListItem>
                <ListItem>
                  <Tooltip title="Transaction Thread">
                    <IconButton aria-label="QuotationThread" size="small">
                      <QuotationThread
                        onClick={TransactionThreadOpen("right", true)}
                      />
                    </IconButton>
                  </Tooltip>
                </ListItem>

                {accountModulePermission?.["VendX Connect"]?.module_activated ==
                  "Y" && (
                  <ListItem>
                    <Tooltip title="Vendx Connect">
                      <IconButton aria-label="" size="small">
                        <img
                          src={DataTransferIcon}
                          width="24"
                          onClick={openCommunication}
                        />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                )}

                {viewTxn &&
                  (queryParams.get("supplier") ? (
                    ViewJustification && (
                      <ListItem>
                        <Tooltip title="More">
                          <IconButton
                            aria-label="More"
                            size="small"
                            onClick={handleClick}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </Tooltip>

                        <Menu
                          MenuListProps={{
                            dense: true,
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fill: "var(--secondary-600)",
                            },
                          }}
                        >
                          <MenuItem onClick={handleOpenRegretBox}>
                            <DescriptionIcon />
                            View Regret Justification
                          </MenuItem>
                        </Menu>
                      </ListItem>
                    )
                  ) : (
                    <ListItem>
                      <Tooltip title="More">
                        <IconButton
                          aria-label="More"
                          size="small"
                          onClick={handleClick}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Tooltip>

                      <Menu
                        MenuListProps={{
                          dense: true,
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fill: "var(--secondary-600)",
                          },
                        }}
                      >
                        {ViewJustification && (
                          <MenuItem onClick={handleOpenRegretBox}>
                            <DescriptionIcon />
                            View Regret Justification
                          </MenuItem>
                        )}

                        {!queryParams.get("supplier") && (
                          <>
                            <MenuItem onClick={handleSendMailDrawer}>
                              <MailIcon />
                              Send Mail
                            </MenuItem>
                            <MenuItem onClick={handleResendMailDrawer}>
                              <ResendMailIcon />
                              Resend Negotiation Mail
                            </MenuItem>
                          </>
                        )}
                      </Menu>
                    </ListItem>
                  ))}
              </List>
            </Box>
          </Stack>
          <Box sx={{ width: "100%" }} display={{ xs: "none", md: "block" }}>
            <Tabs
              value={tabValue}
              onChange={handleChangeTabPosition}
              aria-label=""
              sx={{
                "&.MuiTabs-root .MuiTabs-scroller": {
                  overflowX: {
                    xs: "auto !important",
                  },
                },
                "&": {
                  minHeight: "34px",
                  ".MuiButtonBase-root": {
                    minHeight: "34px",
                    padding: {
                      sm: "12px 16px",
                      xs: "12px 12px",
                    },
                  },
                },
              }}
            >
              <Tab
                value="one"
                label="Negotiation Details"
                onClick={() =>
                  scrollToSection(
                    viewTxn
                      ? `NegotiationDetails`
                      : `NegotiationDetails_${
                          props.tabValue ? props.tabValue : "common"
                        }`
                  )
                }
              />
              <Tab
                value="two"
                label="Item Details"
                onClick={() =>
                  scrollToSection(
                    viewTxn
                      ? `Template`
                      : `Template__${
                          props.tabValue ? props.tabValue : "common"
                        }`
                  )
                }
              />
              <Tab
                value="three"
                label="Additional Info"
                onClick={() =>
                  scrollToSection(
                    viewTxn
                      ? `AdditionalInfo`
                      : `AdditionalInfo__${
                          props.tabValue ? props.tabValue : "common"
                        }`
                  )
                }
              />
            </Tabs>
          </Box>
        </PageToolbar>
        <Box sx={{ p: 2, pb: 0 }}>
          <Alert
            severity="warning"
            className="LanguageSelect-Alert"
            sx={{
              mb: 2,
              "&.LanguageSelect-Alert": {
                display: "none",
              },
            }}
            icon={<ErrorIcon fontSize="inherit" />}
          >
            We are not responsible for correctness of translation as we are
            using third party services.
          </Alert>

          {/* Start Nego Details Section HTML */}
          {Object.keys(negotiationJsonData).length > 0 &&
            Object.keys(buyerInfo).length > 0 && (
              <Card variant="outlined" sx={{ mb: 2 }}>
                <CardHeader
                  title={
                    <Typography
                      variant="body2"
                      color={"grey.900"}
                      fontWeight={500}
                      textTransform="uppercase"
                      component="h5"
                    >
                      Details
                    </Typography>
                  }
                  action={
                    <IconButton
                      expand={expandedDetails}
                      onClick={detailsExpandClick}
                      aria-label="Details"
                      size="small"
                      sx={{
                        display: { xs: "inline-flex", sm: "none" },
                        p: 0,
                      }}
                    >
                      {expandedDetails ? <ExpandMore /> : <ExpandLess />}
                    </IconButton>
                  }
                  titleTypographyProps={{
                    variant: "subtitle2",
                    component: "h5",
                    color: "grey.900",
                    textTransform: "uppercase",
                  }}
                  sx={{
                    minHeight: "50px",
                    "& .MuiCardHeader-action": {
                      marginRight: 0,
                      marginTop: 0,
                    },
                  }}
                />
                <Collapse
                  in={isDesktopDetails ? true : expandedDetails}
                  timeout="auto"
                  unmountOnExit
                >
                  <Divider sx={{ marginBottom: "0" }} />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xl={2} lg={3} md={3} sm={6} xs={12}>
                        <Typography
                          component="label"
                          variant="body2"
                          color={"text.primary"}
                          fontWeight={500}
                        >
                          Vendor Details
                        </Typography>
                        {MassNego ? (
                          <Box
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <AvatarGroup
                              elevation={0}
                              sx={{
                                lineHeight: 0,
                                marginTop: "6px",
                                "& .MuiAvatar-root": {
                                  fontSize: "11px",
                                  width: "33px",
                                  height: "33px",
                                  background: "var(--grey-300)",
                                  color: "var(--grey-600)",
                                  backgroundColor: "var(--color-primary)",
                                  color: "#fff",
                                  cursor: "pointer",
                                },
                                mr: 1,
                                "& .MuiAvatar-root:(:first-of-type)": {},
                                // "& .MuiAvatar-root:first-of-type": {
                                //   cursor: "pointer",
                                //   backgroundColor: "var(--color-primary)",
                                //   color: "#fff",
                                // },
                              }}
                              onClick={(event) => {
                                if (itemSpecific) {
                                  setOpenItemwiseDilogue(true);
                                } else {
                                  setIsToggled((prev) => !prev);
                                  setPopperAnchorElVendorList(
                                    PopperAnchorElVendorList
                                      ? null
                                      : event.currentTarget
                                  );
                                }
                              }}
                            >
                              {Vendors.slice(0, 3).map((vendor, index) => (
                                <Tooltip
                                  key={index}
                                  title={vendor?.name}
                                  placement="bottom"
                                  arrow
                                >
                                  <Avatar alt={vendor?.name}>
                                    {stringAvatar(vendor?.name)}
                                  </Avatar>
                                </Tooltip>
                              ))}
                              {Vendors.length > 3 && (
                                <Typography
                                  variant="body2"
                                  component="a"
                                  fontWeight={500}
                                  sx={{
                                    marginLeft: "8px", // Adjust spacing as needed
                                    cursor: "pointer",
                                    mt: "8px",
                                    color: "var(--color-primary)", // Customize color if needed
                                    // textDecoration: "none",
                                    textDecoration: "underline", // Add underline
                                  }}
                                >
                                  +{Vendors.length - 3}
                                </Typography>
                              )}
                            </AvatarGroup>
                            <Popper
                              id={vendorPopperId}
                              open={isToggled}
                              anchorEl={PopperAnchorElVendorList}
                              placement="bottom-end"
                              disablePortal={true}
                              sx={{
                                zIndex: "99",
                              }}
                            >
                              <Box component="span" />
                              <Paper
                                elevation={3}
                                sx={{
                                  "&": {
                                    width: "290px",
                                    position: "relative",
                                    zIndex: "1",
                                  },
                                }}
                              >
                                <Typography
                                  sx={{ padding: "8px 16px" }}
                                  variant="body2"
                                  component="h6"
                                  fontWeight={500}
                                  color="Secondary.900"
                                >
                                  Vendors
                                </Typography>
                                <Divider />
                                <List
                                  sx={{
                                    width: "100%",
                                    maxWidth: 360,
                                    maxHeight: "364px",
                                    overflowY: "auto",
                                    bgcolor: "background.paper",
                                    ".MuiListItemText-root": {
                                      m: 0,
                                      lineHeight: "normal",
                                    },
                                    ".MuiListItemAvatar-root": {
                                      minWidth: "40px",
                                    },
                                  }}
                                >
                                  {Vendors.map((vendor) => (
                                    <ListItem sx={{ padding: "8px" }}>
                                      <ListItemAvatar>
                                        <Avatar
                                          sx={{
                                            width: 32,
                                            height: 32,
                                            fontSize: "12px",
                                            bgcolor: "primary.500",
                                          }}
                                          aria-label=""
                                        >
                                          {stringAvatar(vendor?.name)}
                                        </Avatar>
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary={
                                          <Tooltip title={vendor?.name}>
                                            <Typography
                                              variant="body2"
                                              color={"text.primary"}
                                              fontWeight={500}
                                              lineHeight={"normal"}
                                            >
                                              {vendor?.name}
                                            </Typography>
                                          </Tooltip>
                                        }
                                        secondary={
                                          <Typography
                                            variant="caption"
                                            color={"grey.500"}
                                            lineHeight={"normal"}
                                          >
                                            {vendor?.code}
                                          </Typography>
                                        }
                                      />
                                    </ListItem>
                                  ))}
                                </List>
                              </Paper>
                            </Popper>
                          </Box>
                        ) : (
                          <Card
                            elevation={0}
                            sx={{ lineHeight: 0, marginTop: "6px" }}
                          >
                            <CardHeader
                              avatar={
                                <Avatar
                                  sx={{
                                    width: 32,
                                    height: 32,
                                    fontSize: "12px",
                                    bgcolor: "primary.500",
                                  }}
                                  aria-label=""
                                >
                                  {stringAvatar(
                                    negotiationJsonData.vendors?.[0]?.name
                                  )}
                                </Avatar>
                              }
                              title={
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  gap="4px"
                                >
                                  <Tooltip
                                    title={
                                      negotiationJsonData.vendors?.[0]?.name
                                    }
                                  >
                                    <Typography
                                      gutterBottom
                                      variant="h5"
                                      component="div"
                                      fontWeight={500}
                                      mb={0}
                                      color={"text.primary"}
                                      sx={{ fontSize: "14px !important" }}
                                      noWrap
                                    >
                                      {negotiationJsonData.vendors?.[0]?.name}
                                    </Typography>
                                  </Tooltip>
                                  <IconButton
                                    aria-label="Arrow Down"
                                    size="small"
                                    sx={{ p: 0 }}
                                    onClick={popperClick}
                                  >
                                    {popperOpen ? (
                                      <ExpandMore />
                                    ) : (
                                      <ExpandLess />
                                    )}
                                  </IconButton>
                                  <Popper
                                    id={popperId}
                                    open={popperOpen}
                                    anchorEl={popperAnchorEl}
                                    placement="bottom-start"
                                    disablePortal={true}
                                    modifiers={[
                                      {
                                        name: "flip",
                                        enabled: true,
                                        options: {
                                          altBoundary: true,
                                          rootBoundary: "document",
                                          padding: 8,
                                        },
                                      },
                                      {
                                        name: "preventOverflow",
                                        enabled: true,
                                        options: {
                                          altAxis: false,
                                          altBoundary: false,
                                          tether: false,
                                          rootBoundary: "document",
                                          padding: 8,
                                        },
                                      },
                                      {
                                        name: "arrow",
                                        enabled: true,
                                        options: {
                                          element: buyerArrowRef,
                                        },
                                      },
                                    ]}
                                    sx={{
                                      zIndex: "99",
                                    }}
                                  >
                                    <Box
                                      component="span"
                                      className="popper-arrow"
                                      ref={setBuyerArrowRef}
                                    />
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        "&": {
                                          width: "290px",
                                          height: "400px",
                                          position: "relative",
                                          zIndex: "1",
                                          overflowY: "auto",
                                        },
                                      }}
                                    >
                                      <Typography
                                        sx={{ padding: "8px 16px" }}
                                        variant="body2"
                                        component="h6"
                                        fontWeight={500}
                                        color="Secondary.900"
                                      >
                                        Contact Person
                                      </Typography>
                                      <Divider />
                                      <PopoverDetailList>
                                        <ListItem>
                                          <ListItemIcon>
                                            <PersonIcon fontSize="small" />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary="Contact Person"
                                            secondary={
                                              negotiationJsonData.vendors?.[0]
                                                .contact_person_details?.name
                                                ? negotiationJsonData
                                                    .vendors?.[0]
                                                    .contact_person_details
                                                    ?.name
                                                : "N/A"
                                            }
                                          />
                                        </ListItem>
                                        <ListItem>
                                          <ListItemIcon>
                                            <EmailIcon fontSize="small" />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary="Mail I’D"
                                            secondary={
                                              <>
                                                <Box
                                                  display="flex"
                                                  gap="8px"
                                                  className="EmailId-Info"
                                                >
                                                  <Tooltip
                                                    title={
                                                      negotiationJsonData
                                                        .vendors?.[0]
                                                        .contact_person_details
                                                        ?.email &&
                                                      negotiationJsonData
                                                        .vendors?.[0]
                                                        .contact_person_details
                                                        ?.email
                                                    }
                                                  >
                                                    {negotiationJsonData
                                                      .vendors?.[0]
                                                      .contact_person_details
                                                      ?.email ? (
                                                      <Link
                                                        href={`mailto:${
                                                          negotiationJsonData
                                                            .vendors?.[0]
                                                            .contact_person_details
                                                            ?.email &&
                                                          negotiationJsonData
                                                            .vendors?.[0]
                                                            .contact_person_details
                                                            ?.email
                                                        }`}
                                                        display="block"
                                                        underline="none"
                                                        noWrap
                                                      >
                                                        {negotiationJsonData
                                                          .vendors?.[0]
                                                          .contact_person_details
                                                          ?.email
                                                          ? negotiationJsonData
                                                              .vendors?.[0]
                                                              .contact_person_details
                                                              ?.email
                                                          : "N/A"}
                                                      </Link>
                                                    ) : (
                                                      <Link
                                                        display="block"
                                                        underline="none"
                                                        noWrap
                                                      >
                                                        N/A
                                                      </Link>
                                                    )}
                                                  </Tooltip>
                                                  <Tooltip title="Copy">
                                                    <IconButton
                                                      aria-label="Arrow Down"
                                                      color="primary"
                                                      size="small"
                                                      className="CopyIcon"
                                                      sx={{ p: 0 }}
                                                      onClick={() => {
                                                        handleCopy(
                                                          negotiationJsonData
                                                            .vendors?.[0]
                                                            .contact_person_details
                                                            ?.email || "N/A"
                                                        );
                                                      }}
                                                    >
                                                      <ContentCopyIcon
                                                        sx={{ fontSize: 18 }}
                                                      />
                                                    </IconButton>
                                                  </Tooltip>
                                                  {/* {copied && <span style={{ color: "green" }}>Copied!</span>} */}
                                                </Box>
                                              </>
                                            }
                                          />
                                        </ListItem>
                                        <ListItem>
                                          <ListItemIcon>
                                            <LocalPhoneIcon fontSize="small" />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary="Phone No"
                                            secondary={
                                              negotiationJsonData.vendors?.[0]
                                                .contact_person_details?.phone
                                                ? negotiationJsonData
                                                    .vendors?.[0]
                                                    .contact_person_details
                                                    ?.phone
                                                : "N/A"
                                            }
                                          />
                                        </ListItem>
                                      </PopoverDetailList>
                                      <Divider />
                                      <Typography
                                        sx={{ padding: "8px 16px" }}
                                        variant="body2"
                                        component="h6"
                                        fontWeight={500}
                                        color="Secondary.900"
                                      >
                                        Vendor Info
                                      </Typography>
                                      <Divider />
                                      <PopoverDetailList>
                                        <ListItem alignItems="flex-start">
                                          <ListItemIcon>
                                            <EmailIcon fontSize="small" />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary="Mail I’D"
                                            secondary={
                                              <>
                                                <Box
                                                  display="flex"
                                                  justifyContent="space-between"
                                                  gap="8px"
                                                  className="EmailId-Info"
                                                >
                                                  <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    gap="8px"
                                                    sx={{
                                                      width:
                                                        "calc(100% - 25px)",
                                                    }}
                                                  >
                                                    <Tooltip
                                                      title={
                                                        negotiationJsonData
                                                          .vendors?.[0].email
                                                          ? negotiationJsonData.vendors?.[0].email.split(
                                                              ","
                                                            )[0]
                                                          : "N/A"
                                                      }
                                                    >
                                                      {negotiationJsonData
                                                        .vendors?.[0].email !=
                                                      null ? (
                                                        <Link
                                                          href={`mailto:${
                                                            negotiationJsonData.vendors?.[0].email.split(
                                                              ","
                                                            )[0]
                                                          }`}
                                                          display="block"
                                                          underline="none"
                                                          noWrap
                                                        >
                                                          {
                                                            negotiationJsonData.vendors?.[0].email.split(
                                                              ","
                                                            )[0]
                                                          }
                                                        </Link>
                                                      ) : (
                                                        <Link
                                                          display="block"
                                                          underline="none"
                                                          noWrap
                                                        >
                                                          N/A
                                                        </Link>
                                                      )}
                                                    </Tooltip>
                                                    <Tooltip title="Copy">
                                                      <IconButton
                                                        aria-label="Arrow Down"
                                                        color="primary"
                                                        size="small"
                                                        className="CopyIcon"
                                                        sx={{ p: 0 }}
                                                        onClick={() => {
                                                          handleCopy(
                                                            negotiationJsonData.vendors?.[0].email.split(
                                                              ","
                                                            )[0] || "N/A"
                                                          );
                                                        }}
                                                      >
                                                        <ContentCopyIcon
                                                          sx={{ fontSize: 18 }}
                                                        />
                                                      </IconButton>
                                                    </Tooltip>
                                                    {/* {copied && <span style={{ color: "green" }}>Copied!</span>} */}
                                                  </Box>
                                                  {negotiationJsonData.vendors?.[0].email.split(
                                                    ","
                                                  ).length > 1 && (
                                                    <IconButton
                                                      aria-label="Arrow Down"
                                                      size="small"
                                                      sx={{ p: 0 }}
                                                      onClick={EmailListClick}
                                                    >
                                                      {openEmailList ? (
                                                        <ExpandMore fontSize="small" />
                                                      ) : (
                                                        <ExpandLess fontSize="small" />
                                                      )}
                                                    </IconButton>
                                                  )}
                                                </Box>
                                                {negotiationJsonData.vendors?.[0].email.split(
                                                  ","
                                                ).length > 1 && (
                                                  <Collapse
                                                    in={openEmailList}
                                                    timeout="auto"
                                                    unmountOnExit
                                                  >
                                                    <List disablePadding>
                                                      {negotiationJsonData.vendors?.[0].email
                                                        .split(",")
                                                        .slice(1)
                                                        .map((email) => (
                                                          <ListItem
                                                            disableGutters
                                                          >
                                                            <ListItemText
                                                              primary={
                                                                <Box
                                                                  display="flex"
                                                                  gap="8px"
                                                                  className="EmailId-Info"
                                                                >
                                                                  <Tooltip
                                                                    title={
                                                                      email
                                                                    }
                                                                  >
                                                                    <Link
                                                                      href={`mailto:${email}`}
                                                                      display="block"
                                                                      underline="none"
                                                                      noWrap
                                                                    >
                                                                      {email}
                                                                    </Link>
                                                                  </Tooltip>
                                                                  <Tooltip title="Copy">
                                                                    <IconButton
                                                                      aria-label="Arrow Down"
                                                                      color="primary"
                                                                      size="small"
                                                                      className="CopyIcon"
                                                                      sx={{
                                                                        p: 0,
                                                                      }}
                                                                      onClick={() => {
                                                                        handleCopy(
                                                                          email ||
                                                                            "N/A"
                                                                        );
                                                                      }}
                                                                    >
                                                                      <ContentCopyIcon
                                                                        sx={{
                                                                          fontSize: 18,
                                                                        }}
                                                                      />
                                                                    </IconButton>
                                                                  </Tooltip>
                                                                </Box>
                                                              }
                                                            />
                                                          </ListItem>
                                                        ))}
                                                    </List>
                                                  </Collapse>
                                                )}
                                              </>
                                            }
                                          />
                                        </ListItem>
                                        <ListItem>
                                          <ListItemIcon>
                                            <LocalPhoneIcon fontSize="small" />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary="Phone No"
                                            secondary={
                                              <>
                                                <Box
                                                  display="flex"
                                                  justifyContent="space-between"
                                                  gap="8px"
                                                  className="EmailId-Info"
                                                >
                                                  <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    gap="8px"
                                                    sx={{
                                                      width:
                                                        "calc(100% - 25px)",
                                                    }}
                                                  >
                                                    <Tooltip
                                                      title={
                                                        negotiationJsonData?.vendors[0]?.phone?.split(
                                                          ","
                                                        )[0] || "N/A"
                                                      }
                                                    >
                                                      {negotiationJsonData
                                                        .vendors[0]?.phone
                                                        ? negotiationJsonData?.vendors[0]?.phone?.split(
                                                            ","
                                                          )[0]
                                                        : " N/A"}
                                                    </Tooltip>
                                                  </Box>
                                                  {negotiationJsonData?.vendors?.[0]?.phone?.split(
                                                    ","
                                                  ).length > 1 && (
                                                    <IconButton
                                                      aria-label="Arrow Down"
                                                      size="small"
                                                      sx={{ p: 0 }}
                                                      onClick={() => {
                                                        PhoneListClick(
                                                          "Vendor"
                                                        );
                                                      }}
                                                    >
                                                      {openPhoneList.Vendor ? (
                                                        <ExpandMore fontSize="small" />
                                                      ) : (
                                                        <ExpandLess fontSize="small" />
                                                      )}
                                                    </IconButton>
                                                  )}
                                                </Box>
                                                {negotiationJsonData?.vendors?.[0]?.phone?.split(
                                                  ","
                                                ).length > 1 && (
                                                  <Collapse
                                                    in={openPhoneList.Vendor}
                                                    timeout="auto"
                                                    unmountOnExit
                                                  >
                                                    <List disablePadding>
                                                      {negotiationJsonData.vendors?.[0]?.phone
                                                        .split(",")
                                                        .slice(1)
                                                        .map((email) => (
                                                          <ListItem
                                                            disableGutters
                                                          >
                                                            <ListItemText
                                                              primary={
                                                                <Box
                                                                  display="flex"
                                                                  gap="8px"
                                                                  className="EmailId-Info"
                                                                >
                                                                  <Tooltip
                                                                    title={
                                                                      email
                                                                    }
                                                                  >
                                                                    {email}
                                                                  </Tooltip>
                                                                </Box>
                                                              }
                                                            />
                                                          </ListItem>
                                                        ))}
                                                    </List>
                                                  </Collapse>
                                                )}
                                              </>
                                            }
                                          />
                                        </ListItem>
                                        <ListItem>
                                          <ListItemIcon>
                                            <ConstructionIcon fontSize="small" />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary="Industry Type"
                                            secondary={
                                              negotiationJsonData.vendors?.[0]
                                                .industry_details?.type
                                                ? negotiationJsonData
                                                    .vendors?.[0]
                                                    .industry_details?.type
                                                : "N/A"
                                            }
                                          />
                                        </ListItem>
                                        <ListItem>
                                          <ListItemIcon>
                                            <PersonIcon fontSize="small" />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary="Sender"
                                            secondary={
                                              negotiationJsonData.vendors?.[0]
                                                ?.sender || "N/A"
                                            }
                                          />
                                        </ListItem>
                                        <ListItem alignItems="flex-start">
                                          <ListItemIcon>
                                            <NavigationIcon fontSize="small" />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary="Address"
                                            secondary={
                                              negotiationJsonData.vendors?.[0]
                                                .address || "N/A"
                                            }
                                          />
                                        </ListItem>
                                        <ListItem alignItems="flex-start">
                                          <ListItemIcon>
                                            <Public fontSize="small" />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary="Country"
                                            secondary={
                                              negotiationJsonData.vendors?.[0]
                                                .country || "N/A"
                                            }
                                          />
                                        </ListItem>
                                      </PopoverDetailList>
                                    </Paper>
                                  </Popper>
                                </Box>
                              }
                              subheader={
                                <Typography
                                  gutterBottom
                                  variant="caption"
                                  color={"grey.500"}
                                  sx={{ fontSize: "12px !important" }}
                                >
                                  {negotiationJsonData.vendors?.[0]?.code}
                                </Typography>
                              }
                              sx={{
                                p: 0,
                                "& .MuiCardHeader-content": {
                                  maxWidth: "calc(100% - 60px)",
                                  flex: "0 0 auto",
                                },
                                "& .MuiCardHeader-action": {
                                  m: 0,
                                },
                                "& .MuiCardHeader-avatar": {
                                  mr: 1,
                                },
                              }}
                            />
                          </Card>
                        )}
                      </Grid>
                      <Grid item xl={2} lg={3} md={3} sm={6} xs={12}>
                        <Typography
                          component="label"
                          variant="body2"
                          color={"text.primary"}
                          fontWeight={500}
                        >
                          Buyer Details
                        </Typography>
                        <Card
                          elevation={0}
                          sx={{ lineHeight: 0, marginTop: "6px" }}
                        >
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  width: 32,
                                  height: 32,
                                  fontSize: "12px",
                                  bgcolor: "primary.500",
                                }}
                                aria-label=""
                              >
                                {stringAvatar(buyerInfo?.name)}
                              </Avatar>
                            }
                            title={
                              <Box display="flex" alignItems="center" gap="4px">
                                <Tooltip title={buyerInfo?.name}>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                    fontWeight={500}
                                    mb={0}
                                    color={"text.primary"}
                                    sx={{ fontSize: "14px !important" }}
                                    noWrap
                                  >
                                    {buyerInfo?.name}
                                  </Typography>
                                </Tooltip>
                                <IconButton
                                  aria-label="Arrow Down"
                                  size="small"
                                  sx={{ p: 0 }}
                                  onClick={buyerPopperClick}
                                >
                                  {buyerPopperOpen ? (
                                    <ExpandMore />
                                  ) : (
                                    <ExpandLess />
                                  )}
                                </IconButton>
                                <Popper
                                  id={buyerPopperId}
                                  open={buyerPopperOpen}
                                  anchorEl={buyerPopperAnchorEl}
                                  placement="bottom-start"
                                  disablePortal={true}
                                  modifiers={[
                                    {
                                      name: "flip",
                                      enabled: true,
                                      options: {
                                        altBoundary: true,
                                        rootBoundary: "document",
                                        padding: 8,
                                      },
                                    },
                                    {
                                      name: "preventOverflow",
                                      enabled: true,
                                      options: {
                                        altAxis: false,
                                        altBoundary: false,
                                        tether: false,
                                        rootBoundary: "document",
                                        padding: 8,
                                      },
                                    },
                                    {
                                      name: "arrow",
                                      enabled: true,
                                      options: {
                                        element: arrowRef,
                                      },
                                    },
                                  ]}
                                  sx={{
                                    zIndex: "99",
                                  }}
                                >
                                  <Box
                                    component="span"
                                    className="popper-arrow"
                                    ref={setArrowRef}
                                  />
                                  <Paper
                                    elevation={3}
                                    sx={{
                                      "&": {
                                        width: "290px",
                                        height: "400px",
                                        position: "relative",
                                        zIndex: "1",
                                        overflowY: "auto",
                                      },
                                    }}
                                  >
                                    <Typography
                                      sx={{ padding: "8px 16px" }}
                                      variant="body2"
                                      component="h6"
                                      fontWeight={500}
                                      color="Secondary.900"
                                    >
                                      Buyer Info
                                    </Typography>
                                    <Divider />
                                    <PopoverDetailList>
                                      <ListItem alignItems="flex-start">
                                        <ListItemIcon>
                                          <EmailIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Mail I’D"
                                          secondary={
                                            <>
                                              <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                gap="8px"
                                                className="EmailId-Info"
                                              >
                                                <Box
                                                  display="flex"
                                                  justifyContent="space-between"
                                                  gap="8px"
                                                  sx={{
                                                    width: "calc(100% - 25px)",
                                                  }}
                                                >
                                                  <Tooltip
                                                    title={
                                                      buyerInfo?.email.split(
                                                        ","
                                                      )[0] || "N/A"
                                                    }
                                                  >
                                                    {buyerInfo?.email ? (
                                                      <Link
                                                        href={`mailto:${
                                                          buyerInfo?.email.split(
                                                            ","
                                                          )[0]
                                                        }`}
                                                        display="block"
                                                        underline="none"
                                                        noWrap
                                                      >
                                                        {
                                                          buyerInfo?.email.split(
                                                            ","
                                                          )[0]
                                                        }
                                                      </Link>
                                                    ) : (
                                                      <Link
                                                        display="block"
                                                        underline="none"
                                                        noWrap
                                                      >
                                                        N/A
                                                      </Link>
                                                    )}
                                                  </Tooltip>
                                                  <Tooltip title="Copy">
                                                    <IconButton
                                                      aria-label="Arrow Down"
                                                      color="primary"
                                                      size="small"
                                                      className="CopyIcon"
                                                      sx={{ p: 0 }}
                                                      onClick={() => {
                                                        handleCopy(
                                                          buyerInfo?.email.split(
                                                            ","
                                                          )[0] || "N/A"
                                                        );
                                                      }}
                                                    >
                                                      <ContentCopyIcon
                                                        sx={{ fontSize: 18 }}
                                                      />
                                                    </IconButton>
                                                  </Tooltip>
                                                </Box>
                                                {buyerInfo?.email.split(",")
                                                  .length > 1 && (
                                                  <IconButton
                                                    aria-label="Arrow Down"
                                                    size="small"
                                                    sx={{ p: 0 }}
                                                    onClick={EmailListClick}
                                                  >
                                                    {openEmailList ? (
                                                      <ExpandMore fontSize="small" />
                                                    ) : (
                                                      <ExpandLess fontSize="small" />
                                                    )}
                                                  </IconButton>
                                                )}
                                              </Box>
                                              {buyerInfo?.email.split(",")
                                                .length > 1 && (
                                                <Collapse
                                                  in={openEmailList}
                                                  timeout="auto"
                                                  unmountOnExit
                                                >
                                                  <List disablePadding>
                                                    {buyerInfo?.email
                                                      .split(",")
                                                      .slice(1)
                                                      .map((email) => (
                                                        <ListItem
                                                          disableGutters
                                                        >
                                                          <ListItemText
                                                            primary={
                                                              <Box
                                                                display="flex"
                                                                gap="8px"
                                                                className="EmailId-Info"
                                                              >
                                                                <Tooltip
                                                                  title={email}
                                                                >
                                                                  <Link
                                                                    href={`mailto:${email}`}
                                                                    display="block"
                                                                    underline="none"
                                                                    noWrap
                                                                  >
                                                                    {email}
                                                                  </Link>
                                                                </Tooltip>
                                                                <Tooltip title="Copy">
                                                                  <IconButton
                                                                    aria-label="Arrow Down"
                                                                    color="primary"
                                                                    size="small"
                                                                    className="CopyIcon"
                                                                    sx={{
                                                                      p: 0,
                                                                    }}
                                                                    onClick={() => {
                                                                      handleCopy(
                                                                        email
                                                                      );
                                                                    }}
                                                                  >
                                                                    <ContentCopyIcon
                                                                      sx={{
                                                                        fontSize: 18,
                                                                      }}
                                                                    />
                                                                  </IconButton>
                                                                </Tooltip>
                                                              </Box>
                                                            }
                                                          />
                                                        </ListItem>
                                                      ))}
                                                  </List>
                                                </Collapse>
                                              )}
                                            </>
                                          }
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemIcon>
                                          <LocalPhoneIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Phone No"
                                          secondary={
                                            <>
                                              <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                gap="8px"
                                                className="EmailId-Info"
                                              >
                                                <Box
                                                  display="flex"
                                                  justifyContent="space-between"
                                                  gap="8px"
                                                  sx={{
                                                    width: "calc(100% - 25px)",
                                                  }}
                                                >
                                                  <Tooltip
                                                    title={
                                                      buyerInfo?.phone.split(
                                                        ","
                                                      )[0] || "N/A"
                                                    }
                                                  >
                                                    {buyerInfo?.phone
                                                      ? buyerInfo?.phone.split(
                                                          ","
                                                        )[0]
                                                      : " N/A"}
                                                  </Tooltip>
                                                </Box>
                                                {buyerInfo?.phone.split(",")
                                                  .length > 1 && (
                                                  <IconButton
                                                    aria-label="Arrow Down"
                                                    size="small"
                                                    sx={{ p: 0 }}
                                                    onClick={() => {
                                                      PhoneListClick("Buyer");
                                                    }}
                                                  >
                                                    {openPhoneList.Buyer ? (
                                                      <ExpandMore fontSize="small" />
                                                    ) : (
                                                      <ExpandLess fontSize="small" />
                                                    )}
                                                  </IconButton>
                                                )}
                                              </Box>
                                              {buyerInfo?.phone.split(",")
                                                .length > 1 && (
                                                <Collapse
                                                  in={openPhoneList.Buyer}
                                                  timeout="auto"
                                                  unmountOnExit
                                                >
                                                  <List disablePadding>
                                                    {buyerInfo?.phone
                                                      .split(",")
                                                      .slice(1)
                                                      .map((email) => (
                                                        <ListItem
                                                          disableGutters
                                                        >
                                                          <ListItemText
                                                            secondary={
                                                              <Box
                                                                display="flex"
                                                                gap="8px"
                                                                className="EmailId-Info"
                                                              >
                                                                <Tooltip
                                                                  title={email}
                                                                >
                                                                  {email}
                                                                </Tooltip>
                                                              </Box>
                                                            }
                                                          />
                                                        </ListItem>
                                                      ))}
                                                  </List>
                                                </Collapse>
                                              )}
                                            </>
                                          }
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemIcon>
                                          <BusinessIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Company Name"
                                          secondary={
                                            buyerInfo?.company_name
                                              ? buyerInfo?.company_name
                                              : "N/A"
                                          }
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemIcon>
                                          <AccountCircleIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Account Name"
                                          secondary={
                                            buyerInfo?.acc_name
                                              ? buyerInfo?.acc_name
                                              : "N/A"
                                          }
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemIcon>
                                          <AccountCircleIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Account Code"
                                          secondary={
                                            buyerInfo?.acc_code
                                              ? buyerInfo?.acc_code
                                              : "N/A"
                                          }
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemIcon>
                                          <WorkOutline fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Designation"
                                          secondary={
                                            buyerInfo?.designation
                                              ? buyerInfo?.designation
                                              : "N/A"
                                          }
                                        />
                                      </ListItem>
                                      <ListItem alignItems="flex-start">
                                        <ListItemIcon>
                                          <NavigationIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Address"
                                          secondary={
                                            buyerInfo?.address
                                              ? buyerInfo?.address
                                              : "N/A"
                                          }
                                        />
                                      </ListItem>
                                      <ListItem alignItems="flex-start">
                                        <ListItemIcon>
                                          <Public fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary="Country"
                                          secondary={
                                            buyerInfo?.country
                                              ? buyerInfo?.country
                                              : "N/A"
                                          }
                                        />
                                      </ListItem>
                                    </PopoverDetailList>
                                  </Paper>
                                </Popper>
                              </Box>
                            }
                            subheader={
                              <Typography
                                gutterBottom
                                variant="caption"
                                color={"grey.500"}
                                sx={{ fontSize: "12px !important" }}
                              >
                                {buyerInfo?.phone || "N/A"}
                              </Typography>
                            }
                            sx={{
                              p: 0,
                              "& .MuiCardHeader-content": {
                                maxWidth: "calc(100% - 60px)",
                                flex: "0 0 auto",
                              },
                              "& .MuiCardHeader-action": {
                                m: 0,
                              },
                              "& .MuiCardHeader-avatar": {
                                mr: 1,
                              },
                            }}
                          />
                        </Card>
                      </Grid>
                      {MassNego ? (
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <Typography
                            component="label"
                            variant="body2"
                            color={"text.primary"}
                            fontWeight={500}
                          >
                            RFQ Details
                          </Typography>
                          <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={2}
                          >
                            <Box>
                              <Typography
                                component="label"
                                variant="body2"
                                color={"grey.500"}
                              >
                                RFQ Start Date
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.primary"
                                fontWeight={500}
                              >
                                {dayjs(
                                  negotiationJsonData?.created_at,
                                  "YYYY-MM-DD HH:mm:ss"
                                ).format(
                                  `DD/MM/YYYY HH:mm:ss [${negotiationJsonData?.created_at
                                    ?.split(" ")
                                    .pop()}]`
                                )}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                component="label"
                                variant="body2"
                                color={"grey.500"}
                              >
                                RFQ End Date
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.primary"
                                fontWeight={500}
                              >
                                {dayjs(
                                  negotiationJsonData?.expiry_at,
                                  "YYYY-MM-DD HH:mm:ss"
                                ).format(
                                  `DD/MM/YYYY HH:mm:ss [${negotiationJsonData?.expiry_at
                                    ?.split(" ")
                                    .pop()}]`
                                )}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                component="label"
                                variant="body2"
                                color={"grey.500"}
                              >
                                RFQ No
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.primary"
                                fontWeight={500}
                              >
                                {negotiationJsonData?.ref_no}
                              </Typography>
                            </Box>
                          </Stack>
                        </Grid>
                      ) : (
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <Typography
                            component="label"
                            variant="body2"
                            color={"text.primary"}
                            fontWeight={500}
                          >
                            {`${lastTxn?.type} Details`}
                          </Typography>
                          <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={2}
                          >
                            <Box>
                              <Typography
                                component="label"
                                variant="body2"
                                color={"grey.500"}
                              >
                                {`${lastTxn?.type} Date`}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.primary"
                                fontWeight={500}
                              >
                                {dayjs(
                                  lastTxn?.action_date,
                                  "YYYY-MM-DD HH:mm:ss"
                                ).format(
                                  `DD/MM/YYYY HH:mm:ss [${lastTxn?.action_date
                                    ?.split(" ")
                                    .pop()}]`
                                )}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                component="label"
                                variant="body2"
                                color={"grey.500"}
                              >
                                {`${lastTxn?.type} Validity Date`}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.primary"
                                fontWeight={500}
                              >
                                {dayjs(
                                  lastTxn?.validity_date,
                                  "YYYY-MM-DD HH:mm:ss"
                                ).format(
                                  `DD/MM/YYYY HH:mm:ss [${lastTxn?.validity_date
                                    ?.split(" ")
                                    .pop()}]`
                                )}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                component="label"
                                variant="body2"
                                color={"grey.500"}
                              >
                                {`${lastTxn?.type} Ref No`}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.primary"
                                fontWeight={500}
                              >
                                {lastTxn?.no}
                              </Typography>
                            </Box>
                          </Stack>
                        </Grid>
                      )}

                      {/* Start Header Nego Details Mobile Responsive UI Display */}
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        sx={{ display: { xs: "block", md: "none" } }}
                      >
                        <Typography
                          component="label"
                          variant="body2"
                          color={"grey.500"}
                        >
                          RFQ No
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.primary"
                          fontWeight={500}
                        >
                          {negotiationJsonData?.ref_no}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        sx={{ display: { xs: "block", md: "none" } }}
                      >
                        {negotiationJsonData &&
                          negotiationJsonData?.vendors &&
                          negotiationJsonData?.vendors[0] &&
                          negotiationJsonData?.vendors[0]
                            ?.transaction_thread && (
                            <>
                              {negotiationJsonData?.vendors[0]?.transaction_thread.map(
                                (item, index) => {
                                  if (
                                    item?.template &&
                                    index ===
                                      negotiationJsonData?.vendors[0]
                                        ?.transaction_thread.length -
                                        1
                                  ) {
                                    return (
                                      <React.Fragment key={index}>
                                        <Typography
                                          component="label"
                                          variant="body2"
                                          color={"grey.500"}
                                        >
                                          {item?.no && "Last Quote No:"}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color="text.primary"
                                          fontWeight={500}
                                        >
                                          {item?.no}
                                        </Typography>
                                      </React.Fragment>
                                    );
                                  } else {
                                    return null; // Return null for items that don't satisfy the condition
                                  }
                                }
                              )}
                            </>
                          )}
                      </Grid>
                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={3}
                        sm={6}
                        xs={12}
                        sx={{ display: { xs: "block", md: "none" } }}
                      >
                        <Typography
                          component="label"
                          variant="body2"
                          color={"grey.500"}
                        >
                          Category Name
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.primary"
                          fontWeight={500}
                        >
                          {categoryName}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={3}
                        sm={6}
                        xs={12}
                        sx={{ display: { xs: "block", md: "none" } }}
                      >
                        <Typography
                          component="label"
                          variant="body2"
                          color={"grey.500"}
                        >
                          Category Code{" "}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.primary"
                          fontWeight={500}
                        >
                          {categoryCode}
                        </Typography>
                      </Grid>
                      {/* Start Header Nego Details Mobile Responsive UI Display */}
                    </Grid>
                  </CardContent>
                </Collapse>
              </Card>
            )}
          {/* End Nego Details Section HTML */}

          {Object.keys(negotiationJsonData).length > 0 &&
            Object.keys(JsonTemplate).length > 0 &&
            (viewTxn == true ? (
              <NegotiationTemplateView
                setClick={setClick}
                resetSettingDrawer={props.resetSettingDrawer}
                JsonTemplate={JsonTemplate}
                lastTransactionData={lastTransactionData}
                negotiationJsonData={negotiationJsonData}
                inquiryTransaction={inquiryTransaction}
                props={props}
                location={props.location}
                match={props.location}
                destinationDetails={destinationDetails}
                currencyDetails={currencyDetails}
                currencyOptions={currencyOptions}
                lastTransactionInThread={lastTransactionInThread}
                lastTransactionTypeInThread={lastTransactionTypeInThread}
                fileSpecificationData={fileSpecificationData}
                CurrentTransaction={CurrentTransaction}
                filteredSelectedNotData={filteredSelectedNotData}
                termsAndConditionsDetails={termsAndConditionsDetails}
                vendorType={vendorType}
                categoryId={categoryId}
                IsLastTxn={IsLastTxn}
                PrevTransaction={PrevTransaction}
                items={items}
                vendorId={vendorId}
                inquiryItemsId={inquiryItemsId}
                itemSpecific={itemSpecific}
                multiplePlantsFlag={multiplePlantsFlag}
                slabFlag={slabFlag}
                multipleSolutionFlag={multipleSolutionFlag}
                lastTransactionInThreadCommon={lastTransactionInThreadCommon}
                transactionItemSequence={transactionItemSequence}
                quotedCurrency={quotedCurrency}
                PermissionInquiry={PermissionInquiry}
                inquiryId={inquiryId}
                isServiceRfq={
                  negotiationJsonData.template?.type === "service"
                    ? true
                    : false
                }
                isMultipleCurrency={
                  negotiationJsonData.template?.service_multiple_currency
                    ? true
                    : false
                }
                AlternativeNames={AlternativeNames}
                isSupplier={queryParams.get("supplier") ? true : false}
                regretJustificationMessage={regretJustificationMessage}
                handleRegretMessage={handleRegretMessage}
                SessionID={sessionId}
                tlGuid={queryParams.get("TLGUID")}
                getRegret={getRegret}
                OfflineRegretFlag={OfflineRegretFlag}
                ViewJustificationFlag={(flag) => {
                  setViewJustification(flag);
                }}
                lastTxnGuid={lastTxn?.guid}
              />
            ) : (
              (Object.keys(fetchedTxn).length > 0 || lastTxnInquiryState) && (
                <NegotiationTemplate
                  setClick={setClick}
                  resetSettingDrawer={props.resetSettingDrawer}
                  JsonTemplate={JsonTemplate}
                  lastTransactionData={lastTransactionData}
                  lastNegotiationData={lastNegotiationData}
                  headerLabels={headerLabels}
                  transactionItemSequence={transactionItemSequence}
                  negotiationJsonData={negotiationJsonData}
                  lastTransactionGuidInThread={lastTransactionGuidInThread}
                  props={props}
                  location={props.location}
                  match={props.location}
                  destinationDetails={destinationDetails}
                  currencyDetails={currencyDetails}
                  currencyOptions={currencyOptions}
                  lastTransactionInThread={lastTransactionInThread}
                  lastTransactionTypeInThread={lastTransactionTypeInThread}
                  fileSpecificationData={fileSpecificationData}
                  filteredSelectedNotData={filteredSelectedNotData}
                  vendorType={vendorType}
                  categoryId={categoryId}
                  categoryName={categoryName}
                  termsAndConditionsDetails={termsAndConditionsDetails}
                  items={items}
                  transactionType={transactionType}
                  inquiryId={inquiryId}
                  vendorId={vendorId}
                  featurePermissionDetails={featurePermissionDetails}
                  validityDate={validityDate}
                  itemBoxId={itemBoxId}
                  industryDetails={industryDetails}
                  newSessionID={newSessionID}
                  inquiryItemsId={
                    MassItemIds.length > 0 ? MassItemIds : inquiryItemsId
                  }
                  itemSpecific={itemSpecific}
                  CurrentTransaction={CurrentTransaction}
                  multipleSolutionFlag={multipleSolutionFlag}
                  slabFlag={slabFlag}
                  shipmentMark={shipmentMark}
                  fetchedTxn={fetchedTxn}
                  quotedCurrency={quotedCurrency}
                  multiplePlantsFlag={multiplePlantsFlag}
                  lastTransactionInThreadCommon={lastTransactionInThreadCommon}
                  PermissionInquiry={PermissionInquiry}
                  isServiceRfq={
                    negotiationJsonData.template?.type === "service"
                      ? true
                      : false
                  }
                  isMultipleCurrency={
                    negotiationJsonData.template?.service_multiple_currency
                      ? true
                      : false
                  }
                  activity_template_id={
                    negotiationJsonData.template?.service_template_id
                  }
                  ServiceFileName={ServiceFileName}
                  AlternativeNames={AlternativeNames}
                  MassNego={MassNego}
                  Vendors={Vendors}
                  AllInquiryVendors={AllInquiryVendors}
                  multipleVendors={
                    props.multipleVendors ? props.multipleVendors : false
                  }
                  SubmitMultipleNegotiaion={props.SubmitMultipleNegotiaion}
                  termsConditionChecked={props.termsConditionChecked}
                  resetFlag={props.resetFlag}
                  errorFlag={props.errorFlag}
                  openAlert={props.openAlert}
                  selectedCurrency={props.selectedCurrency}
                  combinedSubmit={props.combinedSubmit}
                  resetCombinedSubmitFlag={props.resetCombinedSubmitFlag}
                  sucessMessage={props.sucessMessage}
                  sucessFlg={props.sucessFlg}
                  submitflg={props.submitflg}
                  tabValue={props?.tabValue ? props.tabValue : "common"}
                />
              )
            ))}
        </Box>
        {negotiationJsonData &&
        negotiationJsonData.vendors &&
        negotiationJsonData.vendors[0] ? (
          <ViewDetailsDrawer
            vendorDetails={negotiationJsonData.vendors[0]}
            isViewDetailsDrawerOpened={viewDetails}
            isViewDetailsDrawerClosed={() => setViewDetails(false)}
            buyerInfo={buyerInfo}
          />
        ) : (
          ""
        )}

        {negotiationJsonData &&
        negotiationJsonData.vendors &&
        negotiationJsonData.vendors[0] ? (
          <QuotationThreadDrawer
            isQuotationDrawerOpened={transactionThread}
            isQuotationDrawerClosed={() => setTransactionThread(false)}
            transactionThread={
              negotiationJsonData.vendors[0].transaction_thread
            }
            vendorDetails={negotiationJsonData.vendors[0]}
            buyerDetails={buyerInfo}
            rfqDate={rfqDate}
            referenceNo={referenceNo}
            AccountUsers={AccountUsers}
            CurrentTransaction={CurrentTransaction}
            newSessionID={newSessionID}
            filterData={negotiationJsonData?.filter_data}
          />
        ) : (
          ""
        )}

        <SendMail
          CloseSendMailDrawer={CloseSendMailDrawer}
          openSendMailDrawer={openSendMailDrawer}
          transactionData={negotiationJsonData}
          buyerInfo={buyerInfo}
          userInfo={userInfo}
          newSessionID={newSessionID}
          transactionThread={
            negotiationJsonData.vendors?.[0]?.transaction_thread
          }
        />
        {openResendMailDrawer && (
          <ResendMail
            CloseResendMailDrawer={CloseResendMailDrawer}
            openResendMailDrawer={openResendMailDrawer}
            transactionData={negotiationJsonData}
            buyerInfo={buyerInfo}
            userInfo={userInfo}
            newSessionID={newSessionID}
            inquiryId={inquiryId}
            ResendMailVendorDetails={ResendMailVendorDetails}
            Vendors={AllInquiryVendors}
            currentTxn={CurrentTransaction}
          />
        )}
        {OpenItemwiseDilogue && (
          <ItemWiseVendor
            openDialogueBox={OpenItemwiseDilogue}
            handleCloseDialogue={handleCloseDialogue}
            MassItemIds={MassItemIds}
            TransactionFilteredItems={TransactionFilteredItems}
            Vendors={Vendors}
          />
        )}

        <Dialog
          onClose={handleCloseRegretBox}
          aria-labelledby="customized-dialog-title"
          open={openRegretDialogBox}
          fullWidth={true}
          maxWidth={"xs"}
        >
          <DialogTitle
            sx={{
              "&.MuiTypography-root": {
                p: 2,
              },
            }}
          >
            View Regret Justification
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseRegretBox}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Typography gutterBottom>{regretJustificationMessage}</Typography>
          </DialogContent>
        </Dialog>
      </main>
      <CustomSnackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }} // Positioning Snackbar at top-right
      >
        {
          <CustomAlert
            onClose={handleCloseSnackbar}
            severity="error"
            variant="filled"
          >
            {snackbar.message}
          </CustomAlert>
        }
      </CustomSnackbar>
    </Fragment>
  ) : (
    <Card
      sx={{
        backgroundColor: "#ffebee",
        borderColor: "#d32f2f",
        borderWidth: 1,
        borderStyle: "solid",
      }}
    >
      <CardContent>
        <Typography sx={{ color: "#d32f2f" }}>{mainValidationError}</Typography>
      </CardContent>
    </Card>
  );
}

export default NegotiationForm;
