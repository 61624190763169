import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";

export const DefaultTermsStyle = styled(Drawer)(({ theme }) => ({
  "&.SidebarDrawer": {
    ".MuiPaper-root": {
      boxShadow: "none",
      borderRadius: "0",
    },
    "& .MuiDrawer-paper": {
      width: "500px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .MuiCard-root": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    "& .MuiCardContent-root": {
      padding: 0,
      flex: "1 1 auto",
      overflow: "auto",
    },
    "& .MuiTableHead-root tr th": {
      paddingTop: "8px",
      paddingBottom: "8px",
    },
  },
}));
