import React, { Component } from "react";
import {
  Avatar,
  Typography,
  Box,
  Tooltip,
  InputBase,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { connect } from "react-redux";
import { DataGridPro, GridActionsCell, GridActionsCellItem } from "@mui/x-data-grid-pro";
import {
  getItemizedVendors,
  filterItemizedVendors,
} from "../../../redux/actions/vendorActions";
import {
  getFilteredItemizedVendors,
  getLinkedItemizedVendors,
  searchItemizedVendorsByNameOrCode,
} from "../../../config/apiUrl";
import axios from "axios";
import { getFilterOperators } from "../../Utils/datagridHelper";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { debounce } from "lodash";
import { setSelectedVendors } from "../../../redux/actions/vendorActions";
import { MoreVert } from "@mui/icons-material";
import { getVendorGMPFlag } from "../../Vendors/VendorChip";

function QuickSearchToolbar(props) {
  return (
    <Box
      borderTop={1}
      sx={{
        borderBottom: "1px solid",
        borderColor: (theme) => theme.palette.grey[500],
      }}>
      <InputBase
        sx={{
          backgroundColor: (theme) => theme.palette.grey[50],
          width: "100%",
          height: "46px",
          borderRadius: "0",
          border: 0,
          padding: "0 16px",
          "& .MuiInputAdornment-positionStart .MuiSvgIcon-root": {
            color: (theme) => theme.palette.grey[600],
          },
          "& .search-clear svg": {
            width: "18px",
            height: "18px",
            color: (theme) => theme.palette.grey[500],
          },
        }}
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        }
        endAdornment={
          <IconButton
            className="search-clear"
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{ visibility: props.value ? "visible" : "hidden" }}
            onClick={props.clearSearch}>
            <ClearIcon />
          </IconButton>
        }
      />
    </Box>
  );
}

class ItemizedVendors extends Component {
  state = {
    rows: [],
    loading: false,
    total: 0,
    filteredTotal: 0,
    filteredRows: [],
    filterLoading: false,
    vendorPage: 0,
    isFilterActive: false,
    filterDataCurrentPage: 0,
    vendor_name: "",
    vendor_name_operator: "",
    vendor_code: "",
    vendor_code_operator: "",
    industry_type: "",
    industry_type_operator: "",
    country: "",
    country_operator: "",
    filterModel: {
      items: [],
    },
    searchText: "",
    vendorSearchDebounceDelay: false,
    searchedRows: [],
    searchedDataCurrentPage: 0,
    searchededTotal: 0,
  };

  componentDidMount() {
    this.fetchItemizedVendors();
  }

  fetchItemizedVendors = () => {
    this.setState({
      loading: true,
    });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;

    let plant = undefined;

    if (
      this.props.accountModulePermission[
        "Plant Specific Categroy Item Vendor Linking"
      ].module_activated === "Y"
    ) {
      if (
        this.props.selectedCategory?.id ===
        this.props.selectedPrCategory?.category?.id
      ) {
        plant = this.props.selectedPrCategory.plant.code;
      }
    }
    const url = getLinkedItemizedVendors(
      this.props.item?.id,
      this.props.selectedCategory?.id,
      this.state.vendorPage + 1,
      plant
    );

    const queryParam = this.props.getVendorLocationFlag 
      ? `&vendor_classification=${JSON.stringify(this.props.getVendorLocationFlag)}` 
      : '';
    axios
      .get(url + `&locality=${this.props.isRecipients}${queryParam}`)
      .then((res) => {
        this.setState({
          loading: false,
          rows: res.data.data,
          ...(!this.state.total && { total: res.data.meta.total }),
        });
        // this.props.setSelectedVendors(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  filterItemizedVendors = () => {
    this.setState({
      filterLoading: true,
    });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;

    const url = getFilteredItemizedVendors(
      this.props.item.id,
      this.props.selectedCategory.id,
      this.state.vendor_name.trim(),
      this.state.vendor_name_operator,
      this.state.vendor_code.trim(),
      this.state.vendor_code_operator,
      this.state.country.trim(),
      this.state.country_operator,
      this.state.industry_type.trim(),
      this.state.industry_type_operator,
      this.state.filterDataCurrentPage + 1
    );

    axios
      .get(url + `&locality=${this.props.isRecipients}`)
      .then((res) => {
        this.setState({
          filterLoading: false,
          filteredRows: res.data.data,
          ...(!this.state.filteredTotal && {
            filteredTotal: res.data.meta.total,
          }),
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          filterLoading: false,
        });
      });
  };

  //for vendors pagination
  handlePageChange = (page) => {
    if (this.state.isFilterActive) {
      this.setState(
        {
          filterDataCurrentPage: page,
        },
        () => {
          this.filterItemizedVendors();
        }
      );
    } else {
      if (this.state.searchText.trim() === "") {
        this.setState(
          {
            vendorPage: page,
          },
          () => {
            this.fetchItemizedVendors();
          }
        );
      } else {
        this.setState(
          {
            searchedDataCurrentPage: page,
          },
          () => {
            this.searchVendors();
          }
        );
      }
    }
  };

  handleFilterModelChange = (filterModel) => {
    let vendor_name = "";
    let vendor_name_operator = "";
    let vendor_code = "";
    let vendor_code_operator = "";
    let country = "";
    let country_operator = "";
    let industry_type = "";
    let industry_type_operator = "";

    this.setState({
      filterModel,
    });
    filterModel.items.forEach((filter) => {
      if (filter.value) {
        if (filter.field === "vendor_name") {
          vendor_name = filter.value;
          vendor_name_operator = filter.operator;
        } else if (filter.field === "vendor_code") {
          vendor_code = filter.value;
          vendor_code_operator = filter.operator;
        } else if (filter.field === "country") {
          country = filter.value;
          country_operator = filter.operator;
        } else if (filter.field === "industry_type_value") {
          industry_type = filter.value;
          industry_type_operator = filter.operator;
        }
      }
    });
    //api call
    if (vendor_name || vendor_code || country || industry_type) {
      // console.log("filter api calling");
      this.setState(
        {
          isFilterActive: true,
          vendor_name,
          vendor_name_operator,
          vendor_code,
          vendor_code_operator,
          country,
          country_operator,
          industry_type,
          industry_type_operator,
          filterDataCurrentPage: 0,
          filteredRows: [],
          filteredTotal: 0,
        },
        () => {
          this.filterItemizedVendors();
        }
      );
    } else {
      this.setState({
        isFilterActive: false,
        vendor_name,
        vendor_code,
        country,
        industry_type,
        filterDataCurrentPage: 0,
        filteredRows: [],
      });
    }
  };

  getVendorLocation = (country) => {
    if (this.props.accountlevelInfo?.country === country) {
      return "L";
    } else return "I";
  };

  requestSearch = (searchValue) => {
    this.setState({
      loading: true,
      searchText: searchValue,
    });

    if (searchValue.trim() !== "") {
      this.setState(
        {
          isFilterActive: false,
          vendorSearchDebounceDelay: true,
          loading: true,
          searchedRows: [],
          searchedDataCurrentPage: 0,
          searchededTotal: 0,
          filterModel: {
            items: [],
          },
        },
        () => {
          this.getSearchApi();
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  getSearchApi = debounce(() => {
    this.setState({
      vendorSearchDebounceDelay: false,
    });
    this.searchVendors();
  }, 1000);

  searchVendors = () => {
    const url = searchItemizedVendorsByNameOrCode(
      this.props.item.id,
      this.props.selectedCategory.id,
      this.state.searchText.trim(),
      this.state.searchedDataCurrentPage + 1
    );

    this.setState({
      loading: true,
    });

    axios
      .get(url + `&locality=${this.props.isRecipients}`)
      .then((res) => {
        this.setState({
          loading: false,
          searchedRows: res.data.data,
          ...(!this.state.searchededTotal && {
            searchededTotal: res.data.meta.total,
          }),
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log("searched vendors err", err);
      });
  };

  render() {
    const columns = [
      {
        field: "actions",
      type: "actions",
      width: 80,
      resizable: false,
      hide:
        !(
          this.props.userInfo?.is_super_user === "Y" ||
          this.props.masterPermission.vendor_master === "Y"
        ) || this.props.isRecipients,
      getActions: (params) => [
        <GridActionsCellItem
          className="dropdown_menu_with_icon"
          // icon={<MoreVert />}
          label="Edit Vendor"
          showInMenu
          onClick={() => {
            // deleteUser(params.id);
            this.props.handleAddVendorDialogOpen(
              params.id,
              params.row.vendor_name
            );
          }}
        />,
      ],
    },
      {
        field: "vendor_name",
        headerName: "VENDOR NAME",
        width: 350,
        editable: false,
        filterable: this.state.searchText.trim() === "",
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return (
            <React.Fragment>
              <Avatar
                sx={{
                  backgroundColor: (theme) => theme.palette.success.main,
                }}>
                {params.row.vendor_name.slice(0, 1)}
              </Avatar>
              <Box sx={{ lineHeight: "12px", ml: 1 }}>
                <Tooltip title={params.row.vendor_name}>
                  <Typography color="text.primary" fontWeight={500} noWrap>
                    {params.row.vendor_name}
                  </Typography>
                </Tooltip>
                <Typography variant="span" color="grey.500">
                  {params.row.vendor_code}
                </Typography>
              </Box>
            </React.Fragment>
          );
        },
      },
      {
        field: "vendor_status",
        headerName: "VENDOR STATUS",
        width: 150,
        editable: false,
        filterable: this.state.searchText.trim() === "",
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return (
            <React.Fragment>
              <Typography fontWeight={500}>
                {params.row.is_approved === "approved" ? 
                  getVendorGMPFlag("Y", "A", "Approved", 'A', "") : 
                  getVendorGMPFlag("Y", "N", "Non-Approved", 'N', "")
                }
                {getVendorGMPFlag(this.props.companyInfo.gst_verification, params.row.gstin_no, params.row.gstin_status, 'G', "GSTIN")}
                {params.row.industry_type === "MSME" ? 
                  getVendorGMPFlag(this.props.companyInfo.msme_verification, params.row.industry_type_value, params.row.msme_status, 'M', "MSME") : 
                  getVendorGMPFlag(this.props.companyInfo.msme_verification, "", params.row.msme_status, 'M', "MSME")
                }
                {getVendorGMPFlag(this.props.companyInfo.pan_verification, params.row.pan_no, params.row.pan_status, 'P', "PAN")}
              </Typography>
            </React.Fragment>
          );
        },
        sortable: false,
      },
      {
        field: "country",
        headerName: "COUNTRY",
        width: 150,
        editable: false,
        filterable: this.state.searchText.trim() === "",
        filterOperators: getFilterOperators(),
        // sortable: false,
      },
      {
        field: "industry_type",
        headerName: "INDUSTRY TYPE",
        width: 200,
        editable: false,
        filterable: this.state.searchText.trim() === "",
        filterOperators: getFilterOperators(),
        // sortable: false,
      },
    ];

    const rows = this.state.isFilterActive
      ? this.state.filteredRows
      : this.state.searchText.trim() === ""
      ? this.state.rows
      : this.state.searchedRows;

    return (
      <div style={{ height: 300, width: "100%" }}>
        <DataGridPro
          columnVisibilityModel={{
            actions: !(!(
              this.props.userInfo?.is_super_user === "Y" ||
              this.props.masterPermission.vendor_master === "Y"
            ) || this.props.isRecipients)
          }}
          slots={{ toolbar: QuickSearchToolbar }}
          slotProps={{
            toolbar: {
              value: this.state.searchText,
              onChange: (event) => this.requestSearch(event.target.value),
              clearSearch: () => this.requestSearch(""),
            },
          }}
          columns={columns}
          rows={rows}
          page={
            this.state.isFilterActive
              ? this.state.filterDataCurrentPage
              : this.state.searchText.trim() === ""
              ? this.state.vendorPage
              : this.state.searchedDataCurrentPage
          }
          loading={
            this.state.isFilterActive
              ? this.state.filterLoading
              : this.state.loading
          }
          rowCount={
            this.state.isFilterActive
              ? this.state.filteredTotal
              : this.state.searchText.trim() === ""
              ? this.state.total
              : this.state.searchededTotal
          }
          disableRowSelectionOnClick
          disableColumnSelector
          disableColumnPinning
          pagination
          paginationMode="server"
          onPageChange={this.handlePageChange}
          pageSize={15}
          rowsPerPageOptions={[15]}
          filterModel={this.state.filterModel}
          onFilterModelChange={this.handleFilterModelChange}
          filterMode="server"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  sessionId: state.sessionReducer.sessionId,
  userInfo: state.userInfoReducer.userInfo,
  accountlevelInfo: state.permissionsReducer.accountlevelInfo,
  masterPermission: state.permissionsReducer.masterPermission,
  accountModulePermission: state.permissionsReducer.accountModulePermission,
  selectedPrCategory: state.prrfqreducer.selectedPrCategory,
  companyInfo: state.companyInfoReducer.companyInfo,
  getVendorLocationFlag: state.itemReducer.vendorLocationFlag
});

export default connect(mapStateToProps, {
  getItemizedVendors,
  filterItemizedVendors,
  setSelectedVendors,
})(ItemizedVendors);
