import { GlobalStyles } from "@mui/material";
import * as React from "react";

export const RfqFormViewStyle=(
    <GlobalStyles
        styles={(theme) => ({
            ".quotation_template_table":{
                "& .MuiTableHead-root": {
                    backgroundColor: "var(--bluegrey-500)",
                    height:"40px",
                    "& .MuiTableCell-root .MuiTypography-root":{
                        fontSize:"12px"
                    }
                },
                "& .MuiTableHead-root ": {},
                "& .MuiTableBody-root": {
                    backgroundColor: "var(--grey-0)",
                },
                "& tr": {
                    whiteSpace: "nowrap",
                },
                "& .MuiTableHead-root tr th": {
                    padding: "6px 10px",
                    backgroundColor:"var(--grey-50)",
                    zIndex:"11"
                },
                "& .MuiTableBody-root tr td": {
                    padding: "6px 10px",
                },
                ".MuiTableBody-root tr td:first-of-type, .MuiTableHead-root tr th:first-of-type ":{
                    position: "sticky",
                    left: "0",
                    // minWidth: "380px",
                    width: "380px",
                    zIndex:"12",
                    backgroundColor:"var(--grey-50)",
                    [theme.breakpoints.down('md')]: {
                        // position: "static",
                        left:"auto"
                    }
                },
                // ".SlabRange-Body > tr > td:first-of-type, .MultiSolution-Body > tr > td:first-of-type": {
                //     width:"450px",
                // },
                ".SlabDetailsForm":{
                    "& .MuiTableHead-root tr th":{
                        backgroundColor:"var(--grey-100)",
                        "tr th:first-of-type":{
                            backgroundColor:"var(--grey-100)",
                        }
                    },                  
                    "& .MuiTableBody-root tr td":{
                        backgroundColor:"var(--grey-50)",
                        ":first-of-type":{
                            backgroundColor:"var(--grey-50)",
                        }
                    }
                },
                ".SlabRange-Body tr td:not(:first-of-type), .MultiSolution-Body tr td:not(:first-of-type)": {
                    minWidth: "200px",
                    maxWidth: "200px",
                },
                "& .MuiTableBody-root tr td:first-of-type, & .MuiTableBody-root tr td:first-of-type": {
                    backgroundColor:"var(--grey-0)",
                    zIndex: "3",
                },
                "& .MuiTableBody-root .MuiFormControl-root .MuiInputBase-root":{
                    backgroundColor:"var(--grey-0)",
                },
                '& .MuiTableBody-root .MuiTypography-root':{
                    fontSize:"14px"
                },
                "& .MuiFormControlLabel-label" :{
                    fontSize: "14px",
                    fontWeight: "500"
                },
                '& .MuiCheckbox-root, .MuiFormControl-root .MuiButtonBase-root:not(.MuiInputAdornment-root .MuiButtonBase-root)': {
                    padding: "2px",
                    marginRight:"8px",
                },
                "& .MuiFormControlLabel-asterisk":{
                    color:"var(--error-900)"
                },
                "& .MuiFormControlLabel-root":{
                    marginLeft:"-5px"
                },
                // "& .MuiTableHead-root tr th:last-child":{
                //     textAlign: "right"
                // },                
                "& .MuiTableCell-root .MuiInputBase-input":{
                    fontSize:"14px",
                }
            },
            "& .EmailId-Info":{                
                ":hover .CopyIcon":{
                    visibility:"visible"
                },
                ".CopyIcon":{
                    visibility:"hidden",
                    [theme.breakpoints.down('md')]: {
                        visibility:"visible"
                    }
                }                
            },            
            "& [data-popper-placement*='bottom-start'] .popper-arrow": {
                top: '0',
                left: '0px',
                marginTop: '-7px',
                width: '24px',
                height: '11px'
            },                                
            "& [data-popper-placement*='bottom-start'] .popper-arrow::before": {
                borderWidth: '0px 11px 11px',
                borderColor: 'transparent transparent rgb(255, 255, 255)'
            },
            "& .popper-arrow":{
                "::before": {
                    content: '""',
                    margin: 'auto',
                    display: 'block',
                    width: '0px',
                    height: '0px',
                    borderStyle: 'solid',
                },
                "::after":{
                    content: '""',
                    position: 'absolute',
                    top: '4px',
                    left: '4px',                                  
                    borderLeft: '8px solid transparent',
                    borderRight: '8px solid transparent',
                    borderBottom: '8px solid black',
                    filter:'blur(3px)',
                    zIndex: '-1'
                }
            },
            "&.skiptranslate iframe.skiptranslate":{
                zIndex: 1001
            },
            "& .GoogleTranslate-Box":{
                [theme.breakpoints.down('sm')]: {
                    "#google_translate_element":{                                    
                        height:"26px"
                    },
                },
                [theme.breakpoints.up('sm')]: {
                    ".skiptranslate div": {
                        top: "7px",
                        position: "relative",
                    }
                },
                ".skiptranslate.goog-te-gadget": {
                    color: "#fff",
                    [theme.breakpoints.down('sm')]: {
                        height:"26px"
                    }
                },
                ".skiptranslate.goog-te-gadget span": {
                    display: "none"
                },           
                ".goog-te-gadget .goog-te-combo": {
                    backgroundColor:"var(--grey-0)",
                    fontFamily:'"Roboto","Helvetica","Arial",sans-serif',
                    margin: "4px 0",
                    height: "26px",
                    borderColor: "var(--bluegrey-500)",
                    borderRadius: "4px",
                    outline:"0"
                },            
                ".skiptranslate div:before": {
                    width: "1px",
                    height: "24px",
                    content: '""',
                    background: "var(--bluegrey-500)",
                    position: "absolute",
                    right: "20px",
                    zIndex: "1",
                    display: "block",
                    top: "5px"
                },
            },
            "& .translated-rtl .datagrid-PageToolbar, & .translated-ltr .datagrid-PageToolbar":{
                top: "40px"
            },
            "&.translated-rtl .LanguageSelect-Alert, &.translated-ltr .LanguageSelect-Alert":{
                display:"flex !important"
            }
        })}
    />
)