import React, { useState, useEffect } from "react";
import _, { debounce } from "lodash";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LandedCostRFQRight } from "../LandedCostRFQDrawer/style";
import { makeStyles } from "@mui/styles";
import { getSymbol } from "../../AccountTemplate/CurrencySymbol";
import { styled } from "@mui/material/styles";
import { RFQQtyRight } from "./style";

const drawerWidth = 350;

const useStyles = makeStyles(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export default function MultiplantQty({ open, close, itemData, Unit, item }) {
  const classes = useStyles();
  //  const handleClose = () => {
  //   close()
  //  }
  const totalQuantity = itemData.reduce((sum, item) => sum + item.quantity, 0);

  return (
    <React.Fragment>
      <RFQQtyRight
        onClose={close}
        open={open}
        anchor="right"
        className="RFQQty-Sidebar"
      >
        <Card variant="outlined">
          <CardHeader
            title={
              <>
                <Typography variant="body2" fontSize="14px" fontWeight={500}>
                  {item.name}
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  color="text.secondary"
                >
                  {item.code}
                </Typography>
              </>
            }
            action={
              <Tooltip title="Close">
                <IconButton
                  onClick={close}
                  aria-label="close"
                  size="small"
                  sx={{ color: "var(--grey-500)" }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            }
            sx={{
              "& .MuiCardHeader-action": {
                alignSelf: "center",
              },
              "& .MuiCardHeader-avatar": {
                mr: 1,
              },
              padding: "7px",
            }}
          />
          <Divider />

          <CardContent sx={{ p: 0 }}>
            {/* Display item.code below */}
            <Box
              sx={{
                height: "calc(100vh - 55px)",
                width: "100%",
                overflowX: "auto",
                overflowY: "auto",
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width={130}>DESTINATION</TableCell>
                    <TableCell width={130}>ITEM QTY</TableCell>
                    <TableCell width={130}>UNIT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemData.map((item, key) => {
                    return (
                      <TableRow key={key}>
                        <TableCell>
                          <Tooltip
                            title={_.unescape(item.plant)}
                            placement="bottom"
                          >
                            <Typography
                              variant="body2"
                              sx={{ wordBreak: "break-word" }}
                            >
                              {_.unescape(item.plant)}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            title={_.unescape(
                              parseFloat(item.quantity).toFixed(2)
                            )}
                            placement="bottom"
                          >
                            <Typography
                              variant="body2"
                              sx={{ wordBreak: "break-word" }}
                            >
                              {_.unescape(parseFloat(item.quantity).toFixed(2))}
                            </Typography>
                          </Tooltip>
                        </TableCell>

                        <TableCell>
                          <Tooltip title={Unit} placement="bottom">
                            <Typography
                              variant="body2"
                              sx={{ wordBreak: "break-word" }}
                            >
                              {Unit}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="body2"
                        sx={{ wordBreak: "break-word" }}
                        color={"text.primary"}
                      >
                        Total Item Quantity
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={_.unescape(parseFloat(totalQuantity).toFixed(2))}
                        placement="bottom"
                      >
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          color={"text.primary"}
                          sx={{ wordBreak: "break-word" }}
                        >
                          {_.unescape(parseFloat(totalQuantity).toFixed(2))}
                        </Typography>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      <Tooltip title={Unit} placement="bottom">
                        <Typography
                          variant="body2"
                          color={"text.primary"}
                          sx={{ wordBreak: "break-word" }}
                        >
                          {Unit}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </Box>
          </CardContent>
        </Card>
      </RFQQtyRight>
    </React.Fragment>
  );
}
