//snackbar action
export const OPEN_SNACKBAR = "OPEN_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";

// session related constants

export const SET_SESSION_ID = "SET_SESSION_ID";

// category related constants
export const SET_CATEGORY_ITEMS = "SET_CATEGORY_ITEMS";
export const GET_CATEGORY_ITEMS_ERROR = "GET_CATEGORY_ITEMS_ERROR";
export const SET_CATEGORY_LOADING = "SET_CATEGORY_LOADING";
export const UNSET_CATEGORY_LOADING = "UNSET_CATEGORY_LOADING";
export const SET_CATEGORY_NODATA = "SET_CATEGORY_NODATA";
export const UNSET_CATEGORY_NODATA = "UNSET_CATEGORY_NODATA";
export const RESET_CATEGORY = "RESET_CATEGORY";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";

// item related constants
export const SET_AVAILABLE_ITEM = "SET_AVAILABLE_ITEM";
export const SET_AVAILABLE_ITEM_LOADING = "SET_AVAILABLE_ITEM_LOADING";
export const SET_AVAILABLE_ITEM_ERROR = "SET_AVAILABLE_ITEM_ERROR";
export const SET_SELECTED_ITEM = "SET_SELECTED_ITEM";
export const SET_SEARCHED_ITEM = "SET_SEARCHED_ITEM";
export const SET_SEARCHED_ITEM_LOADING = "SET_SEARCHED_ITEM_LOADING";
export const SET_SEARCHED_ITEM_ERROR = "SET_SEARCHED_ITEM_ERROR";
export const RESET_ITEMS = "RESET_ITEMS";
export const RESET_SELECTED_ITEMS = "RESET_SELECTED_ITEMS";
export const RESET_SEARCHED_ITEMS = "RESET_SEARCHED_ITEMS";
export const RESET_ITEM_SPECIFICATIONS = "RESET_ITEM_SPECIFICATIONS";

export const SET_FILTERED_ITEMS = "SET_FILTERED_ITEMS";
export const SET_FILTERED_ITEMS_LOADING = "SET_FILTERED_ITEMS_LOADING";
export const SET_FILTERED_ITEMS_ERROR = "SET_FILTERED_ITEMS_ERROR";

// for vendor classification
export const SET_VENDOR_LOCATION_FLAG = "SET_VENDOR_LOCATION_FLAG";
export const RESET_VENDOR_LOCATION_FLAG = "RESET_VENDOR_LOCATION_FLAG";

// vendor related constants
export const SET_VENDORS = "SET_VENDORS";
export const SET_VENDORS_LOADING = "SET_VENDORS_LOADING";
export const SET_VENDORS_ERROR = "SET_VENDORS_ERROR";
export const RESET_VENDORS = "RESET_VENDORS";

export const SET_FILTERED_VENDORS = "SET_FILTERED_VENDORS";
export const SET_FILTERED_VENDORS_LOADING = "SET_FILTERED_VENDORS_LOADING";
export const SET_FILTERED_VENDORS_ERROR = "SET_FILTERED_VENDORS_ERROR";
export const RESET_FILTERED_VENDORS = "RESET_FILTERED_VENDORS";

export const SET_ITEMIZED_VENDORS = "SET_ITEMIZED_VENDORS";
export const SET_ITEMIZED_VENDORS_LOADING = "SET_ITEMIZED_VENDORS_LOADING";
export const SET_ITEMIZED_VENDORS_ERROR = "SET_ITEMIZED_VENDORS_ERROR";

export const SET_FILTERED_ITEMIZED_VENDORS = "SET_FILTERED_ITEMIZED_VENDORS";
export const SET_FILTERED_ITEMIZED_VENDORS_LOADING =
  "SET_FILTERED_ITEMIZED_VENDORS_LOADING";
export const SET_FILTERED_ITEMIZED_VENDORS_ERROR =
  "SET_FILTERED_ITEMIZED_VENDORS_ERROR";

export const SET_SELECTED_VENDORS = "SET_SELECTED_VENDORS";
export const SET_SELECT_ALL_VENDORS = "SET_SELECT_ALL_VENDORS";

// DRAWER
export const SET_RFQ_SETTINGS = "SET_RFQ_SETTINGS";
export const REMIDER_DRAWER = "REMIDER_DRAWER";
export const RESET_REMINDER_DRAWER = "RESET_REMINDER_DRAWER";
export const SET_INCLUDE_TB = "SET_INCLUDE_TB";
export const SET_ACTIVITY_TEMPLATE = "SET_ACTIVITY_TEMPLATE";
//company info
export const SET_COMPANY_INFO = "SET_COMPANY_INFO";
export const SET_COMPANY_INFO_ERROR = "SET_COMPANY_INFO_ERROR";

//user info
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_USER_INFO_ERROR = "SET_USER_INFO_ERROR";

//permissions
export const SET_ACCOUNT_LEVEL_INFO = "SET_ACCOUNT_LEVEL_INFO";
export const SET_ACCOUNT_LEVEL_INFO_ERROR = "SET_ACCOUNT_LEVEL_INFO_ERROR";

export const SET_ACCOUNT_MODULE_PERMISSION = "SET_ACCOUNT_MODULE_PERMISSION";
export const SET_ACCOUNT_MODULE_PERMISSION_ERROR =
  "SET_ACCOUNT_MODULE_PERMISSION_ERROR";

export const SET_MASTER_PERMISSION = "SET_MASTER_PERMISSION";
export const SET_MASTER_PERMISSION_ERROR = "SET_MASTER_PERMISSION_ERROR";

export const SET_FEATURE_PERMISSION = "SET_FEATURE_PERMISSION";
export const SET_FEATURE_PERMISSION_ERROR = "SET_FEATURE_PERMISSION_ERROR";
export const SET_SELECTED_PR_CATEGORY = 'SET_SELECTED_PR_CATEGORY';
export const SET_CHECK_VALID_TECHNICALBID_ITEMS = "SET_CHECK_VALID_TECHNICALBID_ITEMS";
export const UNSET_CHECK_VALID_TECHNICALBID_ITEMS = "UNSET_CHECK_VALID_TECHNICALBID_ITEMS";
export const SET_CHECK_VALID_TECHNICALBID_CATEGORY = "SET_CHECK_VALID_TECHNICALBID_CATERGORY";
export const UNSET_CHECK_VALID_TECHNICALBID_CATEGORY = "UNSET_CHECK_VALID_TECHNICALBID_CATERGORY";
export const SET_FILE_SPECIFICATIONS = 'SET_FILE_SPECIFICATIONS'
export const SET_ITEM_SPECIFICATIONS = 'SET_ITEM_SPECIFICATIONS'
export const SET_DISABLED_PR_CATEGORY = 'SET_DISABLED_PR_CATEGORY'
export const SET_DISABLE_INDX = "SET_DISABLE_INDX"
export const SET_PLANT_CODES = "SET_PLANT_CODES"
export const SET_PR_DIALOG = "SET_PR_DIALOG"
export const CHANGE_PR_SELECTED = "CHANGE_PR_SELECTED"
export const SET_PR_ITEMS = "SET_PR_ITEMS"
export const SET_PR_LINE_ITEMS_DATA = "SET_PR_LINE_ITEMS_DATA"
export const SET_PRDROPDOWN_VALUES = "SET_PRDROPDOWN_VALUES"
export const SET_PR_AND_ITEMS_LINK_VALUE = "SET_PR_AND_ITEMS_LINK_VALUE"
export const SET_RFQ_PRS = "SET_RFQ_PRS"
export const SET_PR_COMBINATIONS = "SET_PR_COMBINATIONS"
export const SET_GROUP_NO = "SET_GROUP_NO"
export const SET_PR_COMBINATIONS_ITEMS = "SET_PR_COMBINATIONS_ITEMS"
export const SET_TEMPLATE = "SET_TEMPLATE"
export const SET_DEFAULT_VALUES = "SET_DEFAULT_VALUES"
export const SET_EMPTY_PR_COMBINATION = "SET_EMPTY_PR_COMBINATION"
export const SET_REMAINING_PR_COMBINATION = "SET_REMAINING_PR_COMBINATION"
export const SET_API_DATA = "SET_API_DATA"
export const SET_TEMPLATE_OPTIONS = "SET_TEMPLATE_OPTIONS"
export const SET_JSON_TEMPLATES = "SET_JSON_TEMPLATES"
export const SET_TEMPLATE_VALUES = "SET_TEMPLATE_VALUES"
export const SET_TERMS_AND_CONDTIONS = "SET_TERMS_AND_CONDTIONS"
export const SET_FILE_SPECIFICATION_FROM_API = "SET_FILE_SPECIFICATION_FROM_API"
export const SET_INVALID_COMBINATIONS = "SET_INVALID_COMBINATIONS"
export const SET_DISPLAY_OPTIONS = "SET_DISPLAY_OPTIONS"
export const SET_RFQ_LOADING = "SET_RFQ_LOADING"

//RFQ LIST
export const SET_RFQ_LIST= "SET_RFQ_LIST"
export const SET_RFQ_LIST_ERROR= "SET_RFQ_LIST_ERROR"
export const LINKUNLINKUSER =  "LINKUNLINKUSER"
export const VENDOR_MENU = 'VENDOR_MENU'
export const SELECTEDUSERIDS = 'SELECTEDUSERIDS'

export const SET_QUOTATION_INFO= "SET_QUOTATION_INFO"
export const SET_IMPORT_EXCEL_DATA= "SET_IMPORT_EXCEL_DATA"
export const SET_IMPORT_EXCEL_RFQ_DATA= "SET_IMPORT_EXCEL_RFQ_DATA"
