import React, { useEffect, useState } from 'react';
//import { styles } from "./style";
import {
  Grid,
  Drawer,
  Toolbar,
  Typography,
  Box,
  Divider,
  Backdrop,
  CircularProgress,
  Badge,
  IconButton,
  Tooltip,
  ListItemButton,
  List,
  ListItem,
  Chip,
  Alert,
  Stack
} from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import {withStyles} from "@mui/styles";
import { Close, DeleteOutlined, Error, ErrorOutline, MapsHomeWorkOutlined, ShoppingBagOutlined,SourceOutlined } from '@mui/icons-material';
import {styles} from '../TemporaryDrawer/style';
import { groupRfqMail, getPrCombinationUrl,getPrLineItems,geteditable_fields_json, groupRfqs,pr_dropdown_mapping,dropdown_detail} from "../../config/apiUrl";
import { setDisabledPrCategory, setSelectedPrCategory,
       setDisableIndex,setPrLineItemsData,setPlantCodes,setApiData,
       setPrCombinations,setPrDropdownValues,setGroupNo,setEmptyPrCombination,
       setRemainingPrCombination,setInValidCombinations,setRfqLoading
    } from '../../redux/actions/prrfqactions';
import {setFileSpecification, setSelectedCategory} from '../../redux/actions/categoryAction'
import {resetItemspecification, setSelectedItem} from '../../redux/actions/itemActions'
import { useDispatch, useSelector,connect } from 'react-redux';
import axiosInstance from  "../../interceptor/axiosInstance";
import { useLocation } from 'react-router-dom';
import "./prgroup.css"
import ConfirmAlert from '../Dialogs/ConfirmAlert.js';
import axios from 'axios'
import GroupRFQ from "../Dialogs/GroupRFQ/GroupRFQ";
import { openSnackbar } from "../../redux/actions/snackbarAction";
import { PRcombinationstyles } from './style';
import EmptyPRcombination from '../EmptyPRcombination';
import { setReminderSettings, setRFQSettings } from '../../redux/actions/drawerActions';
import DestinationDetailsDialog from '../DestinationDetailsDialog/DestinationDialog';
import {resetVendorLocationFlag} from '../../redux/actions/itemActions'

const PrGroupList = (props) => {
    const disablepr = useSelector(state => state.prrfqreducer.disbledPRCategory)
    const disableIndex = useSelector(state => state.prrfqreducer.disableIndex)
    const rfqSubmittedPrs = useSelector(state => state.prrfqreducer.rfqSubmittedPrs)
    const prCombinations = useSelector(state => state.prrfqreducer.prCombinations)
    const defaultSetting = useSelector(state => state.prrfqreducer.defaultSetting);
    const defaultValues = useSelector(state => state.prrfqreducer.defaultValues);
    const PrItemsData = useSelector(state => state.prrfqreducer.setPrLineItemsData);
    const apiData = useSelector(state => state.prrfqreducer.apiData);
    const selectedItems = useSelector(state => state.itemReducer.selectedItems);
    const onekey = useSelector(state => state.prrfqreducer.onekey)
    const [prOpen,setPrOpen] = useState(props.prOpen)
    const [data,setData] = useState(prCombinations)
    const [color,setColor] = useState("#EBF3FE")
    const [selectedId,setSelectedId] = useState(0)
    const [loading,setLoading] = useState(true);
    const [combinationLength, setCombinationLength] = useState()
    const [dropdown_values, setdropdown_values] = useState({})
    const [deletecategory,setDeletecategory] = useState() ;
    const [disabledCategory,setDisabledCategory] = useState() || [] ;
    const [confirmDialogOpen,setConfirmDialogOpen] = useState(false)
    const [groupRfqopen,setGroupRfqopen] = useState(false)
    const [grouprfqid,setGrouprfqid] = useState(0)
    const [validcombinationData,setValidcombinationData] = useState([])
    const [validcombinationArr,setValidcombinationArr] = useState([])
    // const [inValidcombinationArr,setInValidcombinationArr] = useState([])
    const [inValidCombination,setInValidCombination] = useState(false)
    const [selectedToDisable,setSelectedToDisable] = useState({})
    const [EmptyPRcombinationopen,setEmptyPRcombinationopen] = useState(false)
    const [rfqChangeOpen,setRfqChangeOpen] = useState(false)
    const [newRfq,setNewRfq]=useState({})
    const [dontShowAgain,setDontShowAgain]=useState(false)
    const [plantData,setPlantData]=useState({})
    const [noPlantData,setNoPlantData]=useState(false)
    const [plantDataDialogue, setPlantDataDialogue] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(()=>{
        if(Object.keys(defaultValues).length !== 0){
            dispatch(setRFQSettings(defaultValues.rfqSettingValues));
            dispatch(setReminderSettings(defaultValues.remiderDrawer))
        }else{
            dispatch(setRFQSettings(defaultSetting.rfqSettingValues));
            dispatch(setReminderSettings(defaultSetting.remiderDrawer));
        }
    },[selectedId]);
    const handleSelect = async (i,categoryObj) => {
        dispatch(setRfqLoading(true))
        setColor('#EBF3FE')
        setSelectedId(i);
        props.selectedKey(i)
        dispatch(setFileSpecification([]));
        dispatch(resetItemspecification());
        dispatch(setSelectedPrCategory(categoryObj))
        dispatch(setSelectedCategory(categoryObj.category))
            // await fetchPrData(categoryObj.items)
        // categoryObj.items.sort((a,b) => a.name.localeCompare(b.name));
        // dispatch(setSelectedItem(categoryObj.items))
         //getting prItemData and Selected item data from redux if it is available else calling api
         if(Object.keys(apiData).length!==0 && Object.keys(apiData).some(val=>val==i)){
            dispatch(setPrLineItemsData(apiData[i].PrItemsData))
            dispatch(setSelectedItem(apiData[i].selectedItems))
            dispatch(setRfqLoading(false))
        }else{
            await fetchPrData(categoryObj.items);
           
        }
        dispatch(setDisableIndex(i))
        //set prItemData and Selected item data to redux every time pr is changed
        if(disableIndex !== undefined){
        dispatch(setApiData({[disableIndex]:{
            PrItemsData:PrItemsData,
            selectedItems:selectedItems
        }
        }))
        }
        props.handleClose()
    }
    //for showing confirm box when changing rfq
    const handleRfqChange = (i,categoryObj) => {
        if(disableIndex!==i){
            setRfqChangeOpen(true)
            setNewRfq({i,categoryObj})
        }
    }

    const handleDisable = (i,categoryObj) => {
        setConfirmDialogOpen(true)
        setDeletecategory(i);
        setSelectedToDisable(i)
        // setSelectedId(i+1)
    }

    async function fetchData() {
        // dispatch(setEmptyPrCombination(true))
        // if( dropdown_values ){
        let prids= new URLSearchParams(location.search)?.get('id')?.split(",");
        let multiplantRfq = new URLSearchParams(location.search)?.get('multiplant_rfq')
        let str = "?id[]="
        prids.forEach((id,j)=> {
            if( j=== prids.length-1)
                str = str+id;
            else 
                str = str+id+"&id[]="
        })
        if (multiplantRfq == 1) {
            str = str + `&multiplant_rfq=1`
        }
        
        let url = getPrCombinationUrl()+str
        
        try {
            let response = await  axiosInstance.get(url);
            //for checking if response is valid on not
            if(response.data){
                if(response.data.length>10){
                    setInValidCombination(true)
                    dispatch(setInValidCombinations(true));
                }
                setPlantData(response)
            }else{
                props.openSnackbar(
                    "error",
                    response.response?.data?.message
                    ? response.response?.data?.message
                    : "Provided pr id is not valid"
                );
                    dispatch(setEmptyPrCombination(false));  
                     setLoading(false)
            }
            // let distinct_plants = [];
            // let validData = [];
           
            // response.data.forEach((obj,i) => {
            //     if(obj.plant?.code !== undefined || obj.plant?.code !== "") {
            //         if(dropdown_values?.plant.hasOwnProperty(obj.plant.code)){
            //             obj.plant.name = dropdown_values?.plant[obj.plant?.code]["Dropdown_Element_Name"];
            //         }
            //         else{
            //             let plant_error_mes = "Plant "+ obj.plant.code + " is deleted in master"
            //             if(!obj.hasOwnProperty("validation_errors")) obj['validation_errors'] = [];
            //             obj['validation_errors'].push({
            //                 'error' : plant_error_mes 
            //             });
            //         }
            //         if(distinct_plants.indexOf(obj.plant?.code) === -1) {
            //             distinct_plants.push(obj.plant.code)
            //         }
            //     }
            //     // if(obj.hasOwnProperty("validation_errors")){
            //     //     obj.final_error = get_error_message(obj.validation_errors);
            //     //     //dispatch(openSnackbar("error","Error in generating RFQ Form" ));
            //     // }
            //     // else{
            //         //     validData.push(i);
            //         // }
            //     });
            // //sorting combination by category name and then by plant name
            // response.data.sort((a,b) => a.category.name == b.category.name ? a.plant.name && a.plant.name.localeCompare(b.plant.name) : a.category.name.localeCompare(b.category.name));
            // dispatch(setPrCombinations(response.data));
            // setData(response.data)
            // response.data.forEach((obj,i) => {
            //     if(obj.hasOwnProperty("validation_errors")){
            //         obj.final_error = get_error_message(obj.validation_errors);
            //         //dispatch(openSnackbar("error","Error in generating RFQ Form" ));
            //     }
            //     else{
            //         validData.push(i);
            //     }
            // });
            // setValidcombinationData(validData)
            // if(validData.length > 0 ){
            //     const firstvalue = validData[0];
            //     handleSelect(firstvalue,response.data[firstvalue])
            //     dispatch(setEmptyPrCombination(false));  
            // } 
            // else{
            //     setPrOpen(true)
            //     setEmptyPRcombinationopen(true);
            //     dispatch(setEmptyPrCombination(false));  
            // }
            // setCombinationLength(validData.length)
            // // props.prcombinationcount(validData.length)
            // dispatch(setPlantCodes(distinct_plants))
            setLoading(false)
        } catch(err) {
            props.openSnackbar(
            "error",
            err.response?.data?.message
            ? err.response?.data?.message
            : "Provided pr id is not valid"
        );
            dispatch(setEmptyPrCombination(false));  
             setLoading(false)
        }
        // }
    }

   const setPlantName = ()=>{
   let response = plantData;
    //response.data = response.data.slice(0, 10);
    let distinct_plants = [];
    let validData = [];
    response.data.forEach((obj,i) => {
        if(obj.plant?.code !== undefined || obj.plant?.code !== "") {
            if(Object.keys(dropdown_values).length>0){
                if(dropdown_values?.plant.hasOwnProperty(obj.plant.code)){
                    obj.plant.name = dropdown_values?.plant[obj.plant?.code]["Dropdown_Element_Name"];
                }
                else{
                    let plant_error_mes = "Plant "+ obj.plant.code + " is deleted in master"
                    if(!obj.hasOwnProperty("validation_errors")) obj['validation_errors'] = [];
                    obj['validation_errors'].push({
                        'error' : plant_error_mes 
                    });
                }
            }else{            
                let plant_error_mes = "Oops! We're experiencing technical difficulties loading plant details at the moment. Please try again later. Our team is working to resolve the issue. Thank you for your patience!";
                if(!obj.hasOwnProperty("validation_errors")) obj['validation_errors'] = [];
                obj['validation_errors'].push({
                    'error' : plant_error_mes 
                });
            }
            if(distinct_plants.indexOf(obj.plant?.code) === -1) {
                distinct_plants.push(obj.plant.code)
            }
        }
        // if(obj.hasOwnProperty("validation_errors")){
        //     obj.final_error = get_error_message(obj.validation_errors);
        //     //dispatch(openSnackbar("error","Error in generating RFQ Form" ));
        // }
        // else{
            //     validData.push(i);
            // }
        });
    //sorting combination by category name and then by plant name
    response.data.sort((a,b) => a.category.name == b.category.name ? a.plant.name && a.plant.name.localeCompare(b.plant.name) : a.category.name.localeCompare(b.category.name));
    response.data = response.data.slice(0, 10);
    dispatch(setPrCombinations(response.data));
    setData(response.data)
    response.data.forEach((obj,i) => {
        if(obj.hasOwnProperty("validation_errors")){
            obj.final_error = get_error_message(obj.validation_errors);
            //dispatch(openSnackbar("error","Error in generating RFQ Form" ));
        }
        else{
            validData.push(i);
        }
    });
    setValidcombinationData(validData)
    if(validData.length > 0 ){
        const firstvalue = validData[0];
        handleSelect(firstvalue,response.data[firstvalue])
        // dispatch(setEmptyPrCombination(false));  
    } 
    else{
        setPrOpen(true)
        setEmptyPRcombinationopen(true);
        dispatch(setEmptyPrCombination(false));  
    }
    setCombinationLength(validData.length)
    // props.prcombinationcount(validData.length)
    dispatch(setPlantCodes(distinct_plants))
   }

   const setMultiplantName = ()=>{
    let response = plantData;
    // response.data = response.data.slice(0, 10);
    let distinct_plants = [];
    let validData = [];
    response.data.forEach((obj, i) => {

        if (obj.plant && Array.isArray(obj.plant.code) && obj.plant.code.length > 0) {
            obj.plant.code = Array.isArray(obj.plant.code) ? obj.plant.code : [obj.plant.code]; // Ensure code is always an array
            obj.plant.name = Array.isArray(obj.plant.name) ? obj.plant.name : [obj.plant.name]; // Ensure name is always an array
            obj.plant.code.forEach((plantCode, index) => {
                if (Object.keys(dropdown_values).length > 0) {
                    if (dropdown_values.plant && dropdown_values.plant.hasOwnProperty(plantCode)) {
                        let plantName = dropdown_values.plant[plantCode]["Dropdown_Element_Name"];
                        if (obj.plant.name[index] != 'undefined') {
                            obj.plant.name[index] = plantName; // Update the corresponding name in the array
                        } else {
                            obj.plant.name.push(plantName); // Add plant name to the array
                        }
                    } else {
                        let plant_error_mes = "Plant " + plantCode + " is deleted in master";
                        if (!obj.hasOwnProperty("validation_errors")) obj["validation_errors"] = [];
                        obj["validation_errors"].push({ error: plant_error_mes });
                    }
                }
                else{            
                    let plant_error_mes = "Oops! We're experiencing technical difficulties loading plant details at the moment. Please try again later. Our team is working to resolve the issue. Thank you for your patience!";
                    if(!obj.hasOwnProperty("validation_errors")){
                         obj['validation_errors'] = [];
                        obj['validation_errors'].push({
                            'error' : plant_error_mes 
                        });
                    }
                }
                if (distinct_plants.indexOf(plantCode) === -1) {
                    distinct_plants.push(plantCode);
                }
            });
        }
    });
    

    
    // Sort the data as before
    response.data.sort((a, b) =>
        a.category.name === b.category.name
            ? 0
            : a.category.name.localeCompare(b.category.name)
    );

    response.data = response.data.slice(0, 10);
    dispatch(setPrCombinations(response.data));
    setData(response.data);
    response.data.forEach((obj,i) => {
        if(obj.hasOwnProperty("validation_errors")){
            obj.final_error = get_error_message(obj.validation_errors);
            //dispatch(openSnackbar("error","Error in generating RFQ Form" ));
        }
        else{
            validData.push(i);
        }
    });
    setValidcombinationData(validData);
    if (validData.length > 0) {
        const firstvalue = validData[0];

        handleSelect(firstvalue, response.data[firstvalue]);
    } else {
        setPrOpen(true);
        setEmptyPRcombinationopen(true);
        dispatch(setEmptyPrCombination(false));
    }

    setCombinationLength(validData.length);
    dispatch(setPlantCodes(distinct_plants));
   }
    async function fetchPrDropdownData() {
       //alert("fetchPrDropdownData"+props.userInfo?.division_id);
        if(props.userInfo?.division_id){

            // await axios.get("http://192.168.1.158/react/mapping.json").then((res) =>{
            //     //axios.get(pr_dropdown_mapping(props.userInfo.division_id,props.companyInfo.business_gr_master_id)).then((res) =>{
            //     let dropdown_values = {};
            //         Object.keys(res.data).map((key, index) => {
            //             //axios.get(dropdown_detail(props.userInfo.division_id,props.companyInfo.business_gr_master_id,res.data[key])).then((res) =>{
            //             await axios.get(`http://192.168.1.158/react/${res.data[key]}.json`).then((res) =>{
            //                 dropdown_values[key] = console.log(res.data);
            //                 //dispatch(setPrDropdownValues(dropdown_values))
            //             });
            //         });
            //         console.log(dropdown_values);
        
            // }).catch(Error => {
                    
            // });
                let mapping_url = pr_dropdown_mapping(props.userInfo.division_id, props.companyInfo.business_gr_master_id);
                //mapping_url = "https://local-app-data.vend-x.com/4/v2/pr/157/mapping25.json?version=1702531706348";
                //let res = await  axiosInstance.get(mapping_url);

               try {
                let res = await  fetch(mapping_url);  
                res = await res.json();
                    var dropdown_values = {};
                    var count = Object.keys(res).filter(x => x == 'pgr' || x == 'plant').length;
                    Object.keys(res).map((key, index) => {
                        if( key == 'pgr' || key == 'plant' ){
                        let dropdown_detail_url = dropdown_detail(props.userInfo.division_id,props.companyInfo.business_gr_master_id,res[key]);
                        //dropdown_detail_url = `http://192.168.1.158/react/${res.data[key]}.json`;
                        //let response1 = axiosInstance.get(dropdown_detail_url);
                        fetch(dropdown_detail_url).then((res) => res.json()).then(function(successMessage) {
                        //count -= 1;
                        dropdown_values[key] = successMessage;
                        // if(!count){
                        //     fetch(`${geteditable_fields_json}`).then((response) => response.json()).then((responseJSON) => {
                        //         dropdown_values["prconfig"] = responseJSON;
                        //         dispatch(setPrDropdownValues(dropdown_values))
                        //         setdropdown_values(dropdown_values)
                        //         // dispatch(setEmptyPrCombination(false));  
                        //     });
                        // }
                    })
                    .catch(function(errorMessage) {
                        console.log(errorMessage);
                        setNoPlantData(true)
                    }).finally(() => {
                        count -= 1;
                        if(!count){
                            fetch(`${geteditable_fields_json}`).then((response) => response.json()).then((responseJSON) => {
                                dropdown_values["prconfig"] = responseJSON;
                                dispatch(setPrDropdownValues(dropdown_values))
                                setdropdown_values(dropdown_values)
                                // dispatch(setEmptyPrCombination(false));  
                            });
                        }
                      });
                }
                    });
               } catch (error) {
                setNoPlantData(true)
               }
        }
    }
    
    useEffect( () => {
       if(disableIndex===undefined) {
          setSelectedId(parseInt(0))
        props.selectedKey(parseInt(0))

       } 
    },[]);    
    useEffect(() => {
        const combinationlength = checkedfunc1(true);
        setCombinationLength(combinationlength);
        props.prcombinationcount(combinationlength)
    },[disabledCategory,onekey,disablepr,validcombinationData,rfqSubmittedPrs]);
    useEffect(() => {
        checkedfunc1();
    },[disabledCategory,onekey]);

    useEffect(() => {
        setPrOpen(props.prOpen)
        if(disableIndex !== undefined)
            setSelectedId(disableIndex)
            props.selectedKey(disableIndex)

        if(rfqSubmittedPrs.length>0) {
            rfqSubmittedPrs.forEach(pr => {

               // setColor("#E6FCF6")
            })
            
        }
   
    },[props])

    

    useEffect(() => {
        if(Object.keys(props.companyInfo).length!==0){
            fetchPrDropdownData();
            fetchData();
        }
     },[props.companyInfo])
    
     useEffect(() => {
        if(Object.keys(dropdown_values).length!==0 && Object.keys(plantData).length!==0){
            if (new URLSearchParams(location.search)?.get('multiplant_rfq') == 1) {
                setMultiplantName()
            } else {

                setPlantName();
            }
        }else if(noPlantData && Object.keys(plantData).length!==0){ 
            if (new URLSearchParams(location.search)?.get('multiplant_rfq') == 1) {
                setMultiplantName()
            } else {

                setPlantName();
            }
            // setPlantName();
           
        }
     },[dropdown_values,plantData,noPlantData])
    
    const fetchPrData =  async(items)=> {
        console.log(items)
        let PrItems = {}
        items.forEach(async (item,i) => {
            let str = "?id[]="
            item.prlineitems_ids.forEach((prId,j) => {
                if( j=== item.prlineitems_ids.length-1)
                    str = str+prId;
                else 
                    str = str+prId+"&id[]=";
            });
            let url = getPrLineItems()+str+'&embed=customfield_details,attachment';
            try {
                let response = await  axiosInstance.get(url);
                console.log(url,response)
                PrItems[item.id] = response.data;
                PrItems[item.id].forEach((pr,i) => {
                    PrItems[item.id][i].item_code= item.code;
                    PrItems[item.id][i].item_name= item.name;
                })
                if(Object.keys(PrItems).length == items.length) {
                    dispatch(setPrLineItemsData(PrItems))
                    items.sort((a,b) => a.name.localeCompare(b.name));         
                    dispatch(setSelectedItem(items))           
                    dispatch(setEmptyPrCombination(false))
                    dispatch(setRfqLoading(false))                    
                }
               
            } catch(err) {
                props.openSnackbar(
                "error",
                err.response?.data?.message
                ? err.response?.data?.message
                : "Provided pr id is not valid"
                );
            }
            
        });
    }
    
    const checkedfunc1 =(qq)=>{
        if(data.length > 0){
            let validCombination = [];
            // let inValidcombination=[];
            let remainingCombinations=[];
            const length = data.length;
            let oneflag = 0;
            let i= 0;
            let count = 0;
            while(i <= length-1){
                const categoryObj = data[i];
                // const filteredArray = rfqSubmittedPrs.some((pr)=>pr.category.id == categoryObj.category.id && pr.plant.code == categoryObj.plant.code);
                const filteredArray = rfqSubmittedPrs.some((pr)=>i == pr.index);
                if(disablepr[i] !== true 
                    && categoryObj.items.length <= 50 
                    && filteredArray == false
                    && validcombinationData.indexOf(i) !== -1) {
                        remainingCombinations.push(data[i])
                        oneflag=1;
                        
                        if(!qq){
                            handleSelect(i, data[i]);
                            break;
                        }
                        else{
                            count++;
                        }
                        
                }
                i++;
            }
            // data.map((item,idx)=>{
            //     const categoryObj = data[idx];
            //     const filteredArray = rfqSubmittedPrs.some((pr)=>idx == pr.index);
            //     if(disablepr[idx] !== true 
            //         && categoryObj.items.length <= 50 
            //         && (validcombinationData.indexOf(idx) !== -1 || filteredArray)){
            //             if(validCombination.length<10){
            //                 validCombination.push(idx);
            //             }
            //             else{
            //                 inValidcombination.push(idx)
            //             }
            //         }
                   
            // })
            if(!qq){
                if(oneflag == 0){
                    checkGroupRFQ();
                }
            }
            dispatch(setRemainingPrCombination(remainingCombinations))
            // setValidcombinationArr(validCombination);
            // setInValidcombinationArr(inValidcombination);
            // dispatch(setInValidCombinations(inValidcombination));
            return count;
        }
    }
    const checkGroupRFQ = ()=>{
        if(rfqSubmittedPrs?.length>0) {
            let prcombinationlength = validcombinationData.length;
            let deletecombinationlength = Object.keys(disablepr).length;
            let remaininglength = prcombinationlength - deletecombinationlength;
            if(rfqSubmittedPrs.length === remaininglength) {
              let inquiry_ids = []
              rfqSubmittedPrs.forEach(obj=>{
                inquiry_ids.push(obj.inquiry_id)
              });
              let data = { "inquiry_ids":inquiry_ids};
              axiosInstance.post(groupRfqs,data).then(res=>{
                setGrouprfqid(res.data.group_rfq.id);
                props.setGroupNo(res.data.group_rfq.id)
                setGroupRfqopen((e)=>!e);
                let formData = new FormData();
                formData.append('sessionID',new URLSearchParams(location.search)?.get('q'));
                formData.append('groupRfqID',res.data.group_rfq.id);
                axios.post(groupRfqMail,formData)
              });
            }
        }
        else{
            setPrOpen(true)
            setEmptyPRcombinationopen(true);

        }
    };
    const chipvalue = (value,errormsg)=>{

        if(value === "SUBMITTED"){
            return (
                <Chip size="small" color="success" sx={{borderRadius:'4px',fontWeight:500}} icon={<CheckCircleOutlineOutlinedIcon color="success" />} label={value}/>                     
            );
        }
        if(value === "ERROR"){
            return (
                <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{errormsg}</div>}>
                <Chip 
                size="small"
                color="error" 
                sx={{borderRadius:'4px',
                fontWeight:500,
                }} icon={ 
                        <ErrorOutlineIcon color="error" fontSize="small" /> } label={value} />   
                </Tooltip>
            );
        }
        else{
            return (
                <Chip 
                size="small" 
                sx={{borderRadius:'4px',
                fontWeight:500,
                color:'grey.300',                
                '.MuiChip-label': {
                    color:'grey.500'
                }
                }} icon={ 
                <DoDisturbAltOutlinedIcon sx={{color:'grey.400'}} /> } label={value} />   
            );
        }
        
    }
    const EmptyPRcombinationonclose = ()=> {
        setEmptyPRcombinationopen(false);
        props.handleOpen();
        //window.parent.postMessage("refresh-pr-grid", "*");
    }

    const get_error_message = (validation_errors) => {
        let error_Str = "";
        validation_errors.forEach(function (error_obj, index) {
            error_Str += error_obj.error + "\n";
        });
        return error_Str;
    }
    const handleOpenDialog = (names, codes) => {
        const values1 = names;
        // values1.shift();
        const plantsName = values1;
        const values2 = codes;
        // values2.shift();
        const plantsCode = values2;
        const plant = plantsName.map((name, i) => {
          return { plant_name: name, plant_code: plantsCode[i] };
        });
        setPlantDataDialogue(plant);
        setIsDialogOpen(true);
      };
      const handleCloseDialog = () => {
        setIsDialogOpen(false);
      };
    
    return (
        <>
            <React.Fragment>
            {PRcombinationstyles}
            <GroupRFQ
                open={groupRfqopen}
                grouprfqid={grouprfqid}
                close={()=>{
                    setGroupRfqopen((e)=>!e);
                }}
            />
            <EmptyPRcombination
            open={EmptyPRcombinationopen}
            rfq_no={"value"}
            handleClose={EmptyPRcombinationonclose}
            />
            <ConfirmAlert
            open={confirmDialogOpen}
            handleClose={() => {
                setConfirmDialogOpen(false)
            }}
            handleSubmit={() => {
              dispatch(setDisabledPrCategory({[selectedToDisable]:true}));
              if(deletecategory === selectedId){
                    setDisabledCategory(deletecategory);
              }
            //   setCombinationLength(combinationLength - 1)
              setConfirmDialogOpen(false)
            }}
            msg="RFQ will be removed & you can not add it again"
            title="Remove RFQ for PR?"
            />

             <ConfirmAlert
            open={rfqChangeOpen && !dontShowAgain}
            handleDontShow={()=>{
                handleSelect(newRfq.i,newRfq.categoryObj);
                setRfqChangeOpen(false)
                setDontShowAgain(true)
            }}
            handleClose={() => {
                setRfqChangeOpen(false)
                props.handleClose()
            }}
            handleSubmit={() => {
              handleSelect(newRfq.i,newRfq.categoryObj);
              setRfqChangeOpen(false)
              dispatch(resetVendorLocationFlag())
            }}
            msg="This action will remove the currently inserted values in RFQ."
            title="Are you sure want to Switch RFQ?"
            />
                <Drawer
                    className={props.classes.RFQSettingSlide}           
                    anchor={"left"}
                    open={prOpen}
                >
                     <Backdrop
                        style={{ color: "#fff", zIndex: "10000000000" }}
                        open={loading }
                    >
                        <CircularProgress style={{ color: "#fff" }} />
                    </Backdrop>
                    <Toolbar className="drawer-toolbar" sx={{ borderBottom : '1px solid'}}>
    
                        <Typography className="drawer-title">Group Combinations &nbsp; &nbsp; </Typography> 
                            <Badge badgeContent={combinationLength} color="error">
                        </Badge>
                        
                        <IconButton
                            sx={{ml:'auto'}}
                            onClick={props.handleClose}
                        >
                            <Close></Close>
                        </IconButton>
                        
                    </Toolbar>
                    <Grid container className="prdrawerinner_content" >
                    { (inValidCombination) &&
                     <Alert severity="error" 
                     sx={{
                        width: '100%',
                       display: 'inline-flex',               
                        '& .MuiAlert-message, .MuiAlert-icon': {
                          padding: 0,
                        }
                     }}
                     >
                      1 Group RFQ can only have 10 RFQ combinations
                     
                    </Alert>
             }
                        <List className="prcombi-list" sx={{p:0}}>
                        
                        { // this.props.prcombination
                            
                            data?.map((categoryObj,i) => {
                                let cellCol = "";
                                let submittedTxt = ""
                                //let selectId = 0;
                                let pEvent = "";
                                let errorshow = "";
                                let Opactiy = 1;
                                if(selectedId===i) { 
                                   cellCol = color;
                                } else {
                                    cellCol =""
                                }
                                if(categoryObj.hasOwnProperty("validation_errors")){
                                    pEvent = "none"
                                    cellCol = "var(--error-50)"
                                    errorshow = categoryObj.final_error
                                    Opactiy = 0.5;
                                    submittedTxt = "ERROR"
                                }
                                if(categoryObj.items.length > 50){
                                    pEvent = "none"
                                    errorshow = "No more than 50 items are allowed" 
                                    Opactiy = 0.5;
                                    cellCol = "var(--error-50)"
                                    submittedTxt = "ERROR"  
                                }
                                if(rfqSubmittedPrs?.length>0) {
                                    rfqSubmittedPrs.forEach((pr)=> {
                                        // if(categoryObj.category.id == pr.category.id && categoryObj.plant.code == pr.plant.code) {
                                            if(i == pr.index) {
                                            cellCol = "var(--success-50)"
                                            pEvent = "none"
                                            submittedTxt = "SUBMITTED"
                                        }
                                    })
                                }
                                
                                if(disablepr[i] === true) {
                                    cellCol = "var(--grey-50)"
                                    pEvent = "none"
                                    submittedTxt = "REMOVED"
                                    Opactiy = 0.5;
                                }
                                // if(inValidcombinationArr.some(element=>element===i)) {
                                //     cellCol = "#FFF6F5"
                                //     pEvent = "none"
                                //     errorshow = "1 Group RFQ can only have 10 RFQ combinations" 
                                //     // submittedTxt = "ERROR"
                                //     Opactiy = 0.5;
                                // }
                                
                                return(
                                    <React.Fragment>
                                    <ListItem disablePadding >
                                    <Grid className="pr_comb_button">
                                        { 
                                            submittedTxt !== "" ? chipvalue(submittedTxt,errorshow)
                                            :
                                            <Tooltip title={"Delete"} disableInteractive>
                                                 <IconButton 
                                                className="prdelet-btn"
                                                onClick={()=>handleDisable(i,categoryObj)}>
                                                <DeleteOutlined></DeleteOutlined>
                                            </IconButton> 
                                                </Tooltip>
                                        }  
                                    </Grid>
                                    {/* {inValidcombinationArr.some(element=>element===i)&&
                                   <Grid className="pr_comb_error">
                                         <Box sx={{ display:'flex', alignItems:'center', width:'20%' }}>
                                            <Tooltip title="1 Group can float 10 RFQ Combinations" disableInteractive>
                                            <ErrorOutline color="error"/>
                                            </Tooltip>
                                        </Box>
                                     </Grid>   
                                        } */}
                                    
                                    <ListItemButton style={{backgroundColor: cellCol,width:'100%',
                                        color:selectedId===i?'text.primary':'',pointerEvents: pEvent,flexDirection :"column",opacity:Opactiy}}
                                        className="" 
                                        onClick={() => {
                                            if(dontShowAgain){
                                                return handleSelect(i,categoryObj)
                                            }else{
                                                return handleRfqChange(i,categoryObj)
                                            } 
                                        }
                                        }
                                    >
                                        
                                        <Box sx={{ display:'flex', alignItems:'center', width:'100%' }}>
                                            <Typography sx={{ fontWeight:selectedId!==i?'400':'500'}} color={'text.primary'}  >
                                                {categoryObj?.category?.name}
                                            </Typography>                                                                            
                                        </Box>
                                        <Box sx={{ display:'flex', alignItems:'center', width:'100%', mb:1 }}>
                                            <Typography sx={{fontSize:'12px', fontWeight:'400', color: selectedId!==i? 'text.secondary':'text.primary'}}>
                                                {categoryObj?.category?.code}
                                            </Typography>
                                        </Box>
                                        <Stack spacing={1} direction={'row'} alignItems={'center'} sx={{width: '100%', mb:1 }}>                                        
                                            <ShoppingBagOutlined sx={{fontSize:'22px', color: selectedId!==i? 'text.secondary':'text.primary'}}></ShoppingBagOutlined>                                        
                                            <Typography sx={{fontSize:'12px', color: selectedId!==i? 'text.secondary':'text.primary'}}>
                                                Items: {categoryObj?.items?.length}
                                            </Typography>
                                        </Stack>
                                        <Stack spacing={1} direction={'row'} alignItems={'center'} sx={{ width:'100%' }}>
                                            {Array.isArray(categoryObj?.plant?.name) ? 
                                            <Stack spacing={1} direction={'row'} alignItems={'center'} sx={{width:'50%' }}>
                                                <MapsHomeWorkOutlined sx={{fontSize:'22px', color: selectedId!==i? 'text.secondary':'text.primary'}}></MapsHomeWorkOutlined>
                                                <Tooltip title={categoryObj?.plant?.name[0]} disableInteractive>
                                                <Typography sx={{fontSize:'12px', color: selectedId!==i? 'text.secondary':'text.primary'}} noWrap>
                                                    {categoryObj?.plant?.name[0]}
                                                </Typography>
                                                </Tooltip>
                                                {(categoryObj?.plant?.name.length - 1) > 0 && (
                                                    <Typography
                                                    sx={{
                                                        color: "primary.main",
                                                        textDecoration: "underline",
                                                        cursor: "pointer",
                                                        padding: "0 4px",
                                                        fontWeight: "bold",
                                                    }}
                                                    // onClick={() => handleOpenDialog(categoryObj?.plant?.name, categoryObj?.plant?.code)}
                                                    >
                                                    +{categoryObj?.plant?.name.length - 1}
                                                    </Typography>
                                                )}
                                            </Stack>
                                            :<Stack spacing={1} direction={'row'} alignItems={'center'} sx={{width:'50%' }}>
                                                <MapsHomeWorkOutlined sx={{fontSize:'22px', color: selectedId!==i? 'text.secondary':'text.primary'}}></MapsHomeWorkOutlined>
                                                <Tooltip title={categoryObj?.plant?.name} disableInteractive>
                                                <Typography sx={{fontSize:'12px', color: selectedId!==i? 'text.secondary':'text.primary'}} noWrap>
                                                    {categoryObj?.plant?.name}
                                                </Typography>
                                                </Tooltip>
                                            </Stack>}
                                            {Array.isArray(categoryObj?.plant?.code) ? 
                                            <Stack spacing={1} direction={'row'} alignItems={'center'} sx={{width:'50%' }}>
                                                <SourceOutlined sx={{fontSize:'22px', color: selectedId!==i? 'text.secondary':'text.primary'}}></SourceOutlined>
                                                <Tooltip title={categoryObj?.plant?.code[0]} disableInteractive>
                                                <Typography sx={{fontSize:'12px', color: selectedId!==i? 'text.secondary':'text.primary'}} noWrap>
                                                    {categoryObj?.plant?.code[0]}
                                                </Typography>
                                                </Tooltip>
                                                {(categoryObj?.plant?.code.length - 1) > 0 && (
                                                    <Typography
                                                    sx={{
                                                        color: "primary.main",
                                                        textDecoration: "underline",
                                                        cursor: "pointer",
                                                        padding: "0 4px",
                                                        fontWeight: "bold",
                                                    }}
                                                    // onClick={() => handleOpenDialog(categoryObj?.plant?.name, categoryObj?.plant?.code)}
                                                    >
                                                    +{categoryObj?.plant?.code.length - 1}
                                                    </Typography>
                                                )}
                                            </Stack>
                                            :<Stack spacing={1} direction={'row'} alignItems={'center'} sx={{width:'50%' }}>
                                                <SourceOutlined sx={{fontSize:'22px', color: selectedId!==i? 'text.secondary':'text.primary'}}></SourceOutlined>
                                                <Tooltip title={categoryObj?.plant?.code} disableInteractive>
                                                <Typography sx={{fontSize:'12px', color: selectedId!==i? 'text.secondary':'text.primary'}} noWrap>
                                                    {categoryObj?.plant?.code}
                                                </Typography>
                                                </Tooltip>
                                            </Stack>}
                                            {/* <Stack spacing={1} direction={'row'} alignItems={'center'} sx={{ width:'50%' }}>                                            
                                                <SourceOutlined sx={{fontSize:'22px', color: selectedId!==i? 'text.secondary':'text.primary'}}></SourceOutlined>
                                                <Typography sx={{fontSize:'12px', color: selectedId!==i? 'text.secondary':'text.primary'}} noWrap>
                                                    {categoryObj?.plant?.code}
                                                </Typography>
                                            </Stack> */}
                                        </Stack>
                                        
                                    </ListItemButton>
                                </ListItem>
                                </React.Fragment>
                                )
                        })
                    }
                    <Divider/>
                    </List>
                    </Grid>
                </Drawer>
                <DestinationDetailsDialog
                    handleClose={handleCloseDialog}
                    open={isDialogOpen}
                    plantData={plantDataDialogue}
                />
            </React.Fragment>
        </>
    )
}

const mapStateToProps = (state) => ({
    userInfo: state.userInfoReducer.userInfo,
    companyInfo: state.companyInfoReducer.companyInfo,
    prCombinations:state.prrfqreducer.prCombinations,
  });

export default connect(mapStateToProps,{
    setGroupNo
})(withStyles(styles)(PrGroupList));
//export default (withStyles(styles)(PrGroupList));
