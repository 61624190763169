import React, { useState, useEffect } from "react";
import { Avatar, Backdrop, Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormHelperText, Grid, IconButton, InputLabel, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { ExtendMassNegoDrawer } from "./Style";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { get_Inquiries_data } from "../../../config/apiUrl";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { openSnackbar } from "../../../redux/actions/snackbarAction";
import { useDispatch } from "react-redux";
import successimage from "../../../assets/vendxAdminPanelAsstes/assetsnew/rfq_submit.svg";
import moment from "moment/moment";

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: "var(--primary-500)",
            fontSize: "10px",
            width: "24px",
            height: "24px"
        },
        children: `${name.split(' ')[0][0]}`,
    };
}

export default function ExtendMassNegoValidityDate(props) {
    const [negotiationNo, setNegotiationNo] = useState("");
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [negotiationValidityDate, setNegotiationValidityDate] = useState(null);
    const [afterChangeNegoDate, setAfterChangeNegoDate] = useState(null);
    const [afterChangeNegoTime, setAfterChangeNegoTime] = useState(null);
    const [validationError, setValidationError] = React.useState(null);
    const [validationErrordropdown, setValidationErrordropdown] = React.useState(null);
    const dispatch = useDispatch();
    const [handleSuccessOpen, setHandleSuccessOpen] = React.useState(false);
    const [createAtDate, setCreateAtDate] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        setValidationError(null);
        setValidationErrordropdown(null);
        const transactionMap = new Map();
        props.mainMassNegoResponse?.transactions
            ?.filter(transaction => transaction.transaction_flag == "MI" || transaction.transaction_flag == "ML")
            .forEach(transaction => {
                const key = `${transaction.id}-${transaction.trans_valid_date}-${transaction.quote_no}`;
                if (!transactionMap.has(key)) {
                    transactionMap.set(key, {
                        id: transaction.id,
                        trans_valid_date: transaction.trans_valid_date,
                        quote_no: transaction.quote_no,
                        quote_date: transaction.quote_date,
                        vendors: []
                    });
                }
                transactionMap.get(key).vendors.push({
                    id: transaction.vendors.id,
                    name: transaction.vendors.name,
                    code: transaction.vendors.code
                });
            });

        setFilteredTransactions(Array.from(transactionMap.values()));
    }, [props.mainMassNegoResponse]);

    useEffect(() => {
        setIsLoading(false);
        setValidationError(null);
        setValidationErrordropdown(null);
        setAfterChangeNegoDate("");
        setAfterChangeNegoTime("");
        setNegotiationNo("");
        setNegotiationValidityDate(null);
        setCreateAtDate("");
        setVendors([]);
        setHandleSuccessOpen(false);
    }, [props.openExtendNegoDateDrawer]);

    const NegotiationNoChange = (event) => {
        const selectedValue = event.target.value;
        setNegotiationNo(selectedValue);
        if (!selectedValue) {
            setNegotiationValidityDate(null);
            setCreateAtDate('');
            setVendors([]);
        } else {
            // Update vendors list and negotiation validity date based on selected negotiation
            const selectedTransaction = filteredTransactions.find(transaction => transaction.id == selectedValue);
            if (selectedTransaction) {
                setVendors(selectedTransaction.vendors);
                setNegotiationValidityDate(selectedTransaction.trans_valid_date);
                setCreateAtDate(selectedTransaction.quote_date);
                setValidationErrordropdown(null);
            }
        }
    };
    const configAxios = {
        headers: { Authorization: `Bearer ${props.sessionID}` },
    };

    const handleDateChange = (newValue) => {
        if (newValue) {
            const dayjsDate = dayjs(newValue);

            if (dayjsDate.isValid()) {
                const dateObject = dayjsDate.toDate();
                setAfterChangeNegoDate(dateObject); // Always store as a Date object
                setValidationError(null);
            } else {
                setValidationError('Invalid date selected.');
            }
        } else {
            setValidationError('No date selected.');
        }
    };

    const handleTimeChange = (newValue) => {
        if (newValue) {
            const dayjsTime = dayjs(newValue);

            if (dayjsTime.isValid()) {
                setAfterChangeNegoTime(dayjsTime.toDate());
                setValidationError(null);
            } else {
                setValidationError("Invalid time selected.");
            }
        } else {
            setValidationError("No time selected.");
        }
    };

    const handleSubmit = (event) => {
        if (!negotiationNo) {
            setValidationErrordropdown("Please Select from the Dropdown.");
            return;
        }

        let finalDatetime = '';
        const beforeSelectedDate = dayjs(negotiationValidityDate).format('YYYY-MM-DD');
        const beforeSelectedTime = dayjs(negotiationValidityDate).format('HH:mm:ss');
        //After change date and time
        const selectedDate = dayjs(afterChangeNegoDate).format('YYYY-MM-DD');
        const selectedTime = dayjs(afterChangeNegoTime).format('HH:mm:ss');

        // Ensure both date and time are selected
        if (selectedDate === "Invalid Date" && selectedTime === "Invalid Date") {
            setValidationError("No changes have been found.");
            return;
        }else if(selectedDate === "Invalid Date" && selectedTime != "Invalid Date"){
            finalDatetime = `${beforeSelectedDate} ${selectedTime}`;
            setValidationError(null);
        }else if(selectedDate !== "Invalid Date" && selectedTime === "Invalid Date"){
            finalDatetime = `${selectedDate} ${beforeSelectedTime}`
            setValidationError(null);
        }else{
            finalDatetime = `${selectedDate} ${selectedTime}`;
        }

        if(negotiationValidityDate === finalDatetime){
            setValidationError("No changes have been found.");
            return;
        }
        setValidationError(""); 
        const result = {
            id: negotiationNo,
            finalDatetime,
        };
        getMassExtendVendorDetails(result);
        setIsLoading(true);
    }

    const getMassExtendVendorDetails = async (result) => {
        const url = get_Inquiries_data + `${props.selectedId}/negotiations/${result.id}/updateNegoValidity`;
        if (props.selectedId === undefined) {
            dispatch(
                openSnackbar(
                    "error",
                    "Some required values are undefined."
                )
            );
            return;
        }
        try {
            const response = await axios.patch(url, { validity_date: result.finalDatetime, mass_nego_flag: 'Y' }, configAxios);
            if (response.status == 200) {
                setHandleSuccessOpen(true);
                setIsLoading(false);
            }
        } catch (error) {
            if (error.response?.data?.error) {
                dispatch(
                    openSnackbar(
                        "error",
                        error.response.data.error[0]
                    )
                );
            } else {
                dispatch(
                    openSnackbar(
                        "error",
                        error.message
                    )
                );
            }
            setIsLoading(false);
        }
    };
    const handleSuccessClose = () => {
        setHandleSuccessOpen(false);
        setVendors([]);
        setCreateAtDate(null);
        props.CloseExtendNegoDateDrawer(); // Close the drawer on success
    }
    return (
        <ExtendMassNegoDrawer anchor="right" open={props.openExtendNegoDateDrawer} onClose={props.CloseExtendNegoDateDrawer} sx={{ zIndex: "1300" }} className="SidebarDrawer">
            <Card>
                <CardHeader
                    action={
                        <Tooltip title="Close">
                            <IconButton onClick={props.CloseExtendNegoDateDrawer} aria-label="close" size="small">
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    title={
                        <Typography variant="subtitle1" component="h6" color={"secondary.main"} lineHeight={"normal"}>
                            Extend Mass Negotiation Validity Date
                        </Typography>
                    }
                    subheader={
                        <React.Fragment>
                            <Typography variant="body2" color={"text.primary"} component="span" whiteSpace={"nowrap"}>
                                RFQ No:
                            </Typography>
                            <Tooltip title="test" placement="bottom-start">
                                <Typography variant="body2" color={"text.primary"} component="span" noWrap>
                                    {props.inquiryNumber}
                                </Typography>
                            </Tooltip>
                        </React.Fragment>
                    }
                    sx={{
                        pt: 1,
                        pb: 1,
                        "& .MuiCardHeader-action": {
                            alignSelf: "center",
                        },
                        "& .MuiCardHeader-content": {
                            width: "calc(100% - 75px)"
                        },
                        "& .MuiCardHeader-subheader": {
                            display: "flex",
                            gap: "4px"
                        },
                        "& .MuiCardHeader-avatar": {
                            mr: 1,
                        },
                    }}
                />
                <Divider />
                <CardContent>
                    <Box component="form" sx={{ '& > :not(:last-child)': { mb: 2 } }} p={2} noValidate autoComplete="off">
                        <FormControl fullWidth size="small" error={!!validationErrordropdown}>
                            <InputLabel id="NegotiationNo">Negotiation No *</InputLabel>
                            <Select labelId="NegotiationNo" value={negotiationNo} label="Negotiation No" onChange={NegotiationNoChange}>
                                <MenuItem value="">Select</MenuItem>
                                {filteredTransactions.map((transaction) => (
                                    <MenuItem key={transaction.id} value={transaction.id}>
                                        {transaction.quote_no}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>
                                {validationErrordropdown}
                            </FormHelperText>
                        </FormControl>
                        <TextField
                            label="Negotiation Date"
                            value={createAtDate ? moment(createAtDate).format('DD/MM/YYYY HH:mm:ss') : ""}
                            InputProps={{
                                readOnly: true,
                            }}
                            InputLabelProps={{
                                shrink: !!createAtDate,
                            }}
                            size="small"
                            fullWidth
                        />
                        <Box>
                            <Typography variant="body2" component="label" color={"secondary.600"} sx={{ mb: 1, display: "block" }}>Negotiation Validity</Typography>
                            <Stack direction="row" spacing={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={negotiationValidityDate ? dayjs(negotiationValidityDate) : null}
                                        onChange={handleDateChange}
                                        label="Date *"
                                        format="DD/MM/YYYY"
                                        disabled={!negotiationNo}
                                        minDate={dayjs()}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                size: 'small',
                                                placeholder: "DD/MM/YYYY",
                                                helperText: validationError,
                                                error: !!validationError,
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        value={negotiationValidityDate ? dayjs(negotiationValidityDate) : null}
                                        onChange={handleTimeChange}
                                        label="Time *"
                                        format="HH:mm:ss"
                                        minDateTime={dayjs()}
                                        disabled={!negotiationNo}
                                        ampm={false}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                size: 'small',
                                                helperText: validationError,
                                                error: !!validationError,
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </Box>
                    </Box>
                    <Divider />
                    <Box>
                        <Box
                            sx={{
                                height: "32px",
                                background: "var(--grey-50)",
                                borderBottom: "1px solid var(--bluegrey-500)",
                                overflow: "hidden",
                                position: "sticky",
                                top: 0,
                                zIndex: 11,
                                padding: "6px 16px"
                            }}
                        >
                            <Typography variant='body2' color={"text.primary"}>Vendor(s)</Typography>
                        </Box>
                        <List
                            sx={{
                                "&.MuiList-root ": {
                                    ".MuiListItemText-root": {
                                        m: 0,
                                        lineHeight: "normal"
                                    },
                                    ".MuiListItemAvatar-root": {
                                        minWidth: "32px"
                                    }
                                }
                            }}
                        >
                            {vendors.map((vendor, index) => (
                                <ListItem key={index}>
                                    <ListItemAvatar>
                                        <Avatar
                                            {...stringAvatar(vendor.name)}
                                            sx={{
                                                fontSize: "10px",
                                                bgcolor: "var(--color-primary)",
                                                width: 24, height: 24
                                            }}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant='body2' color={"text.primary"} fontWeight={500} lineHeight={'normal'}>{vendor.name}</Typography>
                                        }
                                        secondary={
                                            <Typography variant='caption' color={"grey.500"} lineHeight={'normal'}>{vendor.code}</Typography>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </CardContent>
                <Divider />
                <CardActions sx={{ p: 2 }}>
                    <Button variant="outlined" size="small" onClick={props.CloseExtendNegoDateDrawer} fullWidth>Cancel</Button>
                    <Button variant="contained" size="small" fullWidth onClick={handleSubmit}>SUBMIT</Button>
                </CardActions>
            </Card>
            {/* *-----Success Dialog Box-----* */}
            <Dialog scroll="body" maxWidth={"xs"} open={handleSuccessOpen} aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">
                    <IconButton sx={{ color: (theme) => theme.palette.bluegrey[500], ml: "auto" }} onClick={handleSuccessClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className="linkcategorydialog_main">
                            <Grid container>
                                <Grid item lg={12} xl={12} md={12} sm={12} className="text-center" >
                                    <img src={successimage} width={140} alt="Category Link Icon" />
                                    <Typography variant="h6" mb={1}>Successfully</Typography>
                                    <Typography variant="body2">
                                        Negotiation validity date updated successfully.
                                    </Typography>
                                    <Typography variant="body2">
                                        A transaction copy mail has been sent to following supplier stating that Negotiation Validity Date has been changed.
                                    </Typography>
                                    <List 
                                        sx={{ 
                                            "&.MuiList-root ": { 
                                                display: "flex",
                                                flexWrap: "wrap",
                                                ".MuiListItem-root":{
                                                    flex: "0 0 auto",
                                                    width: "auto",
                                                    padding: "8px"
                                                },
                                                ".MuiListItemText-root": { m: 0, lineHeight: "normal" }
                                            } 
                                        }}
                                    >
                                        {vendors.map((vendor, index) => (
                                            <ListItem key={index}>
                                                <ListItemText primary={<Typography variant='body2' color={"text.primary"} fontWeight={500} lineHeight={'normal'}>{vendor.name},</Typography>} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </ExtendMassNegoDrawer>
    )
}
