import * as React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { ExchangeRateDrawer } from "./ExchangeRateStyle";
import CloseIcon from "@mui/icons-material/Close";
import { getSymbol } from "../../AccountTemplate/CurrencySymbol";

export default function ExchangeRateView(props) {
  return (
    <ExchangeRateDrawer
      anchor="right"
      open={props.openExchangeRateView}
      onClose={props.CloseExchangeRateView}
      className="SidebarDrawer"
    >
      <Card>
        <CardHeader
          title={
            <Typography
              variant="h6"
              fontSize="18px"
              fontWeight={500}
              color={"secondary.main"}
            >
              Exchange Rate
            </Typography>
          }
          action={
            <Tooltip title="Close">
              <IconButton
                onClick={props.CloseExchangeRateView}
                aria-label="close"
                size="small"
                sx={{ color: "var(--grey-500)" }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          }
          sx={{
            "& .MuiCardHeader-action": {
              alignSelf: "center",
            },
            "& .MuiCardHeader-avatar": {
              mr: 1,
            },
          }}
        />
        <Divider />
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={260}>Exchange rates for inr (₹) 1</TableCell>
                <TableCell width={160} align="right">
                  RATES
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(props.currencyExchangeRates).map(
                ([currency, rate]) => {
                  if (currency !== "INR") {
                    return (
                      <TableRow>
                        <TableCell width={260}>{`${currency} (${getSymbol(
                          currency
                        )})`}</TableCell>
                        <TableCell
                          width={160}
                          align="right"
                          sx={{ color: "var(--grey-500)" }}
                        >
                          {rate}
                        </TableCell>
                      </TableRow>
                    );
                  }
                }
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </ExchangeRateDrawer>
  );
}
