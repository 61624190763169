import GlobalStyles from "@mui/material/GlobalStyles";
import * as React from "react";

export const VendorStyle = (
  <GlobalStyles
    styles={(theme) => ({
      ".RFQ-VendorDialog .Dialog-AppTitle": {
        height: "50px",
        padding: "5px 20px",
        borderBottom: "solid 1px var(--bluegrey-500)",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        oveflow: "hidden",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
      "& .Dialog-AppTitle .Breadcrumb-Vendor":{
        ".MuiBreadcrumbs-ol":{
          flexWrap:"nowrap",
          ".MuiBreadcrumbs-li:not(:first-child)":{
            overflow:"hidden"
          }
        }        
      },
      "& .Dialog-AppTitle .Breadcrumb-Vendor, .Dialog-AppTitle .Breadcrumb-Vendor .MuiTypography-root":
        {
          fontSize: "12px",
          color: "var(--text-color)",
          textTransform: "uppercase",
          fontWeight: "500",
          "& .MuiBreadcrumbs-separator": {
            marginLeft: "4px",
            marginRight: "4px",
          },
          "& a": {
            textDecoration: "none",
            color: "var(--text-color)",
            fontWeight: "500",
          },
        },
      ".RFQ-VendorDialog .RFQ-VendorDataGrid": {
        width: "calc(100% - 450px)",
        [theme.breakpoints.down("lg")]: {
          width: "calc(100% - 375px)",
        },
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
      },
      ".RFQ-VendorDialog .DataGFullScreen-wrap": {
        height: "calc(100vh - var(--PageToolbarHeight) - 88px )",
        [theme.breakpoints.down("md")]: {
          height: "calc(100vh - var(--PageToolbarHeight) - 48px )",
        },
      },
      ".RFQ-VendorDialog .MuiDataGrid-root .datagrid-toolbar": {
        padding: "3px 16px",
        // borderBottom: '0px',
      },
      ".itemWiseVendor-Tabs .tabs-panel": {
        borderTop: "1px solid var(--bluegrey-500)",
      },
    })}
  />
);
