import { Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { getSymbol } from "../../AccountTemplate/CurrencySymbol";
import commifyWithPrecision from "../../../common/utility";
import { useDispatch, useSelector } from "react-redux";

const EstimatedValue = ({ open, handleClose, rate, qty, currency, unit }) => {

  const [mainRate, setMainRate] = useState(rate);
  const [mainQty, setMainQty] = useState(qty);
  const feature_permission_obj = useSelector((state)=>state.permissionsReducer.featurePermission);
  const international_numeric_format = feature_permission_obj?.international_numeric_format?.feature_value

  useEffect(() => {
    setMainRate(rate);
  }, [rate])

  useEffect(() => {
    setMainQty(qty);
  }, [qty])

  return (
    <div>
      <Dialog
        scroll="body"
        fullWidth
        maxWidth="sm"
        open={open}
        // onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Estimated Value Details
          <IconButton
            onClick={handleClose}
            sx={{ ml: "auto", color: (theme) => theme.palette.bluegrey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          
            <div className="linkcategorydialog_main">
              <Grid
                container
                sx={{
                  borderBottom: 1,
                  borderColor: "bluegrey.500",
                  padding: "15px 0",
                }}
              >
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Typography sx={{ textTransform: "uppercase" }}>
                    Budgeted Rate
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ textAlign: "right" }}
                >
                  <Typography sx={{ fontWeight: "700" }}>
                    {getSymbol(currency)} {mainRate ? commifyWithPrecision(mainRate, international_numeric_format) : 0} per {unit}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  borderBottom: 1,
                  borderColor: "bluegrey.500",
                  padding: "15px 0",
                }}
              >
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Typography sx={{ textTransform: "uppercase" }}>
                    Proposed Order Quantity
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ textAlign: "right" }}
                >
                  {/* <p>200 Per KG</p> */}
                  <Typography sx={{ fontWeight: "700" }}>
                     {mainQty ? commifyWithPrecision(mainQty, international_numeric_format, true) : 0} {unit}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ padding: "15px 0" }}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Typography sx={{ textTransform: "uppercase" }}>
                    Estimated Value
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ textAlign: "right" }}
                >
                  <Typography sx={{ fontWeight: "700" }}>
                    {getSymbol(currency)} {commifyWithPrecision(mainQty * mainRate, international_numeric_format)}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EstimatedValue;
