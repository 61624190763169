import {
  SET_AVAILABLE_ITEM,
  SET_AVAILABLE_ITEM_LOADING,
  SET_AVAILABLE_ITEM_ERROR,
  SET_SELECTED_ITEM,
  SET_SEARCHED_ITEM,
  SET_SEARCHED_ITEM_LOADING,
  SET_SEARCHED_ITEM_ERROR,
  RESET_ITEMS,
  RESET_SELECTED_ITEMS,
  SET_FILTERED_ITEMS,
  SET_FILTERED_ITEMS_LOADING,
  RESET_SEARCHED_ITEMS,
  SET_CHECK_VALID_TECHNICALBID_ITEMS,
  UNSET_CHECK_VALID_TECHNICALBID_ITEMS,
  SET_ITEM_SPECIFICATIONS,
  RESET_ITEM_SPECIFICATIONS,
  SET_VENDOR_LOCATION_FLAG,
  RESET_VENDOR_LOCATION_FLAG,
} from "../constants/type";
import {
  getAllLinkedItems,
  searchLinkedItem,
  filterItems,
} from "../../config/apiUrl";
import axios from "axios";

//for getting all items of category
export const setAvailableItem =
  (category, page = 1, per_page = 15, sessionId) =>
  (dispatch) => {
    dispatch({
      type: SET_AVAILABLE_ITEM_LOADING,
    });
    axios.defaults.headers.common["Authorization"] = `Bearer ${sessionId}`;
    // axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Bearer 1623996009138030.702506846885026`;
    const url = getAllLinkedItems(category.id, page, per_page);
    axios
      .get(url)
      .then((res) => {
          dispatch({
            type: SET_AVAILABLE_ITEM,
            payload: res.data,
          });
      })
      .catch((error) => {
        console.log(error);

        if (error.response?.data?.message) {
          dispatch({
            type: SET_AVAILABLE_ITEM_ERROR,
            payload: error.response.data.message,
          });
        } else {
          dispatch({
            type: SET_AVAILABLE_ITEM_ERROR,
            payload: error.message,
          });
        }
      });
  };

//for searching items of category
export const setSearchedItem = (category, item, page = 1, sessionId) => (dispatch) => {
  dispatch({
    type: SET_SEARCHED_ITEM_LOADING,
  });
  axios.defaults.headers.common["Authorization"] = `Bearer ${sessionId}`;
  // axios.defaults.headers.common[
  //   "Authorization"
  // ] = `Bearer 1623996009138030.702506846885026`;
  const url = searchLinkedItem(category.id, item.trim(), page);
  axios
    .get(url)
    .then((res) => {
      if (res.data.data.length) {
        dispatch({
          type: SET_SEARCHED_ITEM,
          payload: res.data,
        });
      } else {
        dispatch({
          type: SET_SEARCHED_ITEM_ERROR,
          payload: "No item found",
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_SEARCHED_ITEM_ERROR,
        payload: err.message,
      });
    });
};

//for filtering items of category
export const filterAvailableItems =
  (category, item, code, page = 1, sessionId) =>
  (dispatch) => {
    dispatch({
      type: SET_FILTERED_ITEMS_LOADING,
    });
    const url = filterItems(category.id, item.trim(), code.trim(), page);
    axios.defaults.headers.common["Authorization"] = `Bearer ${sessionId}`;
    axios
      .get(url)
      .then((res) => {
        dispatch({
          type: SET_FILTERED_ITEMS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.message) {
          dispatch({
            type: SET_FILTERED_ITEMS_LOADING,
            payload: err.response.data.message,
          });
        } else {
          dispatch({
            type: SET_FILTERED_ITEMS_LOADING,
            payload: err.message,
          });
        }
      });
  };

//for setting error manually
export const setErrorMsgItems = (msg) => (dispatch) => {
  dispatch({
    type: SET_AVAILABLE_ITEM_ERROR,
    payload: msg,
  });
};
export const setSearchedItemError = (msg) => (dispatch) => {
  dispatch({
    type: SET_SEARCHED_ITEM_ERROR,
    payload: msg,
  });
};
export const setSelectedItem = (selectedItem) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_ITEM,
    payload: selectedItem,
  });
};
export const resetItems = () => (dispatch) => {
  dispatch({
    type: RESET_ITEMS,
  });
};
export const resetSelectedItems = () => (dispatch) => {
  dispatch({
    type: RESET_SELECTED_ITEMS,
  });
};
export const resetItemspecification = () => (dispatch) => {
  dispatch({
    type: RESET_ITEM_SPECIFICATIONS,
  });
};


export const resetSearchedItems = () => (dispatch) => {
  dispatch({
    type: RESET_SEARCHED_ITEMS,
  });
};

export const setCheckValidTechnicalBidItems = (technicalBidItems) => {

  return {
    type: SET_CHECK_VALID_TECHNICALBID_ITEMS,
    payload: {...technicalBidItems},
 }
}

export const unsetCheckValidTechnicalBidItems = (items) =>  (dispatch) => {
  dispatch({
    type: UNSET_CHECK_VALID_TECHNICALBID_ITEMS,
    payload: items,
 });
}

export const setItemSpecifications = (itemSpecs) =>(dispatch) => {
  dispatch({
    type:SET_ITEM_SPECIFICATIONS,
    payload:{...itemSpecs}
  })
}

export const setVendorLocationFlag = (vendorLocationFlag) =>(dispatch) => {
  dispatch({
    type: SET_VENDOR_LOCATION_FLAG,
    payload: vendorLocationFlag,
  })
}

export const resetVendorLocationFlag = () =>(dispatch) => {
  dispatch({
    type: RESET_VENDOR_LOCATION_FLAG,
  })
} 
