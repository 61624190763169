import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
// import "../../../pages/style.css";
import _, { debounce, filter } from "lodash";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Alert, Badge, Fab, Modal, ToggleButton, Tooltip } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ResizableBox } from "react-resizable";
// import "./resize.style.css";
// import More from "../More/More";
import Upload from "../../Dialogs/Upload/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import QuotationFormField from "../QuotationFormField";
import { styled } from "@mui/material/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  List,
  ListItem,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Close as CloseIcon } from "@mui/icons-material";
import LandedCostRFQDrawer from "../LandedCostRFQDrawer/LandedCostRFQDrawer";
import UploadDynamicView from "../../Dialogs/Upload/UploadDynamicView";
import { postFileSpecifications } from "../../../config/apiUrl";
import axios from "axios";
import { getSymbol } from "../../AccountTemplate/CurrencySymbol";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LandedCostSlabDrawer from "../LandedCostSlabDrawer/LandedCostSlabDrawer";
import ConfirmAlert from "../../Dialogs/ConfirmAlert.js";

// const PageToolbar = styled("div")(({ theme }) => ({

// export default class QuotationFormSectionTable extends Component {
export default function QuotationFormSectionTable(props) {

  const [disabledProps, setDisabledProps] = useState({});
  const [disabledPropsForSlabParent, setDisabledPropsForSlabParent] = useState(
    {}
  );
  const [disabledPropsForSlabChild, setDisabledPropsForSlabChild] = useState(
    {}
  );
  const [disabledPropsForMS, setDisabledPropsForMS] = useState(
    {}
  );
  const [switchDisabledProps, setSwitchDisabledProps] = useState({});
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [updatedFileValue, setUpdatedFileValue] = useState([]);
  const [popHash, setPopHash] = useState({});
  const [Toggle, setToggle] = useState({});
  const [ToggleMS, setToggleMS] = useState({});
  const [modalData, setModalData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [fileSpecificationsOpen, setFileSpecificationsOpen] = useState(false);
  const [landedCostRFQ, setLandedCostRFQ] = useState({
    // Start Total Landed Cost For RFQ Drawer script
    right: false,
  });
  const [landedCostSlab, setLandedCostSlab] = useState({
    // Start Total Landed Cost For RFQ Drawer script
    right: false,
  });
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [state, setState] = useState({
    isScrollActive: false,
    currentItemIndex: null,
    anchorEl: null,
    qty: "",
    rate: "",
    estimatedValueOpen: false,
  });
  const [Loading, setLoading] = useState(true);
  const [filteredSelectedNotDataSlab, setFilteredSelectedNotDataSlab] =
    useState({});
  const [AllItemsTemplate, setAllItemsTemplate] = useState([]);
  const [specification, setSpecification] = useState([]);
  const [SpecificationText, setSpecificationText] = useState("");
  const [unit, setUnit] = useState("");
  const [templateData, setTemplateData] = useState({});
  const [editableValues, setEditableValues] = useState({});
  const [destinationCount, setDestinationCount] = useState(0);
  const [Errors, setErrors] = useState([]);
  const [RearrangedArray, setRearrangedArray] = useState([]);
  const [TotalCostFIeld, setTotalCostFIeld] = useState({});
  const [PerCost, setPerCost] = useState({});
  const [RateField, setRateFIeld] = useState({});
  const [FilteredMainTemplate, setFilteredMainTemplate] = useState({});
  const [FilteredSlabMainTemplate, setFilteredSlabMainTemplate] = useState({});
  const [hoverTooltip, setHoverTooltip] = useState(false);
  const { multiSolmainTemplateQuotation, handleUpdateQuotation } = props;
  const [keys, setKeys] = useState([]);
  const [iconState, setIconState] = useState({});
  const [iconStateMS, setIconStateMS] = useState({});
  // const [checkboxState, setCheckboxState] = useState({});
  // const [disabledRows, setDisabledRows] = useState({});
  const [multipleSolutionNameFlag, setMultipleSolutionNameFlag] =
    useState(false);
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [selectedIndexDelete, setSelectedIndexDelete] = useState(null);
  const [selectedKeyDelete, setSelectedKeyDelete] = useState(null);
  const [destinationValues, setDestinationValues] = useState({});
  const [keyValueWithHashGSTType, setKeyValueWithHashGSTType] = useState({});

  const openModalDelete = (index, key) => {
    const solutionArray = props.multiSolmainTemplateQuotation[key];

    // Check if it's the only row or the last remaining row
    if (solutionArray.length <= 1) {
      // Do not show modal if there's only one row
      return;
    }
    setSelectedIndexDelete(index);
    setSelectedKeyDelete(key);
    setIsModalOpenDelete(true);
  };

  const handleModalOkDelete = () => {
    if (selectedIndexDelete !== null && selectedKeyDelete) {
      deleteRow(
        selectedIndexDelete,
        selectedKeyDelete,
        props.multiSolmainTemplateQuotation[selectedKeyDelete]
      );
    }
    setIsModalOpenDelete(false);
    setSelectedIndexDelete(null);
    setSelectedKeyDelete(null);
  };

  const handleModalCancelDelete = () => {
    setIsModalOpenDelete(false);
    setSelectedIndexDelete(null);
    setSelectedKeyDelete(null);
  };

  const handleParentCheckboxChange = (event, key) => {

    // Ensure that mainTemplateQuotation and lastTransactionTypeInThread exist before accessing their properties

    let updatedQuotation = { ...props.slabMainTemplateQuotation };
    if (props.lastTransactionTypeInThread === "Inquiry") {


      const updatedSlabMainTemplateQuotation = {
        ...props.slabMainTemplateQuotation,
      };
      const parentChecked = !event.target.checked; // Get the current checked state from the event
      updatedSlabMainTemplateQuotation[key].selected = parentChecked
        ? "N"
        : "Y"; // Set selected based on checkbox state
      updatedSlabMainTemplateQuotation[key].forEach((child) => {
        child.select_slab = parentChecked ? "N" : "Y"; // Update all child checkboxes
      });
      props.setSlabMainTemplateQuotation(updatedSlabMainTemplateQuotation);
      return; // Early return after handling Inquiry case
    }

    if (
      props.lastTransactionInThread &&
      props.lastTransactionInThread[key] &&
      props.slabMainTemplateQuotation &&
      props.slabMainTemplateQuotation[key]
    ) {
      const isNegotiation = props.lastTransactionTypeInThread === "Negotiation";
      const lastSelected = props.lastTransactionInThread[key].selected === "Y";
      const mainSelected =
        props.slabMainTemplateQuotation[key].selected === "Y";
      const is_deleted_from_master = props.items[key].is_deleted_from_master;
      const is_unlink_from_category = props.items[key].is_unlink_from_category;

      // Check if filteredSelectedNotData contains the key and its selected value is 'N'
      const filteredSelectedNotDataContainsKey = props.filteredSelectedNotData && props.filteredSelectedNotData[key] && props.filteredSelectedNotData[key].selected;

      if (is_deleted_from_master || is_unlink_from_category) {
        if (updatedQuotation && updatedQuotation[key]) {
          updatedQuotation[key].selected = "N";
        }
        props.setSlabMainTemplateQuotation(updatedQuotation);
      } else if (props.lastTransactionTypeInThread === "Inquiry") {
        // console.log("updatedQuotation 2");
        if (!event.target.checked) {
          if (updatedQuotation && updatedQuotation[key]) {
            updatedQuotation[key].selected = "N";
          }
        } else {
          if (updatedQuotation && updatedQuotation[key]) {
            updatedQuotation[key].selected = "Y";
          }
        }
        props.setSlabMainTemplateQuotation(updatedQuotation);
      } else if (isNegotiation && lastSelected) {
        // console.log("updatedQuotation 3");
        if (updatedQuotation && updatedQuotation[key]) {
          updatedQuotation[key].selected = "Y";
        }
        props.setSlabMainTemplateQuotation(updatedQuotation);
      } else if (
        isNegotiation &&
        !lastSelected &&
        filteredSelectedNotDataContainsKey === "N" &&
        mainSelected
      ) {
        // console.log("updatedQuotation 4");
        if (updatedQuotation && updatedQuotation[key]) {
          updatedQuotation[key].selected = "N";
          updatedQuotation[key].forEach(child => {
            child.select_slab = event.target.checked ? "Y" : "N"; // Update all child checkboxes
          });
        }
        // console.log("updatedQuotation 4",updatedQuotation);        
        props.setSlabMainTemplateQuotation(updatedQuotation);
      } else if (
        isNegotiation &&
        !lastSelected &&
        filteredSelectedNotDataContainsKey === "N" &&
        !mainSelected
      ) {
        // console.log("updatedQuotation 5");
        if (updatedQuotation && updatedQuotation[key]) {
          updatedQuotation[key].selected = "Y";
          updatedQuotation[key].forEach(child => {
            child.select_slab = event.target.checked ? "Y" : "N"; // Update all child checkboxes
          });
        }
        // console.log("updatedQuotation 5",updatedQuotation);        
        props.setSlabMainTemplateQuotation(updatedQuotation);
      } else if (
        isNegotiation &&
        !lastSelected &&
        filteredSelectedNotDataContainsKey !== "N"
      ) {
        // console.log("updatedQuotation 6");
        if (updatedQuotation && updatedQuotation[key]) {
          updatedQuotation[key].selected = "Y";
        }
        props.setSlabMainTemplateQuotation(updatedQuotation);
      } else if (mainSelected && !lastSelected) {
        if (!event.target.checked) {
          if (updatedQuotation && updatedQuotation[key]) {
            updatedQuotation[key].selected = "N";
            updatedQuotation[key].forEach(child => {
              child.select_slab = event.target.checked ? "Y" : "N"; // Update all child checkboxes
            });
          }
        } else {
          if (updatedQuotation && updatedQuotation[key]) {
            updatedQuotation[key].selected = "Y";
            updatedQuotation[key].forEach(child => {
              child.select_slab = event.target.checked ? "Y" : "N"; // Update all child checkboxes
            });
          }
        }
        props.setSlabMainTemplateQuotation(updatedQuotation);
      } else if (!mainSelected) {
        if (updatedQuotation && updatedQuotation[key]) {
          updatedQuotation[key].selected = event.target.checked ? "Y" : "N";

          updatedQuotation[key].forEach(child => {
            child.select_slab = event.target.checked ? "Y" : "N"; // Update all child checkboxes
          });
        }
        props.setSlabMainTemplateQuotation(updatedQuotation);
      } else {
        const filteredSelectedNotDataContainsKey =
          props.filteredSelectedNotData &&
          props.filteredSelectedNotData[key] &&
          props.filteredSelectedNotData[key].selected === "N";
        const selectedStatus = filteredSelectedNotDataContainsKey ? "N" : "Y";
        if (updatedQuotation && updatedQuotation[key]) {
          updatedQuotation[key].selected = selectedStatus;
        }
        props.setSlabMainTemplateQuotation(updatedQuotation);
      }
    };
    if (props.lastTransactionTypeInThread !== "Inquiry" && props.multiplePlantsFlag) {
      handleSaveClick();
    }
  };

  const handleChildCheckboxChange = (event, key, rowIndex, solutionRange) => {

    if (
      props.lastTransactionInThread &&
      props.lastTransactionInThread[key] &&
      props.slabMainTemplateQuotation &&
      props.slabMainTemplateQuotation[key]
    ) {
      const isNegotiation = props.lastTransactionTypeInThread === "Negotiation";
      const lastSelected =
        props.lastTransactionInThread[key].solution[0].data[rowIndex]
          .select_slab === "Y";
      const mainSelected =
        props.slabMainTemplateQuotation[key][rowIndex].select_slab === "Y";
      const is_deleted_from_master = props.items[key].is_deleted_from_master;
      const is_unlink_from_category = props.items[key].is_unlink_from_category;

      const filteredDataItems = props.lastTransactionData?.itemize[key].solution[0].data[rowIndex].select_slab === "N";
      const filteredSelectedNotDataContainsKey = filteredDataItems;
      const slabChildStateToggleCheckMain = props.slabChildStateToggleCheck[key][rowIndex];

      let updatedQuotation = { ...props.slabMainTemplateQuotation };

      if (is_deleted_from_master || is_unlink_from_category) {
        if (updatedQuotation && updatedQuotation[key]) {
          updatedQuotation[key][rowIndex].select_slab = "N";
        }
        props.setSlabMainTemplateQuotation(updatedQuotation);
      } else if (props.lastTransactionTypeInThread === "Inquiry") {
        if (!event.target.checked) {
          if (updatedQuotation && updatedQuotation[key]) {
            updatedQuotation[key][rowIndex].select_slab = "N";
          }
        } else {
          if (updatedQuotation && updatedQuotation[key]) {
            updatedQuotation[key][rowIndex].select_slab = "Y";
          }
        }
        props.setSlabMainTemplateQuotation(updatedQuotation);
      } else if (isNegotiation && lastSelected) {
        // console.log("updatedQuotation slab 1");
        if (updatedQuotation && updatedQuotation[key]) {
          updatedQuotation[key][rowIndex].select_slab = "Y";
          // updatedQuotation[key][rowIndex].select_slab = event.target.checked
          // ? "Y"
          // : "N";
        }
        props.setSlabMainTemplateQuotation(updatedQuotation);
      } else if (
        isNegotiation &&
        !lastSelected &&
        filteredSelectedNotDataContainsKey === "N" &&
        mainSelected
      ) {
        // console.log("updatedQuotation slab 2");
        if (updatedQuotation && updatedQuotation[key]) {
          updatedQuotation[key][rowIndex].select_slab = "N";
          updatedQuotation[key][rowIndex].select_slab = event.target.checked
            ? "Y"
            : "N";
        }
        props.setSlabMainTemplateQuotation(updatedQuotation);
      } else if (
        isNegotiation &&
        !lastSelected &&
        filteredSelectedNotDataContainsKey === "N" &&
        !mainSelected
      ) {
        // console.log("updatedQuotation slab 3");
        if (updatedQuotation && updatedQuotation[key]) {
          updatedQuotation[key][rowIndex].select_slab = "Y";
          // updatedQuotation[key][rowIndex].select_slab = event.target.checked
          // ? "Y"
          // : "N";
        }
        props.setSlabMainTemplateQuotation(updatedQuotation);
      } else if (
        isNegotiation &&
        !lastSelected &&
        filteredSelectedNotDataContainsKey !== "N" &&
        // mainSelected
        slabChildStateToggleCheckMain

      ) {
        // console.log("updatedQuotation slab 4");
        if (updatedQuotation && updatedQuotation[key]) {
          updatedQuotation[key][rowIndex].select_slab = "Y";
          // updatedQuotation[key][rowIndex].select_slab = event.target.checked
          // ? "Y"
          // : "N";
        }
        props.setSlabMainTemplateQuotation(updatedQuotation);
      } else if (
        isNegotiation &&
        !lastSelected &&
        filteredSelectedNotDataContainsKey !== "N" &&
        !mainSelected && slabChildStateToggleCheckMain
      ) {
        // console.log("updatedQuotation slab 44");
        if (updatedQuotation && updatedQuotation[key]) {
          updatedQuotation[key][rowIndex].select_slab = "N";
          updatedQuotation[key][rowIndex].select_slab = event.target.checked
            ? "Y"
            : "N";
        }
        props.setSlabMainTemplateQuotation(updatedQuotation);
      } else if (
        isNegotiation &&
        !lastSelected &&
        filteredSelectedNotDataContainsKey !== "N" &&
        !slabChildStateToggleCheckMain
      ) {
        // console.log("updatedQuotation slab 55");
        if (updatedQuotation && updatedQuotation[key]) {
          updatedQuotation[key][rowIndex].select_slab = "N";
          updatedQuotation[key][rowIndex].select_slab = event.target.checked
            ? "Y"
            : "N";
        }
        props.setSlabMainTemplateQuotation(updatedQuotation);
      }
      else if (mainSelected && !lastSelected) {
        // console.log("updatedQuotation slab 5");
        if (!event.target.checked) {
          if (updatedQuotation && updatedQuotation[key]) {
            updatedQuotation[key][rowIndex].select_slab = "N";
          }
        } else {
          if (updatedQuotation && updatedQuotation[key]) {
            updatedQuotation[key][rowIndex].select_slab = "Y";
          }
        }
        props.setSlabMainTemplateQuotation(updatedQuotation);
      } else if (!mainSelected) {
        // console.log("updatedQuotation slab 6");
        if (updatedQuotation && updatedQuotation[key]) {
          updatedQuotation[key][rowIndex].select_slab = "Y";
          updatedQuotation[key][rowIndex].select_slab = event.target.checked
            ? "Y"
            : "N";
        }
        // console.log("updatedQuotation slab 666",updatedQuotation);

        props.setSlabMainTemplateQuotation(updatedQuotation);
      } else {
        const filteredSelectedNotDataContainsKey =
          props.filteredSelectedNotData &&
          props.filteredSelectedNotData[key] &&
          props.filteredSelectedNotData[key].selected === "N";
        const selectedStatus = filteredSelectedNotDataContainsKey ? "N" : "Y";
        if (updatedQuotation && updatedQuotation[key]) {
          updatedQuotation[key][rowIndex].select_slab = selectedStatus;
        }
        props.setSlabMainTemplateQuotation(updatedQuotation);
      }

      // Check if all child checkboxes are unchecked
      const allUnchecked = updatedQuotation[key].every(
        (item) => item.select_slab !== "Y"
      );
      if (allUnchecked) {
        const parentUpdatedQuotation = { ...props.slabMainTemplateQuotation };
        parentUpdatedQuotation[key].selected = "N";
        props.setSlabMainTemplateQuotation(parentUpdatedQuotation);
      } else {
        // Check if any child checkbox is checked
        const anyChecked = updatedQuotation[key].some(
          (item) => item.select_slab === "Y"
        );
        if (anyChecked) {
          const parentUpdatedQuotation = { ...props.slabMainTemplateQuotation };
          parentUpdatedQuotation[key].selected = "Y";
          props.setSlabMainTemplateQuotation(parentUpdatedQuotation);
        }
      }

      // Update the disabledRows state
      const updatedDisabledRows = { ...props.disabledRows };
      if (!event.target.checked) {
        updatedDisabledRows[`${key}-${solutionRange}`] = true;
      } else {
        updatedDisabledRows[`${key}-${solutionRange}`] = false;
      }
      props.setDisabledRows(updatedDisabledRows);
    } else {
      if (
        props.slabMainTemplateQuotation[key]?.added_after_rfq_created == true
      ) {
        const updatedQuotation = { ...props.slabMainTemplateQuotation };
        if (updatedQuotation && updatedQuotation[key]) {
          updatedQuotation[key][rowIndex].select_slab = event.target.checked
            ? "Y"
            : "N";
        }
        props.setSlabMainTemplateQuotation(updatedQuotation);
      }
      console.error(
        `Template with key ${key} does not exist in either mainTemplateQuotation or lastTransactionInThread.`
      );
    }
    if (props.lastTransactionTypeInThread !== "Inquiry" && props.multiplePlantsFlag) {
      handleSaveClick();
    }
  };

  const handleAddClick = (event, key) => {
    setIconState((prevState) => ({
      ...prevState,
      [key]: true,
    }));
    setToggle((prevState) => ({
      ...prevState,
      [key]: true,
    }));
  };

  const handleRemoveClick = (event, key) => {
    setIconState((prevState) => ({
      ...prevState,
      [key]: false,
    }));
    setToggle((prevState) => ({
      ...prevState,
      [key]: false,
    }));
  };

  const handleAddClickForMS = (event, key) => {
    setIconStateMS((prevState) => {
      const newState = { ...prevState, [key]: true };
      return newState;
    });

    setToggleMS(prevState => {
      const newState = { ...prevState, [key]: true };
      return newState;
    });
  };

  const handleRemoveClickForMS = (event, key) => {
    setIconStateMS((prevState) => {
      const newState = { ...prevState, [key]: false };
      return newState;
    });

    setToggleMS(prevState => {
      const newState = { ...prevState, [key]: false };
      return newState;
    });
  };

  const handleSwitchBoxChange = (event, key) => {
    const { mainTemplateQuotation, lastTransactionInThread, multiSolmainTemplateQuotation } = props;

    if (
      !mainTemplateQuotation ||
      !lastTransactionInThread ||
      !multiSolmainTemplateQuotation ||
      !mainTemplateQuotation[key] ||
      !lastTransactionInThread[key] ||
      !multiSolmainTemplateQuotation[key]
    ) {
      return;
    }

    const checked = event.target.checked;

    if (!checked) {
      setIsModalOpen(true);
      setSelectedKey(key);

      return;
    }

    if (multiSolmainTemplateQuotation[key].length > 0) {
      // Check if there is only one solution and duplicate it
      if (multiSolmainTemplateQuotation[key].length === 1) {
        multiSolmainTemplateQuotation[key] = [
          ...multiSolmainTemplateQuotation[key],
          { ...multiSolmainTemplateQuotation[key][0] }
        ];
      }

      setToggleMS(prevState => ({
        ...prevState,
        [key]: checked,
      }));

      setSwitchDisabledProps(prevState => ({
        ...prevState,
        [key]: checked,
      }));

      setIconStateMS((prevState) => {
        const newState = { ...prevState, [key]: checked };
        return newState;
      });

      props.toggleSwitch(key);

      // Sonali code :: START
      const {
        solutionNames,
        setSolutionNames,
      } = props;

      // Update solution names if necessary
      let newSolName = { ...solutionNames };

      if (!newSolName[`${key}-0`]) {
        newSolName[`${key}-0`] = ""; // Ensure the initial value for the 0th index is set
      }

      newSolName[`${key}-1`] = ""; // Set the next index as an empty string

      // Update the state in the parent component
      setSolutionNames(newSolName); // Update solutionNameErrors in parent state

      // Sonali code :: END

    } else {
      console.log("No data available for key:", key);
    }
  };

  const handleModalOk = () => {
    if (selectedKey !== null) {
      props.updateMultiSolmainTemplateQuotation(selectedKey);

      props.toggleSwitch(selectedKey);

      // Update the states to disable the row
      setToggleMS(prevState => ({
        ...prevState,
        [selectedKey]: false, // Disable the row
      }));

      setSwitchDisabledProps(prevState => ({
        ...prevState,
        [selectedKey]: false, // Disable the switch
      }));

      setIconStateMS(prevState => ({
        ...prevState,
        [selectedKey]: false, // Update icon state
      }));
    }

    // Close the modal and reset the selected key
    setIsModalOpen(false);
    setSelectedKey(null);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setSelectedKey(null);
    setIconStateMS((prevState) => ({
      ...prevState,
      [selectedKey]: false,
    }));
  };

  useEffect(() => {
    if (props.destination) {
      const destinationsArray = props.destination.split("###");
      setDestinationCount(destinationsArray?.length);
    }
  }, [props.destination]);

  useEffect(() => {
    // Use setTimeout to introduce a delay before calling updateSwitchState
    const { lastTransactionTypeInThread, updateSwitchState, slabFlag, multipleSolutionFlag } = props;
    const timer = setTimeout(() => {
      if (multipleSolutionFlag && !slabFlag && lastTransactionTypeInThread !== "Inquiry") {
        // updateSwitchState();
      }
    }, 5000); // Delay of 5 seconds

    // Cleanup function to clear the timeout if the component unmounts or updates
    return () => clearTimeout(timer);
  }, [props.lastTransactionTypeInThread, props.updateSwitchState]);

  const addRow = (key, index, solutions) => {
    const {
      multiSolmainTemplateQuotation = {},
      handleUpdateQuotation,
      mainTemplateQuotation = {},
      solutionNames,
      setSolutionNames,
    } = props;

    // Ensure the key exists and is an array
    if (!Array.isArray(multiSolmainTemplateQuotation[key])) {
      multiSolmainTemplateQuotation[key] = [];
    }

    if (multiSolmainTemplateQuotation[key].length >= 10) {
      props.openSnackbar("error", "Cannot add more than 10 rows");
      return; // Exit the function if there are already 10 rows
    }

    // Create a new entry based on the solutions or mainTemplateQuotation
    const newEntry = solutions[index]
      ? { ...solutions[index] }
      : { ...mainTemplateQuotation[key] };

    // Clone the existing data for the given key and add the new entry
    const newRow = [...solutions, newEntry];

    // Update solution names if necessary
    let newSolName = { ...solutionNames };
    // console.log("newSolName", newSolName);

    if (!newSolName[`${key}-0`]) {
      newSolName[`${key}-0`] = ""; // Ensure the initial value for the 0th index is set
    }
    newSolName[`${key}-${index + 1}`] = ""; // Set the next index as an empty string

    // Update the state in the parent component
    handleUpdateQuotation(key, newRow);
    setSolutionNames(newSolName); // Update solutionNameErrors in parent state
  };

  const handleInputChange = (key, event) => {
    props.handleSolNameChangeMainTemplate(key, event.target.value);
  };
  const deleteRow = (index, key, solution) => {
    const { solutionNames, setSolutionNames, setSolutionNamesErrors } = props;

    // Check if the solution has only one row and it's the 0th index
    if (solution.length === 1 && index === 0) {
      return;
    }

    // Check if the solution has only one row and it's not the 0th index
    if (solution.length === 1 && index !== 0) {
      return; // Do not allow deletion of the last remaining row
    }

    // Proceed with deletion if there are more than one row
    const updatedSolutions = [...solution];
    updatedSolutions.splice(index, 1);

    let newSolName = { ...solutionNames };
    Object.keys(newSolName).forEach(solKey => {
      if (solKey === `${key}-${index}`) {
        delete newSolName[solKey];
      } else if (solKey.startsWith(`${key}-`) && parseInt(solKey.split('-')[1], 10) > index) {
        const newIndex = parseInt(solKey.split('-')[1], 10) - 1;
        newSolName[`${key}-${newIndex}`] = newSolName[solKey];
        delete newSolName[solKey];
      }
    });

    let newSolErrors = {...props.solutionNameErrors}
    Object.keys(newSolErrors).forEach(solKey => {
      if (solKey === `${key}-${index}`) {
        delete newSolErrors[solKey];
      } else if (solKey.startsWith(`${key}-`) && parseInt(solKey.split('-')[1], 10) > index) {
        const newIndex = parseInt(solKey.split('-')[1], 10) - 1;
        newSolErrors[`${key}-${newIndex}`] = newSolErrors[solKey];
        delete newSolErrors[solKey];
      }
    });
     
    handleUpdateQuotation(key, updatedSolutions);
    setSolutionNames(newSolName);
    setSolutionNamesErrors(newSolErrors)
  };

  useEffect(() => {
    //use in future if needed
  }, [props.multiSolmainTemplateQuotation]);

  useEffect(() => {
    const filterMainTemplate = {};

    Object.keys(props.mainTemplateQuotation).forEach((key) => {
      if (props.mainTemplateQuotation[key]?.selected === "Y") {
        filterMainTemplate[key] = props.mainTemplateQuotation[key];
      }
    });
    setFilteredMainTemplate(filterMainTemplate);
  }, [props.mainTemplateQuotation]);

  useEffect(() => {
    const filterSlabMainTemplate = {};

    Object.keys(props.slabMainTemplateQuotation).forEach((key) => {
      if (props.slabMainTemplateQuotation[key]?.selected === "Y") {
        filterSlabMainTemplate[key] = props.slabMainTemplateQuotation[key];
      }
    });
    setFilteredSlabMainTemplate(filterSlabMainTemplate);
  }, [props.slabMainTemplateQuotation]);

  useEffect(() => {
    if (props.data && props.data[0]) {
      // Convert templateJson to key-value pairs
      const keyValueData = {};
      props.data[0].forEach((item) => {
        keyValueData[item.FieldId] = item;
      });
      // Update state with key-value data
      setTemplateData(keyValueData);
    }
  }, [props.data]);

  useEffect(() => {
    if (Object.keys(keyValueWithHashGSTType).length > 0) {
      let gstTypes = keyValueWithHashGSTType.value.split("###");
      const result = gstTypes.reduce((acc, current, index) => {
        acc[index] = current;
        return acc;
      }, {});
      setDestinationValues(result);
    }
  }, [keyValueWithHashGSTType]);


  useEffect(() => {
    const gstTypeField =
      props.itemizeData.find((item) => item.Identifier === "GSTType") ||
      props.foreignData.find((item) => item.Identifier === "GSTType");
    let selectedItems = Object.values(props.mainTemplateQuotation).filter(main => {
      return main?.selected == "Y"
    })
    if (gstTypeField) {
      const key = gstTypeField.FieldId;
      let value = "";
      if (props.additionalInfoTemplate?.hasOwnProperty(key)) {
        value = props.additionalInfoTemplate[key]["value"];
      } else if (Object.values(selectedItems)[0]?.hasOwnProperty(key)) {
        value = Object.values(selectedItems)[0][key]["value"];
      }
      if (value) {
        setKeyValueWithHashGSTType({ key, value });
      }
    }
  }, [
    props.lastTransactionInThreadCommon,
    props.itemizeData,
    props.additionalInfoTemplate,
    props.mainTemplateQuotation,
  ]);

  const handleSaveClick = () => {
    try {
      const savedValues = Object.values(destinationValues).join("###");
      props.handleSave(savedValues);
    } catch (error) {
      console.error("Error in handleSaveClick:", error);
    }
  };

  function rearrangeArray(array) {
    // Step 1: Build a mapping of FieldId to their respective objects
    let fieldMap = {};
    array.forEach((item) => {
      fieldMap[item.FieldId] = item;
    });

    // Step 2: Initialize an empty array to store the rearranged items
    let rearranged = [];

    // Step 3: Helper function to recursively resolve dependencies
    const resolveDependency = (identifier, visited) => {
      if (visited.has(identifier)) {
        throw new Error(
          "Circular dependency detected for FieldId: " + identifier
        );
      }

      visited.add(identifier);

      // Get the item for the current identifier
      const item = fieldMap[identifier];

      // Resolve dependencies recursively
      const matches = item.DefaultValue?.Formula?.match(/\(f\d+\)/g);
      if (matches) {
        matches.forEach((dependency) => {
          const depIdentifier = dependency.substring(2, dependency.length - 1);
          if (fieldMap.hasOwnProperty(depIdentifier)) {
            resolveDependency(depIdentifier, visited);
          }
        });
      }

      // Add the item to the rearranged array if it's not already added
      if (!rearranged.some((i) => i.FieldId === item.FieldId)) {
        rearranged.push(item);
      }

      visited.delete(identifier);
    };

    // Step 4: Iterate over the array to start resolving dependencies
    array.forEach((item) => {
      if (!rearranged.some((i) => i.FieldId === item.FieldId)) {
        resolveDependency(item.FieldId, new Set());
      }
    });

    // Return the rearranged array
    return rearranged;
  }

  const getSumOfField = (data, fieldId) => {
    let sum = 0;
    for (let key in data) {
      if (data[key][fieldId]) {
        sum += parseFloat(data[key][fieldId].value);
      }
    }
    return sum;
  };

  const getSumOfFieldForSlab = (data, fieldId) => {
    let sum = 0;
    for (let key in data) {
      for (let rowIndex in data[key]) {
        if (data[key][rowIndex][fieldId]) {
          sum += parseFloat(data[key][rowIndex][fieldId].value);
        }
      }
    }
    return sum;
  };

  const arrangedEval = (formula, key, result, identifier) => {
    if (typeof formula != "undefined") {
      let pattern = /f(\d+)/g;
      const matches = [...formula.matchAll(pattern)];
      const matchedIdentifiersFormula = matches.map((match) => match[1]);
      matchedIdentifiersFormula.forEach((formulaIdentifier) => {
        if (result.hasOwnProperty(formulaIdentifier)) {
          formula = formula.replace(
            `f${formulaIdentifier}`,
            result[formulaIdentifier]
              ? !isNaN(parseFloat(result[formulaIdentifier]))
                ? parseFloat(result[formulaIdentifier])
                : `"${result[formulaIdentifier]}"`
              : 0
          );
        } else if (
          props.additionalInfoTemplate.hasOwnProperty(formulaIdentifier)
        ) {
          if (
            typeof props.additionalInfoTemplate[formulaIdentifier]?.value ===
            "string" &&
            props.additionalInfoTemplate[formulaIdentifier]?.value.includes(
              "###"
            )
          ) {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              `"${props.additionalInfoTemplate[formulaIdentifier]?.value.split(
                "###"
              )[0]
              }"`
            );
          } else {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              props.additionalInfoTemplate[formulaIdentifier]?.value
                ? !isNaN(
                  parseFloat(
                    props.additionalInfoTemplate[formulaIdentifier]?.value
                  )
                )
                  ? parseFloat(
                    props.additionalInfoTemplate[formulaIdentifier]?.value
                  )
                  : `"${props.additionalInfoTemplate[formulaIdentifier]?.value}"`
                : 0
            );
          }
        } else {
          if (
            !props.mainTemplateQuotation[key].hasOwnProperty(formulaIdentifier)
          ) {
            let defValue = AllItemsTemplate.find((item) => {
              return item.FieldId == formulaIdentifier;
            });
            if (
              defValue &&
              defValue.ElementType != "D" &&
              !defValue.DefaultValue.hasOwnProperty("Formula") &&
              !defValue.DefaultValue.includes("(f")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                parseFloat(defValue.DefaultValue) || 0
              );
            }
          } else {
            if (
              typeof props.mainTemplateQuotation[key]?.[formulaIdentifier]
                ?.value === "string" &&
              props.mainTemplateQuotation[key]?.[
                formulaIdentifier
              ]?.value?.includes("###")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                `"${props.mainTemplateQuotation[key]?.[
                  formulaIdentifier
                ]?.value.split("###")[0]
                }"`
              );
            } else {
              if (
                identifier == "TotalReqQty" ||
                identifier == "TotalReqQtyFor" ||
                identifier == "TotalQuantity"
              ) {
                const sumOfFieldQty = getSumOfField(
                  props.mainTemplateQuotation,
                  formulaIdentifier
                );
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  sumOfFieldQty
                );
              } else {
                let replaceValue = props.mainTemplateQuotation[key]?.[
                  formulaIdentifier
                ]?.value
                  ? !isNaN(
                    parseFloat(
                      props.mainTemplateQuotation[key]?.[formulaIdentifier]
                        ?.value
                    )
                  ) &&
                    isFinite(
                      props.mainTemplateQuotation[key]?.[formulaIdentifier]
                        ?.value
                    )
                    ? parseFloat(
                      props.mainTemplateQuotation[key]?.[formulaIdentifier]
                        ?.value
                    )
                    : `"${props.mainTemplateQuotation[key]?.[formulaIdentifier]?.value}"`
                  : 0;
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  replaceValue
                );
              }
            }
          }
        }
      });
      return formula;
    }
  };

  const arrangedEvalForMS = (formula, key, rowIndex, result, identifier) => {
    if (typeof formula != "undefined") {
      let pattern = /f(\d+)/g;
      const matches = [...formula.matchAll(pattern)];
      const matchedIdentifiersFormula = matches.map((match) => match[1]);
      matchedIdentifiersFormula.forEach((formulaIdentifier) => {
        if (result[rowIndex]?.hasOwnProperty(formulaIdentifier)) {
          formula = formula.replace(
            `f${formulaIdentifier}`,
            result[rowIndex][formulaIdentifier]
              ? !isNaN(parseFloat(result[rowIndex][formulaIdentifier]))
                ? parseFloat(result[rowIndex][formulaIdentifier])
                : `"${result[rowIndex][formulaIdentifier]}"`
              : 0
          );
        } else if (
          props.additionalInfoTemplate.hasOwnProperty(formulaIdentifier)
        ) {
          if (
            typeof props.additionalInfoTemplate[formulaIdentifier]?.value ===
            "string" &&
            props.additionalInfoTemplate[formulaIdentifier]?.value.includes(
              "###"
            )
          ) {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              `"${props.additionalInfoTemplate[formulaIdentifier]?.value.split(
                "###"
              )[0]
              }"`
            );
          } else {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              props.additionalInfoTemplate[formulaIdentifier]?.value
                ? !isNaN(
                  parseFloat(
                    props.additionalInfoTemplate[formulaIdentifier]?.value
                  )
                )
                  ? parseFloat(
                    props.additionalInfoTemplate[formulaIdentifier]?.value
                  )
                  : `"${props.additionalInfoTemplate[formulaIdentifier]?.value}"`
                : 0
            );
          }
        } else {
          if (
            !props.multiSolmainTemplateQuotation[key][rowIndex].hasOwnProperty(
              formulaIdentifier
            )
          ) {
            let defValue = AllItemsTemplate.find((item) => {
              return item.FieldId == formulaIdentifier;
            });
            if (
              defValue &&
              defValue.ElementType != "D" &&
              !defValue.DefaultValue.hasOwnProperty("Formula") &&
              !defValue.DefaultValue.includes("(f")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                parseFloat(defValue.DefaultValue) || 0
              );
            }
          } else {
            if (
              typeof props.multiSolmainTemplateQuotation[key][rowIndex]?.[
                formulaIdentifier
              ]?.value === "string" &&
              props.multiSolmainTemplateQuotation[key][rowIndex]?.[
                formulaIdentifier
              ]?.value?.includes("###")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                `"${props.multiSolmainTemplateQuotation[key][rowIndex]?.[
                  formulaIdentifier
                ]?.value.split("###")[0]
                }"`
              );
            } else {
              if (
                identifier == "TotalReqQty" ||
                identifier == "TotalReqQtyFor" ||
                identifier == "TotalQuantity"
              ) {
                const sumOfFieldQty = getSumOfField(
                  props.mainTemplateQuotation,
                  formulaIdentifier
                );
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  sumOfFieldQty
                );
              } else {
                let replaceValue = props.multiSolmainTemplateQuotation[key][
                  rowIndex
                ]?.[formulaIdentifier]?.value
                  ? !isNaN(
                    parseFloat(
                      props.multiSolmainTemplateQuotation[key][rowIndex]?.[
                        formulaIdentifier
                      ]?.value
                    )
                  ) &&
                    isFinite(
                      props.multiSolmainTemplateQuotation[key][rowIndex]?.[
                        formulaIdentifier
                      ]?.value
                    )
                    ? parseFloat(
                      props.multiSolmainTemplateQuotation[key][rowIndex]?.[
                        formulaIdentifier
                      ]?.value
                    )
                    : `"${props.multiSolmainTemplateQuotation[key][rowIndex]?.[formulaIdentifier]?.value}"`
                  : 0;
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  replaceValue
                );
              }
            }
          }
        }
      });
      return formula;
    }
  };

  const arrangedEvalForSlab = (formula, key, rowIndex, result, identifier) => {
    if (typeof formula !== "undefined") {
      let pattern = /f(\d+)/g;
      const matches = [...formula.matchAll(pattern)];
      const matchedIdentifiersFormula = matches.map((match) => match[1]);
      matchedIdentifiersFormula.forEach((formulaIdentifier) => {
        if (result[rowIndex]?.hasOwnProperty(formulaIdentifier)) {
          formula = formula.replace(
            `f${formulaIdentifier}`,
            result[rowIndex][formulaIdentifier]
              ? !isNaN(parseFloat(result[rowIndex][formulaIdentifier]))
                ? parseFloat(result[rowIndex][formulaIdentifier])
                : `"${result[rowIndex][formulaIdentifier]}"`
              : 0
          );
        } else if (
          props.additionalInfoTemplate.hasOwnProperty(formulaIdentifier)
        ) {
          if (
            typeof props.additionalInfoTemplate[formulaIdentifier]?.value ===
            "string" &&
            props.additionalInfoTemplate[formulaIdentifier]?.value.includes(
              "###"
            )
          ) {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              `"${props.additionalInfoTemplate[formulaIdentifier]?.value.split(
                "###"
              )[0]
              }"`
            );
          } else {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              props.additionalInfoTemplate[formulaIdentifier]?.value
                ? !isNaN(
                  parseFloat(
                    props.additionalInfoTemplate[formulaIdentifier]?.value
                  )
                )
                  ? parseFloat(
                    props.additionalInfoTemplate[formulaIdentifier]?.value
                  )
                  : `"${props.additionalInfoTemplate[formulaIdentifier]?.value}"`
                : 0
            );
          }
        } else {
          // if (!props.slabMainTemplateQuotation[key][rowIndex].hasOwnProperty(formulaIdentifier)) {
          if (props.slabMainTemplateQuotation.hasOwnProperty(key) &&
            props.slabMainTemplateQuotation[key].hasOwnProperty(rowIndex) &&
            !props.slabMainTemplateQuotation[key][rowIndex].hasOwnProperty(formulaIdentifier)) {
            let defValue = AllItemsTemplate.find((item) => {
              return item.FieldId == formulaIdentifier;
            });
            if (
              defValue &&
              defValue.ElementType != "D" &&
              !defValue.DefaultValue.hasOwnProperty("Formula") &&
              !defValue.DefaultValue.includes("(f")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                parseFloat(defValue.DefaultValue) || 0
              );
            }
          } else {
            if (
              typeof props.slabMainTemplateQuotation[key][rowIndex]?.[
                formulaIdentifier
              ]?.value === "string" &&
              props.slabMainTemplateQuotation[key][rowIndex]?.[
                formulaIdentifier
              ]?.value?.includes("###")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                `"${props.slabMainTemplateQuotation[key][rowIndex]?.[
                  formulaIdentifier
                ]?.value.split("###")[0]
                }"`
              );
            } else {
              if (
                identifier == "TotalReqQty" ||
                identifier == "TotalReqQtyFor" ||
                identifier == "TotalQuantity"
              ) {
                const sumOfFieldQty = getSumOfFieldForSlab(
                  props.slabMainTemplateQuotation,
                  formulaIdentifier
                );
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  sumOfFieldQty
                );
              } else {
                let replaceValue = props.slabMainTemplateQuotation[key][
                  rowIndex
                ]?.[formulaIdentifier]?.value
                  ? !isNaN(
                    parseFloat(
                      props.slabMainTemplateQuotation[key][rowIndex]?.[
                        formulaIdentifier
                      ]?.value
                    )
                  ) &&
                    isFinite(
                      props.slabMainTemplateQuotation[key][rowIndex]?.[
                        formulaIdentifier
                      ]?.value
                    )
                    ? parseFloat(
                      props.slabMainTemplateQuotation[key][rowIndex]?.[
                        formulaIdentifier
                      ]?.value
                    )
                    : `"${props.slabMainTemplateQuotation[key][rowIndex]?.[formulaIdentifier]?.value}"`
                  : 0;
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  replaceValue
                );
              }
            }
          }
        }
      });
      return formula;
    }
  };

  useEffect(() => {
    setTooltipOpen(true);
  }, []);

  useEffect(() => {
    let allItems = [];
    let templateData = props.template;
    let elementTypes = [];

    for (let section in templateData) {
      if (section !== "PARAM") {
        if (section === "ITEMIZE") {
          allItems = allItems.concat(templateData[section][0]);
        } else {
          allItems = allItems.concat(templateData[section]);
        }
      }
    }
    props.setItemsTemplate(allItems);
    setAllItemsTemplate(allItems);
    allItems.forEach((items) => {
      if (items?.ElementType === "D") {
        elementTypes.push(items);
      }
    });
    let rearrangedArray = rearrangeArray(elementTypes);
    setRearrangedArray(rearrangedArray);
    props.setRearrangedTemplate(rearrangedArray);
  }, [props.template]);

  const handleDetailsClick = (key, unitFlag) => {
    setMultipleSolutionNameFlag(false);
    let EvalResults = {};
    let additionalInfoElement = [];
    let formulaElementKey = [];
    RearrangedArray.forEach((defaultIdentifier) => {
      let formula = defaultIdentifier.DefaultValue.Formula;
      let newFormula = arrangedEval(
        formula,
        key,
        EvalResults,
        defaultIdentifier.Identifier
      );
      if (newFormula && newFormula.includes("ExchangeRate_SelectedCurrency")) {
        let exchangeRate = props.currencyOptions.find(
          (currency) =>
            currency.Currency ==
            (props.selectedCurrency || props.quotedCurrency)
        );
        if (exchangeRate) {
          newFormula = newFormula.replace(
            /ExchangeRate_SelectedCurrency/g,
            exchangeRate.ExchangeRate
          );
        }
      }

      if (
        newFormula &&
        newFormula.includes("ExchangeRate_BuyerLocalCurrency")
      ) {
        let exchangeRate = props.currencyOptions.find(
          (currency) => currency.Currency === (props.base_currency || "INR")
        );
        if (exchangeRate) {
          newFormula = newFormula.replace(
            /ExchangeRate_BuyerLocalCurrency/g,
            exchangeRate.ExchangeRate
          );
        }
      }
      // Now set the property
      if (newFormula && newFormula.includes("(f")) {
        const match = newFormula.match(/f\d+/)[0];
        newFormula = newFormula.replace(match, 0);
      }
      EvalResults[defaultIdentifier.FieldId] = parseFloat(
        eval(newFormula)
      ).toFixed(3);
      let pattern = /f(\d+)/g;
      if (formula) {
        let matches = [...formula.matchAll(pattern)];
        let matchedIdentifiersFormula = matches.map((match) => match[1]);
        formulaElementKey = formulaElementKey.concat(matchedIdentifiersFormula);
      }

      // result[key][defaultIdentifier.FieldId] = parseFloat(eval(newFormula)).toFixed(3)
    });

    if (props.mainTemplateQuotation && key !== undefined) {
      const rowData = props.mainTemplateQuotation[key];
      const RateCell = props.data[0].find(
        (cell) =>
          cell.Identifier === "Rate" ||
          cell.Identifier === "CIFRate" ||
          cell.Identifier === "RatePerLot"
      );
      const gstTypeCell =
        props.data[0].find((cell) => cell.Identifier === "GSTType") ||
        props?.commonData.find((cell) => cell.Identifier === "GSTType");

      const gstValueCell = props.data[0].find(
        (cell) => cell.Identifier === "GSTValue"
      );
      let errorMsg = [];
      let placeholderPattern = /f(\d+)/g;

      if (props.mainTemplateQuotation[key][RateCell?.FieldId]) {
        errorMsg.push(props.items[key].name);
        if (RateCell.Validation != "") {
          let replacedRate = RateCell.Validation.replace(
            placeholderPattern,
            (match, p1) => {
              // p1 is the captured group (the digits following 'f')
              return props.mainTemplateQuotation[key][match.replace("f", "")]
                .value !== undefined
                ? props.mainTemplateQuotation[key][match.replace("f", "")]
                  .value == ""
                  ? 0
                  : props.mainTemplateQuotation[key][match.replace("f", "")]
                    .value
                : match;
            }
          );
          if (!eval(replacedRate)) {
            errorMsg.push(RateCell.ErrorMessage);
          }
        }
      }

      if (props.mainTemplateQuotation[key][gstTypeCell?.FieldId]) {
        if (gstTypeCell.Validation != "") {
          let replacedGstType = gstTypeCell.Validation.replace(
            placeholderPattern,
            (match, p1) => {
              return props.mainTemplateQuotation[key][match.replace("f", "")]
                ?.value !== undefined
                ? props.mainTemplateQuotation[key][match.replace("f", "")]
                  ?.value == ""
                  ? "''"
                  : `"${props.mainTemplateQuotation[key][match.replace("f", "")]
                    ?.value
                  }"`
                : match;
            }
          );
          if (!eval(replacedGstType)) {
            errorMsg.push(gstTypeCell.ErrorMessage);
          }
        }
      } else if (props.additionalInfoTemplate[gstTypeCell?.FieldId]) {
        if (gstTypeCell?.Validation != "") {
          let replacedGstType = gstTypeCell.Validation.replace(
            placeholderPattern,
            (match, p1) => {
              // p1 is the captured group (the digits following 'f')
              return props.additionalInfoTemplate[match.replace("f", "")]
                .value !== undefined
                ? props.additionalInfoTemplate[match.replace("f", "")]?.value ==
                  ""
                  ? "''"
                  : `"${props.additionalInfoTemplate[match.replace("f", "")]
                    ?.value
                  }"`
                : match;
            }
          );
          if (!eval(replacedGstType)) {
            errorMsg.push(gstTypeCell.ErrorMessage);
          }
        }
      }
      if (props.mainTemplateQuotation[key][gstValueCell?.FieldId]) {
        if (gstValueCell.Validation != "") {
          let replacedGst = gstValueCell.Validation.replace(
            placeholderPattern,
            (match, p1) => {
              // p1 is the captured group (the digits following 'f')
              return props.mainTemplateQuotation[key][match.replace("f", "")]
                .value !== undefined
                ? props.mainTemplateQuotation[key][match.replace("f", "")]
                  .value == ""
                  ? "''"
                  : `"${props.mainTemplateQuotation[key][match.replace("f", "")]
                    .value
                  }"`
                : match;
            }
          );
          if (!eval(replacedGst)) {
            errorMsg.push(gstValueCell.ErrorMessage);
          }
        }
      }

      // for additional field validation
      let uniqueFormulaElementKey = [...new Set(formulaElementKey)];
      additionalInfoElement = uniqueFormulaElementKey.filter(
        (item) => item in props.additionalInfoTemplate
      );
      if (additionalInfoElement.length > 0) {
        additionalInfoElement.map((id) => {
          let additionalInfoCell = props.template.LOCAL.find(
            (cell) => cell.FieldId === id
          );
          if (additionalInfoCell) {
            if (additionalInfoCell.Validation != "") {
              let additionalInfoCellFormula =
                additionalInfoCell.Validation.replace(
                  placeholderPattern,
                  (match, p1) => {
                    return props.additionalInfoTemplate[match.replace("f", "")]
                      .value !== undefined
                      ? props.additionalInfoTemplate[match.replace("f", "")]
                        ?.value == "" ||
                        props.additionalInfoTemplate[match.replace("f", "")]
                          ?.value == null
                        ? "''"
                        : !isNaN(
                          props.additionalInfoTemplate[match.replace("f", "")]
                            ?.value
                        )
                          ? parseFloat(
                            props.additionalInfoTemplate[match.replace("f", "")]
                              ?.value
                          )
                          : `"${props.additionalInfoTemplate[match.replace("f", "")]
                            ?.value
                          }"`
                      : match;
                  }
                );
              if (
                !eval(additionalInfoCellFormula) &&
                additionalInfoCell.VisibilityFlag === "Y"
              ) {
                errorMsg.push(additionalInfoCell.ErrorMessage);
              }
            }
          }
        });
      }

      const unit = props.items[key]?.unit || "";

      // Extract FieldLabels and FieldIds from TotalCost template formula
      const totalCostCell = props.data[0].find(
        (cell) => cell.DefaultValue?.Button === "Y"
      );
      const fieldIds = totalCostCell.DefaultValue.Formula.match(
        /\(f\d+\)/g
      ).map((id) => id.slice(2, -1));
      let values = [];
      const fieldLabels = fieldIds.map((id) => {
        const correspondingCell = props.data[0].find(
          (cell) => cell.FieldId === id
        );
        // const correspondingCell = AllItemsTemplate.find(
        //   (cell) => cell.FieldId === id
        // );
        if (EvalResults.hasOwnProperty(correspondingCell.FieldId)) {
          values.push({
            label: correspondingCell.FieldLabel,
            value: EvalResults[correspondingCell.FieldId],
            unit,
          });

          // values[correspondingCell.FieldLabel] = result[correspondingCell.FieldId]
        } else if (
          props.additionalInfoTemplate.hasOwnProperty(correspondingCell.FieldId)
        ) {
          values.push({
            label: correspondingCell.FieldLabel,
            value:
              props.additionalInfoTemplate[correspondingCell.FieldId]?.value ||
              0,
            unit,
          });

          // values[correspondingCell.FieldLabel] = props.additionalInfoTemplate[correspondingCell.FieldId].value
        } else {
          values.push({
            label: correspondingCell.FieldLabel,
            value:
              props.mainTemplateQuotation[key][correspondingCell.FieldId]
                ?.value || 0,
            unit,
          });
        }
      });

      values.push({
        label: totalCostCell.FieldLabel,
        value: EvalResults[totalCostCell.FieldId],
        unit,
      });

      setModalOpen(true);
      setSelectedRowData(rowData);
      setModalData(values);
      setUnit(unitFlag == 3 ? "Pack" : unit);
      setErrors(errorMsg);
    } else {
      console.error("mainTemplateQuotation or key is null or undefined.");
    }
  };

  const handleDetailsClickForMS = (key, rowIndex, unitFlag) => {
    setMultipleSolutionNameFlag(true);
    let additionalInfoElement = [];
    let formulaElementKey = [];
    let EvalMultiSolResults = {};
    const solutionKey = `${key}-${rowIndex}`;
    let newMultiSolQuotationTemplate = props.multiSolmainTemplateQuotation;
    // RearrangedArray.forEach((defaultIdentifier) => {
    //   let formula = defaultIdentifier.DefaultValue.Formula;
    //   let newFormula = arrangedEval(formula, key, EvalResults, defaultIdentifier.Identifier);
    //   if (newFormula && newFormula.includes("ExchangeRate_SelectedCurrency")) {
    //     let exchangeRate = props.currencyOptions.find(
    //       (currency) =>
    //         currency.Currency ==
    //         (props.selectedCurrency || props.quotedCurrency)
    //     );
    //     if (exchangeRate) {
    //       newFormula = newFormula.replace(
    //         "ExchangeRate_SelectedCurrency",
    //         exchangeRate.ExchangeRate
    //       );
    //     }
    //   }

    //   if (
    //     newFormula &&
    //     newFormula.includes("ExchangeRate_BuyerLocalCurrency")
    //   ) {
    //     let exchangeRate = props.currencyOptions.find(
    //       (currency) => currency.Currency === (props.base_currency || "INR")
    //     );
    //     if (exchangeRate) {
    //       newFormula = newFormula.replace(
    //         "ExchangeRate_BuyerLocalCurrency",
    //         exchangeRate.ExchangeRate
    //       );
    //     }
    //   }
    //   // Now set the property
    //   if (newFormula && newFormula.includes("(f")) {
    //     const match = newFormula.match(/f\d+/)[0];
    //     newFormula = newFormula.replace(match, 0);
    //   }
    //   EvalResults[defaultIdentifier.FieldId] = parseFloat(
    //     eval(newFormula)
    //   ).toFixed(3);
    //   let pattern = /f(\d+)/g;
    //   let matches = [...formula.matchAll(pattern)];
    //   let matchedIdentifiersFormula = matches.map((match) => match[1]);
    //   formulaElementKey = formulaElementKey.concat(matchedIdentifiersFormula)

    //   // result[key][defaultIdentifier.FieldId] = parseFloat(eval(newFormula)).toFixed(3)
    // });
    Object.entries(newMultiSolQuotationTemplate).forEach(([key, obj]) => {
      if (Array.isArray(obj)) {
        obj.forEach((rowdata, rowIndex) => {
          RearrangedArray.forEach((defaultIdentifier) => {
            let formula = defaultIdentifier.DefaultValue.Formula;
            if (formula) {
              let newFormulaMS = arrangedEvalForMS(
                formula,
                key,
                rowIndex,
                EvalMultiSolResults,
                defaultIdentifier.Identifier
              );
              if (newFormulaMS.includes("ExchangeRate_SelectedCurrency")) {
                let exchangeRate = props.currencyOptions.find(
                  (currency) =>
                    currency.Currency ==
                    (props.selectedCurrency || props.quotedCurrency)
                );
                if (exchangeRate) {
                  newFormulaMS = newFormulaMS.replace(
                    "ExchangeRate_SelectedCurrency",
                    exchangeRate.ExchangeRate
                  );
                }
              }
              if (newFormulaMS.includes("ExchangeRate_BuyerLocalCurrency")) {
                let exchangeRate = props.currencyOptions.find(
                  (currency) =>
                    currency.Currency === (props.currencyDetails || "INR")
                );
                if (exchangeRate) {
                  newFormulaMS = newFormulaMS.replace(
                    "ExchangeRate_BuyerLocalCurrency",
                    exchangeRate.ExchangeRate
                  );
                }
              }
              if (newFormulaMS && newFormulaMS.includes("(f")) {
                const match = newFormulaMS.match(/f\d+/)[0];
                newFormulaMS = newFormulaMS.replace(match, 0);
              }
              if (props.multipleSolutionFlag && props.switchState[key]) {
                if (!EvalMultiSolResults[rowIndex]) {
                  EvalMultiSolResults[rowIndex] = {};
                }
                EvalMultiSolResults[rowIndex][defaultIdentifier.FieldId] = parseFloat(eval(newFormulaMS)).toFixed(3);
                let pattern = /f(\d+)/g;
                let matches = [...formula.matchAll(pattern)];
                let matchedIdentifiersFormula = matches.map((match) => match[1]);
                formulaElementKey = formulaElementKey.concat(matchedIdentifiersFormula)
              }
            }
          });
        });
      }
      // else if (typeof obj === 'object') {
      //   // Handle case where obj is a single object
      //   this.state.rearrangedArray.forEach((defaultIdentifier) => {
      //     let formula = defaultIdentifier.DefaultValue.Formula;
      //     if (formula) {
      //       let newFormulaMS = this.arrangedEvalForMS(formula, key, EvalMultiSolResults, 0); // Assuming rowIndex as 0 for object case
      //       if (newFormulaMS.includes("ExchangeRate_SelectedCurrency")) {
      //         let exchangeRate = this.props.currencyOptions.find(
      //           (currency) =>
      //             currency.Currency == (this.state.selectedCurrency || this.props.quotedCurrency)
      //         );
      //         if (exchangeRate) {
      //           newFormulaMS = newFormulaMS.replace(
      //             "ExchangeRate_SelectedCurrency",
      //             exchangeRate.ExchangeRate
      //           );
      //         }
      //       }
      //       if (newFormulaMS.includes("ExchangeRate_BuyerLocalCurrency")) {
      //         let exchangeRate = this.props.currencyOptions.find(
      //           (currency) =>
      //             currency.Currency === (this.props.currencyDetails || "INR")
      //         );
      //         if (exchangeRate) {
      //           newFormulaMS = newFormulaMS.replace(
      //             "ExchangeRate_BuyerLocalCurrency",
      //             exchangeRate.ExchangeRate
      //           );
      //         }
      //       }
      //       if (newFormulaMS && newFormulaMS.includes("(f")) {
      //         const match = newFormulaMS.match(/f\d+/)[0];
      //         newFormulaMS = newFormulaMS.replace(match, 0);
      //       }
      //       if (this.props.multipleSolutionFlag && this.state.switchState[key]) {
      //         if (!EvalMultiSolResults[0]) {
      //           EvalMultiSolResults[0] = {};
      //         }
      //         EvalMultiSolResults[0][defaultIdentifier.FieldId] = parseFloat(eval(newFormulaMS)).toFixed(3);
      //       }
      //     }
      //   });
      // } else {
      //   console.error(`Unexpected type for obj at key ${key}`);
      //   // Handle other unexpected cases as needed
      // }
    });

    if (newMultiSolQuotationTemplate && key !== undefined) {
      const rowData = newMultiSolQuotationTemplate[key][rowIndex];
      const RateCell = props.data[0].find(
        (cell) =>
          cell.Identifier === "Rate" ||
          cell.Identifier === "CIFRate" ||
          cell.Identifier === "RatePerLot"
      );
      const gstTypeCell =
        props.data[0].find((cell) => cell.Identifier === "GSTType") ||
        props?.commonData.find((cell) => cell.Identifier === "GSTType");

      const gstValueCell = props.data[0].find(
        (cell) => cell.Identifier === "GSTValue"
      );
      let errorMsg = [];
      let placeholderPattern = /f(\d+)/g;

      if (newMultiSolQuotationTemplate[key][rowIndex][RateCell?.FieldId]) {
        // errorMsg.push(props.items[key].name);
        errorMsg.push(props.solutionNames[solutionKey]);
        // console.log("props.solutionNames1",props.solutionNames);
        // if(props.solutionNames[solutionKey]===solutionKey){
        // console.log("props.solutionNames2",props.solutionNames,props.solutionNames[solutionKey]);
        //   errorMsg.push(props.solutionNames[solutionKey]);
        // }
        let replacedRate = RateCell.Validation.replace(
          placeholderPattern,
          (match, p1) => {
            // p1 is the captured group (the digits following 'f')
            return newMultiSolQuotationTemplate[key][rowIndex][
              match.replace("f", "")
            ].value !== undefined
              ? newMultiSolQuotationTemplate[key][rowIndex][
                match.replace("f", "")
              ].value == ""
                ? 0
                : newMultiSolQuotationTemplate[key][rowIndex][
                  match.replace("f", "")
                ].value
              : match;
          }
        );
        if (!eval(replacedRate)) {
          errorMsg.push(RateCell.ErrorMessage);
        }
      }

      if (newMultiSolQuotationTemplate[key][rowIndex][gstTypeCell?.FieldId]) {
        let replacedGstType = gstTypeCell.Validation.replace(
          placeholderPattern,
          (match, p1) => {
            return newMultiSolQuotationTemplate[key][rowIndex][
              match.replace("f", "")
            ]?.value !== undefined
              ? newMultiSolQuotationTemplate[key][rowIndex][
                match.replace("f", "")
              ]?.value == ""
                ? "''"
                : `"${newMultiSolQuotationTemplate[key][rowIndex][
                  match.replace("f", "")
                ]?.value
                }"`
              : match;
          }
        );
        if (!eval(replacedGstType)) {
          errorMsg.push(gstTypeCell.ErrorMessage);
        }
      } else if (props.additionalInfoTemplate[gstTypeCell?.FieldId]) {
        let replacedGstType = gstTypeCell.Validation.replace(
          placeholderPattern,
          (match, p1) => {
            // p1 is the captured group (the digits following 'f')
            return props.additionalInfoTemplate[match.replace("f", "")]
              .value !== undefined
              ? props.additionalInfoTemplate[match.replace("f", "")]?.value ==
                ""
                ? "''"
                : `"${props.additionalInfoTemplate[match.replace("f", "")]?.value
                }"`
              : match;
          }
        );
        if (!eval(replacedGstType)) {
          errorMsg.push(gstTypeCell.ErrorMessage);
        }
      }
      if (newMultiSolQuotationTemplate[key][rowIndex][gstValueCell?.FieldId]) {
        let replacedGst = gstValueCell.Validation.replace(
          placeholderPattern,
          (match, p1) => {
            // p1 is the captured group (the digits following 'f')
            return newMultiSolQuotationTemplate[key][rowIndex][
              match.replace("f", "")
            ].value !== undefined
              ? newMultiSolQuotationTemplate[key][rowIndex][
                match.replace("f", "")
              ].value == ""
                ? "''"
                : `"${newMultiSolQuotationTemplate[key][rowIndex][
                  match.replace("f", "")
                ].value
                }"`
              : match;
          }
        );
        if (!eval(replacedGst)) {
          errorMsg.push(gstValueCell.ErrorMessage);
        }
      }

      if (props.solutionNames[solutionKey] === "") {
        errorMsg.push("Please enter solution name");
      }

      // for additional field validation
      let uniqueFormulaElementKey = [...new Set(formulaElementKey)];
      additionalInfoElement = uniqueFormulaElementKey.filter(
        (item) => item in props.additionalInfoTemplate
      );
      if (additionalInfoElement.length > 0) {
        additionalInfoElement.map((id) => {
          let additionalInfoCell = props.template.LOCAL.find(
            (cell) => cell.FieldId === id
          );
          if (additionalInfoCell) {
            if (additionalInfoCell.Validation != '') {
              let additionalInfoCellFormula = additionalInfoCell.Validation.replace(
                placeholderPattern,
                (match, p1) => {
                  return props.additionalInfoTemplate[match.replace("f", "")]
                    .value !== undefined
                    ?
                    props.additionalInfoTemplate[match.replace("f", "")]?.value ==
                      "" || props.additionalInfoTemplate[match.replace("f", "")]?.value == null
                      ? "''"
                      : !isNaN(props.additionalInfoTemplate[match.replace("f", "")]?.value) ? parseFloat(props.additionalInfoTemplate[match.replace("f", "")]?.value) : `"${props.additionalInfoTemplate[match.replace("f", "")]?.value
                        }"`
                    : match;
                }
              );
              if (!eval(additionalInfoCellFormula) && additionalInfoCell.VisibilityFlag === "Y") {
                errorMsg.push(additionalInfoCell.ErrorMessage);
              }
            }
          }
        });
      }

      const unit = props.items[key]?.unit || "";

      // Extract FieldLabels and FieldIds from TotalCost template formula
      const totalCostCell = props.data[0].find(
        (cell) => cell.DefaultValue?.Button === "Y"
      );
      const fieldIds = totalCostCell.DefaultValue.Formula.match(
        /\(f\d+\)/g
      ).map((id) => id.slice(2, -1));
      let values = [];
      const fieldLabels = fieldIds.map((id) => {
        const correspondingCell = props.data[0].find(
          (cell) => cell.FieldId === id
        );
        // const correspondingCell = AllItemsTemplate.find(
        //   (cell) => cell.FieldId === id
        // );
        if (
          EvalMultiSolResults[rowIndex].hasOwnProperty(
            correspondingCell.FieldId
          )
        ) {
          values.push({
            label: correspondingCell.FieldLabel,
            value: EvalMultiSolResults[rowIndex][correspondingCell.FieldId],
            unit,
          });

          // values[correspondingCell.FieldLabel] = result[correspondingCell.FieldId]
        } else if (
          props.additionalInfoTemplate.hasOwnProperty(correspondingCell.FieldId)
        ) {
          values.push({
            label: correspondingCell.FieldLabel,
            value:
              props.additionalInfoTemplate[correspondingCell.FieldId]?.value ||
              0,
            unit,
          });

          // values[correspondingCell.FieldLabel] = props.additionalInfoTemplate[correspondingCell.FieldId].value
        } else {
          values.push({
            label: correspondingCell.FieldLabel,
            value:
              newMultiSolQuotationTemplate[key][rowIndex][correspondingCell.FieldId]
                ?.value || 0,
            unit,
          });
        }
      });

      values.push({
        label: totalCostCell.FieldLabel,
        value: EvalMultiSolResults[rowIndex][totalCostCell.FieldId],
        unit,
      });

      setModalOpen(true);
      setSelectedRowData(rowData);
      setModalData(values);
      setUnit(unitFlag == 3 ? "Pack" : unit);
      setErrors(errorMsg);
    } else {
      console.error(
        "multiSolmainTemplateQuotation or key is null or undefined."
      );
    }
  };

  const handleDetailsClickForSlab = (key, rowIndex, unitFlag) => {
    let additionalInfoElement = [];
    let formulaElementKey = [];
    let EvalSlabResults = {};
    let newSlabQuotationTemplate = props.slabMainTemplateQuotation;

    // Object.entries(newSlabQuotationTemplate).forEach(([key, obj]) => {
    // if (Array.isArray(obj)) {
    // newSlabQuotationTemplate[key][rowIndex].forEach((rowdata) => {
    RearrangedArray.forEach((defaultIdentifier) => {
      let formula = defaultIdentifier.DefaultValue.Formula;
      if (formula) {
        let newFormulaSlab = arrangedEvalForSlab(formula, key, rowIndex, EvalSlabResults, defaultIdentifier.Identifier);
        if (newFormulaSlab.includes("ExchangeRate_SelectedCurrency")) {
          let exchangeRate = props.currencyOptions.find(
            (currency) => currency.Currency == (props.selectedCurrency || props.quotedCurrency)
          );
          if (exchangeRate) {
            newFormulaSlab = newFormulaSlab.replace(
              "ExchangeRate_SelectedCurrency",
              exchangeRate.ExchangeRate
            );
          }
        }
        if (newFormulaSlab.includes("ExchangeRate_BuyerLocalCurrency")) {
          let exchangeRate = props.currencyOptions.find(
            (currency) => currency.Currency === (props.currencyDetails || "INR")
          );
          if (exchangeRate) {
            newFormulaSlab = newFormulaSlab.replace(
              "ExchangeRate_BuyerLocalCurrency",
              exchangeRate.ExchangeRate
            );
          }
        }
        if (newFormulaSlab && newFormulaSlab.includes("(f")) {
          const match = newFormulaSlab.match(/f\d+/)[0];
          newFormulaSlab = newFormulaSlab.replace(match, 0);
        }
        if (props.slabFlag && props.multipleSolutionFlag) {
          if (!EvalSlabResults[rowIndex]) {
            EvalSlabResults[rowIndex] = {};
          }
          EvalSlabResults[rowIndex][defaultIdentifier.FieldId] = parseFloat(eval(newFormulaSlab)).toFixed(3);
          let pattern = /f(\d+)/g;
          let matches = [...formula.matchAll(pattern)];
          let matchedIdentifiersFormula = matches.map((match) => match[1]);
          formulaElementKey = formulaElementKey.concat(matchedIdentifiersFormula);
        }
      }
    });
    // });
    // }
    // });

    if (newSlabQuotationTemplate && key !== undefined) {
      const rowData = newSlabQuotationTemplate[key][rowIndex];
      const RateCell = props.data[0].find(
        (cell) =>
          cell.Identifier === "Rate" ||
          cell.Identifier === "CIFRate" ||
          cell.Identifier === "RatePerLot"
      );
      const gstTypeCell =
        props.data[0].find((cell) => cell.Identifier === "GSTType") ||
        props?.commonData.find((cell) => cell.Identifier === "GSTType");

      const gstValueCell = props.data[0].find(
        (cell) => cell.Identifier === "GSTValue"
      );
      let errorMsg = [];
      let placeholderPattern = /f(\d+)/g;

      if (newSlabQuotationTemplate[key][rowIndex][RateCell?.FieldId]) {
        errorMsg.push(props.items[key].name);
        let replacedRate = RateCell.Validation.replace(
          placeholderPattern,
          (match, p1) => {
            return newSlabQuotationTemplate[key][rowIndex][
              match.replace("f", "")
            ].value !== undefined
              ? newSlabQuotationTemplate[key][rowIndex][match.replace("f", "")]
                .value == ""
                ? 0
                : newSlabQuotationTemplate[key][rowIndex][
                  match.replace("f", "")
                ].value
              : match;
          }
        );
        if (!eval(replacedRate)) {
          errorMsg.push(RateCell.ErrorMessage);
        }
      }

      if (newSlabQuotationTemplate[key][rowIndex][gstTypeCell?.FieldId]) {
        let replacedGstType = gstTypeCell.Validation.replace(
          placeholderPattern,
          (match, p1) => {
            return newSlabQuotationTemplate[key][rowIndex][
              match.replace("f", "")
            ]?.value !== undefined
              ? newSlabQuotationTemplate[key][rowIndex][match.replace("f", "")]
                ?.value == ""
                ? "''"
                : `"${newSlabQuotationTemplate[key][rowIndex][
                  match.replace("f", "")
                ]?.value
                }"`
              : match;
          }
        );
        if (!eval(replacedGstType)) {
          errorMsg.push(gstTypeCell.ErrorMessage);
        }
      } else if (props.additionalInfoTemplate[gstTypeCell?.FieldId]) {
        let replacedGstType = gstTypeCell.Validation.replace(
          placeholderPattern,
          (match, p1) => {
            return props.additionalInfoTemplate[match.replace("f", "")]
              .value !== undefined
              ? props.additionalInfoTemplate[match.replace("f", "")]?.value ==
                ""
                ? "''"
                : `"${props.additionalInfoTemplate[match.replace("f", "")]?.value
                }"`
              : match;
          }
        );
        if (!eval(replacedGstType)) {
          errorMsg.push(gstTypeCell.ErrorMessage);
        }
      }
      if (newSlabQuotationTemplate[key][rowIndex][gstValueCell?.FieldId]) {
        let replacedGst = gstValueCell.Validation.replace(
          placeholderPattern,
          (match, p1) => {
            return newSlabQuotationTemplate[key][rowIndex][
              match.replace("f", "")
            ].value !== undefined
              ? newSlabQuotationTemplate[key][rowIndex][match.replace("f", "")]
                .value == ""
                ? "''"
                : `"${newSlabQuotationTemplate[key][rowIndex][
                  match.replace("f", "")
                ].value
                }"`
              : match;
          }
        );
        if (!eval(replacedGst)) {
          errorMsg.push(gstValueCell.ErrorMessage);
        }
      }

      // Additional field validation
      let uniqueFormulaElementKey = [...new Set(formulaElementKey)];
      additionalInfoElement = uniqueFormulaElementKey.filter(
        (item) => item in props.additionalInfoTemplate
      );
      if (additionalInfoElement.length > 0) {
        additionalInfoElement.map((id) => {
          let additionalInfoCell = props.template.LOCAL.find(
            (cell) => cell.FieldId === id
          );
          if (additionalInfoCell) {
            if (additionalInfoCell.Validation != '') {
              let additionalInfoCellFormula = additionalInfoCell.Validation.replace(
                placeholderPattern,
                (match, p1) => {
                  return props.additionalInfoTemplate[match.replace("f", "")]
                    .value !== undefined
                    ?
                    props.additionalInfoTemplate[match.replace("f", "")]?.value ==
                      "" || props.additionalInfoTemplate[match.replace("f", "")]?.value == null
                      ? "''"
                      : !isNaN(props.additionalInfoTemplate[match.replace("f", "")]?.value) ? parseFloat(props.additionalInfoTemplate[match.replace("f", "")]?.value) : `"${props.additionalInfoTemplate[match.replace("f", "")]?.value
                        }"`
                    : match;
                }
              );
              if (!eval(additionalInfoCellFormula) && additionalInfoCell.VisibilityFlag === "Y") {
                errorMsg.push(additionalInfoCell.ErrorMessage);
              }
            }
          }
        });
      }

      const unit = props.items[key]?.unit || "";

      // Extract FieldLabels and FieldIds from TotalCost template formula
      const totalCostCell = props.data[0].find(
        (cell) => cell.DefaultValue?.Button === "Y"
      );
      const fieldIds = totalCostCell.DefaultValue.Formula.match(
        /\(f\d+\)/g
      ).map((id) => id.slice(2, -1));
      let values = [];
      const fieldLabels = fieldIds.map((id) => {
        const correspondingCell = props.data[0].find(
          (cell) => cell.FieldId === id
        );
        // const correspondingCell = AllItemsTemplate.find(
        //   (cell) => cell.FieldId === id
        // );
        if (
          EvalSlabResults[rowIndex].hasOwnProperty(correspondingCell.FieldId)
        ) {
          values.push({
            label: correspondingCell.FieldLabel,
            value: EvalSlabResults[rowIndex][correspondingCell.FieldId],
            unit,
          });
        } else if (
          props.additionalInfoTemplate.hasOwnProperty(correspondingCell.FieldId)
        ) {
          values.push({
            label: correspondingCell.FieldLabel,
            value:
              props.additionalInfoTemplate[correspondingCell.FieldId]?.value ||
              0,
            unit,
          });
        } else {
          values.push({
            label: correspondingCell.FieldLabel,
            value:
              newSlabQuotationTemplate[key][rowIndex][correspondingCell.FieldId]
                ?.value || 0,
            unit,
          });
        }
      });


      values.push({
        label: totalCostCell.FieldLabel,
        value: EvalSlabResults[rowIndex][totalCostCell.FieldId],
        unit,
      });

      setModalOpen(true);
      setSelectedRowData(rowData);
      setModalData(values);
      setUnit(unitFlag == 3 ? "Pack" : unit);
      setErrors(errorMsg);
    } else {
      console.error("slabMainTemplateQuotation or key is null or undefined.");
    }
  };

  const finalFormulaFunction = (
    formula,
    placeholders,
    basicPriceRate,
    fieldId,
    key,
    value
  ) => {
    placeholders.forEach((placeholder) => {
      const matchId = placeholder.slice(2, -1);
      let matchValue = props.mainTemplateQuotation[key][matchId]?.value;
      if (matchValue === undefined) {
        let fieldCell = props.data[0].find((cell) => cell.FieldId === matchId);
        if (fieldCell) {
          matchValue = "(" + fieldCell.DefaultValue.Formula + ")";
          let TemperoraryPlaceholders = "";
          if (
            fieldCell.DefaultValue.Formula &&
            fieldCell.DefaultValue.Formula !== ""
          ) {
            TemperoraryPlaceholders =
              fieldCell.DefaultValue.Formula.match(/\(f\d+\)/g);
          } else if (
            fieldCell.DefaultFormula &&
            fieldCell.DefaultFormula !== ""
          ) {
            TemperoraryPlaceholders =
              fieldCell.DefaultFormula.match(/\(f\d+\)/g);
          }
          if (TemperoraryPlaceholders != "") {
            let data_hash = finalFormulaFunction(
              fieldCell.DefaultValue.Formula,
              TemperoraryPlaceholders,
              basicPriceRate,
              matchId,
              key,
              0
            );
            if (data_hash.eval) {
              matchValue = eval(data_hash.formula);
            } else {
              matchValue = data_hash.value;
            }
          } else {
            matchValue = fieldCell.DefaultValue || 0;
          }
        } else {
          matchValue = props.additionalInfoTemplate[matchId]?.value || 0;
          if (props.additionalInfoTemplate[matchId]?.Identifier === "GSTType") {
            matchValue = "'" + matchValue + "'";
          }

          if (
            props.additionalInfoTemplate[matchId]?.Identifier === "GSTValue"
          ) {
            matchValue = "'" + (matchValue - 0.0) + "'";
          }
          // fieldCell = props.additionalInfoTemplate[0].find(
          //   (cell) => cell.FieldId === matchId
          // );
        }
      }
      if (matchValue === "") {
        matchValue = 0;
      }
      if (matchValue !== undefined) {
        // If value is defined, update the result
        if (
          props.mainTemplateQuotation[key][matchId]?.Identifier === "GSTType"
        ) {
          matchValue = "'" + matchValue + "'";
        }
        if (
          props.mainTemplateQuotation[key][matchId]?.Identifier === "GSTValue"
        ) {
          matchValue = "'" + (matchValue - 0.0) + "'";
        }
        // "Identifier": "GSTValue",
        if (basicPriceRate.FieldId === fieldId) {
          value = matchValue || 0;
          return { formula: formula, value: value, eval: 0 };
        } else {
          formula = formula.replace(placeholder, matchValue);
        }
      }
    });
    return { formula: formula, value: value, eval: 1 };
  };

  const handleUplaodSpecificationOpen = (
    index,
    specifications,
    specificationText
  ) => {
    setUpdatedFileValue(specifications);
    setFileSpecificationsOpen(!fileSpecificationsOpen);
    setPopHash({ index: index, type: "specification" });
    getExistingFiles(specifications);
    setSpecificationText(specificationText);
  };
  const getExistingFiles = (file_id) => {
    const queryString = file_id
      .map((file) => `file_ids[]=${file.id}`)
      .join("&");
    const url = `${postFileSpecifications}?${queryString}`;
    axios
      .get(url)
      .then((res) => {
        setSpecification(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, " downloadFile error");
        this.props.openSnackbar(
          "error",
          err.response.data?.message
            ? err.response.data?.message
            : "Something went wrong!"
        );
        setLoading(false);
      });
  };
  const handleUploadClose = () => {
    setFileSpecificationsOpen(false);
    setSpecification([]);
    setLoading(true);
    setSpecificationText("");
  };

  const updateDisabledProps = () => {
    const disabledProps = {};
    for (const key in props.mainTemplateQuotation) {
      const isNegotiation = props.lastTransactionTypeInThread === "Negotiation";
      const lastSelected = props.lastTransactionInThread[key]?.selected === "Y";
      const mainSelected = props.mainTemplateQuotation[key]?.selected === "Y";
      const is_deleted_from_master =
        props.mainTemplateQuotation[key]?.is_deleted_from_master;
      const is_unlink_from_category =
        props.mainTemplateQuotation[key]?.is_unlink_from_category;
      const filteredSelectedNotDataSelected =
        props.filteredSelectedNotData[key]?.selected;

      // Check if either is_deleted_from_master or is_unlink_from_category is true, if so, disable the checkbox
      if (is_deleted_from_master || is_unlink_from_category) {
        disabledProps[key] = true;
      } else if (
        props.lastTransactionTypeInThread === "Inquiry" &&
        !mainSelected
      ) {
        disabledProps[key] = true;
      } else {
        // Your existing conditions
        if (isNegotiation) {
          if (lastSelected) {
            if (mainSelected) {
              disabledProps[key] = false;
            }
          } else {
            if (filteredSelectedNotDataSelected == "N") {
              if (mainSelected) {
                disabledProps[key] = false;
              } else {
                disabledProps[key] = true;
              }
            } else {
              disabledProps[key] = true;
            }
          }
        } else {
          if (!mainSelected && filteredSelectedNotDataSelected == "N") {
            // Allow toggling between 'Y' and 'N' when mainSelected is 'N' and
            // filteredSelectedNotDataSelected is 'N'
            disabledProps[key] = true;
          } else if (!mainSelected && filteredSelectedNotDataSelected !== "N") {
            disabledProps[key] = true;
          } else if (!mainSelected && filteredSelectedNotDataSelected !== "N") {
            disabledProps[key] = true;
          } else if (!mainSelected) {
            // quotation = N , RFQ = Y
            // Enable item if mainSelected matches filteredSelectedNotDataSelected
            disabledProps[key] = false;
          }
          //    else if (mainSelected) {
          //     // quotation = N , RFQ = Y
          //     // Enable item if mainSelected matches filteredSelectedNotDataSelected
          //     disabledProps[key] = true;
          //   }
          else if (mainSelected && filteredSelectedNotDataSelected == "N") {
            // quotation = N , RFQ = Y
            // Enable item if mainSelected matches filteredSelectedNotDataSelected
            disabledProps[key] = false;
          } else {
            disabledProps[key] = false;
          }
        }
      }
    }
    props.disabledProps(disabledProps);
    setDisabledProps(disabledProps);
  };

  const updateDisabledPropsForMS = () => {
    const disabledPropsForMS = {};
    // Loop through multiSolmainTemplateQuotation keys
    for (const key in props.multiSolmainTemplateQuotation) {
      // Loop through each row in the multiSolmainTemplateQuotation[key]
      for (const rowIndex in props.multiSolmainTemplateQuotation[key]) {

        const isNegotiation = props.lastTransactionTypeInThread === "Negotiation";
        const lastSelected = props.lastTransactionInThread[key]?.selected === "Y";
        const mainSelected = props.multiSolmainTemplateQuotation[key][rowIndex]?.selected === "Y";
        const is_deleted_from_master = props.items[key]?.is_deleted_from_master;
        const is_unlink_from_category = props.items[key]?.is_unlink_from_category;
        const filteredSelectedNotDataContainsKey =
          props.lastTransactionData?.itemize[key]?.selected;
        // Initialize disabledPropsForMS[key] if not already
        if (!disabledPropsForMS[key]) {
          disabledPropsForMS[key] = {};
        }
        // Disable the checkbox if either is_deleted_from_master or is_unlink_from_category is true
        if (is_deleted_from_master || is_unlink_from_category) {
          disabledPropsForMS[key][rowIndex] = true;
        } else if (props.lastTransactionTypeInThread === "Inquiry" && !mainSelected) {
          disabledPropsForMS[key][rowIndex] = true;
        } else if (isNegotiation) {
          // Check if there's a corresponding negotiation solution for the current rowIndex
          const negotiationSolutions = props.lastNegotiationData?.[key]?.solution;
          const transactionSolutions = props.lastTransactionData?.itemize?.[key]?.solution;

          if (negotiationSolutions && transactionSolutions && transactionSolutions[rowIndex] && props.lastNegotiationData[key].selected==="Y" ) {
            const transactionSolution = transactionSolutions[rowIndex].name;
            // Check if the current solution in lastNegotiationData matches the current transaction solution name
            const matchedSolution = negotiationSolutions.some(
              (negotiationSolution) => negotiationSolution.name === _.unescape(transactionSolution)
            );

            // If there's a match, disable this rowIndex
            if (matchedSolution) {
              disabledPropsForMS[key][rowIndex] = false;
            } else {
              // If no match, the row is not disabled for negotiation
              disabledPropsForMS[key][rowIndex] = true;
            }

          } else if (!props.lastNegotiationData.hasOwnProperty(key)) {
            disabledPropsForMS[key][rowIndex] = true;
          }
        } else {
          // Default case for non-negotiation scenarios        
          if (!mainSelected && filteredSelectedNotDataContainsKey === "N") {
            disabledPropsForMS[key][rowIndex] = false;
          } else if (!mainSelected && filteredSelectedNotDataContainsKey !== "N") {
            disabledPropsForMS[key][rowIndex] = true;
          } else if (mainSelected && filteredSelectedNotDataContainsKey !== "N" && props.lastTransactionTypeInThread !== "Inquiry") {
            disabledPropsForMS[key][rowIndex] = false;
          } else if (mainSelected && filteredSelectedNotDataContainsKey !== "N" && props.lastTransactionTypeInThread === "Inquiry") {
            disabledPropsForMS[key][rowIndex] = false;
          } else if (mainSelected && filteredSelectedNotDataContainsKey === "N") {
            disabledPropsForMS[key][rowIndex] = false;
          } else if (!mainSelected) {
            disabledPropsForMS[key][rowIndex] = false;
          } else {
            disabledPropsForMS[key][rowIndex] = false;
          }
        }
      }
    }

    // Set the updated disabledPropsForMS
    setDisabledPropsForMS(disabledPropsForMS);
  };

  const updateDisabledPropsForSlabParent = () => {
    const disabledPropsForSlabParent = {};
    for (const key in props.slabMainTemplateQuotation) {
      const isNegotiation = props.lastTransactionTypeInThread === "Negotiation";
      const lastSelected = props.lastTransactionInThread[key]?.selected === "Y";
      const mainSelected =
        props.slabMainTemplateQuotation[key]?.selected === "Y";
      const is_deleted_from_master = props.items[key].is_deleted_from_master;
      const is_unlink_from_category = props.items[key].is_unlink_from_category;
      const filteredSelectedNotDataSelected =
        props.filteredSelectedNotData[key]?.selected;

      // Check if either is_deleted_from_master or is_unlink_from_category is true, if so, disable the checkbox
      if (is_deleted_from_master || is_unlink_from_category) {
        disabledPropsForSlabParent[key] = true;
      } else if (
        props.lastTransactionTypeInThread === "Inquiry" &&
        !mainSelected
      ) {
        disabledPropsForSlabParent[key] = true;
      } else {
        // Your existing conditions
        if (isNegotiation) {
          if (lastSelected) {
            if (mainSelected) {
              disabledPropsForSlabParent[key] = false;
            }
          } else {
            if (filteredSelectedNotDataSelected == "N") {
              if (mainSelected) {
                disabledPropsForSlabParent[key] = false;
              } else {
                disabledPropsForSlabParent[key] = true;
              }
            } else {
              disabledPropsForSlabParent[key] = true;
            }
          }
        } else {
          if (!mainSelected && filteredSelectedNotDataSelected == "N") {
            // Allow toggling between 'Y' and 'N' when mainSelected is 'N' and
            // filteredSelectedNotDataSelected is 'N'
            disabledPropsForSlabParent[key] = true;
          } else if (!mainSelected && filteredSelectedNotDataSelected !== "N") {
            disabledPropsForSlabParent[key] = true;
          } else if (!mainSelected && filteredSelectedNotDataSelected !== "N") {
            disabledPropsForSlabParent[key] = true;
          } else if (!mainSelected) {
            // quotation = N , RFQ = Y
            // Enable item if mainSelected matches filteredSelectedNotDataSelected
            disabledPropsForSlabParent[key] = false;
          }
          //    else if (mainSelected) {
          //     // quotation = N , RFQ = Y
          //     // Enable item if mainSelected matches filteredSelectedNotDataSelected
          //     disabledPropsForSlabParent[key] = true;
          //   }
          else if (mainSelected && filteredSelectedNotDataSelected == "N") {
            // quotation = N , RFQ = Y
            // Enable item if mainSelected matches filteredSelectedNotDataSelected
            disabledPropsForSlabParent[key] = false;
          } else {
            disabledPropsForSlabParent[key] = false;
          }
        }
      }
      // }
    }
    setDisabledPropsForSlabParent(disabledPropsForSlabParent);
  };

  const updateDisabledPropsForSlabChild = () => {
    const disabledPropsForSlabChild = {};

    for (const key in props.slabMainTemplateQuotation) {
      for (const rowIndex in props.slabMainTemplateQuotation[key]) {
        let isNegotiation = false;

        if (
          props.lastNegotiationData &&
          props.lastNegotiationData[key]?.solution?.[0]?.data?.[rowIndex]
        ) {
          isNegotiation =
            props.lastNegotiationData[key].solution[0].data[rowIndex]
              ?.select_slab === "Y";
        }
        const lastSelected =
          props.lastTransactionInThread[key]?.solution[0]?.data[rowIndex]
            ?.select_slab === "Y";
        let mainSelected =
          props.slabMainTemplateQuotation[key][rowIndex]?.select_slab === "Y";
        const is_deleted_from_master = props.items[key]?.is_deleted_from_master;
        const is_unlink_from_category = props.items[key]?.is_unlink_from_category;
        let filteredSelectedNotDataContainsKey =
          props.lastTransactionData?.itemize[key]?.solution[0]?.data[rowIndex]
            ?.select_slab;

        const slabChildStateToggleCheckMain =
          props.slabChildStateToggleCheck[key][rowIndex];

        // Initialize disabledPropsForSlabChild[key] if not already
        if (!disabledPropsForSlabChild[key]) {
          disabledPropsForSlabChild[key] = {};
        }

        // Disable the checkbox if either is_deleted_from_master or is_unlink_from_category is true
        if (is_deleted_from_master || is_unlink_from_category) {
          disabledPropsForSlabChild[key][rowIndex] = true;
        } else if (props.lastTransactionTypeInThread === "Inquiry" && !mainSelected) {
          disabledPropsForSlabChild[key][rowIndex] = true;
        }
        else if (isNegotiation) {
          if (lastSelected && mainSelected) {
            if (slabChildStateToggleCheckMain) {
              // disabledProps[key] = false;
              disabledPropsForSlabChild[key][rowIndex] = false;
            }
          } else {
            if (filteredSelectedNotDataContainsKey == "N") {
              if (slabChildStateToggleCheckMain) {
                // disabledProps[key] = false;
                disabledPropsForSlabChild[key][rowIndex] = false;
              } else {
                // disabledProps[key] = true;
                disabledPropsForSlabChild[key][rowIndex] = true;
              }
            } else {
              // disabledProps[key] = true;
              disabledPropsForSlabChild[key][rowIndex] = true;
            }
          }
        }
        else {
          // Default case for non-negotiation scenarios
          if (props.lastNegotiationData && props?.lastNegotiationData[key]?.solution[0]?.data[rowIndex]
            ?.select_slab === "N" && filteredSelectedNotDataContainsKey === "Y") {
            mainSelected = false;
            filteredSelectedNotDataContainsKey = "N"
          }
          if (!mainSelected && filteredSelectedNotDataContainsKey === "N") {
            disabledPropsForSlabChild[key][rowIndex] = true;
          } else if (!mainSelected && filteredSelectedNotDataContainsKey !== "N") {
            disabledPropsForSlabChild[key][rowIndex] = false;
          } else if (mainSelected && filteredSelectedNotDataContainsKey !== "N" && props.lastTransactionTypeInThread !== "Inquiry") {
            disabledPropsForSlabChild[key][rowIndex] = false;
          } else if (!mainSelected && filteredSelectedNotDataContainsKey === "N" && props.lastTransactionTypeInThread !== "Inquiry") {
            disabledPropsForSlabChild[key][rowIndex] = false;
          } else if (mainSelected && props.lastTransactionTypeInThread === "Inquiry") {
            disabledPropsForSlabChild[key][rowIndex] = false;
          }
          else if (mainSelected && filteredSelectedNotDataContainsKey === "N") {
            disabledPropsForSlabChild[key][rowIndex] = false;
          } else if (!mainSelected) {
            disabledPropsForSlabChild[key][rowIndex] = false;
          }
          else {
            disabledPropsForSlabChild[key][rowIndex] = false;
          }
        }
      }
    }

    setDisabledPropsForSlabChild(disabledPropsForSlabChild);
  };


  useEffect(() => {
    updateDisabledProps();
  }, [
    props.lastTransactionTypeInThread,
    props.lastTransactionInThread,
    props.mainTemplateQuotation,
    multiSolmainTemplateQuotation,
  ]);

  useEffect(() => {
    if (props.multipleSolutionFlag && props.slabFlag) {
      updateDisabledPropsForSlabParent();
      updateDisabledPropsForSlabChild();
    }
  }, [
    props.slabFlag,
    props.multipleSolutionFlag,
    props.lastTransactionTypeInThread,
    props.lastTransactionInThread,
    props.mainTemplateQuotation,
    props.slabMainTemplateQuotation,
  ]);

  useEffect(() => {
    if (props.multipleSolutionFlag && !props.slabFlag) {
      updateDisabledPropsForMS();
    }
  }, [
    props.slabFlag,
    props.multipleSolutionFlag,
    props.lastTransactionTypeInThread,
    props.lastTransactionInThread,
    props.mainTemplateQuotation,
    props.multiSolmainTemplateQuotation,
  ]);

  const getTableBody = () => {
    let itemsToRender;
    const { SlabTableToggle } = state;

    if (props.selectedQuotedItems || props.selectedQuotedItems[0]) {
      const { mainTemplateQuotation } = props;
      let filteredItems = {};
      if (props.itemSpecific) {
        filteredItems = Object.entries(props.selectedQuotedItems)
          .filter(([key, value]) =>
            props.inquiryItemsId.includes(parseInt(key))
          )
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});
      } else {
        filteredItems = props.selectedQuotedItems;
      }
      const filteredItemsMap = new Map(
        Object.entries(filteredItems).map((item) => [item[0], item[1]])
      );
      const reorderedTransactionItemSequence = props?.transactionItemSequence
        .filter((key) => filteredItemsMap.has(key)) // Filter keys that are present in map
        .map((key) => [key, filteredItemsMap.get(key)]);
      // itemsToRender = Object.entries(filteredItems)
      //   .sort(([, a], [, b]) => a.name.localeCompare(b.name))
      itemsToRender = reorderedTransactionItemSequence.map(
        ([key, value], index) => {
          const solutions = props.multiSolmainTemplateQuotation[key] || [];
          const lastSelected = props.lastTransactionInThread[key]?.selected === "Y";
          const mainSelected = props.mainTemplateQuotation[key]?.selected === "Y";
          let  hasError = false
          if (props.multipleSolutionFlag && !props.slabFlag && props.switchState[key] && mainTemplateQuotation[key]?.selected === "Y" ) {
            if (props.multiplePlantsFlag) {
              hasError = props.multiSolmainTemplateQuotation && props.multiSolmainTemplateQuotation?.[key]?.some(item => {
                return Object.values(item)?.some(field => field?.error === true && field?.Identifier != "GSTType")
              }
              );
            } else {

              hasError = props.multiSolmainTemplateQuotation && props.multiSolmainTemplateQuotation?.[key]?.some(item => {
               return Object.values(item)?.some(field => field?.error === true)
             }
             );
            }
          }
          return (
            <>
              <TableRow key={key}>
                <TableCell
                  sx={{
                    WebkitBoxShadow: {
                      md: state.isScrollActive && "11px 0 19px -2px #bebebe54",
                      sm: "none",
                    },
                    boxShadow: {
                      md: state.isScrollActive && "11px 0 19px -2px #bebebe54",
                      sm: "none",
                    },
                  }}
                >
                  <Box display="flex" alignItems="center" gap="16px">
                    <Checkbox
                      checked={mainTemplateQuotation[key]?.selected === "Y"} // Assuming 'selected' indicates whether the item is selected
                      onChange={(event) =>
                        props.handleCheckboxChange(event, key)
                      } // Call a function to handle checkbox change
                      sx={{ "&.MuiCheckbox-root": { mr: 0, p: 0 } }}
                    />
                    {props.multipleSolutionFlag && !props.slabFlag && (
                      <div key={key}>
                        {/* {(props.multipleSolutionFlag && !props.slabFlag ) && ( */}
                        <>
                          {!iconStateMS[key] ? (
                            props.switchState[key] ? (
                              <Tooltip
                                title={"Click here to view Multiple Solution"}
                                placement="bottom-start"
                                disableInteractive
                              >
                                <Badge
                                  color={"primary"}
                                  sx={{
                                    "& .MuiBadge-badge": {
                                      backgroundColor: "var(--secondary-500)",
                                    },
                                  }}
                                  badgeContent={solutions?.length}
                                >
                                  <AddIcon
                                    onClick={(event) =>
                                      handleAddClickForMS(event, key)
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </Badge>
                              </Tooltip>
                            ) : (
                              <AddIcon
                                // onClick={(event) => handleAddClickForMS(event, key)}
                                style={{ cursor: "pointer" }}
                              />
                            )
                          ) : props.switchState[key] ? (
                            <Tooltip
                              title={"Click here to close Multiple Solution"}
                              placement="bottom-start"
                              disableInteractive
                            >
                              <Badge
                                color="primary"
                                sx={{
                                  "& .MuiBadge-badge": {
                                    backgroundColor: "var(--secondary-500)",
                                  },
                                }}
                                badgeContent={solutions?.length}
                              >
                                <RemoveIcon
                                  onClick={(event) =>
                                    handleRemoveClickForMS(event, key)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </Badge>
                            </Tooltip>
                          ) : (
                            <RemoveIcon
                              // onClick={(event) => handleRemoveClickForMS(event, key)}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </>
                        {/* )} */}
                      </div>
                    )}
                    {props.multipleSolutionFlag && !props.slabFlag && (
                      <Tooltip
                        title={"Multiple Solution"}
                        placement="bottom-start"
                        disableInteractive
                      >
                        <Switch
                          // checked={props.lastTransactionTypeInThread !== "Inquiry" ? props.switchState[key]: true}
                          checked={props.switchState[key]}
                          // disabled={props.lastTransactionTypeInThread !== "Inquiry"}
                          disabled={
                            props?.mainTemplateQuotation[key]?.selected ===
                              "Y" &&
                            props.lastTransactionTypeInThread === "Inquiry"
                              ? false
                              : props.lastTransactionTypeInThread !==
                                  "Inquiry" && props.switchStateToggleCheck[key]
                              ? true
                              : props?.mainTemplateQuotation[key]?.selected !==
                                "Y"
                              ? true
                              : false
                          }
                          onChange={(event) => {
                            // props.toggleSwitch(key);
                            handleSwitchBoxChange(event, key);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Tooltip>
                    )}
                    <Tooltip // This is for Attachments specific files
                      title="Specification"
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Badge
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        // badgeContent={value?.files?.length}
                        badgeContent={`${value?.files?.length}`}
                        color="primary"
                      >
                        <AttachFileIcon
                          onClick={() =>
                            value?.files?.length > 0 &&
                            handleUplaodSpecificationOpen(
                              key,
                              value.files,
                              value.specification_text
                            )
                          }
                          sx={{ cursor: "pointer" }}
                        />
                      </Badge>
                    </Tooltip>
                    <Tooltip //Item name header starts from here
                      title={_.unescape(value.name)}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Badge
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          badgeContent={props.mainTemplateQuotation[key]?.added_after_rfq_created ? "New" : null}
                          color="primary"
                          sx={{ '& .MuiBadge-badge': { top:"-5px", height:"16px", fontSize:"10px" } }}
                        >
                        <Typography
                          noWrap
                          variant="body2"
                          color="text.primary"
                          fontWeight={500}
                          sx={{
                            width: {
                              lg: `${
                                state.itemCellWidth > 300
                                  ? state.itemCellWidth
                                  : 300
                              }px`,
                              md: `${
                                state.itemCellWidth > 200
                                  ? state.itemCellWidth
                                  : 200
                              }px`,
                              sm: `${
                                state.itemCellWidth > 200
                                  ? state.itemCellWidth
                                  : 200
                              }px`,
                              xs: `${
                                state.itemCellWidth > 125
                                  ? state.itemCellWidth
                                  : 125
                              }px`,
                            },
                            // width: `${
                            //   state.itemCellWidth > 350
                            //     ? state.itemCellWidth
                            //     : 350
                            // }px`,
                            marginLeft: "10px",
                          }}
                        >
                          {_.unescape(value.name)}
                        </Typography>
                      </Badge>
                      <Typography
                        sx={{ color: "error.main", marginLeft: "10px" }}
                      >
                        {props.mainTemplateQuotation[key]
                          ?.is_deleted_from_master &&
                          "This item is deleted from item master."}
                      </Typography>
                      <Typography sx={{ color: "error.main", marginLeft: "10px" }}>
                        {props.mainTemplateQuotation[key]
                          ?.is_unlink_from_category &&
                          "This item is unlinked from category."}
                      </Typography>

                      {props.multipleSolutionFlag && !props.slabFlag && props.switchState[key] &&
                        (hasError ||
                          props.solutionNameErrors[
                            Object.keys(props.solutionNameErrors).find((k) =>
                              k.startsWith(key)
                            )
                          ]) && (
                          <Typography sx={{ color: "error.main", marginLeft: "10px" }}>
                            There are errors in solution
                          </Typography>
                        )}
                      {/* {props.mainTemplateQuotation[key]
                        ?.added_after_rfq_created && (
                        <Chip
                          label="New"
                          size="small"
                          color="primary"
                          sx={{ marginLeft: "10px", height: "16px" }}
                        />
                      )} */}
                    </Tooltip>
                  </Box>
                </TableCell>
                {props.data[0]?.find(
                  (obj) =>
                    obj.Identifier === "ProductCode" &&
                    obj.VisibilityFlag === "Y"
                ) && (
                  <TableCell
                  // sx={{
                  //   WebkitBoxShadow:
                  //     state.isScrollActive && "11px 0 19px -4px #bebebe54",
                  //   boxShadow:
                  //     state.isScrollActive && "11px 0 19px -4px #bebebe54",
                  // }}
                  >
                    <Tooltip //Item code header starts from here
                      title={_.unescape(value.code)}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Typography
                        noWrap
                        variant="body2"
                        color="text.primary"
                        fontWeight={500}
                        // sx={{
                        //   width: {
                        //     lg: `${state.itemCellWidth > 300
                        //       ? state.itemCellWidth
                        //       : 250
                        //       }px`,
                        //     md: `${state.itemCellWidth > 200
                        //       ? state.itemCellWidth
                        //       : 200
                        //       }px`,
                        //     sm: `${state.itemCellWidth > 200
                        //       ? state.itemCellWidth
                        //       : 200
                        //       }px`,
                        //     xs: `${state.itemCellWidth > 125
                        //       ? state.itemCellWidth
                        //       : 125
                        //       }px`,
                        //   },
                        // }}
                      >
                        {_.unescape(value.code)}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                )}
                {props.multipleSolutionFlag && !props.slabFlag && (
                  <TableCell>
                    <Tooltip //Item code header starts from here
                      title={props.lastTransactionInThread[key]?.name}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="text"
                        value={
                          _.unescape(props.solutionNames[`${key}-${0}`]) || ""
                        } // Always take value from props.solutionNames
                        onChange={(e) => {
                          props.handleSolNameChangeMainTemplate(
                            key,
                            e.target.value
                          ); // Pass the new value to the handler
                        }}
                        error={!!props.errorArrayForMSParentName[key]}
                        helperText={props.errorArrayForMSParentName[key] || ""}
                        disabled={
                          mainSelected &&
                          lastSelected &&
                          props.lastTransactionTypeInThread !== "Inquiry"
                            ? true
                            : (Object.keys(disabledProps).length > 0 &&
                                disabledProps[key]) ||
                              switchDisabledProps[key]
                        }
                      />
                    </Tooltip>
                  </TableCell>
                )}
                {/* {(props.slabFlag && props.multipleSolutionFlag) && (@
                  <TableCell>
                    <Tooltip //Item code header starts from here
                      title={props.lastTransactionInThread[key]?.name}
                      placement="bottom-start"
                      disableInteractive
                    >
                       <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          type="text"
                          disabled={props.lastTransactionTypeInThread !== "Inquiry" || props.switchState[key]}
                          value={_.unescape(props.solutionNameMainTemplate[key])} // Use editable value from state or default value
                          onChange={(e)=>handleInputChange(key,e)} // Pass key to identify which row is being updated
                        />
                    </Tooltip>
                  </TableCell>
                )} */}
                {props?.data[index]?.map((cell) => {
                  const DefaultFormula = cell.DefaultFormula;

                  // Regular expression to extract field IDs
                  const regex = /f(\d+)/g;
                  let match;
                  const fieldIDs = [];

                  // Extract field IDs from DefaultFormula
                  while ((match = regex.exec(DefaultFormula)) !== null) {
                    fieldIDs.push(match[1]);
                  }

                  // Assuming you always have two field IDs in the DefaultFormula
                  const [field1, field2] = fieldIDs;

                  // Initialize FieldState
                  const FieldState = {};

                  // If field IDs are found, set data in FieldState
                  if (field1 && field2) {
                    // Assuming num1 and num2 are the values you want to set
                    //  const num1 = /* Your value for field1 */;
                    //  const num2 = /* Your value for field2 */;
                    // Setting data in FieldState for both field1 and field2
                    //  const num1 = FieldState[field1];
                    //  const num2 = FieldState[field2];
                  }
                  // All I/P fields comes from there in the template section table
                  if (
                    cell.VisibilityFlag === "Y" ||
                    (cell.DefaultValue &&
                      cell.DefaultValue.Button === "Y" &&
                      cell.ElementType === "D")
                  ) {
                    if (
                      props.multiplePlantsFlag &&
                      cell.Identifier === "GSTType"
                    ) {
                      return null;
                    }
                    if (
                      !(
                        cell.Identifier === "Product" ||
                        cell.Identifier === "ProductCode" ||
                        cell.Identifier === "ProductForeign"
                      )
                    ) {
                      let itemPoTextClass = (
                        ["POText", "ItemText", "POTextForeign", "ItemTextForeign"]
                        .includes(cell.Identifier) &&
                        cell.ElementType === "T"
                      ) ?
                      "item-po-text" :
                      "";
                      return (
                        <TableCell
                          key={cell.FieldId}
                          // sx={{ minWidth: "120px" }}
                          className={itemPoTextClass}
                        >
                          <QuotationFormField
                            inquiryId={props.inquiryId}
                            batch_id={props.batch_id}
                            LATEST={props.LATEST}
                            vendorType={props?.vendorType}
                            itemPoTextClass={itemPoTextClass}
                            // upd_budget_master={
                            //   props.mainTemplateQuotation[item.id] &&
                            //   props.mainTemplateQuotation[item.id][cell?.FieldId] &&
                            //   props.mainTemplateQuotation[item.id][cell?.FieldId][
                            //     `upd_budget_master_${item.id}`
                            //   ]
                            // }
                            // qty={
                            //   props.mainTemplateQuotation[item.id] &&
                            //   props.mainTemplateQuotation[item.id]["qty"]
                            // }
                            // rate={
                            //   props.mainTemplateQuotation[item.id] &&
                            //   props.mainTemplateQuotation[item.id]["rate"]
                            // }
                            // readOnly={
                            //   props.mainTemplateQuotation[item.id]?.[cell?.FieldId]
                            //     ?.readOnly
                            // }
                            cell={cell}
                            item={value}
                            index={index}
                            // FieldState={
                            //   props.selectedQuotedItems.vendors[0]
                            //     .transaction_thread[0].template.itemize[key]
                            //     .solution[0] &&
                            //   (props.selectedQuotedItems.vendors[0]
                            //     .transaction_thread[0].template.itemize[key]
                            //     .solution[0].data[0][field1] ||
                            //     props.selectedQuotedItems.vendors[0]
                            //       .transaction_thread[0].template.itemize[key]
                            //       .solution[0].data[0][field2])
                            // }
                            //Important: there I'll be sending the mainTemplateQuotation doing this just for the static data
                            // props.selectedQuotedItems
                            //   .transaction_thread[0].template.itemize[
                            //   item.id
                            // ][cell.FieldId].value}
                            // FieldState={
                            //   mainTemplateQuotation[key] &&
                            //   mainTemplateQuotation[key][cell?.FieldId]
                            // }
                            FieldState={
                              mainTemplateQuotation[key] &&
                              mainTemplateQuotation[key][cell?.FieldId]
                            }
                            base_currency={props?.base_currency}
                            selectedCurrency={
                              props.lastTransactionTypeInThread === "Inquiry" &&
                              (props.vendorType === "International" ||
                                props.vendorType === "Local Importer")
                                ? props?.selectedCurrency
                                : props?.selectedCurrency ||
                                  props.quotedCurrency
                              // props.lastTransactionTypeInThread === "Inquiry" &&
                              // (props.vendorType === "International" ||
                              //   props.vendorType === "Local Importer")
                              //   ? props.selectedCurrency
                              //   : Object.values(
                              //       props.lastTransactionInThread
                              //     )[0].solution[0]?.currency
                            }
                            // handleUpdateInMasterClick={
                            //   props.handleUpdateInMasterClick
                            // }
                            updateDeliveryScheduleFieldValue={
                              props.updateDeliveryScheduleFieldValue
                            }
                            handleChange={(
                              evt,
                              FieldId,
                              Identifier,
                              FieldLabel,
                              ElementType,
                              key
                            ) =>
                              props.handleChange(
                                evt,
                                index,
                                FieldId,
                                Identifier,
                                FieldLabel,
                                ElementType,
                                key
                              )
                            }
                            handleDateChange={(newVal, FieldId, Validation) =>
                              props.handleDateChange(
                                newVal,
                                FieldId,
                                "mainTemplateQuotation",
                                index,
                                Validation,
                                key
                              )
                            }
                            handleUploadFiles={(
                              FieldId,
                              Identifier,
                              itemId,
                              index
                            ) => {
                              props.uploadItemFiles(
                                FieldId,
                                Identifier,
                                itemId,
                                index,
                                "mainTemplateQuotation"
                              );
                            }}
                            default_dropdown_type={props.default_dropdown_type}
                            destination={props.destination}
                            SlabTableToggle={SlabTableToggle}
                            // disabled={
                            //   Object.keys(disabledProps).length > 0 &&
                            //   disabledProps[key]
                            // }
                            // disabled={ props.multipleSolutionFlag && !props.slabFlag ?
                            //   props.lastTransactionTypeInThread !== "Inquiry" && props.switchState[key]
                            //     ? true
                            //     :(Object.keys(disabledProps).length > 0 && disabledProps[key]) || switchDisabledProps[key]:
                            //     Object.keys(disabledProps).length > 0 && disabledProps[key]
                            // }
                            disabled={
                              Object.keys(disabledProps).length > 0 &&
                              disabledProps[key]
                            }
                            readOnlyForSwitch={
                              props.multipleSolutionFlag && !props.slabFlag
                                ? (props.lastTransactionTypeInThread !==
                                    "Inquiry" && props.switchState[key]
                                    ? true
                                    : Object.keys(disabledProps).length > 0 &&
                                      disabledProps[key]) ||
                                  switchDisabledProps[key]
                                : switchDisabledProps[key]
                            }
                            // readOnlyForSwitch={switchDisabledProps[key]}
                            keys={key.length > 0 && key}
                            handleDetailsClick={handleDetailsClick}
                            mainTemplateQuotation={props.mainTemplateQuotation}
                            multiSolmainTemplateQuotation={
                              props.multiSolmainTemplateQuotation
                            }
                            multiplePlantsFlag={props.multiplePlantsFlag}
                            isServiceRfq={props.isServiceRfq}
                            multipleSolutionFlag={props.multipleSolutionFlag}
                            slabFlag={props.slabFlag}
                            switchState={props.switchState[key]}
                            DetailsClickFlag={false}
                          />
                        </TableCell>
                      );
                    }
                  }
                })}
              </TableRow>
              {props.slab[key] && props.slab[key].isOpen && (
                <TableRow>
                  <TableCell style={{ padding: 0 }} colSpan={100}>
                    <TableContainer
                      className="quotation_template_table slab_range_table"
                      sx={{
                        maxHeight: "400px",
                        overflowY: "auto",
                        borderTop: "solid 1px",
                        borderColor: (theme) => theme.palette.bluegrey[500],
                        borderBottomRightRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      onScroll={debounce((event) => {
                        if (
                          event.target.scrollLeft === 0 &&
                          state.isScrollActive
                        ) {
                          setState({
                            isScrollActive: false,
                          });
                        } else if (
                          event.target.scrollLeft !== 0 &&
                          !state.isScrollActive
                        ) {
                          setState({
                            isScrollActive: true,
                          });
                        }
                      }, 200)}
                    >
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                WebkitBoxShadow:
                                  state.isScrollActive &&
                                  "11px 0 19px -2px #bebebe54",
                                boxShadow:
                                  state.isScrollActive &&
                                  "11px 0 19px -2px #bebebe54",
                              }}
                            >
                              <Box
                                className=""
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Checkbox sx={{ visibility: "hidden" }} />
                                <Tooltip
                                  title={
                                    props.data[0]?.find(
                                      (obj) =>
                                        obj.Identifier === "Product" ||
                                        obj.Identifier === "ProductForeign"
                                    )?.FieldLabel
                                  }
                                >
                                  <Box
                                    sx={{
                                      minWidth: "350px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography fontWeight={500}>
                                      {
                                        props.data[0]?.find(
                                          (obj) =>
                                            obj.Identifier === "Product" ||
                                            obj.Identifier === "ProductForeign"
                                        )?.FieldLabel
                                      }
                                    </Typography>

                                    <ResizableBox
                                      axis="x"
                                      height={20}
                                      width={20}
                                      onResizeStop={(e, data) => {
                                        setState({
                                          itemCellWidth: data.size.width,
                                        });
                                      }}
                                      style={{
                                        textAlign: "left",
                                        width: "100%",
                                      }}
                                      minConstraints={[20, 20]}
                                    ></ResizableBox>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Tooltip
                                  title={
                                    props.data[0]?.find(
                                      (obj) => obj.Identifier === "ProductCode"
                                    )?.FieldLabel
                                  }
                                >
                                  <Typography fontWeight={500}>
                                    {
                                      props.data[0]?.find(
                                        (obj) =>
                                          obj.Identifier === "ProductCode"
                                      )?.FieldLabel
                                    }
                                  </Typography>
                                </Tooltip>
                                <ResizableBox
                                  axis="x"
                                  height={20}
                                  width={20}
                                  style={{
                                    textAlign: "left",
                                    width: "100%",
                                  }}
                                  minConstraints={[20, 20]}
                                ></ResizableBox>
                              </Box>
                            </TableCell>

                            {props.data[0]?.map((cell, index) => {
                              if (
                                props.multiplePlantsFlag === true &&
                                cell.Identifier === "GSTType"
                              ) {
                                return null;
                              }
                              if (
                                cell.VisibilityFlag === "Y" ||
                                (cell.DefaultValue &&
                                  cell.DefaultValue.Button === "Y" &&
                                  cell.ElementType === "D")
                              ) {
                                if (
                                  !(
                                    cell.Identifier === "Product" ||
                                    cell.Identifier === "ProductForeign" ||
                                    cell.Identifier === "ProductCode"
                                  )
                                ) {
                                  return (
                                    <TableCell key={cell.FieldId}>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Tooltip
                                          title="Apply to All"
                                          placement="bottom-start"
                                          disableInteractive
                                        >
                                          <ToggleButton
                                            value="check"
                                            selected={
                                              props.applyToAll[
                                                `${cell.FieldId}_applyToAll`
                                              ]
                                            }
                                            disabled={
                                              cell.Identifier ===
                                              "EstimatedValue"
                                            }
                                            // title="Apply to All"
                                            onClick={() => {
                                              //for not doing apply to all when field is read only
                                              let isReadOnly = false;
                                              if (!isReadOnly) {
                                                Object.keys(
                                                  props.mainTemplateQuotation
                                                ).map((key, i) => {
                                                  let element =
                                                    props.mainTemplateQuotation[
                                                      key
                                                    ];
                                                  Object.keys(element).map(
                                                    (key1, i) => {
                                                      if (
                                                        key1 == cell.FieldId &&
                                                        typeof element[key1] ===
                                                          "object" &&
                                                        element[key1].readOnly
                                                      ) {
                                                        isReadOnly = key1;
                                                      }
                                                    }
                                                  );
                                                });
                                              }
                                              if (
                                                !props.applyToAll[
                                                  `${cell.FieldId}_applyToAll`
                                                ] &&
                                                isReadOnly != cell.FieldId
                                              ) {
                                                props.handleApplyToAllClick(
                                                  cell.FieldId,
                                                  cell.ElementType === "TB" &&
                                                    cell.ElementType === "TA",
                                                  cell.Identifier
                                                );
                                              }
                                            }}
                                            sx={{
                                              p: 0,
                                              mr: 1,
                                              border: 0,
                                            }}
                                          >
                                            {!["T", "D", "L", "F"].includes(
                                              cell.ElementType
                                            ) ? (
                                              props.applyToAll[
                                                `${cell.FieldId}_applyToAll`
                                              ] ? (
                                                <CheckBoxIcon
                                                  sx={{
                                                    color: (theme) =>
                                                      theme.palette.primary
                                                        .main,
                                                  }}
                                                />
                                              ) : (
                                                <CheckBoxOutlineBlankIcon
                                                  sx={{
                                                    color: (theme) =>
                                                      theme.palette
                                                        .bluegrey[500],
                                                  }}
                                                />
                                              )
                                            ) : (
                                              ""
                                            )}
                                          </ToggleButton>
                                        </Tooltip>
                                        <Tooltip
                                          title={
                                            cell?.InstructionText === ""
                                              ? cell?.FieldLabel
                                              : cell?.InstructionText
                                          }
                                        >
                                          <Typography fontWeight={500}>
                                            {cell.FieldLabel}
                                          </Typography>
                                        </Tooltip>
                                        <ResizableBox
                                          axis="x"
                                          height={20}
                                          width={20}
                                          style={{
                                            textAlign: "left",
                                            width: "100%",
                                          }}
                                          minConstraints={[20, 20]}
                                        ></ResizableBox>
                                      </div>
                                    </TableCell>
                                  );
                                }
                              } else {
                                return "";
                              }
                            })}
                          </TableRow>
                        </TableHead>
                        {/* {getSecondTableBody()} */}
                      </Table>
                    </TableContainer>
                  </TableCell>
                </TableRow>
              )}
              {/* For Multiple Solution */}
              {props.multipleSolutionFlag &&
                !props.slabFlag &&
                props.multiSolmainTemplateQuotation[key] &&
                ToggleMS[key] &&
                getHeaders(
                  props.multiSolmainTemplateQuotation[key],
                  key,
                  value
                )}
              {/* For Slab */}
              {/* {(props.slabFlag && props.multipleSolutionFlag) &&
                props.slabMainTemplateQuotation[key] &&
                getHeadersForSlab(props.slabMainTemplateQuotation[key], key)} */}
            </>
          );
        }
      );
    } else {
      itemsToRender = "loading...";
    }

    return (
      <TableBody className="MultiSolution-Body">{itemsToRender}</TableBody>
    );
  };

  const getTableSlabBody = () => {
    let itemsToRender;
    const { SlabTableToggle } = state;

    if (props.selectedQuotedItems || props.selectedQuotedItems[0]) {
      const { slabMainTemplateQuotation } = props;
      // const { slabMainTemplateQuotation } = props;
      let filteredItems = {};
      if (props.itemSpecific) {
        filteredItems = Object.entries(props.selectedQuotedItems)
          .filter(([key, value]) =>
            props.inquiryItemsId.includes(parseInt(key))
          )
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});
      } else {
        filteredItems = props.selectedQuotedItems;
      }
      const filteredItemsMap = new Map(
        Object.entries(filteredItems).map((item) => [item[0], item[1]])
      );

      const reorderedTransactionItemSequence = props?.transactionItemSequence
        .filter((key) => filteredItemsMap.has(key)) // Filter keys that are present in map
        .map((key) => [key, filteredItemsMap.get(key)]);
      // itemsToRender = Object.entries(filteredItems)
      //   .sort(([, a], [, b]) => a.name.localeCompare(b.name))
      itemsToRender = reorderedTransactionItemSequence.map(
        ([key, value], index) => {
          const quantity = props.selectedQuotedItems[key]?.quantity;

          // Get the solutions array from lastTransactionData
          const solutions = slabMainTemplateQuotation[key] || [];

          // Initialize an array to store the indices of filtered solutions
          let filteredSolutionIndices = [];

          // Filter selected slabs first
          const selectedSlabs = solutions.filter(
            (solution) => solution.select_slab === "Y"
          );



          // First, try to find an exact match
          let filteredSolutions = solutions.filter((solution, index) => {
            if (solution.select_slab === "Y") {
              const range = solution?._range;

              // Exact match logic for "More Than"
              if (range && range.includes("More Than")) {
                const min = parseInt(range.split(" ")[2], 10);
                if (quantity === min) {
                  filteredSolutionIndices.push(index); // Store the index
                  return true; // Exact match for "More Than"
                }
              }
              // Exact match logic for range
              else if (range) {
                const [min, max] = range.split('-').map(Number);
                if (quantity >= min && quantity <= max) {
                  filteredSolutionIndices.push(index); // Store the index
                  return true; // Exact match for range
                }
              }
            }
            return false; // Default return false if no conditions are met
          });

          // If no exact match is found, check for the next nearest slab
          if (filteredSolutions.length === 0) {
            filteredSolutions = solutions.filter((solution, index) => {
              if (solution.select_slab === "Y") {
                const range = solution?._range;

                // Logic for the next nearest "More Than"
                if (range && range.includes("More Than")) {
                  const min = parseInt(range.split(" ")[2], 10);
                  if (quantity < min) {
                    filteredSolutionIndices.push(index); // Store the index
                    return true; // Ensure the quantity is less than min for "More Than"
                  }
                }
                // Logic for the next nearest range
                else if (range) {
                  const [min] = range.split('-').map(Number);
                  if (quantity < min) {
                    filteredSolutionIndices.push(index); // Store the index
                    return true; // Ensure the quantity is less than min for the selected ranges
                  }
                }
              }
              return false; // Default return false if no conditions are met
            });

            // Sort by ascending order to get the next available slab
            filteredSolutions = filteredSolutions.sort((a, b) => {
              const rangeA = a._range.includes("More Than")
                ? parseInt(a._range.split(" ")[2], 10)
                : parseInt(a._range.split('-')[0], 10);
              const rangeB = b._range.includes("More Than")
                ? parseInt(b._range.split(" ")[2], 10)
                : parseInt(b._range.split('-')[0], 10);
              return rangeA - rangeB; // Sort by ascending order
            });

            // Update the indices accordingly after sorting
            filteredSolutionIndices = filteredSolutionIndices.sort((a, b) => {
              const rangeA = solutions[a]._range.includes("More Than")
                ? parseInt(solutions[a]._range.split(" ")[2], 10)
                : parseInt(solutions[a]._range.split('-')[0], 10);
              const rangeB = solutions[b]._range.includes("More Than")
                ? parseInt(solutions[b]._range.split(" ")[2], 10)
                : parseInt(solutions[b]._range.split('-')[0], 10);
              return rangeA - rangeB;
            });

            // Select the first (smallest) valid slab that is greater than the quantity
            if (filteredSolutions.length > 0) {
              filteredSolutions = filteredSolutions.slice(0, 1);
              filteredSolutionIndices = [filteredSolutionIndices[0]];
            }
          }

          // If still no solutions found, ensure to return the solution with select_slab "Y"
          if (filteredSolutions.length === 0) {
            // Try to find the largest available range
            filteredSolutions = solutions
              .filter((solution, index) => {
                if (solution.select_slab === "Y") {
                  filteredSolutionIndices.push(index); // Store the index
                  return true;
                }
                return false;
              })
              .sort((a, b) => {
                const rangeA = a._range.includes("More Than")
                  ? parseInt(a._range.split(" ")[2], 10)
                  : parseInt(a._range.split('-')[0], 10);
                const rangeB = b._range.includes("More Than")
                  ? parseInt(b._range.split(" ")[2], 10)
                  : parseInt(b._range.split('-')[0], 10);
                return rangeA - rangeB; // Sort by ascending order
              });

            // Select the last (largest) range
            filteredSolutions = filteredSolutions.slice(-1);
            filteredSolutionIndices = [filteredSolutionIndices.slice(-1)[0]];
          }
          let hasError
          if (props.multiplePlantsFlag) {
            hasError =
              slabMainTemplateQuotation &&
              slabMainTemplateQuotation?.[key]?.some((item) => {
                if (slabMainTemplateQuotation?.[key].length > 1) {
                  return (
                    item.select_slab === "Y" &&
                    Object.values(item)?.some(
                      (field) =>
                        field?.error === true && field.Identifier != "GSTType"
                    )
                  );
                }
              });
          } else {
            hasError =
              slabMainTemplateQuotation &&
              slabMainTemplateQuotation?.[key]?.some((item) => {
                if (slabMainTemplateQuotation?.[key].length > 1) {
                  return (
                    item.select_slab === "Y" &&
                    Object.values(item)?.some((field) => field?.error === true)
                  );
                }
              });
          }
          

          return (
            <>
              <TableRow key={key}>
                <TableCell
                  sx={{
                    WebkitBoxShadow: {
                      md: state.isScrollActive && "11px 0 19px -2px #bebebe54",
                      sm: "none",
                    },
                    boxShadow: {
                      md: state.isScrollActive && "11px 0 19px -2px #bebebe54",
                      sm: "none",
                    },
                  }}
                >
                  <Box display="flex" alignItems="center" gap="16px">
                    <Checkbox
                      checked={
                        props?.slabMainTemplateQuotation[key]?.selected === "Y"
                      }
                      onChange={(e) => handleParentCheckboxChange(e, key)}
                      sx={{ "&.MuiCheckbox-root": { mr: 0, p: 0 } }}
                    />
                    {/* <div key={key}>
                            {(props.slabFlag && props.multipleSolutionFlag && solutions[0]?._range !== undefined) && (
                              <>
                                    {!iconState[key] ? (
                                       <Tooltip
                                       title={"Slab"}
                                       placement="bottom-start"
                                       disableInteractive
                                     >
                                      <Badge color="primary" badgeContent={solutions?.length}>
                                      <AddIcon
                                        onClick={(event) => handleAddClick(event, key)}
                                        style={{ cursor: 'pointer' }}
                                      />
                                      </Badge>
                                      </Tooltip>
                                    ) : (
                                      <>
                                  <Tooltip
                                    title={"Slab"}
                                    placement="bottom-start"
                                    disableInteractive
                                  >
                                      <Badge color="primary" badgeContent={solutions?.length}>
                                      <RemoveIcon
                                        onClick={(event) => handleRemoveClick(event, key)}
                                        style={{ cursor: 'pointer' }}
                                      />
                                      </Badge>
                                  </Tooltip>
                                    )}
                                  </>
                                )}
                            </div> */}

                    <div key={key}>
                      {props.slabFlag &&
                      props.multipleSolutionFlag &&
                      solutions[0]?._range !== undefined ? (
                        <>
                          {!iconState[key] ? (
                            <Tooltip
                              title={"Click here to View Slab"}
                              placement="bottom-start"
                              disableInteractive
                            >
                              <Badge
                                color="primary"
                                sx={{
                                  "& .MuiBadge-badge": {
                                    backgroundColor: "var(--secondary-500)",
                                  },
                                }}
                                badgeContent={solutions?.length}
                              >
                                <AddIcon
                                  onClick={(event) =>
                                    handleAddClick(event, key)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </Badge>
                            </Tooltip>
                          ) : (
                            <>
                              <Tooltip
                                title={"Click here to close Slab"}
                                placement="bottom-start"
                                disableInteractive
                              >
                                <Badge
                                  color="primary"
                                  sx={{
                                    "& .MuiBadge-badge": {
                                      backgroundColor: "var(--secondary-500)",
                                    },
                                  }}
                                  badgeContent={solutions?.length}
                                >
                                  <RemoveIcon
                                    onClick={(event) =>
                                      handleRemoveClick(event, key)
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </Badge>
                              </Tooltip>
                            </>
                          )}
                        </>
                      ) : (
                        <AddIcon style={{ visibility: "hidden" }} />
                      )}
                    </div>

                    <Tooltip // This is for Attachments specific files
                      title="Specification"
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Badge
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        badgeContent={`${value?.files?.length}`}
                        color="primary"
                      >
                        <AttachFileIcon
                          onClick={() =>
                            value?.files?.length > 0 &&
                            handleUplaodSpecificationOpen(
                              key,
                              value.files,
                              value.specification_text
                            )
                          }
                          sx={{ cursor: "pointer" }}
                        />
                      </Badge>
                    </Tooltip>
                    <Tooltip //Item name header starts from here
                      title={_.unescape(value.name)}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Badge
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        badgeContent={props.mainTemplateQuotation[key]?.added_after_rfq_created ? "New" : null}
                        color="primary"
                        sx={{ '& .MuiBadge-badge': { top:"-5px", height:"16px", fontSize:"10px" } }}
                      >
                        <Typography
                          noWrap
                          variant="body2"
                          color="text.primary"
                          fontWeight={500}
                          sx={{
                            width: {
                              lg: `${
                                state.itemCellWidth > 300
                                  ? state.itemCellWidth
                                  : 300
                              }px`,
                              md: `${
                                state.itemCellWidth > 200
                                  ? state.itemCellWidth
                                  : 200
                              }px`,
                              sm: `${
                                state.itemCellWidth > 200
                                  ? state.itemCellWidth
                                  : 200
                              }px`,
                              xs: `${
                                state.itemCellWidth > 125
                                  ? state.itemCellWidth
                                  : 125
                              }px`,
                            },
                            // width: `${
                            //   state.itemCellWidth > 350
                            //     ? state.itemCellWidth
                            //     : 350
                            // }px`,
                            marginLeft: "10px",
                          }}
                        >
                          {_.unescape(value.name)}
                        </Typography>
                      </Badge>
                      <Typography
                        sx={{ color: "error.main", marginLeft: "10px" }}
                      >
                        {props.mainTemplateQuotation[key]
                          ?.is_deleted_from_master &&
                          "This item is deleted from item master."}
                      </Typography>
                      <Typography sx={{ color: "red", marginLeft: "10px" }}>
                        {props.mainTemplateQuotation[key]
                          ?.is_unlink_from_category &&
                          "This item is unlinked from category."}
                      </Typography>
                      {props.multipleSolutionFlag &&
                        props.slabFlag &&
                        hasError &&
                        props?.slabMainTemplateQuotation[key]?.selected ===
                          "Y" && (
                          <Typography
                            sx={{ color: "error.main", marginLeft: "10px" }}
                          >
                            There are errors in slab range
                          </Typography>
                        )}
                      {/* {props.mainTemplateQuotation[key]
                        ?.added_after_rfq_created && (
                        <Chip
                          label="New"
                          size="small"
                          color="primary"
                          sx={{ marginLeft: "10px", height: "16px" }}
                        />
                      )} */}
                    </Tooltip>
                  </Box>
                </TableCell>
                {props.data[0]?.find(
                  (obj) =>
                    obj.Identifier === "ProductCode" &&
                    obj.VisibilityFlag === "Y"
                ) && (
                  <TableCell
                  // sx={{
                  //   WebkitBoxShadow:
                  //     state.isScrollActive && "11px 0 19px -4px #bebebe54",
                  //   boxShadow:
                  //     state.isScrollActive && "11px 0 19px -4px #bebebe54",
                  // }}
                  >
                    <Tooltip //Item code header starts from here
                      title={_.unescape(value.code)}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Typography
                        noWrap
                        variant="body2"
                        color="text.primary"
                        fontWeight={500}
                        // sx={{
                        //   width: {
                        //     lg: `${state.itemCellWidth > 300
                        //       ? state.itemCellWidth
                        //       : 250
                        //       }px`,
                        //     md: `${state.itemCellWidth > 200
                        //       ? state.itemCellWidth
                        //       : 200
                        //       }px`,
                        //     sm: `${state.itemCellWidth > 200
                        //       ? state.itemCellWidth
                        //       : 200
                        //       }px`,
                        //     xs: `${state.itemCellWidth > 125
                        //       ? state.itemCellWidth
                        //       : 125
                        //       }px`,
                        //   },
                        // }}
                      >
                        {_.unescape(value.code)}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                )}
                {props.multipleSolutionFlag && !props.slabFlag && (
                  <TableCell>
                    <Tooltip //Item code header starts from here
                      title={props.lastTransactionInThread[key]?.name}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="text"
                        disabled={
                          props.lastTransactionTypeInThread !== "Inquiry"
                        }
                        value={props.solutionNameMainTemplate[key]} // Use editable value from state or default value
                        onChange={(e) => handleInputChange(key, e)} // Pass key to identify which row is being updated
                      />
                    </Tooltip>
                  </TableCell>
                )}
                {props.slabFlag && props.multipleSolutionFlag && (
                  <TableCell>
                    <Tooltip //Item code header starts from here
                      title={solutions[key]?._range}
                      placement="bottom-start"
                      disableInteractive
                    >
                      {/* <Typography>{solutions[key]?._range}</Typography> */}
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="text"
                        disabled
                        // disabled={props.lastTransactionTypeInThread !== "Inquiry" || props.switchState[key]}
                        value={
                          filteredSolutions[0]?._range
                            ? filteredSolutions[0]?._range
                            : "N/A"
                        } // Use editable value from state or default value
                        // onChange={(e)=>handleInputChange(key,e)} // Pass key to identify which row is being updated
                      />
                    </Tooltip>
                  </TableCell>
                )}
                {props?.data[index]?.map((cell) => {
                  const DefaultFormula = cell.DefaultFormula;

                  // Regular expression to extract field IDs
                  const regex = /f(\d+)/g;
                  let match;
                  const fieldIDs = [];

                  // Extract field IDs from DefaultFormula
                  while ((match = regex.exec(DefaultFormula)) !== null) {
                    fieldIDs.push(match[1]);
                  }

                  // Assuming you always have two field IDs in the DefaultFormula
                  const [field1, field2] = fieldIDs;

                  // Initialize FieldState
                  const FieldState = {};

                  // If field IDs are found, set data in FieldState
                  if (field1 && field2) {
                    // Assuming num1 and num2 are the values you want to set
                    //  const num1 = /* Your value for field1 */;
                    //  const num2 = /* Your value for field2 */;
                    // Setting data in FieldState for both field1 and field2
                    //  const num1 = FieldState[field1];
                    //  const num2 = FieldState[field2];
                  }
                  // All I/P fields comes from there in the template section table
                  if (
                    cell.VisibilityFlag === "Y" ||
                    (cell.DefaultValue &&
                      cell.DefaultValue.Button === "Y" &&
                      cell.ElementType === "D")
                  ) {
                    if (
                      props.multiplePlantsFlag &&
                      cell.Identifier === "GSTType"
                    ) {
                      return null;
                    }
                    if (
                      !(
                        cell.Identifier === "Product" ||
                        cell.Identifier === "ProductCode" ||
                        cell.Identifier === "ProductForeign"
                      )
                    ) {
                      return (
                        <TableCell
                          key={cell.FieldId}
                          // sx={{ minWidth: "120px" }}
                        >
                          <QuotationFormField
                            batch_id={props.batch_id}
                            LATEST={props.LATEST}
                            vendorType={props?.vendorType}
                            // upd_budget_master={
                            //   props.mainTemplateQuotation[item.id] &&
                            //   props.mainTemplateQuotation[item.id][cell?.FieldId] &&
                            //   props.mainTemplateQuotation[item.id][cell?.FieldId][
                            //     `upd_budget_master_${item.id}`
                            //   ]
                            // }
                            // qty={
                            //   props.mainTemplateQuotation[item.id] &&
                            //   props.mainTemplateQuotation[item.id]["qty"]
                            // }
                            // rate={
                            //   props.mainTemplateQuotation[item.id] &&
                            //   props.mainTemplateQuotation[item.id]["rate"]
                            // }
                            // readOnly={
                            //   props.mainTemplateQuotation[item.id]?.[cell?.FieldId]
                            //     ?.readOnly
                            // }
                            cell={cell}
                            item={value}
                            index={index}
                            // FieldState={
                            //   props.selectedQuotedItems.vendors[0]
                            //     .transaction_thread[0].template.itemize[key]
                            //     .solution[0] &&
                            //   (props.selectedQuotedItems.vendors[0]
                            //     .transaction_thread[0].template.itemize[key]
                            //     .solution[0].data[0][field1] ||
                            //     props.selectedQuotedItems.vendors[0]
                            //       .transaction_thread[0].template.itemize[key]
                            //       .solution[0].data[0][field2])
                            // }
                            //Important: there I'll be sending the mainTemplateQuotation doing this just for the static data
                            // props.selectedQuotedItems
                            //   .transaction_thread[0].template.itemize[
                            //   item.id
                            // ][cell.FieldId].value}
                            // FieldState={
                            //   mainTemplateQuotation[key] &&
                            //   mainTemplateQuotation[key][cell?.FieldId]
                            // }
                            // FieldState={
                            //   filteredSolutions[key] &&
                            //   filteredSolutions[key][cell?.FieldId]
                            // }
                            // FieldState={filteredSolutions[0][cell?.FieldId]}
                            FieldState={
                              solutions[0]?._range !== undefined
                                ? filteredSolutions?.[0]?.[cell?.FieldId] ??
                                  undefined
                                : solutions?.[0]?.[cell?.FieldId] ?? undefined
                            }
                            base_currency={props?.base_currency}
                            selectedCurrency={
                              props.lastTransactionTypeInThread === "Inquiry" &&
                              (props.vendorType === "International" ||
                                props.vendorType === "Local Importer")
                                ? props?.selectedCurrency
                                : props?.selectedCurrency ||
                                  props.quotedCurrency
                              // props.lastTransactionTypeInThread === "Inquiry" &&
                              // (props.vendorType === "International" ||
                              //   props.vendorType === "Local Importer")
                              //   ? props.selectedCurrency
                              //   : Object.values(
                              //       props.lastTransactionInThread
                              //     )[0].solution[0]?.currency
                            }
                            // handleUpdateInMasterClick={
                            //   props.handleUpdateInMasterClick
                            // }
                            updateDeliveryScheduleFieldValueSlab={
                              props.updateDeliveryScheduleFieldValueSlab
                            }
                            slabFlag={props.slabFlag}
                            multipleSolutionFlag={props.multipleSolutionFlag}
                            handleChange={(
                              evt,
                              FieldId,
                              Identifier,
                              FieldLabel,
                              // ElementType,
                              key,
                              index
                            ) =>
                              props.handleWithOutSlabChange(
                                evt,
                                FieldId,
                                Identifier,
                                FieldLabel,
                                // ElementType,
                                key,
                                index
                              )
                            }
                            handleDateChange={(newVal, FieldId, Validation) =>
                              props.handleDateWithOutSlabChange(
                                newVal,
                                FieldId,
                                "slabMainTemplateQuotation",
                                index,
                                Validation,
                                key
                              )
                            }
                            handleUploadFiles={(
                              FieldId,
                              Identifier,
                              itemId,
                              index
                            ) => {
                              props.uploadItemFiles(
                                FieldId,
                                Identifier,
                                itemId,
                                index,
                                "slabMainTemplateQuotation"
                              );
                            }}
                            default_dropdown_type={props.default_dropdown_type}
                            destination={props.destination}
                            SlabTableToggle={SlabTableToggle}
                            // disabled={
                            //   Object.keys(disabledProps).length > 0 &&
                            //   disabledProps[key]
                            // }
                            disabled={
                              solutions[0]?._range !== undefined ? true : false
                            }
                            // readOnlyForSwitch={switchDisabledProps[key]}
                            readOnlyForSwitch={disabledPropsForSlabParent[key]} //Please re-check condition
                            keys={key.length > 0 && key}
                            handleDetailsClick={handleDetailsClick}
                            // rowIndex={filteredSolutionIndices === undefined ? filteredSolutionIndices:0}
                            rowIndex={
                              solutions[0]?._range !== undefined
                                ? filteredSolutionIndices
                                : 0
                            }
                            handleDetailsClickForSlab={
                              handleDetailsClickForSlab
                            }
                            mainTemplateQuotation={props.mainTemplateQuotation}
                            multiplePlantsFlag={props.multiplePlantsFlag}
                          />
                        </TableCell>
                      );
                    }
                  }
                })}
              </TableRow>
              {/* For Slab */}
              {props.slabFlag &&
                props.multipleSolutionFlag &&
                solutions[0]?._range !== undefined &&
                Toggle[key] &&
                props.slabMainTemplateQuotation[key] &&
                getHeadersForSlab(
                  props.slabMainTemplateQuotation[key],
                  key,
                  value
                )}
            </>
          );
        }
      );
    } else {
      itemsToRender = "loading...";
    }

    return <TableBody className="SlabRange-Body">{itemsToRender}</TableBody>;
  };

  const LandedCostRFQOpen = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (AllItemsTemplate.length > 0) {
      let totalCost = AllItemsTemplate.find((items) => {
        return (
          items.DefaultValue?.Button == "Y" || items.Identifier === "TotalCost"
        );
      });
      let rateCell = AllItemsTemplate.find((items) => {
        return (
          (items.Identifier === "EstimatedCost" ||
            items.Identifier === "EstimatedCostForeign" ||
            items.Identifier == "Budgetedrate" ||
            items.Identifier === "Rate" ||
            items.Identifier === "CIFRate" ||
            items.Identifier == "rate") &&
          items.ElementType === "D"
        );
      });
      let PerCost = AllItemsTemplate.find((items) => {
        return items.Identifier === "PER";
      });
      setPerCost(PerCost);
      setTotalCostFIeld(totalCost);
      setRateFIeld(rateCell);
    }
    setLandedCostRFQ({ ...landedCostRFQ, [anchor]: open });
  };

  const LandedCostSlabOpen = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (AllItemsTemplate.length > 0) {
      let totalCost = AllItemsTemplate.find((items) => {
        return (
          items.DefaultValue?.Button == "Y" || items.Identifier === "TotalCost"
        );
      });
      let rateCell = AllItemsTemplate.find((items) => {
        return (
          (items.Identifier === "EstimatedCost" ||
            items.Identifier === "EstimatedCostForeign" ||
            items.Identifier == "Budgetedrate" ||
            items.Identifier === "Rate" ||
            items.Identifier === "CIFRate" ||
            items.Identifier == "rate") &&
          items.ElementType === "D"
        );
      });
      let PerCost = AllItemsTemplate.find((items) => {
        return items.Identifier === "PER";
      });
      setPerCost(PerCost);
      setTotalCostFIeld(totalCost);
      setRateFIeld(rateCell);
    }
    setLandedCostSlab({ ...landedCostSlab, [anchor]: open });
  };

  function isInErrorArray(attributeName) {
    return props.errorArray.includes(attributeName);
  }

  function isInErrorArrayForMS(key, attributeName) {
    // Check if the key exists and if the array for the "0" key exists as well
    if (props.errorArrayForMS[key] && Array.isArray(props.errorArrayForMS[key][0])) {
      // Check if the attributeName exists in the array
      return props.errorArrayForMS[key][0].includes(attributeName);
    }

    return false; // Return false if the key or the array doesn't exist
  }


  // Function to render the error message based on condition
  // Function to render the error message based on condition
  function renderErrorMessage(attribute, identifier, errorDisplayedFlag) {
    if (
      identifier === "GSTType" &&
      isInErrorArray(attribute) &&
      !errorDisplayedFlag.errorDisplayed
    ) {
      errorDisplayedFlag.errorDisplayed = true; // Set the flag to true once the error is displayed
      return (
        <Alert variant="text" sx={{ color: "error.main", p: 0 }} severity="error">Please Select GST Transition Type.</Alert>

        // <Typography color={"error.main"} key={attribute}>
        //   {" "}
        //   {/* Added a key for list rendering */}
        //   <ErrorOutlineIcon />
        //   Please Select GST Transition Type
        // </Typography>
      );
    }
    return null; // Return null if no error condition is met or if error is already displayed
  }

  function renderErrorMessageForMS(attribute, identifier, errorDisplayedFlag, key) {
    if (
      identifier === "GSTType" &&
      isInErrorArrayForMS(key, attribute) &&
      !errorDisplayedFlag.errorDisplayed
    ) {
      errorDisplayedFlag.errorDisplayed = true; // Set the flag to true once the error is displayed
      return (
        <Alert variant="text" sx={{ color: "error.main", p: 0 }} severity="error">Please Select GST Transition Type.</Alert>
      );
    }
    return null; // Return null if no error condition is met or if error is already displayed
  }

  const getHeaders = (solutions, key, value) => {

    if (!props.switchState[key] && props.lastTransactionTypeInThread === "Inquiry") {
      return null;
    }

    return (
      <TableRow>
        <TableCell
          sx={{
            ".quotation_template_table .MuiTableBody-root &.MuiTableCell-root": {
              backgroundColor: "var(--grey-50)",
              padding: scrolledLeft
                ? '0'
                : {
                  lg: '0 0 0 526px',
                  md: '0 0 0 426px',
                  sm: '0 0 0 426px',
                  xs: '0 0 0 406px',
                },
            }
          }}
          colSpan={100}
        >
          <Table
            className="MultiSolution-Table SlabDetailsForm"
            sx={{
              "&.MultiSolution-Table tr td:first-of-type, &.MultiSolution-Table tr th:first-of-type":
              {
                borderLeft: scrolledLeft ? '0' : '1px solid var(--bluegrey-500)',
                width: {
                  lg: "526px",
                  md: "426px",
                  sm: "426px",
                  xs: "426px",
                },
                position: {
                  md: "sticky",
                  sm: "static",
                  xs: "static",
                },
                left: "0",
                boxShadow: {
                  md: scrolledLeft ? 'inset -1px 0px 0 #a6c1d3' : 'none',
                  sm: "none",
                },
              },
              "&.MultiSolution-Table .MuiTableBody-root tr:last-of-type td": {
                borderBottom: "none",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell key={"M2"} align="left">
                  <Tooltip title="MULTIPLE SOLUTION" placement="bottom-start">
                    <Typography
                      variant="body2"
                      color={"grey.600"}
                      fontWeight={500}
                    >
                      MULTIPLE SOLUTION{" "}
                      <span style={{ color: "error.main" }}>*</span>
                    </Typography>
                  </Tooltip>
                </TableCell>
                {scrolledLeft &&
                  <>
                    <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                    <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                  </>
                }
                {props.data[0]?.map((cell, index) => {
                  if (
                    props.multiplePlantsFlag === true &&
                    cell.Identifier === "GSTType"
                  ) {
                    return null;
                  }
                  if (
                    props.vendorType === "Local" ||
                      props.vendorType === "International" ||
                      props.vendorType === "Local Importer"
                      ? cell.VisibilityFlag === "Y" ||
                      (cell.DefaultValue &&
                        cell.DefaultValue.Button === "Y" &&
                        cell.ElementType === "D")
                      : cell.VisibilityFlag === "Y"
                  ) {
                    if (
                      !(
                        cell.Identifier === "Product" ||
                        cell.Identifier === "ProductForeign" ||
                        cell.Identifier === "ProductCode"
                      )
                    ) {
                      return (
                        <TableCell key={cell.FieldId}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip
                              title="Apply to All"
                              placement="bottom-start"
                              disableInteractive
                            >
                              <ToggleButton
                                value="check"
                                selected={
                                  props.multiSolApplyToAll[
                                  `${key}_${cell.FieldId}_multiSolApplyToAll`
                                  ]
                                }
                                disabled={cell.Identifier === "EstimatedValue"}
                                onClick={() => {
                                  let isReadOnly = false;
                                  if (!isReadOnly) {
                                    Object.keys(
                                      props.mainTemplateQuotation
                                    ).forEach((key) => {
                                      const element =
                                        props.mainTemplateQuotation[key];
                                      Object.keys(element).forEach((key1) => {
                                        if (
                                          key1 === cell.FieldId &&
                                          typeof element[key1] === "object" &&
                                          element[key1].readOnly
                                        ) {
                                          isReadOnly = key1;
                                        }
                                      });
                                    });
                                  }
                                  if (
                                    !props.multiSolApplyToAll[
                                    `${key}_${cell.FieldId}_multiSolApplyToAll`
                                    ] &&
                                    isReadOnly !== cell.FieldId
                                  ) {
                                    props.handleMSApplyToAllClick(
                                      key,
                                      cell.FieldId,
                                      cell.ElementType === "TB" &&
                                      cell.ElementType === "TA",
                                      cell.Identifier
                                    );
                                  }
                                }}
                                sx={{
                                  p: 0,
                                  mr: 1,
                                  border: 0,
                                }}
                              >
                                {!["T", "D", "L", "F"].includes(cell.ElementType) ? (
                                  props.multiSolApplyToAll[
                                    `${key}_${cell.FieldId}_multiSolApplyToAll`
                                  ] ? (
                                    <CheckBoxIcon
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.primary.main,
                                      }}
                                    />
                                  ) : (
                                    <CheckBoxOutlineBlankIcon
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.bluegrey[500],
                                      }}
                                    />
                                  )
                                ) : (
                                  ""
                                )}
                              </ToggleButton>
                            </Tooltip>
                            <Tooltip
                              title={
                                cell?.InstructionText === ""
                                  ? cell?.FieldLabel
                                  : cell?.InstructionText
                              }
                            >
                              <Typography fontWeight={500}>
                                {cell.FieldLabel}
                              </Typography>
                            </Tooltip>
                            <ResizableBox
                              axis="x"
                              height={20}
                              width={20}
                              style={{
                                textAlign: "left",
                                width: "100%",
                              }}
                              minConstraints={[20, 20]}
                            ></ResizableBox>
                          </div>
                        </TableCell>
                      );
                    }
                  } else {
                    return "";
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {solutions?.map((solution, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Box display="flex" gap="8px">
                      <Box sx={{ width: "106px" }}>
                        {index === solutions.length - 1 ? (
                          <IconButton
                            color="primary"
                            aria-label="add"
                            onClick={() => {
                              addRow(key, index, solutions);
                            }}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            color="primary"
                            aria-label="add"
                            style={{ visibility: "hidden" }}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        )}
                        {(index >= 2 && index >= props.lastTransactionInThread[key].solution?.length) ? (
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              openModalDelete(index, key, solutions);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label="delete"
                            style={{ visibility: "hidden" }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Box>
                      <Tooltip
                        title={solution.name}
                        placement="bottom-start"
                        disableInteractive
                      >
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          type="text"
                          value={_.unescape(props.solutionNames[`${key}-${index}`]) || ""}

                          // value={props.solutionNames[`${key}-${index}`] !== "" ? props.solutionNames[`${key}-${index}`] : props.solutionNameMainTemplate[key]}
                          onChange={(e) =>
                            props.handleSolutionNameChange(key, index, e)
                          }
                          error={!!props.solutionNameErrors[`${key}-${index}`]}
                          // disabled={
                          // (props.lastTransactionTypeInThread !== "Inquiry" && props.switchStateToggleCheck[key] &&
                          //   props.lastTransactionInThread[key].solution?.[index]?.hasOwnProperty("name"))}
                          disabled={
                            (Object.keys(disabledProps).length > 0 &&
                              disabledProps[key]) ||
                            (props.lastTransactionTypeInThread !== "Inquiry" &&
                              // props.switchStateToggleCheck[key] &&
                              props.switchState[key] &&
                              props.lastTransactionInThread[key]?.solution?.[index]?.hasOwnProperty("name") &&
                              props.lastTransactionInThread[key].solution?.[index]?.name !== "" &&
                              props.lastTransactionInThread[key].solution?.[index]?.name !== null)
                          }
                          helperText={
                            <span style={{ color: "red" }}>
                              {props.solutionNameErrors[`${key}-${index}`]}
                            </span>
                          }
                        />
                      </Tooltip>
                    </Box>
                  </TableCell>
                  {scrolledLeft &&
                    <>
                      <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                      <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                    </>
                  }
                  <TableCell sx={{ display: "none" }}>
                    <Tooltip
                      title={solution.name}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Typography noWrap>{solution.name}</Typography>
                    </Tooltip>
                  </TableCell>
                  {props?.data[index]?.map((cell) => {
                    const DefaultFormula = cell.DefaultFormula;
                    const regex = /f(\d+)/g;
                    let match;
                    const fieldIDs = [];

                    while ((match = regex.exec(DefaultFormula)) !== null) {
                      fieldIDs.push(match[1]);
                    }

                    const [field1, field2] = fieldIDs;
                    const FieldState = {};

                    if (field1 && field2) {
                    }

                    if (
                      cell.VisibilityFlag === "Y" ||
                      (cell.DefaultValue &&
                        cell.DefaultValue.Button === "Y" &&
                        cell.ElementType === "D")
                    ) {
                      if (
                        props.multiplePlantsFlag &&
                        cell.Identifier === "GSTType"
                      ) {
                        return null;
                      }
                      if (
                        !(
                          cell.Identifier === "Product" ||
                          cell.Identifier === "ProductCode" ||
                          cell.Identifier === "ProductForeign"
                        )
                      ) {
                        return (
                          <TableCell key={cell.FieldId}>
                            <QuotationFormField
                              batch_id={props.batch_id}
                              LATEST={props.LATEST}
                              cell={cell}
                              index={index}
                              item={value}
                              vendorType={props?.vendorType}
                              FieldState={solution?.[cell?.FieldId]}
                              base_currency={props?.base_currency}
                              selectedCurrency={
                                props?.selectedCurrency || props.quotedCurrency
                              }
                              handleChange={(
                                evt,
                                FieldId,
                                Identifier,
                                FieldLabel,
                                key,
                                index
                              ) => {
                                props.handleMSChange(
                                  evt,
                                  FieldId,
                                  Identifier,
                                  FieldLabel,
                                  key,
                                  index
                                );
                              }}
                              handleDateChange={(newVal, FieldId, Validation) =>
                                props.handleDateMSChange(
                                  newVal,
                                  FieldId,
                                  "multiSolmainTemplateQuotation",
                                  index,
                                  Validation,
                                  key
                                )
                              }
                              //Added for ms dev work remove in future
                              // slabMainTemplateQuotation={props.slabMainTemplateQuotation}
                              // additionalInfoTemplate={props.additionalInfoTemplate}
                              // totalreqqty={props.totalreqqty}
                              // totalreqqtyFor={props.totalreqqtyFor}
                              // availableQtyForTotalReq={
                              //   props.availableQtyForTotalReq
                              // }
                              // arrSlabValues={props.arrSlabValues}
                              // arrangedEvalForSlab={props.arrangedEvalForSlab}
                              // state={props.state}
                              //Change
                              disabled={
                                (Object.keys(disabledProps).length > 0 &&
                                  disabledProps[key])
                              }
                              // disabled={
                              //   props.multipleSolutionFlag && !props.slabFlag ?
                              //   (Object.keys(disabledPropsForMS).length >
                              //   0 && disabledPropsForMS[key][index]): (Object.keys(disabledProps).length > 0 &&
                              //   disabledProps[key] 
                              // )}
                              readOnlyForSwitch={
                                disabledPropsForMS[key][index]
                              }
                              updateDeliveryScheduleFieldValue={
                                props.updateDeliveryScheduleFieldValue
                              }
                              default_dropdown_type={
                                props.default_dropdown_type
                              }
                              destination={props.destination}
                              rowIndex={index}
                              keys={key.length > 0 && key}
                              handleDetailsClick={handleDetailsClick}
                              multiplePlantsFlag={props.multiplePlantsFlag}
                              multipleSolutionFlag={props.multipleSolutionFlag}
                              slabFlag={props.slabFlag}
                              switchState={props.switchState}
                              handleDetailsClickForMS={handleDetailsClickForMS}
                              handleDetailsClickForSlab={
                                handleDetailsClickForSlab
                              }
                              DetailsClickFlag={true}
                            />
                          </TableCell>
                        );
                      }
                    } else {
                      return "";
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    );
  };

  const getHeadersForSlab = (solutions, key, itemValue) => {
    return (
      <TableRow>
        <TableCell
          sx={{
            ".quotation_template_table .MuiTableBody-root &.MuiTableCell-root": {
              backgroundColor: "var(--grey-50)",
              padding: scrolledLeft
                ? '0'
                : {
                  lg: '0 0 0 450px',
                  md: '0 0 0 406px',
                  sm: '0 0 0 406px',
                  xs: '0 0 0 406px',
                },
            }
          }}
          colSpan={100}
        >
          <Table
            className="MultiSolution-Table SlabDetailsForm"
            sx={{
              "&.MultiSolution-Table tr td:first-of-type, &.MultiSolution-Table tr th:first-of-type":
              {
                borderLeft: scrolledLeft ? '0' : '1px solid var(--bluegrey-500)',
                width: {
                  lg: "450px",
                  md: "406px",
                  sm: "406px",
                  xs: "406px",
                },
                position: {
                  md: "sticky",
                  sm: "static",
                  xs: "static",
                },
                left: "0",
                boxShadow: {
                  md: scrolledLeft ? 'inset -1px 0px 0 #a6c1d3' : 'none',
                  sm: "none",
                },
              },
              "&.MultiSolution-Table .MuiTableBody-root tr:last-of-type td": {
                borderBottom: "none",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell key={"M2"} align="left">
                  <Tooltip title="SLAB RANGE" placement="bottom-start">
                    <Typography
                      variant="body2"
                      color={"grey.600"}
                      fontWeight={500}
                    >
                      Slab Range
                    </Typography>
                  </Tooltip>
                </TableCell>
                {scrolledLeft &&
                  <>
                    <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                    <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                  </>
                }

                {props.data[0]?.map((cell, index) => {
                  if (
                    props.multiplePlantsFlag === true &&
                    cell.Identifier === "GSTType"
                  ) {
                    return null;
                  }
                  if (
                    props.vendorType === "Local" ||
                      props.vendorType === "International" ||
                      props.vendorType === "Local Importer"
                      ? cell.VisibilityFlag === "Y" ||
                      (cell.DefaultValue &&
                        cell.DefaultValue.Button === "Y" &&
                        cell.ElementType === "D")
                      : cell.VisibilityFlag === "Y"
                  ) {
                    if (
                      !(
                        cell.Identifier === "Product" ||
                        cell.Identifier === "ProductForeign" ||
                        cell.Identifier === "ProductCode"
                      )
                    ) {
                      return (
                        <TableCell key={cell.FieldId}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip
                              title="Apply to All Slab"
                              placement="bottom-start"
                              disableInteractive
                            >
                              <ToggleButton
                                value="check"
                                selected={
                                  props.slabApplyToAll[
                                  `${key}_${cell.FieldId}_slabApplyToAll`
                                  ]
                                }
                                disabled={
                                  cell.Identifier === "EstimatedValue"
                                }
                                title="Apply to All Slab"
                                onClick={() => {
                                  //for not doing apply to all when field is read only
                                  let isReadOnly = false;
                                  if (!isReadOnly) {
                                    Object.keys(
                                      props.mainTemplateQuotation
                                    ).map((key, i) => {
                                      let element =
                                        props.mainTemplateQuotation[key];
                                      Object.keys(element).map((key1, i) => {
                                        if (
                                          key1 == cell.FieldId &&
                                          typeof element[key1] === "object" &&
                                          element[key1].readOnly
                                        ) {
                                          isReadOnly = key1;
                                        }
                                      });
                                    });
                                  }
                                  if (
                                    !props.slabApplyToAll[
                                    `${key}_${cell.FieldId}_slabApplyToAll`
                                    ] &&
                                    isReadOnly != cell.FieldId
                                  ) {
                                    props.handleSlabApplyToAllClick(
                                      key,
                                      cell.FieldId,
                                      cell.ElementType === "TB" &&
                                      cell.ElementType === "TA",
                                      cell.Identifier
                                    );
                                  }
                                }}
                                sx={{
                                  p: 0,
                                  mr: 1,
                                  border: 0,
                                }}
                              >
                                {!["T", "D", "L", "F"].includes(
                                  cell.ElementType
                                ) ? (
                                  props.slabApplyToAll[
                                    `${key}_${cell.FieldId}_slabApplyToAll`
                                  ] ? (
                                    <CheckBoxIcon
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.primary.main,
                                      }}
                                    />
                                  ) : (
                                    <CheckBoxOutlineBlankIcon
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.bluegrey[500],
                                      }}
                                    />
                                  )
                                ) : (
                                  ""
                                )}
                              </ToggleButton>
                            </Tooltip>
                            <Tooltip
                              title={
                                cell?.InstructionText === ""
                                  ? cell?.FieldLabel
                                  : cell?.InstructionText
                              }
                            >
                              <Typography fontWeight={500}>
                                {cell.FieldLabel}
                              </Typography>
                            </Tooltip>
                            <ResizableBox
                              axis="x"
                              height={20}
                              width={20}
                              style={{
                                textAlign: "left",
                                width: "100%",
                              }}
                              minConstraints={[20, 20]}
                            ></ResizableBox>
                          </div>
                        </TableCell>
                      );
                    }
                  } else {
                    return "";
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {solutions?.map((solution, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Checkbox
                        // checked={solution.select_slab==="Y"?true:false}
                        // onChange={(event) => this.handleQuotationCheckboxChange(event)}
                        // checked={checkboxState[key]?.children[solution._range] || false}
                        checked={solution?.select_slab === "Y" || false}
                        onChange={(event) =>
                          handleChildCheckboxChange(
                            event,
                            key,
                            index,
                            solution._range
                          )
                        }
                        color="primary"
                      />
                      <Tooltip
                        title={solution._range}
                        placement="bottom-start"
                        disableInteractive
                      >
                        <Typography
                          fontWeight={500}
                          noWrap
                          sx={{
                            width: {
                              lg: scrolledLeft ? '394px' : '302px',
                              md: scrolledLeft ? '350px' : '260px',
                              sm: scrolledLeft ? '350px' : '260px',
                            }
                          }}
                        >
                          {solution._range}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  {scrolledLeft &&
                    <>
                      <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                      <TableCell id="Empty-TableCell">&nbsp;</TableCell>
                    </>
                  }
                  {props?.data[index]?.map((cell) => {
                    const DefaultFormula = cell.DefaultFormula;
                    const regex = /f(\d+)/g;
                    let match;
                    const fieldIDs = [];

                    while ((match = regex.exec(DefaultFormula)) !== null) {
                      fieldIDs.push(match[1]);
                    }

                    const [field1, field2] = fieldIDs;
                    const FieldState = {};

                    if (field1 && field2) {
                    }

                    if (
                      cell.VisibilityFlag === "Y" ||
                      (cell.DefaultValue &&
                        cell.DefaultValue.Button === "Y" &&
                        cell.ElementType === "D")
                    ) {
                      if (
                        props.multiplePlantsFlag &&
                        cell.Identifier === "GSTType"
                      ) {
                        return null;
                      }
                      if (
                        !(
                          cell.Identifier === "Product" ||
                          cell.Identifier === "ProductCode" ||
                          cell.Identifier === "ProductForeign"
                        )
                      ) {
                        return (
                          <TableCell key={cell.FieldId}>
                            <QuotationFormField
                              batch_id={props.batch_id}
                              LATEST={props.LATEST}
                              cell={cell}
                              index={index}
                              item={itemValue}
                              vendorType={props?.vendorType}
                              FieldState={solution?.[cell?.FieldId]}
                              base_currency={props?.base_currency}
                              selectedCurrency={
                                props.lastTransactionTypeInThread === "Inquiry" &&
                                  (props.vendorType === "International" ||
                                    props.vendorType === "Local Importer")
                                  ? props?.selectedCurrency
                                  : props?.selectedCurrency ||
                                  props.quotedCurrency
                                // props.lastTransactionTypeInThread === "Inquiry" &&
                                // (props.vendorType === "International" ||
                                //   props.vendorType === "Local Importer")
                                //   ? props.selectedCurrency
                                //   : Object.values(
                                //       props.lastTransactionInThread
                                //     )[0].solution[0]?.currency
                              }
                              handleChange={(
                                evt,
                                FieldId,
                                Identifier,
                                FieldLabel,
                                // ElementType,
                                key,
                                index
                              ) => {
                                props.handleSlabChange(
                                  evt,
                                  FieldId,
                                  Identifier,
                                  FieldLabel,
                                  // ElementType,
                                  key,
                                  index
                                );
                              }}
                              handleDateChange={(newVal, FieldId, Validation) => {
                                props.handleDateSlabChange(
                                  newVal,
                                  FieldId,
                                  "slabMainTemplateQuotation",
                                  index,
                                  Validation,
                                  key
                                )
                              }
                              }
                              handleUploadFiles={(
                                FieldId,
                                Identifier,
                                itemId,
                                index
                              ) => {
                                props.uploadItemFiles(
                                  FieldId,
                                  Identifier,
                                  itemId,
                                  index,
                                  "slabMainTemplateQuotation"
                                );
                              }}
                              disabled={
                                Object.keys(disabledPropsForSlabChild).length >
                                0 && disabledPropsForSlabChild[key][index]
                              }
                              // disabled={
                              //   Object.keys(props.disabledRows).length > 0 &&
                              //   props.disabledRows[`${key}-${solution._range}`]
                              // }
                              // disabled={solution?.select_slab !== "Y"? true : false}
                              readOnlyForSwitch={
                                disabledPropsForSlabChild[key][index]
                              }
                              // disabled={disabledRows[key]}
                              // disabled={disabledRows[`${key}-${solution._range}`] && disabledRows[`${key}-${solution._range}`]}
                              updateDeliveryScheduleFieldValueSlab={
                                props.updateDeliveryScheduleFieldValueSlab
                              }
                              default_dropdown_type={
                                props.default_dropdown_type
                              }
                              rowIndex={index}
                              keys={key.length > 0 && key}
                              handleDetailsClick={handleDetailsClick}
                              handleDetailsClickForSlab={
                                handleDetailsClickForSlab
                              }
                              multiplePlantsFlag={props.multiplePlantsFlag}
                              multipleSolutionFlag={props.multipleSolutionFlag}
                              slabFlag={props.slabFlag}
                              switchState={props.switchState[key]}
                              destination={props.destination}
                            />
                          </TableCell>
                        );
                      }
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    );
  };

  // Start table duplicate synchronize scrollbar script
  const containerRef = useRef(null);
  const duplicateScrollbarRef = useRef(null);
  const [isVisibleScrollbar, setIsVisibleScrollbar] = useState(false);

  const handleScrollSecond = (scroll) => {
    containerRef.current.scrollLeft = scroll.target.scrollLeft;
  };

  useEffect(() => {
    const container = containerRef.current;
    const duplicateScrollbar = duplicateScrollbarRef.current;
    const boxElement = document.getElementById('CardAdditional');

    const handleScroll = () => {
      duplicateScrollbar.scrollLeft = container.scrollLeft;

      const scrollSection = document.getElementById('ScrollAreaSection');
      if (!scrollSection) return;

      const sectionTop = scrollSection.getBoundingClientRect().top;
      const sectionBottom = scrollSection.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      // Check if cardAdditional is within the viewport
      const boxTop = boxElement.getBoundingClientRect().top;
      const boxBottom = boxElement.getBoundingClientRect().bottom;

      // If cardAdditional is in the viewport, set isVisible to false
      if (boxTop < windowHeight && boxBottom > 0) {
        setIsVisibleScrollbar(false);
        return;
      }

      // Otherwise, check if the ScrollAreaSection is in the viewport
      if (sectionTop < windowHeight && sectionBottom > 100) {
        setIsVisibleScrollbar(true);
      } else {
        setIsVisibleScrollbar(false);
      }
    };

    container.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // End table duplicate synchronize scrollbar script

  // Start left scrollbar tablecell show hide & first child sticky script
  const [scrolledLeft, setScrolledLeft] = useState(false);

  // Function to handle scroll event
  const tableHandleScroll = () => {
    if (containerRef.current) {
      // Check if the scroll position is greater than 0
      setScrolledLeft(containerRef.current.scrollLeft > 400);
    }
  };

  useEffect(() => {
    const tableScroll = containerRef.current;
    if (tableScroll) {
      tableScroll.addEventListener('scroll', tableHandleScroll);
      return () => {
        tableScroll.removeEventListener('scroll', tableHandleScroll);
      };
    }
  }, []);
  // End left scrollbar tablecell show hide & first child sticky script

  return (
    <>
      <Card variant="outlined" sx={{ mb: 2, mt: 3 }} id="Template">
        <CardHeader
          title={
            <Typography variant="subtitle2" component="h5" color={"grey.900"}>
              TEMPLATE
            </Typography>
          }
          action={
            props.multiplePlantsFlag && (
              <Box display="flex" gap="8px">
                {(() => {
                  let gstTypeCell = AllItemsTemplate.find((item) => {
                    return item.Identifier == "GSTType";
                  });
                  let errorDisplayedFlag = { errorDisplayed: false }; // Initialize the error display flag for the whole iteration
                  if (gstTypeCell?.HTMLAttributeName.includes("_")) {
                    // if (!props.multipleSolutionFlag && !props.slabFlag) {
                    //   return Object.entries(props.multiSolmainTemplateQuotation).map(
                    //     ([key, item]) => {
                    //       const attributes = Object.values(item[0]);
                    //       return (
                    //         <div key={key}>
                    //           {/* Assuming formdetailRowId is unique */}
                    //           {attributes.map((attribute) =>
                    //             renderErrorMessageForMS(
                    //               attribute?.HTMLAttributeName,
                    //               attribute?.Identifier,
                    //               errorDisplayedFlag,
                    //               key // Passing the key here
                    //             )
                    //           )}
                    //         </div>
                    //       );
                    //     }
                    //   );
                    // }else{
                    return Object.values(props.mainTemplateQuotation).map(
                      (item) => {
                        const attributes = Object.values(item);
                        return (
                          <div key={item.formdetailRowId}>
                            {" "}
                            {/* Assuming formdetailRowId is unique */}
                            {attributes.map((attribute) =>
                              renderErrorMessage(
                                attribute?.HTMLAttributeName,
                                attribute?.Identifier,
                                errorDisplayedFlag
                              )
                            )}
                          </div>
                        );
                      }
                    );
                    // }

                  } else {
                    if (
                      gstTypeCell &&
                      Object.keys(props.additionalInfoTemplate).length > 0 &&
                      props.additionalInfoTemplate.hasOwnProperty(
                        gstTypeCell.FieldId
                      )
                    ) {
                      if (
                        props.additionalInfoTemplate[gstTypeCell.FieldId]?.error
                      ) {
                        return (
                          <Alert
                            variant="text"
                            sx={{ color: "error.main", p: 0 }}
                            severity="error"
                          >
                            Please Select GST Transition Type.
                          </Alert>
                        );
                      }
                    }
                  }
                })()}
                <Tooltip
                  placement="top"
                  title="Review Auto generation of GST transition type"
                  arrow
                  open={tooltipOpen || hoverTooltip}
                  onOpen={() => setHoverTooltip(true)}
                  onClose={() => setHoverTooltip(false)}
                  PopperProps={{
                    sx: { zIndex: 98 }
                  }}
                >
                  <Badge
                    badgeContent={destinationCount}
                    color="primary"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    sx={{ mr: 2 }}
                  >
                    <Button
                      variant="text"
                      size="small"
                      startIcon={<LocationOnOutlinedIcon />}
                      onClick={() => {
                        setTooltipOpen(false);
                        props.setIsDestinationDetailsDialogOpen(true);
                      }}
                      sx={{ backgroundColor: "var(--primary-50)" }}
                    >
                      Destination
                    </Button>
                  </Badge>
                </Tooltip>
              </Box>
            )
          }
        />
        <CardContent sx={{ p: 0 }} id="ScrollAreaSection">
          <TableContainer
            className="quotation_template_table"
            ref={containerRef}
            // onScroll={handleScrollFirst}
            sx={{
              // maxHeight: "400px",
              // overflowY: "auto",
              overflow: "auto",
              borderTop: "solid 1px",
              borderColor: (theme) => theme.palette.bluegrey[500],
              borderBottomRightRadius: "4px",
              borderBottomLeftRadius: "4px",
            }}
            onScroll={debounce((event) => {
              if (event.target.scrollLeft === 0 && state.isScrollActive) {
                setState({
                  isScrollActive: false,
                });
              } else if (
                event.target.scrollLeft !== 0 &&
                !state.isScrollActive
              ) {
                setState({
                  isScrollActive: true,
                });
              }
            }, 200)}
          // sx={{
          //   maxHeight: "400px",
          //   overflowY: "auto",
          //   borderTop: "solid 1px",
          //   borderColor: (theme) => theme.palette.bluegrey[500],
          //   borderBottomRightRadius: "4px",
          //   borderBottomLeftRadius: "4px",
          //   ".items_template_table .MuiTableHead-root": {
          //     backgroundColor: (theme) => theme.palette.bluegrey[500],
          //   },
          //   "& .MuiTableHead-root ": {},
          //   "& .MuiTableBody-root": {
          //     backgroundColor: (theme) => theme.palette.grey[0],
          //   },
          //   "& tr": {
          //     whiteSpace: "nowrap",
          //   },
          //   "& .MuiTableHead-root tr th": {
          //     padding: "15px 8px",
          //   },
          //   "& .MuiTableBody-root tr td": {
          //     padding: "5px 8px",
          //   },
          //   ".MuiTableBody-root tr td:first-of-type, .MuiTableHead-root tr th:first-of-type ":
          //     {
          //       // position: "sticky",
          //       left: "0",
          //       minWidth: "380px",
          //     },
          //   "& .MuiTableHead-root tr th:first-of-type": {
          //     zIndex: "3",
          //   },
          //   "& .MuiTableBody-root tr td:first-of-type": {
          //     backgroundColor: (theme) => theme.palette.grey[0],
          //     zIndex: "1",
          //   },
          //   "& .css-17hdxs-MuiTypography-root": {
          //     fontSize: "14px",
          //   },
          // }}
          >
            <Table stickyHeader>
              <TableHead className="Itemize_headers">
                <TableRow>
                  <TableCell
                    sx={{
                      WebkitBoxShadow: {
                        md: state.isScrollActive && "11px 0 19px -2px #bebebe54",
                        sm: "none",
                      },
                      boxShadow: {
                        md:
                          state.isScrollActive && "11px 0 19px -2px #bebebe54",
                        sm: "none",
                      },
                    }}
                  >
                    <Box
                      className=""
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox sx={{ visibility: "hidden" }} />
                      <Tooltip
                        title={
                          props.data[0]?.find(
                            (obj) =>
                              obj.Identifier === "Product" ||
                              obj.Identifier === "ProductForeign"
                          )?.FieldLabel
                        }
                      >
                        <Box
                          sx={{
                            minWidth: "350px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography fontWeight={500}>
                            {
                              props.data[0]?.find(
                                (obj) =>
                                  obj.Identifier === "Product" ||
                                  obj.Identifier === "ProductForeign"
                              )?.FieldLabel
                            }
                          </Typography>

                          <ResizableBox
                            axis="x"
                            height={20}
                            width={20}
                            onResizeStop={(e, data) => {
                              setState({
                                itemCellWidth: data.size.width,
                              });
                            }}
                            style={{
                              textAlign: "left",
                              width: "100%",
                            }}
                            minConstraints={[20, 20]}
                          ></ResizableBox>
                        </Box>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  {props.data[0]?.find(
                    (obj) =>
                      obj.Identifier === "ProductCode" &&
                      obj.VisibilityFlag === "Y"
                  ) && (
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Tooltip
                            title={
                              props.data[0]?.find(
                                (obj) => obj.Identifier === "ProductCode"
                              )?.FieldLabel
                            }
                          >
                            <Typography fontWeight={500}>
                              {
                                props.data[0]?.find(
                                  (obj) => obj.Identifier === "ProductCode"
                                )?.FieldLabel
                              }
                            </Typography>
                          </Tooltip>
                          <ResizableBox
                            axis="x"
                            height={20}
                            width={20}
                            style={{
                              textAlign: "left",
                              width: "100%",
                            }}
                            minConstraints={[20, 20]}
                          ></ResizableBox>
                        </Box>
                      </TableCell>
                    )}

                  {props.multipleSolutionFlag && !props.slabFlag && (
                    <TableCell key={"M2"} align="left">
                      <Tooltip title={""}>
                        {/* <p>{cell.FieldLabel}</p> */}
                        <Typography fontWeight={500}>
                          MULTIPLE SOLUTION{" "}
                          <span style={{ color: "error.main" }}>*</span>
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  )}
                  {(props.slabFlag && props.multipleSolutionFlag) && (
                    <TableCell key={"M2"} align="left">
                      <Tooltip title={""}>
                        {/* <p>{cell.FieldLabel}</p> */}
                        <Typography fontWeight={500}>
                          SLAB RANGE NAME
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  )}
                  {props.data[0]?.map((cell, index) => {
                    if (
                      props.multiplePlantsFlag === true &&
                      cell.Identifier === "GSTType"
                    ) {
                      return null;
                    }
                    if (
                      props.vendorType === "Local" ||
                        props.vendorType === "International" ||
                        props.vendorType === "Local Importer"
                        ? cell.VisibilityFlag === "Y" ||
                        (cell.DefaultValue &&
                          cell.DefaultValue.Button === "Y" &&
                          cell.ElementType === "D")
                        : cell.VisibilityFlag === "Y"
                    ) {
                      if (
                        !(
                          cell.Identifier === "Product" ||
                          cell.Identifier === "ProductForeign" ||
                          cell.Identifier === "ProductCode"
                        )
                      ) {
                        return (
                          <TableCell key={cell.FieldId}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {/* <Tooltip
                                title="Apply to All"
                                placement="bottom-start"
                                disableInteractive
                              >
                                <ToggleButton
                                  value="check"
                                  selected={
                                    props.applyToAll[
                                    `${cell.FieldId}_applyToAll`
                                    ]
                                  }
                                  disabled={
                                    cell.Identifier === "EstimatedValue"
                                  }
                                  title="Apply to All"
                                  onClick={() => {
                                    let isReadOnly = false;
                                    if (!isReadOnly) {
                                      Object.keys(
                                        props.mainTemplateQuotation
                                      ).map((key, i) => {
                                        let element =
                                          props.mainTemplateQuotation[key];
                                        Object.keys(element).map((key1, i) => {
                                          if (
                                            key1 == cell.FieldId &&
                                            typeof element[key1] === "object" &&
                                            element[key1].readOnly
                                          ) {
                                            isReadOnly = key1;
                                          }
                                        });
                                      });
                                    }
                                    if (
                                      !props.applyToAll[
                                      `${cell.FieldId}_applyToAll`
                                      ] &&
                                      isReadOnly != cell.FieldId
                                    ) {
                                      props.handleApplyToAllClick(
                                        cell.FieldId,
                                        cell.ElementType === "TB" &&
                                        cell.ElementType === "TA",
                                        cell.Identifier
                                      );
                                    }
                                  }}
                                  sx={{
                                    p: 0,
                                    mr: 1,
                                    border: 0,
                                  }}
                                >
                                  {!["T", "D", "L"].includes(
                                    cell.ElementType
                                  ) ? (
                                    props.applyToAll[
                                      `${cell.FieldId}_applyToAll`
                                    ] ? (
                                      <CheckBoxIcon
                                        sx={{
                                          color: (theme) =>
                                            theme.palette.primary.main,
                                        }}
                                      />
                                    ) : (
                                      <CheckBoxOutlineBlankIcon
                                        sx={{
                                          color: (theme) =>
                                            theme.palette.bluegrey[500],
                                        }}
                                      />
                                    )
                                  ) : (
                                    ""
                                  )}
                                </ToggleButton>
                              </Tooltip> */}
                              {props.multipleSolutionFlag && props.slabFlag ?
                                (
                                  <Tooltip
                                    title="Apply to All Parent Slab"
                                    placement="bottom-start"
                                    disableInteractive
                                  >
                                    <ToggleButton
                                      value="check"
                                      selected={
                                        props.slabParentApplyToAll[
                                        `${cell.FieldId}_slabParentApplyToAll`
                                        ]
                                      }
                                      disabled={
                                        cell.Identifier ===
                                        "EstimatedValue"
                                      }
                                      // title="Apply to All"
                                      onClick={() => {
                                        let isReadOnly = false;
                                        let foundKey = null;

                                        // Loop through slabMainTemplateQuotation to find the correct key
                                        Object.keys(props.slabMainTemplateQuotation).forEach((key) => {
                                          let element = props.slabMainTemplateQuotation[key];

                                          // Check if _range is undefined in the first row (or other condition as per your use case)
                                          if (element[0]?._range === undefined) {

                                            // Loop through the inner object to check for readOnly status
                                            Object.keys(element).forEach((key1) => {
                                              if (
                                                key1 === cell.FieldId &&
                                                typeof element[key1] === "object" &&
                                                element[key1].readOnly
                                              ) {
                                                isReadOnly = true; // Mark as read-only if found
                                              }
                                            });

                                            // Only set foundKey if the field is not read-only
                                            if (!isReadOnly && foundKey === null) {
                                              foundKey = key;
                                            }
                                          }
                                        });

                                        // Ensure foundKey is valid and field is not read-only
                                        if (
                                          !props.slabParentApplyToAll[`${cell.FieldId}_slabParentApplyToAll`] &&
                                          !isReadOnly &&
                                          foundKey !== null
                                        ) {
                                          props.handleSlabParentApplyToAllClick(
                                            foundKey,  // Pass the correct found key here
                                            cell.FieldId,
                                            cell.ElementType === "TB" || cell.ElementType === "TA", // Condition for TB/TA
                                            cell.Identifier
                                          );
                                        }
                                      }}
                                      sx={{
                                        p: 0,
                                        mr: 1,
                                        border: 0,
                                      }}
                                    >
                                      {!["T", "D", "L", "F"].includes(
                                        cell.ElementType
                                      ) ? (
                                        props.slabParentApplyToAll[
                                          `${cell.FieldId}_slabParentApplyToAll`
                                        ] ? (
                                          <CheckBoxIcon
                                            sx={{
                                              color: (theme) =>
                                                theme.palette.primary
                                                  .main,
                                            }}
                                          />
                                        ) : (
                                          <CheckBoxOutlineBlankIcon
                                            sx={{
                                              color: (theme) =>
                                                theme.palette
                                                  .bluegrey[500],
                                            }}
                                          />
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </ToggleButton>
                                  </Tooltip>
                                )
                                : (
                                  <Tooltip
                                    title="Apply to All"
                                    placement="bottom-start"
                                    disableInteractive
                                  >
                                    <ToggleButton
                                      value="check"
                                      selected={
                                        props.applyToAll[
                                        `${cell.FieldId}_applyToAll`
                                        ]
                                      }
                                      disabled={
                                        cell.Identifier ===
                                        "EstimatedValue"
                                      }
                                      // title="Apply to All"
                                      onClick={() => {
                                        //for not doing apply to all when field is read only
                                        let isReadOnly = false;
                                        if (!isReadOnly) {
                                          Object.keys(
                                            props.mainTemplateQuotation
                                          ).map((key, i) => {
                                            let element =
                                              props.mainTemplateQuotation[
                                              key
                                              ];
                                            Object.keys(element).map(
                                              (key1, i) => {
                                                if (
                                                  key1 == cell.FieldId &&
                                                  typeof element[key1] ===
                                                  "object" &&
                                                  element[key1].readOnly
                                                ) {
                                                  isReadOnly = key1;
                                                }
                                              }
                                            );
                                          });
                                        }
                                        if (
                                          !props.applyToAll[
                                          `${cell.FieldId}_applyToAll`
                                          ] &&
                                          isReadOnly != cell.FieldId
                                        ) {
                                          props.handleApplyToAllClick(
                                            cell.FieldId,
                                            cell.ElementType === "TB" &&
                                            cell.ElementType === "TA",
                                            cell.Identifier
                                          );
                                        }
                                      }}
                                      sx={{
                                        p: 0,
                                        mr: 1,
                                        border: 0,
                                      }}
                                    >
                                      {!["T", "D", "L", "F"].includes(
                                        cell.ElementType
                                      ) ? (
                                        props.applyToAll[
                                          `${cell.FieldId}_applyToAll`
                                        ] ? (
                                          <CheckBoxIcon
                                            sx={{
                                              color: (theme) =>
                                                theme.palette.primary
                                                  .main,
                                            }}
                                          />
                                        ) : (
                                          <CheckBoxOutlineBlankIcon
                                            sx={{
                                              color: (theme) =>
                                                theme.palette
                                                  .bluegrey[500],
                                            }}
                                          />
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </ToggleButton>
                                  </Tooltip>
                                )}
                              <Tooltip
                                title={
                                  cell?.InstructionText === ""
                                    ? cell?.FieldLabel
                                    : cell?.InstructionText
                                }
                              >
                                <Typography fontWeight={500}>
                                  {cell.FieldLabel}
                                </Typography>
                              </Tooltip>
                              <ResizableBox
                                axis="x"
                                height={20}
                                width={20}
                                style={{
                                  textAlign: "left",
                                  width: "100%",
                                }}
                                minConstraints={[20, 20]}
                              ></ResizableBox>
                            </div>
                          </TableCell>
                        );
                      }
                    } else {
                      return "";
                    }
                  })}
                </TableRow>
              </TableHead>
              {/* {props.multipleSolutionFlag && !props.slabFlag && getTableBody()} */}
              {props.slabFlag && props.multipleSolutionFlag ? getTableSlabBody() : getTableBody()}
            </Table>
          </TableContainer>
          {/* Start Quotation form Duplicate Scroll bar code */}
          <div
            ref={duplicateScrollbarRef}
            onScroll={handleScrollSecond}
            style={{
              display: isVisibleScrollbar ? 'block' : 'none',
              overflowX: 'scroll',
              overflowY: 'hidden',
              width: 'calc(100% - 34px)',
              height: '18px',
              position: 'fixed',
              bottom: '0',
              zIndex: '13',
            }}
          >
            <div style={{ width: containerRef.current?.scrollWidth || 0, height: "18px" }}></div>
          </div>
          {/* End Quotation form Duplicate Scroll bar code */}
        </CardContent>
        <CardActions
          sx={{
            justifyContent: "end",
            alignItems: "center",
            padding: "8px 16px",
          }}
          disableSpacing
        >
          {!props.isMultipleCurrency && <Button
            variant="text"
            endIcon={<InfoIcon fontSize="small" color="primary" />}
            onClick={props.multipleSolutionFlag && props.slabFlag ? LandedCostSlabOpen("right", true) : LandedCostRFQOpen("right", true)}
            sx={{
              textDecoration: "underline",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
              }
            }}
          >
            Total Value
          </Button>}
        </CardActions>
      </Card>

      {state.specificationsOpen && (
        <Upload
          currentItemIndex={state.currentItemIndex}
          product_id={props.selectedQuotedItems[state.currentItemIndex]?.id}
          randomId={props.randomId}
          master_type={"item"}
          handleUplaod={props.handleUplaod}
          open={state.specificationsOpen}
          handleClose={() => {
            setState({
              specificationsOpen: false,
            });
          }}
          Alreadyexistingfiles={
            props.itemSpecificFiles[
            props.selectedQuotedItems[state.currentItemIndex]?.id
            ] || []
          }
        />
      )}
      {fileSpecificationsOpen && (
        <UploadDynamicView
          Loading={Loading}
          title="Specifications"
          SpecificationText={SpecificationText}
          uploadedFiles={specification?.data}
          open={fileSpecificationsOpen}
          handleClose={handleUploadClose}
        />
      )}
      <LandedCostRFQDrawer
        isLandedCostRFQOpened={landedCostRFQ}
        data={props.selectedQuotedItems}
        isLandedCostRFQClosed={() => setLandedCostRFQ(false)}
        mainTemplateQuotation={FilteredMainTemplate}
        multiSolmainTemplateQuotation={
          props.multipleSolutionFlag ? props.multiSolmainTemplateQuotation : {}
        }
        solutionNames={props.multipleSolutionFlag ? props.solutionNames : {}}
        msFlag={props.multipleSolutionFlag}
        //  mainTemplateQuotation={props.mainTemplateQuotation}
        // selectedCurrency={props?.selectedCurrency || props.quotedCurrency}
        selectedCurrency={
          props.lastTransactionTypeInThread === "Inquiry" &&
            (props.vendorType === "International" ||
              props.vendorType === "Local Importer")
            ? props?.selectedCurrency
            : props?.selectedCurrency || props.quotedCurrency
        }
        currencyDetails={props?.currencyDetails}
        inquiryItemsId={props?.inquiryItemsId}
        itemSpecific={props?.itemSpecific}
        transactionItemSequence={props?.transactionItemSequence}
        vendorType={props?.vendorType}
        TotalCostFIeld={TotalCostFIeld}
        RateField={RateField}
        PerCost={PerCost}
        EvalResultsKeyWise={props.EvalResultsKeyWise}
      />
      <LandedCostSlabDrawer
        isLandedCostSlabOpened={landedCostSlab}
        data={props.selectedQuotedItems}
        isLandedCostSlabClosed={() => setLandedCostSlab(false)}
        slabMainTemplateQuotation={FilteredSlabMainTemplate}
        // selectedCurrency={props?.selectedCurrency || props.quotedCurrency}
        selectedCurrency={
          props.lastTransactionTypeInThread === "Inquiry" &&
            (props.vendorType === "International" ||
              props.vendorType === "Local Importer")
            ? props?.selectedCurrency
            : props?.selectedCurrency || props.quotedCurrency
        }
        currencyDetails={props?.currencyDetails}
        transactionItemSequence={props?.transactionItemSequence}
        vendorType={props?.vendorType}
        TotalCostFIeld={TotalCostFIeld}
        RateField={RateField}
        PerCost={PerCost}
        EvalResultsKeyWiseSlab={props.EvalResultsKeyWiseSlab}
        selectedQuotedItems={props.selectedQuotedItems}
      />
      {/* Switch Toggle Delete solutions in one go*/}
      <ConfirmAlert
        open={isModalOpen}
        handleClose={() => handleModalCancel()}
        handleSubmit={() => handleModalOk()}
        msg="Are you sure you want to delete All solutions?"
        title="Confirm"
      />
      {/* Switch Toggle Delete Each solution one by one*/}
      <ConfirmAlert
        open={isModalOpenDelete}
        handleClose={() => handleModalCancelDelete()}
        handleSubmit={() => handleModalOkDelete()}
        msg="Are you sure you want to delete?"
        title="Confirm"
      />
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          component="div"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "2px 16px",
          }}
        >
          <Typography variant="subtitle1" fontWeight={500} component="h6">
            {Errors.length > 1 ? "Total Cost Errors" : "Total Cost Details"}
          </Typography>
          <IconButton
            edge="end"
            sx={{ color: (theme) => theme.palette.bluegrey[500] }}
            onClick={() => setModalOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Table size="small">
            {Errors.length > 1 ? (
              <TableBody>
                {/* Item: {Errors[0]} */}

                {multipleSolutionNameFlag ? `Multiple Solution Name: ${Errors[0]}` : `Item: ${Errors[0]}`}
                <br />
                <br />
                {Errors.slice(1)?.map((error, index) => (
                  // <TableRow key={error}>

                  < Typography > {`${index + 1}. ${error}`} </Typography>
                  // {multipleSolutionNameFlag && (<Typography>{`${index + 1}. ${error}`}</Typography>)}
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {modalData?.map(({ label, value }) => (
                  <TableRow key={label}>
                    <TableCell>{label}</TableCell>
                    <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                      {getSymbol(
                        props.selectedCurrency || props.quotedCurrency
                      )}{" "}
                      &nbsp;
                      {parseFloat(value).toLocaleString("en-IN", {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                      })}
                      {" per "} {unit}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </DialogContent>
      </Dialog >
    </>
  );
}