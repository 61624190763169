import React, { useState, useEffect, useCallback } from "react";
import {
  Avatar,
  Typography,
  Tooltip,
  InputBase,
  InputAdornment,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import { connect } from "react-redux";
import {
  DataGridPro,
  GridActionsCellItem,
  GridOverlay,
} from "@mui/x-data-grid-pro";
import {
  getItemsLinkedVendorBySearch,
  categoryItemLinkedVendors,
  getFilteredItemLinkedVendors,
} from "../../../config/apiUrl";
import axios from "axios";
import { getFilterOperators } from "../../Utils/datagridHelper";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { debounce } from "lodash";
import noSearchimg from "../../../assets/images/no-search.svg";
import { getVendorGMPFlag } from "../../Vendors/VendorChip";

function QuickSearchToolbar(props) {
  return (
    <Box
      borderTop={1}
      sx={{
        borderBottom: "1px solid",
        borderColor: (theme) => theme.palette.bluegrey[500],
      }}>
      <InputBase
        sx={{
          backgroundColor: (theme) => theme.palette.grey[50],
          width: "100%",
          height: "46px",
          borderRadius: "0",
          padding: "0 16px",
          "& .MuiInputAdornment-positionStart .MuiSvgIcon-root": {
            color: (theme) => theme.palette.grey[600],
          },
          "& .search-clear svg": {
            width: "18px",
            height: "18px",
            color: (theme) => theme.palette.grey[500],
          },
        }}
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        }
        endAdornment={
          <IconButton
            className="search-clear"
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{ visibility: props.value ? "visible" : "hidden" }}
            onClick={props.clearSearch}>
            <ClearIcon />
          </IconButton>
        }
      />
    </Box>
  );
}

const ItemizeVendorSidebar = (props) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [filteredTotal, setFilteredTotal] = useState(0);
  const [filteredRows, setFilteredRows] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [vendorPage, setVendorPage] = useState(1);
  const [vendorLastPage, setVendorLastPage] = useState(1);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [filterDataCurrentPage, setFilterDataCurrentPage] = useState(1);
  const [filterDataLastPage, setFilterDataLastPage] = useState(1);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [currentFilterModel, setCurrentFilterModel] = useState({ items: [] });
  const [searchText, setSearchText] = useState("");
  const [searchedRows, setSearchedRows] = useState([]);
  const [vendorName, setVendorName] = useState("");
  const [vendorNameOperator, setVendorNameOperator] = useState("");
  const [vendorCode, setVendorCode] = useState("");
  const [vendorCodeOperator, setVendorCodeOperator] = useState("");
  const [country, setCountry] = useState("");
  const [countryOperator, setCountryOperator] = useState("");
  const [industryType, setIndustryType] = useState("");
  const [industryTypeOperator, setIndustryTypeOperator] = useState("");
  const [email, setEmail] = useState("");
  const [emailOperator, setEmailOperator] = useState("");
  const [state, setState] = useState("");
  const [stateOperator, setStateOperator] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactPersonOperator, setContactPersonOperator] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [phoneNoOperator, setPhoneNoOperator] = useState("");
  const [address, setAddress] = useState("");
  const [addressOperator, setAddressOperator] = useState("");
  const [plant, setPlant] = useState("");
  const itemIds = props.selectedItems.map(item => item.id);
  const [vendorLocationFlag, setVendorLocationFlag] = useState(props.vendorClassifications);
  useEffect(() => {
    fetchItemizedVendors();
  }, [vendorPage]);

  useEffect(() => {
    if (!isFilterActive && searchText.trim() !== "") {
      if (filterDataCurrentPage <= filterDataLastPage) {
        searchVendors(searchText, filterDataCurrentPage);
      }
    }
  }, [filterDataCurrentPage]);
  const fetchItemizedVendors = () => {
    let plant_arr = undefined;
    let plant_str = "";
    setLoading(true);
    if (
      props.prlineitems &&
      props.accountModulePermission[
        "Plant Specific Categroy Item Vendor Linking"
      ].module_activated === "Y"
    ) {
      plant_arr = [];
      let prlineitems = props.prlineitems;
      itemIds.forEach((id) => {
        prlineitems[id]?.forEach((lineItemData) => {
          if (
            props.selectedCategory?.old_id === lineItemData?.category?.category_old_id
          ) {
            if (!plant_arr.includes(lineItemData.plant)) {
              plant_arr.push(lineItemData.plant);
            }
          }
        });
      });
      plant_str = plant_arr.join(",");
      setPlant(plant_str);
    }
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${props.sessionId}`;

    const url = categoryItemLinkedVendors(
      props.selectedCategory?.id,
      itemIds,
      vendorPage,
      plant_str
    );
    axios
      .get(url)
      .then((res) => {
        let finalRows = [...rows, ...res.data.data];
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueRows = Array.from(uniqueSet).map(JSON.parse);
        const updateVendorLocationFlag = { ...vendorLocationFlag };
        uniqueRows.forEach((vendor) => {
          const vendorID = vendor.id;
          const classification = getVendorLocation(vendor.country);
          if (!updateVendorLocationFlag[vendorID]) {
            updateVendorLocationFlag[vendorID] = classification;
          }
        });
        setRows(uniqueRows);
        setLoading(false);
        setVendorLastPage(res.data.meta?.last_page);
        if (!total) setTotal(res.data.meta.total);
        props.onUpdatevendorClassifications(updateVendorLocationFlag);
        setVendorLocationFlag(updateVendorLocationFlag);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getVendorLocation = (country) => {
    if (props.accountlevelInfo?.country === country) {
      return "L";
    } else return "I";
  };

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    setLoading(true);

    if (searchValue.trim() !== "") {
      setIsFilterActive(false);
      setSearchedRows([]);
      setFilteredRows([]);
      setFilterDataCurrentPage(1);
      setFilterDataLastPage(1);
      debounceSearchVendors(searchValue, searchVendors);
    } else {
      setLoading(false);
    }
  };

  const debounceSearchVendors = useCallback(
    debounce((searchValue) => {
      searchVendors(searchValue);
    }, 1000), []
  );

  const searchVendors = useCallback((searchValue, page = 1) => {
    const url = getItemsLinkedVendorBySearch(
      props.selectedCategory.id,
      searchValue.trim(),
      page,
      itemIds,
      plant
    );

    setLoading(true);

    axios
      .get(url)
      .then((res) => {
        if (page === 1) {
          let result = [...searchedRows, ...res.data.data];
          const updateVendorLocationFlag = { ...vendorLocationFlag };
          result.forEach((vendor) => {
            const vendorID = vendor.id;
            const classification = getVendorLocation(vendor.country);
            if (!updateVendorLocationFlag[vendorID]) {
              updateVendorLocationFlag[vendorID] = classification;
            }
          });
          setVendorLocationFlag(updateVendorLocationFlag);
          props.onUpdatevendorClassifications(updateVendorLocationFlag);
          setSearchedRows(result);
          setFilterDataLastPage(res.data.meta?.last_page);
        } else {
          let finalRows = [...searchedRows, ...res.data.data];
          let jsonObject = finalRows.map(JSON.stringify);
          let uniqueSet = new Set(jsonObject);
          let uniqueRows = Array.from(uniqueSet).map(JSON.parse);
          const updateVendorLocationFlag = { ...vendorLocationFlag };
          uniqueRows.forEach((vendor) => {
            const vendorID = vendor.id;
            const classification = getVendorLocation(vendor.country);
            if (!updateVendorLocationFlag[vendorID]) {
              updateVendorLocationFlag[vendorID] = classification;
            }
          });
          setVendorLocationFlag(updateVendorLocationFlag);
          props.onUpdatevendorClassifications(updateVendorLocationFlag);
          setSearchedRows(uniqueRows);
        }
      })
      .catch((err) => {
        console.log("searched vendors err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filterDataCurrentPage]);

  useEffect(() => {
    return () => {
      debounceSearchVendors.cancel();
    };
  }, []);


  const handlePageChange = () => {
    if (isFilterActive) {
      if (filterDataCurrentPage !== filterDataLastPage) {
        setFilterDataCurrentPage(filterDataCurrentPage + 1);
        fetchFilterVendors();
      }
    } else {
      if (searchText.trim() === "") {
        if (vendorPage !== vendorLastPage) {
          setVendorPage(vendorPage + 1);
        }
      } else {
        if (filterDataCurrentPage !== filterDataLastPage) {
          setFilterDataCurrentPage(filterDataCurrentPage + 1);
        }
      }
    }
  };

  const fetchFilterVendors = () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${props.sessionId}`;
    const url = getFilteredItemLinkedVendors(
      itemIds,
      props.selectedCategory.id,
      vendorName.trim(),
      vendorNameOperator,
      vendorCode.trim(),
      vendorCodeOperator,
      country.trim(),
      countryOperator,
      industryType.trim(),
      industryTypeOperator,
      email.trim(),
      emailOperator,
      state.trim(),
      stateOperator,
      contactPerson.trim(),
      contactPersonOperator,
      phoneNo.trim(),
      phoneNoOperator,
      address.trim(),
      addressOperator,
      filterDataCurrentPage
    );
    axios
      .get(url)
      .then((res) => {
        if (filterDataCurrentPage === 1) {
          setFilteredRows(res.data.data);
          setFilterDataLastPage(res.data.meta?.last_page);
          setFilteredTotal(res.data.meta.total);
        } else {
          let finalRows = [...filteredRows, ...res.data.data];
          let uniqueRows = Array.from(new Set(finalRows.map(JSON.stringify))).map(JSON.parse);
          const updateVendorLocationFlag = { ...vendorLocationFlag };
          uniqueRows.forEach((vendor) => {
            const vendorID = vendor.id;
            const classification = getVendorLocation(vendor.country);
            if (!updateVendorLocationFlag[vendorID]) {
              updateVendorLocationFlag[vendorID] = classification;
            }

          });
          setFilteredRows(uniqueRows);
          setFilterDataLastPage(res.data.meta?.last_page);
          props.onUpdatevendorClassifications(updateVendorLocationFlag);
        }
        setFilterLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setFilterLoading(false);
      });
  }

  const handleFilterModelChange = useCallback((filterModel) => {
    let vendor_name = "";
    let vendor_name_operator = "";
    let vendor_code = "";
    let vendor_code_operator = "";
    let country = "";
    let country_operator = "";
    let industry_type = "";
    let industry_type_operator = "";
    let email = "";
    let email_operator = "";
    let state = "";
    let state_operator = "";
    let contact_person = "";
    let contact_person_operator = "";
    let phone_no = "";
    let phone_no_operator = "";
    let address = "";
    let address_operator = "";

    if (JSON.stringify(filterModel) !== JSON.stringify(currentFilterModel)) {
      setFilterModel(filterModel);
      setCurrentFilterModel(filterModel);
    }

    filterModel.items.forEach((filter) => {
      if (filter.value) {
        switch (filter.field) {
          case "vendor_name":
            vendor_name = filter.value;
            vendor_name_operator = filter.operator;
            break;
          case "vendor_code":
            vendor_code = filter.value;
            vendor_code_operator = filter.operator;
            break;
          case "country":
            country = filter.value;
            country_operator = filter.operator;
            break;
          case "industry_type":
            industry_type = filter.value;
            industry_type_operator = filter.operator;
            break;
          case "email":
            email = filter.value;
            email_operator = filter.operator;
            break;
          case "state":
            state = filter.value;
            state_operator = filter.operator;
            break;
          case "contact_person":
            contact_person = filter.value;
            contact_person_operator = filter.operator;
            break;
          case "phone_no":
            phone_no = filter.value;
            phone_no_operator = filter.operator;
            break;
          case "address":
            address = filter.value;
            address_operator = filter.operator;
            break;
          default:
            break;
        }
      }
    });
    const isAnyFilterApplied = vendor_name || vendor_code || country || industry_type || email || state || contact_person || phone_no || address;
    if (isAnyFilterApplied) {
      if (!isFilterActive) setIsFilterActive(true);
      setVendorName(vendor_name);
      setVendorNameOperator(vendor_name_operator);
      setVendorCode(vendor_code);
      setVendorCodeOperator(vendor_code_operator);
      setCountry(country);
      setCountryOperator(country_operator);
      setIndustryType(industry_type);
      setIndustryTypeOperator(industry_type_operator);
      setEmail(email);
      setEmailOperator(email_operator);
      setState(state);
      setStateOperator(state_operator);
      setContactPerson(contact_person);
      setContactPersonOperator(contact_person_operator);
      setPhoneNo(phone_no);
      setPhoneNoOperator(phone_no_operator);
      setAddress(address);
      setAddressOperator(address_operator);
      setFilterDataCurrentPage(1);
      setFilterDataLastPage(1);
      setFilteredRows([]);
      setFilteredTotal(0);
      fetchFilterVendors();

    } else {
      setIsFilterActive(false);
      setVendorName("");
      setVendorCode("");
      setCountry("");
      setIndustryType("");
      setFilterDataCurrentPage(0);
      setFilterDataLastPage(1);
      setFilteredRows([]);
      setFilterModel({ items: [] });
    }
  }, [currentFilterModel, filteredRows]);

  const handleVendorLocationFlagChange = (vendorID, newFlag, itemIDsString) => {
    const itemIDs = itemIDsString.split(",");

    const updatedVendorLocationFlag = { ...vendorLocationFlag };
    const previousFlag = updatedVendorLocationFlag[vendorID];
    updatedVendorLocationFlag[vendorID] = newFlag;
    setVendorLocationFlag(updatedVendorLocationFlag);
    const updatedItems = props.selectedItems.map(item => {
      if (itemIDs.includes(item.id.toString())) {
        const { vendor_count } = item;

        vendor_count.local_count = parseInt(vendor_count.local_count || 0, 10);
        vendor_count.internation_count = parseInt(vendor_count.internation_count || 0, 10);

        if (previousFlag === "L" && newFlag === "I") {
          vendor_count.local_count = Math.max(0, vendor_count.local_count - 1);
          vendor_count.internation_count += 1;
        } else if (previousFlag === "I" && newFlag === "L") {
          vendor_count.internation_count = Math.max(0, vendor_count.internation_count - 1);
          vendor_count.local_count += 1;
        }
        vendor_count.total_count = vendor_count.local_count + vendor_count.internation_count;
        return { ...item, vendor_count };
      }
      return item;
    });
    props.handleClassificationChange(vendorID, newFlag);
    props.onUpdateItems(updatedItems);
  };

  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 50,
      resizable: false,
      hide:
        !(
          props.userInfo?.is_super_user === "Y" ||
          props.masterPermission.vendor_master === "Y"
        ) || props.isRecipients,
      getActions: (params) => [
        <GridActionsCellItem
          className="dropdown_menu_with_icon"
          label="Edit Vendor"
          showInMenu
          onClick={() => {
            props.handleAddVendorDialogOpen(params.id, params.row.vendor_name);
          }}
        />,
      ],
    },
    {
      field: "vendor_name",
      headerName: "VENDOR NAME",
      width: 320,
      editable: false,
      filterable: searchText.trim() === "",
      filterOperators: getFilterOperators(),
      renderCell: (params) => {
        return (
          <>
            <Avatar sx={{ backgroundColor: (theme) => theme.palette.success.main }}>
              {params.row.vendor_name.slice(0, 1)}
            </Avatar>
            <Box sx={{ lineHeight: "12px", ml: 1 }}>
              <Tooltip title={params.row.vendor_name}>
                <Typography color="text.primary" fontWeight={500} noWrap>
                  {params.row.vendor_name}
                </Typography>
              </Tooltip>
              <Typography variant="span" color="grey.500">
                {params.row.vendor_code}
              </Typography>
            </Box>
          </>
        );
      },
    },
    {
      field: "vendor_status",
      headerName: "VENDOR STATUS",
      width: 150,
      editable: false,
      filterable: searchText.trim() === "",
      filterOperators: getFilterOperators(),
      renderCell: (params) => {
        return (
          <>
            <Typography fontWeight={500}>
              {params.row.is_approved === "approved"
                ? getVendorGMPFlag("Y", "A", "Approved", "A", "")
                : getVendorGMPFlag("Y", "N", "Non-Approved", "N", "")}
              {getVendorGMPFlag(
                props.companyInfo.gst_verification,
                params.row.gstin_no,
                params.row.gstin_status,
                "G",
                "GSTIN"
              )}
              {params.row.industry_type === "MSME"
                ? getVendorGMPFlag(
                  props.companyInfo.msme_verification,
                  params.row.industry_type_value,
                  params.row.msme_status,
                  "M",
                  "MSME"
                )
                : getVendorGMPFlag(
                  props.companyInfo.msme_verification,
                  "",
                  params.row.msme_status,
                  "M",
                  "MSME"
                )}
              {getVendorGMPFlag(
                props.companyInfo.pan_verification,
                params.row.pan_no,
                params.row.pan_status,
                "P",
                "PAN"
              )}
            </Typography>
          </>
        );
      },
    },
    {
      field: "vendor_classification",
      headerName: "VENDOR CLASSIFICATION",
      width: 160,
      editable: false,
      filterable: searchText.trim() === "",
      filterOperators: getFilterOperators(),
      renderCell: (params) => {
        const vendorClassification = vendorLocationFlag[params.row.id];
        return (
          <>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <Select
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                readOnly={props.masterPermission.allow_vendor_type_modification_for_rfq !== 'Y'}
                value={vendorClassification}
                onChange={(event) =>
                  handleVendorLocationFlagChange(params.row.id, event.target.value, params.row.item_id)
                }
              >
                <MenuItem value={"L"}>Local</MenuItem>
                <MenuItem value={"I"}>International</MenuItem>
              </Select>
            </FormControl>
          </>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      filterable: searchText.trim() === "",
      width: 180,
      editable: false,
      filterOperators: getFilterOperators(),
      renderCell: (params) => (
        <Tooltip title={params.row.email} disableInteractive placement="bottom-start">
          <Typography>{params.row.email}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "country",
      headerName: "COUNTRY",
      width: 150,
      editable: false,
      filterable: searchText.trim() === "",
      filterOperators: getFilterOperators(),
    },
    {
      field: "state",
      headerName: "State",
      filterable: searchText.trim() === "",
      width: 150,
      editable: false,
      filterOperators: getFilterOperators(),
      renderCell: (params) => <Typography>{params.row.state}</Typography>,
    },
    {
      field: "industry_type",
      headerName: "INDUSTRY TYPE",
      width: 200,
      editable: false,
      filterable: searchText.trim() === "",
      filterOperators: getFilterOperators(),
    },
    {
      field: "contact_person",
      headerName: "Contact Person",
      filterable: searchText.trim() === "",
      width: 150,
      editable: false,
      filterOperators: getFilterOperators(),
      renderCell: (params) => <Typography>{params.row.contact_person}</Typography>,
    },
    {
      field: "phone_no",
      headerName: "Contact Number",
      filterable: searchText.trim() === "",
      width: 150,
      editable: false,
      filterOperators: getFilterOperators(),
      renderCell: (params) => <Typography>{params.row.phone_no}</Typography>,
    },
    {
      field: "address",
      headerName: "Address",
      filterable: searchText.trim() === "",
      width: 150,
      editable: false,
      filterOperators: getFilterOperators(),
      renderCell: (params) => <Typography>{params.row.address}</Typography>,
    },
  ];

  const rowsToDisplay = isFilterActive
    ? filteredRows
    : searchText.trim() === ""
      ? rows
      : searchedRows;

  return (
    <div style={{ height: "calc(100vh - 49px)", width: "100%" }}>
      <DataGridPro
        sx={{
          "&.MuiDataGrid-root": {
            border: 0,
          },
          ".MuiDataGrid-cell--withRenderer.MuiDataGrid-cell:first-child": {
            padding: 0,
          },
        }}
        slots={{
          toolbar: QuickSearchToolbar,
          noRowsOverlay: () => (
            <GridOverlay>
              <Box className="no-search">
                <img src={noSearchimg} width={"150px"} height={"auto"} />
                <Typography component="p" sx={{ pt: 1, textAlign: "center" }}>
                  There are no vendors
                </Typography>
              </Box>
            </GridOverlay>
          ),
        }}
        slotProps={{
          toolbar: {
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(""),
          },
        }}
        columns={columns}
        rows={rowsToDisplay}
        loading={isFilterActive ? filterLoading : loading}
        onRowsScrollEnd={handlePageChange}
        disableRowSelectionOnClick
        disableColumnSelector
        disableColumnPinning
        filterModel={filterModel}
        onFilterModelChange={handleFilterModelChange}
        filterMode="server"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  sessionId: state.sessionReducer.sessionId,
  userInfo: state.userInfoReducer.userInfo,
  accountlevelInfo: state.permissionsReducer.accountlevelInfo,
  masterPermission: state.permissionsReducer.masterPermission,
  accountModulePermission: state.permissionsReducer.accountModulePermission,
  prlineitems: state.prrfqreducer.setPrLineItemsData,
  companyInfo: state.companyInfoReducer.companyInfo,
});

export default connect(mapStateToProps, {
})(ItemizeVendorSidebar);