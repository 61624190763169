import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";

export const RFQQtyRight = styled(Drawer)(({ theme }) => ({
  "&.RFQQty-Sidebar": {
    "& .MuiPaper-root": {
      boxShadow: "none",
      borderRadius: "0",
      border: "none",
    },
    "& .MuiDrawer-paper": {
      width: "500px",
      boxSizing: "border-box",
      [theme.breakpoints.down("700")]: {
        width: "100%",
      },
    },
    "& .MuiTable-root": {
      borderCollapse: "separate",
      tableLayout: "fixed",
    },
    "& .MuiTableHead-root": {
      position: "sticky",
      top: "0",
      zIndex: "11",
    },
    "& .MuiTableHead-root tr th": {
      padding: "6px 10px",
    },
    "& .MuiTableCell-root ": {
      padding: "10px",
    },
    // "& .MuiTableCell-root:first-child:not(.MuiTableFooter-root .MuiTableCell-root:first-child)": {
    //     position: "sticky",
    //     left: "0",
    //     padding:"6px"
    // },
    "& .MuiTableHead-root .MuiTableCell-root:first-child": {
      background: "var(--grey-50)",
      zIndex: "1",
    },
    "& .MuiTableBody-root .MuiTableCell-root:first-child": {
      background: "var(--grey-0)",
    },
    "& .MuiTableFooter-root": {
      position: "sticky",
      bottom: "0",
      background: "#fff",
      boxShadow: "0px -1px 0 var(--bluegrey-500)",
    },
  },
}));
