import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  Divider,
  FilledInput,
  FormControl,
  InputAdornment,
  ListItem,
  Stack,
  Typography,
  Tab,
  Tabs,
  IconButton,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { RFQItemStyle } from "./Style";
import { makeStyles } from "@mui/styles";
import { CustomIconButton } from "../../../utils/RFQList/utils";
import { get_Inquiries_data } from "../../../config/apiUrl";
import VendorList from "../vendorList/vendorList";
import noSearchimg from "../../../assets/images/no-search.svg";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { BrowserView, MobileView } from 'react-device-detect';
import commifyWithPrecision from "../../../common/utility";
import { useDispatch, useSelector } from "react-redux";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  // SearchInput: {
  // "& .css-1vbc0rj-MuiInputBase-input-MuiFilledInput-input": {
  //   padding: 0,
  // },
  // },
}));

const ItemLists = ({
  sessionID,
  selectedId,
  handleSelectItem,
  selectedItem,
}) => {
  const [value, setValue] = React.useState(0);
  const [searchText, setSearchText] = useState("");
  const [itemName, setItemName] = useState(null);
  const [openItemsId, setOpenItemId] = useState(null); // Track the ID of the open item
  const [itemListData, setItemListData] = useState([]);
  const classes = useStyles();

  const configAxios = {
    headers: { Authorization: `Bearer ${sessionID}` },
  };
  const feature_permission_obj = useSelector((state)=>state.permissionsReducer.featurePermission);
  const international_numeric_format = feature_permission_obj?.international_numeric_format?.feature_value
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredData = itemListData?.filter((item) => {
    return Object.values(item.name)
      .join("")
      .toLowerCase()
      .includes(searchText.toLowerCase());
  });

  const handleClick = (item) => {
    // Toggle the open state of the clicked accordion
    if (openItemsId === item.id) {
      setOpenItemId(null); // Collapse the item if it's already open
    } else {
      setOpenItemId(item.id); // Expand the item
    }
  };

  const fetchItemDetails = async () => {
    let url = get_Inquiries_data + selectedId + "/items";
    try {
      let response = await axios.get(url, configAxios);
      if (response.status === 200 && response.data) {
        setItemListData(response.data.inquiry_items);
      } else {
        // Handle empty data case
        setItemListData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchItemDetails();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseSearch = () => {
    setSearchText("");
  };

  return (
    <Box>
      {RFQItemStyle}
      <Box className="Dialog-AppTitle">
        <Typography variant="subtitle2" color="var(--text-color)">
          ITEMS LIST
        </Typography>
      </Box>

      <FormControl sx={{ width: "100%", p: 0 }}>
        <FilledInput
          className={classes.SearchInput}
          value={searchText}
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <SearchOutlinedIcon />
            </InputAdornment>
          }
          endAdornment={
            searchText !== "" && (
              <IconButton
                className="search-clear-btn"
                size="small"
                onClick={handleCloseSearch}
              >
                <ClearIcon fontSize="inherit" />
              </IconButton>
            )
          }
          onChange={handleSearchChange}
        />
      </FormControl>

      {filteredData.length === 0 ? (
        <Box className="no-search">
          <img src={noSearchimg} />
          <Typography component="p">
            {searchText !== "" ? (
              <>
                There are no item list like <b>{searchText}</b>
              </>
            ) : (
              "There are no item list"
            )}
          </Typography>
        </Box>
      ) : (
        <List className="RFQ-ItemList">
          {filteredData?.map((item) => (
            <ListItem
              className={
                item?.id === selectedItem?.id ? "selected" : "notSelected"
              }
              key={item.name}
              disablePadding
            >
              <ListItemButton disableRipple={true} sx={{ pr: 0 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ width: "100%" }}
                >
                  <ListItemText
                    primary={item.name}
                    secondary={item.code}
                    onClick={() => {
                      handleSelectItem(item);
                      setItemName(item);
                    }}
                  />
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    // spacing={1}
                    textAlign="right"
                    className="ItemQtyText"
                  >
                    <ListItemText
                      primary={commifyWithPrecision(item.quantity, international_numeric_format, true) + "/" + item.unit}
                      secondary="Item Qty/Unit"
                    />

                    <Fragment>
                      {item.id !== "" &&
                      item.id !== undefined &&
                      item?.prlis?.length > 0 ? (
                        <Box item>
                          <CustomIconButton
                            size="small"
                            onClick={() => handleClick(item)}
                          >
                            {openItemsId === item.id ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </CustomIconButton>
                        </Box>
                      ) : (
                        <Box item sx={{ display: "none" }}>
                          <CustomIconButton
                            size="small"
                            onClick={() => handleClick(item)}
                          >
                            {openItemsId === item.id ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </CustomIconButton>
                        </Box>
                      )}
                    </Fragment>
                  </Stack>
                </Stack>
              </ListItemButton>
              {item.id !== "" &&
                item.id !== undefined &&
                item?.prlis?.length > 0 && (
                  <Fragment>
                    <Collapse
                      in={openItemsId === item.id}
                      timeout="auto"
                      unmountOnExit
                    >
                      <BrowserView>
                      <List
                        component="div"
                        sx={{ backgroundColor: "var(--grey-50)" }}
                        disablePadding
                      >
                        {/* Check if item.prlis is an array and has data */}
                        {item?.prlis && item?.prlis?.length > 0 && (
                          <React.Fragment>
                            {/* Render the nested list */}
                            <List
                              component="div"
                              sx={{ backgroundColor: "var(--grey-50)" }}
                              disablePadding
                            >
                              {item?.prlis.map((items) => (
                                // Render each list item
                                <ListItem key={items.pr_no}>
                                  <ListItemText
                                    primary={items.pr_no}
                                    secondary={"Line Item: " + items.line_item}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </React.Fragment>
                        )}
                      </List>
                      </BrowserView>
                      
                      <MobileView>
                      {/* Start Itemize Tab Mobile View */}
                      <Box
                        className="ItemizeVendor-Tab"
                        sx={{ display: { sm: "block", md: "none" } }}
                      >
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                        >
                          <Tab label="Vendors" />
                          <Tab label="PR No" />
                        </Tabs>
                        <TabPanel
                          value={value}
                          index={0}
                          className="tabs-panel"
                        >
                          <VendorList
                            sessionID={sessionID}
                            selectedId={selectedId}
                            itemName={itemName}
                          />
                        </TabPanel>
                        <TabPanel
                          value={value}
                          index={1}
                          className="tabs-panel"
                        >
                          {item.prlis && item.prlis.length > 0 ? (
                            <List
                              component="div"
                              sx={{ backgroundColor: "var(--grey-50)" }}
                              disablePadding
                            >
                              {item.prlis.map((items) => (
                                <ListItem key={items.pr_no}>
                                  <ListItemText
                                    primary={items.pr_no}
                                    secondary={"Line Item: " + items.line_item}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          ) : (
                            ""
                          )}
                        </TabPanel>
                      </Box>
                      {/* End Itemize Tab Mobile View */}
                      </MobileView>

                    </Collapse>
                    
                  </Fragment>
                )}
                <Divider />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default ItemLists;
