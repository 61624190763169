import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Alert,
    Avatar,
    Backdrop,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Menu,
    MenuItem,
    Snackbar,
    Switch,
    TextField,
    Tooltip,
    Typography,
    styled,
} from "@mui/material";
import { QuoteReminderDrawer } from "./QuoteReminderStyle";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/HelpOutline";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSelector } from "react-redux";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { getGroupRfq, getQuotationReminders, getVendorsDetails } from "../../../config/apiUrl";
import ConfirmAlert from "../../Dialogs/ConfirmAlert.js";
import ExpandMoreIcon from '@mui/icons-material/ArrowDropDown';
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import PermissionRegretAlert from "../../Dialogs/PermissionRegretAlert/permissionRegretAlert.js";
// Start Accordian CSS
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `0`,
    borderRadius: "5px",
    marginBottom: '16px',
    overflow: 'auto',
    '& :last-child': {
        marginBottom: "0"
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    borderWidth: "0 1px 1px 1px",
    borderColor: "var(--bluegrey-500)",
    borderStyle: "solid",
    borderRadius: "0 0 5px 5px",
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    backgroundColor: 'var(--secondary-800)',
    "& .MuiAccordionSummary-content": {
        flexDirection: "column",
    },
    '& .MuiAccordionSummary-expandIconWrapper.MuiAccordionSummary-expandIconWrapper': {
        color: "var(--grey-0)"
    },
}));
// End Accordian CSS
export default function QuotationReminder(props) {
    const { rfqJsonData, inquiryId, onApiSuccess } = props;
    const featurePermission = useSelector((state) => state.permissionsReducer.featurePermission);
    const maxDateFeaturePermission = featurePermission?.max_rfq_validity_days?.feature_permission_value;
    const rfqValidityExtensionFeaturePermission = featurePermission?.rfq_validity_extension?.feature_value;
    const rfqValidityExtendFeaturePermissionValue = featurePermission?.rfq_validity_extension?.feature_permission_value;
    const ccUserEmailPermission = props.accountModulePermission["CC User Email"]?.module_activated === "Y";
    const hourlyQuotationReminders = featurePermission?.hourly_quotation_reminders?.feature_value === "Y" ? true : false;
    const reminderStartDate = rfqJsonData?._internal?.reminder?.start_date;
    const reminderTechnicalBid = rfqJsonData?._internal?.reminder?.technical_bid;
    const reminderHourly = rfqJsonData?._internal?.reminder?.hourly;
    const technicalBidFlag = rfqJsonData?.filter_data?.technical_bid;
    const technicalBidDate = reminderTechnicalBid?.valid_date_tb;
    const reminderJustification = rfqJsonData?._internal?.reminder?.justification;
    const reminderIntervalDays = rfqJsonData?._internal?.reminder?.interval_days;
    const quotationReminder = rfqJsonData?._internal?.reminder?.quotation_reminder;
    const quotationReminderSendSms = rfqJsonData?._internal?.reminder?.send_sms;
    const updateAllRFQs = rfqJsonData?._internal?.reminder?.update_all_rfqs;

    // Parse initial date from rfqJsonData
    const rfqValidityDate = dayjs(rfqJsonData.expiry_at, "YYYY-MM-DD HH:mm:ss [IST]").format(
        "YYYY-MM-DD HH:mm:ss [IST]"
    );

    // Parse initial date from rfqJsonData
    const technicalBidValidityDate = dayjs(technicalBidDate, "YYYY-MM-DD HH:mm:ss [IST]").format(
        "YYYY-MM-DD HH:mm:ss [IST]"
    );

    const reminderValidityDate = dayjs(reminderStartDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
    );

    // const reminderValidityDate = dayjs(reminderStartDate, "YYYY-MM-DD").isValid()
    //     ? dayjs(reminderStartDate, "YYYY-MM-DD").format("YYYY-MM-DD")
    //     : null;

    const createdAtDate = dayjs(rfqJsonData?.created_at, "YYYY-MM-DD HH:mm:ss [IST]");
    const maxDateCount = createdAtDate.add(featurePermission?.max_rfq_validity_days?.feature_permission_value, "day");
    const maxDate = createdAtDate && maxDateFeaturePermission
        ? dayjs(createdAtDate).add(maxDateFeaturePermission, "day")
        : null;

    const [mailMenu, setMailMenu] = React.useState(null);
    const [autoExpireValidityDialogOpen, setAutoExpireValidityDialogOpen] = useState(false);
    const [openGroupRFQ, setOpenGroupRFQ] = useState(false);
    const [groupRFQData, setGroupRFQData] = useState(null);
    const [isVendorQuotationComplete, setIsVendorQuotationComplete] = useState(false);
    const [vendorDetails, setVendorDetails] = useState(""); // State to store the API response  
    const [modalContent, setModalContent] = React.useState(""); // State to store modal HTML content
    const [modalOpen, setModalOpen] = React.useState(false); // State to control modal visibility
    const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false); // State to control modal visibility
    const [loading, setLoading] = React.useState(false);
    const [snackbar, setSnackbar] = React.useState(false);
    const [severity, setseverity] = React.useState('')
    const [SnackMessage, setSnackMessage] = React.useState('')
    const [differenceInDays, setDifferenceInDays] = React.useState(0);
    const [justificationErrorMessage, setJustificationErrorMessage] = React.useState("");
    const [rfqValidityErrorMessage, setRfqValidityErrorMessage] = React.useState("");
    const [quoteReminderStartDateErrorMessage, setQuoteReminderStartDateErrorMessage] = React.useState("");
    const [quoteReminderEnableDateErrorMessage, setQuoteReminderEnableDateErrorMessage] = React.useState("");
    const [quoteReminderIntervalErrorMessage, setQuoteReminderIntervalErrorMessage] = React.useState("");
    const [quoteReminderIntervalEmptyErrorMessage, setQuoteReminderIntervalEmptyErrorMessage] = React.useState("");
    const [quoteReminderEnableIntervalErrorMessage, setQuoteReminderEnableIntervalErrorMessage] = React.useState("");
    const [hourlyReminderErrorMessage, setHourlyReminderErrorMessage] = React.useState("");
    const [groupRfqMainData, setGroupRfqMainData] = React.useState(null);
    const [technicalBidReminderIntervalEmptyErrorMessage, setTechnicalBidReminderIntervalEmptyErrorMessage] = React.useState("");
    const [formState, setFormState] = useState({
        sendSms: quotationReminderSendSms,
        rfqValidity: rfqValidityDate,
        quoteReminderStartDate: reminderValidityDate,
        intervalInDays: reminderIntervalDays,
        enableQuotationReminder: quotationReminder,
        updateValidityReminder: updateAllRFQs,
        enableHourlyReminder: reminderHourly?.reminder,
        hourlyReminderHours: reminderHourly?.reminder_hours,
        sendSmsHourlyReminder: reminderHourly?.sms,
        enableTechnicalBidReminders: reminderTechnicalBid?.reminder,
        technicalBidValidity: technicalBidValidityDate,
        technicalBidIntervalInDays: reminderTechnicalBid?.reminder_interval_days,
        justification: reminderJustification,
    });
    const [validationResult, setValidationResult] = useState({
        errorFlag: 0,
        errorMsg: "",
        errorType: ""
    });


    const rfqvalidityDate = formState?.rfqValidity;
    // Parse and standardize dates
    const newRfqValidityDate = dayjs(rfqvalidityDate, "YYYY-MM-DD HH:mm:ss [IST]"); // Keep time intact
    const formattedMaxDate = dayjs(maxDate); // Ensure maxDate keeps its time

    const openMailMenu = Boolean(mailMenu);
    const mailMenuClick = (event) => {
        setMailMenu(event.currentTarget);
    };

    const mailMenuClose = () => {
        setMailMenu(null);
    };

    const handleCloseSnackbar = () => {
        setSnackbar(false);
    };

    const emailIds = rfqJsonData?.cc_user_email_id
        ? rfqJsonData?.cc_user_email_id.split(',')
        : [];

    const handleChange = (field, value) => {
        setFormState((prev) => {
            // If the "enableQuotationReminder" field is being toggled,
            // ensure that "sendSms" is set to false.
            if (field === "enableQuotationReminder" && !value) {
                // If "enableQuotationReminder" is turned off, set "sendSms" to false
                return {
                    ...prev,
                    [field]: value,
                    sendSms: false,
                };
            }

            // Otherwise, handle the normal form field change
            return {
                ...prev,
                [field]:
                    (field === "rfqValidity" || field === "quoteReminderStartDate") && value
                        ? dayjs(value).format("YYYY-MM-DD HH:mm:ss [IST]") // Format for rfqValidity and quoteReminderStartDate
                        : value, // Directly update other fields
            };
        });

        // Clear specific error messages
        if (field === "justification" && value.trim() !== "") {
            setJustificationErrorMessage(""); // Clear the error message for justification
        }

        if (field === "quoteReminderStartDate" && value) {
            setQuoteReminderStartDateErrorMessage(""); // Clear error for quoteReminderStartDate
        }

        if (field === "quoteReminderEnableDateErrorMessage" && value) {
            setQuoteReminderEnableDateErrorMessage(""); // Clear error for quoteReminderEnableDate
        }

        if (field === "technicalBidReminderIntervalEmptyErrorMessage" && value) {
            setTechnicalBidReminderIntervalEmptyErrorMessage("");
        }
    };


    const validateForm = () => {
        let isValid = true;

        // Clear previous error messages
        setRfqValidityErrorMessage("");
        setHourlyReminderErrorMessage("");
        setJustificationErrorMessage("");
        setQuoteReminderStartDateErrorMessage("");
        setQuoteReminderEnableDateErrorMessage("");
        setQuoteReminderIntervalEmptyErrorMessage("");
        setTechnicalBidReminderIntervalEmptyErrorMessage("");
        setQuoteReminderEnableIntervalErrorMessage("");
        setQuoteReminderIntervalErrorMessage("");
        setDifferenceInDays(0);

        // let isValidQuoteReminder = !formState.quoteReminderStartDate || formState.quoteReminderStartDate === null || formState.quoteReminderStartDate === "null";

        const isValidQuoteReminder =
            !quotationReminder ||
            formState.quoteReminderStartDate === null ||
            formState.quoteReminderStartDate === "null";

        // Validate quote reminder start date
        // if (isValidQuoteReminder && formState.enableQuotationReminder) {
        if (formState.enableQuotationReminder) {
            const startDate = dayjs(formState.quoteReminderStartDate, "YYYY-MM-DD").startOf("day");
            const today = dayjs().startOf("day"); // Get current date at midnight

            if (!formState.quoteReminderStartDate || !startDate.isValid()) {
                setQuoteReminderStartDateErrorMessage("Enter a valid Quotation Reminder Date.");
                isValid = false;
            } else if (startDate.isBefore(today)) {
                setQuoteReminderStartDateErrorMessage("Quotation reminder date cannot be in the past.");
                isValid = false;
            }
        }

        if (formState?.rfqValidity) {
            // Compare dates
            if (formattedMaxDate && formattedMaxDate.isBefore(newRfqValidityDate)) {
                setRfqValidityErrorMessage(
                    `Maximum RFQ Validity is ${formattedMaxDate.format("DD/MM/YYYY HH:mm:ss")} IST`
                );
                isValid = false;
            }
        }

        if (formState.enableQuotationReminder) {
            const startDate = formState?.quoteReminderStartDate;
            const rfqvalidityDate = formState?.rfqValidity;

            // Parse dates and align time zones
            const quoteReminderValidityDate = dayjs(startDate, "YYYY-MM-DD").startOf("day");
            const newRfqValidityDate = dayjs(rfqvalidityDate, "YYYY-MM-DD HH:mm:ss [IST]").startOf("day");

            // Compare the dates
            if (quoteReminderValidityDate.isAfter(newRfqValidityDate)) {
                setQuoteReminderStartDateErrorMessage(
                    "Quotation reminder date should be lesser than validity date"
                );
                isValid = false;
            }
        }

        if (!formState?.justification) {
            setJustificationErrorMessage("Justification field cannot be blank.");
            isValid = false;
        }

        if (!formState.hourlyReminderHours && formState.enableHourlyReminder) {
            setHourlyReminderErrorMessage("Enter valid hourly reminder in hours.");
            isValid = false;
        }

        if (quotationReminder && isValidQuoteReminder && !formState?.enableQuotationReminder) {
            setQuoteReminderEnableDateErrorMessage("Quotation reminder date can be entered only if Quotation reminder is enabled.");
            isValid = false;
        }

        if (quotationReminder && !formState.intervalInDays && !formState.enableQuotationReminder) {
            setQuoteReminderEnableIntervalErrorMessage("Interval for sending new Quotation reminder can be entered only if Quotation reminder is enabled.");
            isValid = false;
        }

        if (!formState.intervalInDays && formState.enableQuotationReminder) {
            setQuoteReminderIntervalEmptyErrorMessage("Enter valid interval for sending reminders for Quotation in days.");
            isValid = false;
        }

        if (!formState.technicalBidIntervalInDays && formState.enableTechnicalBidReminders) {
            setTechnicalBidReminderIntervalEmptyErrorMessage("Enter valid technical bid interval for sending reminders for Quotation in days.");
            isValid = false;
        }

        // Check interval validity
        if (formState.intervalInDays && formState.enableQuotationReminder) {
            const startDate = formState.quoteReminderStartDate;
            const rfqvalidityDate = formState.rfqValidity;

            const quoteReminderValidityDate = dayjs(startDate, "YYYY-MM-DD");
            const newRfqValidityDate = dayjs(rfqvalidityDate, "YYYY-MM-DD HH:mm:ss [IST]");

            let differenceInValidityDates;
            if (!quoteReminderValidityDate.isSame(newRfqValidityDate, "day")) {
                const differenceInDays = newRfqValidityDate.diff(quoteReminderValidityDate, "days");
                differenceInValidityDates = Math.abs(differenceInDays) + 1;
                setDifferenceInDays(differenceInValidityDates);
            }

            if (formState.intervalInDays > differenceInValidityDates) {
                setQuoteReminderIntervalErrorMessage(
                    `Intervals for sending new quotation reminder should be less than or equal to the difference between quotation reminder start date and validity date in days (${differenceInValidityDates} days)`
                );
                isValid = false;
            }
        }

        return isValid;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;
        setLoading(true);
        try {
            // Format the dates without IST and convert to UTC
            let formattedQuoteReminderStartDate;
            if (formState?.quoteReminderStartDate === "Invalid Date") {
                formattedQuoteReminderStartDate = dayjs(formState?.quoteReminderStartDate, "YYYY-MM-DD", true).isValid()
                    ? dayjs(formState?.quoteReminderStartDate, "YYYY-MM-DD").format("YYYY-MM-DD")
                    : null;
            } else {
                formattedQuoteReminderStartDate = dayjs(formState?.quoteReminderStartDate, "YYYY-MM-DD").format("YYYY-MM-DD");
            }
            const formattedRfqValidity = dayjs(formState?.rfqValidity, "YYYY-MM-DD HH:mm:ss [IST]").format("YYYY-MM-DD HH:mm:ss");
            const formattedTechnicalBidValidity = dayjs(formState?.technicalBidValidity, "YYYY-MM-DD HH:mm:ss [IST]").format("YYYY-MM-DD HH:mm:ss");

            // Construct the payload with conditional parameters
            const payload = {
                quotation_reminder: formState?.enableQuotationReminder,
                ...(formState?.enableQuotationReminder && {
                    start_date: formattedQuoteReminderStartDate,
                    interval_days: formState.intervalInDays,
                }),
                valid_date: formattedRfqValidity,
                send_sms: formState.sendSms,
                // update_all_rfqs: formState.updateValidityReminder,
                justification: formState.justification,

                // Include hourly reminder params only if enableHourlyReminder is true
                ...(hourlyQuotationReminders && formState.enableHourlyReminder && {
                    hourly: {
                        reminder: formState.enableHourlyReminder,
                        send_sms: formState.sendSmsHourlyReminder,
                        reminder_hours: Number(formState?.hourlyReminderHours),
                    },
                }),

                // Include technical bid reminder params only if enableTechnicalBidReminders is true
                // ...(reminderTechnicalBid && formState.enableTechnicalBidReminders && {
                ...(reminderTechnicalBid && {
                    technical_bid: {
                        reminder: formState?.enableTechnicalBidReminders,
                        validity_date: formattedTechnicalBidValidity,
                        reminder_interval_days: Number(formState?.technicalBidIntervalInDays),
                    }
                }),

                ...(rfqJsonData.group_id > 0 && { update_all_rfqs: formState.updateValidityReminder }),
            };

            // Perform the API call
            const response = await axios.patch(getQuotationReminders(inquiryId), payload);

            // Check if the response is successful
            if (response.status === 200) {
                if (response.data.data) {
                    setOpenGroupRFQ(true);
                    setGroupRFQData(response.data.data);
                } else {
                    setModalOpen(true);
                }
                setModalContent("Quotation Reminder is Updated Successfully!!")
                onApiSuccess(); // Call the parent's function
            }
        } catch (error) {
            // Handle any errors during the API call
            console.error("Error submitting data:", error);
            setSnackbar(true);
            setseverity('error')
            if (error?.response?.data.length > 0) {
                setSnackMessage(error?.response?.data[0].error)
            } else {
                setSnackMessage(error?.response?.data.error
                    ? error?.response?.data.error
                    : "Something went wrong")
            }
            // alert("Error submitting data. Please try again later.");
        } finally {
            setLoading(false); // Hide loader
        }
    };

    const handleUpdate = () => {
        // Validate the RFQs
        const validationResult = validateRFQs();

        if (validationResult.errorFlag) {
            // Handle validation failure
            setValidationResult(validationResult); // Update state if needed
            setAutoExpireValidityDialogOpen(true); // Open dialog or show an error notification
            return; // Exit if validation fails
        }

        // If validation passes, validate the form
        if (!validateForm()) {
            return; // Exit if form validation fails
        }

        // Open the confirmation modal if all validations pass
        setConfirmDialogOpen(true);
    };


    const handleCancel = () => {
        // Reset validation error messages or form state if necessary
        setDifferenceInDays(0);
        setHourlyReminderErrorMessage("");
        setRfqValidityErrorMessage("");
        setJustificationErrorMessage("");
        setQuoteReminderIntervalErrorMessage("");
        setQuoteReminderStartDateErrorMessage("");
        setQuoteReminderEnableDateErrorMessage("");
        setQuoteReminderIntervalEmptyErrorMessage("");
        setQuoteReminderEnableIntervalErrorMessage("");
        setTechnicalBidReminderIntervalEmptyErrorMessage("");

        // Reset form state (or leave it as is if you want to preserve the state when the drawer is closed)
        setFormState({
            sendSms: quotationReminderSendSms,
            rfqValidity: rfqValidityDate,
            quoteReminderStartDate: reminderValidityDate,
            intervalInDays: reminderIntervalDays,
            enableQuotationReminder: quotationReminder,
            updateValidityReminder: updateAllRFQs,
            enableHourlyReminder: reminderHourly?.reminder,
            hourlyReminderHours: reminderHourly?.reminder_hours,
            sendSmsHourlyReminder: reminderHourly?.sms,
            enableTechnicalBidReminders: reminderTechnicalBid?.reminder, //Replace multi_plant with technical_bid
            technicalBidValidity: technicalBidValidityDate,
            technicalBidIntervalInDays: reminderTechnicalBid?.reminder_interval_days,
            justification: reminderJustification,
        });

        // Close the reminder drawer
        props.CloseReminderDrawer();
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setModalContent("");
        handleCancel();
    };

    const handleCloseGroupRFQModal = () => {
        setOpenGroupRFQ(false);
        handleCancel();
    };

    useEffect(() => {
        // const rfqValidityDate = dayjs(rfqJsonData?.expiry_at?.replace(' IST', '')).format('YYYY-MM-DDTHH:mm:ss');
        const formattedQuoteReminderStartDate = dayjs(formState?.quoteReminderStartDate, "YYYY-MM-DD", true).isValid()
            ? dayjs(formState?.quoteReminderStartDate, "YYYY-MM-DD").format("YYYY-MM-DD")
            : null;
        const rfqValidityDate = dayjs(rfqJsonData.expiry_at, "YYYY-MM-DD HH:mm:ss [IST]").format(
            "YYYY-MM-DD HH:mm:ss [IST]"
        );
        const reminderValidityDate = dayjs(reminderStartDate, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
        );
        const technicalBidValidityDate = dayjs(technicalBidDate, "YYYY-MM-DD HH:mm:ss [IST]").format(
            "YYYY-MM-DD HH:mm:ss [IST]"
        );
        setFormState(prevState => ({
            ...prevState,
            sendSms: quotationReminderSendSms,
            rfqValidity: rfqValidityDate,
            quoteReminderStartDate: reminderValidityDate,
            intervalInDays: reminderIntervalDays,
            enableQuotationReminder: quotationReminder,
            // updateValidityReminder: false,
            enableHourlyReminder: reminderHourly?.reminder,
            hourlyReminderHours: reminderHourly?.reminder_hours,
            sendSmsHourlyReminder: reminderHourly?.sms,
            enableTechnicalBidReminders: reminderTechnicalBid?.reminder, //Replace multi_plant with technical_bid
            technicalBidValidity: technicalBidValidityDate,
            technicalBidIntervalInDays: reminderTechnicalBid?.reminder_interval_days,
            justification: reminderJustification,
        }));
    }, [rfqJsonData]); // Make sure to only run when rfqJsonData changes

    useEffect(() => {
        const fetchVendorDetailsAndCheckStatus = async () => {
            try {
                if (inquiryId) {
                    const response = await axios.get(getVendorsDetails(inquiryId));
                    const vendors = response.data.vendors;
                    setVendorDetails(vendors);

                    // Check quotation status
                    const hasIncompleteQuotation = vendors.some((vendor) => {
                        return (
                            !vendor.quotation ||
                            vendor.quotation.status === null ||
                            vendor.quotation.status === "Pending"
                        );
                    });
                    setIsVendorQuotationComplete(hasIncompleteQuotation);
                }
            } catch (error) {
                console.error("Error fetching vendor details:", error);
            }
        };

        fetchVendorDetailsAndCheckStatus();
    }, [inquiryId]);

    // Start Vendor Name String Avatar
    function stringAvatar(name = "") {
        const value = name.split(' ');
        return {
            children: name ? `${value[0][0]}${value[1] ? value?.[1][0] : ""}` : "",
        };
    }

    {/* Extend RFQ validity check start */ }


    useEffect(() => {
        const getGroupRfqData = () => {
            axios
                .get(getGroupRfq(rfqJsonData?.group_id), {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${props.newSessionID}`,
                    },
                })
                .then((response) => {
                    if (response.data) {
                        setGroupRfqMainData(response.data);
                    }
                })
                .catch((error) => {
                    console.error("There was a problem with your axios operation:", error);
                });
        };
        if (rfqJsonData?.group_id > 0) {
            getGroupRfqData();
        }
    }, [props?.newSessionID, rfqJsonData?.group_id]);

    // Utility: Check if RFQ validity is within the allowed extension range
    const checkRFQValidity = (rfq_validity_Date_for_expension, rfq_validity_extension_value) => {
        const rfqDate = dayjs(rfq_validity_Date_for_expension);
        const currentDate = dayjs();
        const extensionTimeInSeconds = rfq_validity_extension_value * 60 * 60;
        const timeDifferenceInSeconds = rfqDate.diff(currentDate, "second");

        return timeDifferenceInSeconds <= extensionTimeInSeconds;
    };

    const rfqDateValidationData = groupRfqMainData;

    const validateRFQs = () => {
        if (rfqValidityExtensionFeaturePermission === "Y") {
            const updateAllRFQs = rfqJsonData?._internal?.reminder?.update_all_rfqs;
            const rfqCount = rfqDateValidationData?.length;

            if (updateAllRFQs && rfqCount > 1) {
                const RFQs = [];
                let selectRFQValid = false;

                const grfqError = Object.entries(rfqDateValidationData || {}).map(([key, data]) => {
                    // const grfqError = rfqDateValidationData.map(([key, data]) => {
                    const quotedVendor = data?._internal?.vendor_count?.quoted;
                    if (quotedVendor >= 1 && (data.status === "Ongoing" || data.status === "Expired" || data.status === "Pending")) {
                        const expiryAt = rfqJsonData?.expiry_at.replace(" IST", ""); // Remove the "IST"
                        const rfqDate = dayjs(expiryAt, "YYYY-MM-DD HH:mm:ss"); // Parse the date string
                        const isExpired = rfqDate.isBefore(dayjs());

                        if (isExpired || checkRFQValidity(data.expiry_at, rfqValidityExtendFeaturePermissionValue)) {
                            RFQs.push(data.InquiryID);
                            // if (rfqDateValidationData.InquiryNo === data.InquiryNO) {
                            if (inquiryId === data.InquiryID) {
                                selectRFQValid = true;
                            }
                            return 1; // Increment the error count
                        }
                    }
                    return 0; // No error
                })
                    .reduce((acc, curr) => acc + curr, 0); // Summing up error counts

                if (grfqError > 0) {
                    let errorMsg = "";

                    if (grfqError === Object.keys(rfqDateValidationData).length) {
                        // if (grfqError === Object.keys(groupRFQValidityDate).length) {
                        errorMsg = `Extension of ${RFQs.join(", ")} validity has been restricted.`;
                    } else if (selectRFQValid) {
                        errorMsg = `Extension of ${RFQs.join(
                            ", "
                        )} validity has been restricted. Please deselect "Update Validity & Reminders for all RFQs" to extend validity of RFQ "${inquiryId}".`;
                    } else {
                        errorMsg = `Extension of RFQ ${RFQs.join(
                            ", "
                        )} validity has been restricted. Please open RFQ ${inquiryId} individually from the Transaction register to extend the validity.`;
                    }

                    return {
                        errorFlag: 1,
                        errorMsg,
                        errorType: "restrict_validity_date"
                    };
                }
            } else {
                if (
                    rfqJsonData?._internal?.vendor_count?.quoted >= 1 &&
                    (rfqJsonData?.status === "Ongoing" || rfqJsonData?.status === "Expired" || rfqJsonData?.status === "Pending")
                    // (rfqDateValidationData.transationStatus === "10" || rfqDateValidationData.transationStatus === "20")
                ) {
                    const expiryAt = rfqJsonData?.expiry_at.replace(" IST", ""); // Remove the "IST"
                    const rfqDate = dayjs(expiryAt, "YYYY-MM-DD HH:mm:ss"); // Parse the date string
                    const isExpired = rfqDate.isBefore(dayjs());
                    if (
                        rfqDate.isBefore(dayjs()) ||
                        rfqJsonData?.status === "Closed" ||
                        checkRFQValidity(
                            // rfqDateValidationData.rfq_validity_Date_for_expension,
                            rfqDate,
                            rfqValidityExtendFeaturePermissionValue
                        )
                    ) {
                        return {
                            errorFlag: 1,
                            errorMsg: "Extension of RFQ validity has been restricted.",
                            errorType: "restrict_validity_date"
                        };
                    }
                }
            }
        }

        return {
            errorFlag: 0,
            errorMsg: "",
            errorType: ""
        };
    };

    const handleAutoExpireValidityDialogClose = () => {
        setAutoExpireValidityDialogOpen(false);
        setGroupRfqMainData(null);
        handleCancel();
    };

    {/* Extend RFQ validity check end */ }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading} // Show backdrop when loading is true
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <QuoteReminderDrawer
                anchor="right"
                open={props.openReminderDrawer}
                // onClose={props.CloseReminderDrawer}
                disableBackdropClick
                className="SidebarDrawer"
            >
                <Card>
                    <CardHeader
                        action={
                            <Tooltip title="Close">
                                <IconButton
                                    onClick={props.CloseReminderDrawer}
                                    aria-label="close"
                                    size="small"
                                    sx={{ color: "var(--grey-500)" }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        title={
                            <Typography
                                variant="subtitle1"
                                fontWeight={500}
                                component="h6"
                                color={"secondary.main"}
                                lineHeight={"normal"}
                            >
                                Update Validity and Reminder
                            </Typography>
                        }
                        subheader={
                            <React.Fragment>
                                <Typography
                                    variant="body2"
                                    color={"text.primary"}
                                    component="span"
                                    whiteSpace={"nowrap"}
                                >
                                    RFQ No:
                                </Typography>
                                <Tooltip title={rfqJsonData?.ref_no} placement="bottom-start">
                                    <Typography
                                        variant="body2"
                                        fontWeight={500}
                                        color={"text.primary"}
                                        component="span"
                                        noWrap
                                    >
                                        {rfqJsonData?.ref_no}
                                    </Typography>
                                </Tooltip>
                            </React.Fragment>
                        }
                    />
                    <Divider />
                    <CardContent>
                        <List disablePadding sx={{ '& .MuiListItem-root': { flexDirection: "column", alignItems: "start", pb: 2, pt: 2 }, '& > :not(:last-child)': { pb: 0 } }}>
                            <ListItem>
                                <Typography variant="body2" component="label" color={'grey.500'}>Category Name</Typography>
                                <Tooltip title={rfqJsonData.categories?.[0]?.name} placement="bottom-start">
                                    <Typography variant="body2" component="p" color={'text.primary'} noWrap>{rfqJsonData.categories?.[0]?.name}</Typography>
                                </Tooltip>
                            </ListItem>
                            {ccUserEmailPermission && (
                                <ListItem>
                                    <Typography
                                        variant="body2"
                                        component="label"
                                        display="block"
                                        color={'grey.500'}
                                    >
                                        CC Users’ Mails
                                    </Typography>
                                    <Box
                                        display={"inline-flex"}
                                        alignItems={"center"}
                                        gap={"8px"}
                                        sx={{ cursor: "pointer" }}
                                        onClick={emailIds.length > 1 ? mailMenuClick : null}
                                    >
                                        <Tooltip
                                            title={emailIds[0] || "No Emails"}
                                            placement="bottom-start"
                                        >
                                            <Typography
                                                variant="body2"
                                                component="p"
                                                color={'text.primary'}
                                                noWrap
                                            >
                                                {emailIds[0] || "No Emails"}
                                            </Typography>
                                        </Tooltip>
                                        {emailIds?.length > 1 && (
                                            <Typography
                                                variant="body2"
                                                component="a"
                                                color={'primary.500'}
                                                sx={{ textDecoration: "underline" }}
                                            >
                                                +{emailIds?.length - 1}
                                            </Typography>
                                        )}
                                    </Box>

                                    {/* Email Menu */}
                                    <Menu
                                        anchorEl={mailMenu}
                                        id="account-menu"
                                        open={Boolean(mailMenu)}
                                        onClose={mailMenuClose}
                                        slotProps={{
                                            paper: {
                                                elevation: 0,
                                                sx: {
                                                    overflow: 'visible',
                                                    border: "1px solid var(--bluegrey-500)",
                                                    mt: 1.5,
                                                    '&::before': {
                                                        content: '""',
                                                        display: 'block',
                                                        position: 'absolute',
                                                        top: -1,
                                                        right: 14,
                                                        width: 10,
                                                        height: 10,
                                                        bgcolor: 'background.paper',
                                                        transform: 'translateY(-50%) rotate(45deg)',
                                                        zIndex: 0,
                                                        borderTop: "1px solid var(--bluegrey-500)",
                                                        borderLeft: "1px solid var(--bluegrey-500)"
                                                    },
                                                    "&.MuiPaper-root .MuiSvgIcon-root": {
                                                        fontSize: "18px",
                                                        ml: 1,
                                                        color: "var(--primary-500)"
                                                    }
                                                },
                                            }
                                        }}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >
                                        {emailIds?.map((email, index) => (
                                            <MenuItem key={index} onClick={mailMenuClose}>
                                                <Typography variant="body2">{email.trim()}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </ListItem>
                            )}
                        </List>
                        <Divider />
                        <Box
                            component="form"
                            sx={{ "& > :not(style)": { mb: 2 } }}
                            p={2}
                            noValidate
                            autoComplete="off"
                        >
                            {rfqJsonData.group_id > 0 && (
                                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={"8px"}>
                                    <Box>
                                        <Typography variant="body2" color={"grey.900"} lineHeight={"normal"}>Update Validity and Reminder - All RFQ</Typography>
                                        {/* <Typography variant="caption" color={"grey.500"} >Default Filter Preferences would be applied</Typography> */}
                                    </Box>
                                    <Switch
                                        checked={formState.updateValidityReminder}
                                        onChange={(e) => handleChange("updateValidityReminder", e.target.checked)}
                                    />
                                </Box>
                            )}

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    label="RFQ Validity"
                                    ampm={false}
                                    value={formState.rfqValidity ? dayjs(formState.rfqValidity, "YYYY-MM-DD HH:mm:ss [IST]") : null}
                                    onChange={(date) => handleChange("rfqValidity", date)} // Pass raw date
                                    minDateTime={dayjs()} // Disable past dates and times
                                    // maxDateTime={maxDateCount} // Restrict to the calculated maximum date
                                    maxDateTime={maxDate} // Restrict to the calculated maximum date
                                    format="DD/MM/YYYY HH:mm A"
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            readOnly: true,
                                            size: "small",
                                            error: formState.rfqValidity && Boolean(rfqValidityErrorMessage),
                                            helperText: formState.rfqValidity && rfqValidityErrorMessage
                                                ? `${rfqValidityErrorMessage} - Maximum RFQ Validity is ${formattedMaxDate?.isValid() ? formattedMaxDate.format("DD/MM/YYYY HH:mm:ss") : ""} IST`
                                                : formattedMaxDate?.isValid() ? "Maximum RFQ Validity is " + formattedMaxDate.format("DD/MM/YYYY HH:mm:ss") + " IST" : ""
                                        },
                                    }}
                                />
                            </LocalizationProvider>

                            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={"8px"}>
                                <Box>
                                    <Typography variant="body2" color={"grey.900"} lineHeight={"normal"}>Enable Quotation Reminder</Typography>
                                    {/* <Typography variant="caption" color={"grey.500"} >Default Filter Preferences would be applied</Typography> */}
                                </Box>
                                <Switch
                                    checked={formState.enableQuotationReminder}
                                    onChange={(e) => handleChange("enableQuotationReminder", e.target.checked)}
                                />
                            </Box>

                            <TextField
                                type="number"
                                label="Interval in Days"
                                value={formState.intervalInDays}
                                error={!formState.enableQuotationReminder ? Boolean(quoteReminderEnableIntervalErrorMessage) : Boolean(quoteReminderIntervalErrorMessage || quoteReminderIntervalEmptyErrorMessage)}
                                helperText={!formState.enableQuotationReminder ? quoteReminderEnableIntervalErrorMessage : quoteReminderIntervalErrorMessage || quoteReminderIntervalEmptyErrorMessage}
                                inputProps={{
                                    max: 11, // Maximum value
                                    min: 1,  // Minimum value
                                }}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    value = value.replace(/^0+(?!$)/, ""); // Remove leading zeroes
                                    if (value) {
                                        value = Math.max(1, Math.min(11, Number(value))); // Clamp between 1 and 11
                                    }
                                    handleChange("intervalInDays", value);
                                }}
                                // onBlur={(e) => {
                                //     let value = e.target.value;
                                //     value = Math.max(1, Math.min(11, Number(value))); // Clamp between 1 and 11
                                //     value = value.toString().replace(/^0+(?!$)/, ""); // Final cleanup
                                //     handleChange("intervalInDays", value);
                                // }}
                                fullWidth
                                size="small"
                                disabled={!formState.enableQuotationReminder}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Enter 7 if 1 reminder has to be sent after every 7 days.">
                                                <HelpIcon sx={{ color: "var(--secondary-600)" }} />
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />


                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Quote Reminder Start Date"
                                    value={
                                        formState?.quoteReminderStartDate
                                            ? dayjs(formState?.quoteReminderStartDate, "YYYY-MM-DD")
                                            : null
                                    }
                                    minDate={dayjs().add(1, "day")} // Sets the minimum date to one day in the future
                                    maxDate={dayjs(formState?.rfqValidity, "YYYY-MM-DD")}
                                    onChange={(date) => handleChange("quoteReminderStartDate", date)}
                                    format="DD/MM/YYYY"
                                    disabled={!formState?.enableQuotationReminder}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            size: "small",
                                            readOnly: true,
                                            error: !formState.enableQuotationReminder
                                                ? Boolean(quoteReminderEnableDateErrorMessage)
                                                : Boolean(quoteReminderStartDateErrorMessage),
                                            helperText: (
                                                <>
                                                    {!formState.enableQuotationReminder ? (
                                                        <Typography variant="caption" color="error">
                                                            {quoteReminderEnableDateErrorMessage}
                                                        </Typography>
                                                    ) : quoteReminderStartDateErrorMessage ? (
                                                        <Typography variant="caption" color="error">
                                                            {quoteReminderStartDateErrorMessage}
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            variant="caption"
                                                            color="grey.500"
                                                        // sx={{ display: "block" }}
                                                        >
                                                            Quotation reminder date should be lesser than validity date
                                                        </Typography>
                                                    )}
                                                </>
                                            ),
                                        },
                                    }}
                                />
                            </LocalizationProvider>

                            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={"8px"}>
                                <Box>
                                    <Typography variant="body2" color={"grey.900"} lineHeight={"normal"}>Send SMS with Quote Reminders</Typography>
                                    {/* <Typography variant="caption" color={"grey.500"} >Default Filter Preferences would be applied</Typography> */}
                                </Box>
                                <Switch
                                    checked={formState.sendSms}
                                    disabled={!formState.enableQuotationReminder}
                                    onChange={(e) => handleChange("sendSms", e.target.checked)}
                                />
                            </Box>
                            {hourlyQuotationReminders && (
                                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={"8px"}>
                                    <Box>
                                        <Typography variant="body2" color={"grey.900"} lineHeight={"normal"}>Enable Hourly Quotation Reminder</Typography>
                                        {/* <Typography variant="caption" color={"grey.500"}>Default Filter Preferences would be applied</Typography> */}
                                    </Box>
                                    <Switch
                                        checked={formState.enableHourlyReminder}
                                        disabled={props.rfqJsonData.status === "Expired"}
                                        onChange={(e) => handleChange("enableHourlyReminder", e.target.checked)}
                                    />
                                </Box>)}

                            {hourlyQuotationReminders && formState.enableHourlyReminder && (
                                <TextField
                                    type="number"
                                    label="Hourly Reminder"
                                    value={formState.hourlyReminderHours || ""} // Set empty string for falsy values like 0
                                    error={formState.enableHourlyReminder && Boolean(hourlyReminderErrorMessage)}
                                    helperText={formState.enableHourlyReminder && hourlyReminderErrorMessage}
                                    inputProps={{
                                        max: 11, // Maximum value
                                        min: 1,  // Minimum value
                                    }}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        value = value.replace(/^0+(?!$)/, ""); // Remove leading zeroes
                                        if (value) {
                                            value = Math.max(1, Math.min(11, Number(value))); // Clamp between 1 and 11
                                            handleChange("hourlyReminderHours", value);
                                        } else {
                                            handleChange("hourlyReminderHours", ""); // Set to empty string if no valid value
                                        }
                                    }}
                                    fullWidth
                                    size="small"
                                />
                            )}

                            {hourlyQuotationReminders && formState.enableHourlyReminder && (
                                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={"8px"}>
                                    <Box>
                                        <Typography variant="body2" color={"grey.900"} lineHeight={"normal"}>Send SMS with Hourly Quotation reminders</Typography>
                                        {/* <Typography variant="caption" color={"grey.500"} >Default Filter Preferences would be applied</Typography> */}
                                    </Box>
                                    <Switch
                                        checked={formState.sendSmsHourlyReminder}
                                        disabled={!formState.enableHourlyReminder}
                                        onChange={(e) => handleChange("sendSmsHourlyReminder", e.target.checked)}
                                    />
                                </Box>
                            )}

                            {technicalBidFlag && (
                                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={"8px"}>
                                    <Box>
                                        <Typography variant="body2" color={"grey.900"} lineHeight={"normal"}>Enable Technical Bid Reminders</Typography>
                                        {/* <Typography variant="caption" color={"grey.500"} >Default Filter Preferences would be applied</Typography> */}
                                    </Box>
                                    <Switch
                                        checked={formState.enableTechnicalBidReminders}
                                        onChange={(e) =>
                                            handleChange("enableTechnicalBidReminders", e.target.checked)
                                        }
                                    />
                                </Box>
                            )}

                            {technicalBidFlag && (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        label="Technical Bid Validity"
                                        ampm={false}
                                        value={formState.technicalBidValidity ? dayjs(formState.technicalBidValidity, "YYYY-MM-DD HH:mm:ss [IST]") : null}
                                        disabled={!formState.enableTechnicalBidReminders}
                                        onChange={(date) => handleChange("technicalBidValidity", date)} // Pass raw date
                                        minDateTime={dayjs()} // Disable past dates and times
                                        maxDateTime={dayjs(formState?.rfqValidity, "YYYY-MM-DD HH:mm:ss [IST]")} // Disable past dates and times
                                        // maxDateTime={maxDateCount} // Restrict to the calculated maximum date
                                        // maxDateTime={maxDate} // Restrict to the calculated maximum date
                                        format="DD/MM/YYYY HH:mm A"
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                size: "small",
                                                error: false,
                                                readOnly: true,
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            )}

                            {technicalBidFlag && (
                                <TextField
                                    type="number"
                                    label="Technical Bid Reminder Interval Days"
                                    value={formState.technicalBidIntervalInDays}
                                    disabled={!formState.enableTechnicalBidReminders}
                                    error={formState.enableTechnicalBidReminders && Boolean(technicalBidReminderIntervalEmptyErrorMessage)}
                                    // helperText={formState.enableTechnicalBidReminders && technicalBidReminderIntervalEmptyErrorMessage}
                                    helperText={
                                        formState.enableTechnicalBidReminders
                                            ? (technicalBidReminderIntervalEmptyErrorMessage ||
                                                "(Reminders will start only after the Quotation is submitted.)")
                                            : "(Reminders will start only after the Quotation is submitted.)"
                                    }
                                    inputProps={{
                                        max: 11, // Maximum value
                                        min: 1,  // Minimum value
                                    }}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        value = value.replace(/^0+(?!$)/, ""); // Remove leading zeroes
                                        if (value) {
                                            value = Math.max(1, Math.min(11, Number(value))); // Clamp between 1 and 11
                                        }
                                        handleChange("technicalBidIntervalInDays", value);
                                    }}
                                    // onBlur={(e) => {
                                    //     let value = e.target.value;
                                    //     value = Math.max(0, Math.min(11, Number(value))); // Clamp between 0 and 11
                                    //     value = value.toString().replace(/^0+(?!$)/, ""); // Final cleanup
                                    //     handleChange("technicalBidIntervalInDays", value);
                                    // }}
                                    fullWidth
                                    size="small"
                                />
                            )}

                            <TextField
                                label="Justification"
                                value={formState.justification}
                                error={Boolean(justificationErrorMessage)}
                                helperText={justificationErrorMessage}
                                onChange={(e) => handleChange("justification", e.target.value)}
                                multiline
                                rows={3}
                                fullWidth
                            />
                        </Box>
                    </CardContent>
                    <Divider />
                    <CardActions sx={{ p: 2, justifyContent: "flex-end" }}>
                        <Button variant="outlined" size="small" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button variant="contained" size="small" onClick={handleUpdate}>
                            Update
                        </Button>
                    </CardActions>
                </Card>
            </QuoteReminderDrawer>
            {/* Modal to display response content */}
            <Dialog
                open={modalOpen}
                onClose={handleCloseModal}
                maxWidth="md"
            >
                <DialogTitle>
                    {/* <IconButton
                        aria-label="close"
                        onClick={handleCloseModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton> */}
                </DialogTitle>
                <DialogContent>
                    <Typography component="div"></Typography>
                    {modalContent}
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Box display="flex" justifyContent="center" width="100%">
                        <Button onClick={handleCloseModal} variant="contained" color="primary">
                            OK
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

            {/* Quotation Form Submit Confirmation */}
            <ConfirmAlert
                open={confirmDialogOpen}
                handleClose={() => {
                    setConfirmDialogOpen(false);
                }}
                handleSubmit={() => {
                    setConfirmDialogOpen(false);
                    handleSubmit();
                }}
                title={"Are you sure you want to continue with the same?"}
                msg={
                    formState.enableQuotationReminder &&
                    (
                        (differenceInDays === 0)
                            ? "1 Quotation reminder will be triggered during the given duration of 1 day (incl. the validity date). The 1st quotation reminder will be sent on the start date."
                            : (differenceInDays % Number(formState?.intervalInDays) === 0)
                                ? `${Math.floor(differenceInDays / Number(formState?.intervalInDays))} Quotation reminders will be triggered during the given duration of ${differenceInDays} days (incl. the validity date). The 1st quotation reminder will be sent on the start date.`
                                : `${Math.floor(differenceInDays / Number(formState?.intervalInDays)) + 1} Quotation reminders will be triggered during the given duration of ${differenceInDays} days (incl. the validity date). The 1st quotation reminder will be sent on the start date.`
                    ) || ((updateAllRFQs || rfqJsonData.group_id > 0) && (`All the RFQ's in this Group will get update.Do you wish to continue?
                    If you wish to update validity and reminder for this RFQ only then please uncheck the required  option in the form.`))
                }
            />

            {/* Group RFQ */}

            <Dialog
                open={openGroupRFQ}
                onClose={handleCloseGroupRFQModal}
                maxWidth={"sm"}
                fullWidth
            >
                <DialogTitle
                    component="div"
                    sx={{ padding: "16px !important" }}
                >
                    <Box width={"calc(100% - 26px)"}>
                        <Typography component="h6" variant="body2" color={"text.primary"} fontWeight={500}>
                            Update Validity and Reminder
                        </Typography>
                        <Typography variant="caption" sx={{ display: "block" }} color={"grey.500"}>
                            A transaction copy mail has been sent to suppliers.
                        </Typography>
                    </Box>
                </DialogTitle>
                <Typography component="h6" ml={2} variant="body2" color="text.primary" fontWeight={500}>
                    {rfqJsonData.group_id > 0 && (
                        <>
                            Group RFQ No: <Box component="span" color="primary.500">{rfqJsonData.group_id}</Box>
                        </>
                    )}
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseGroupRFQModal}
                    sx={{ position: "absolute", right: 8, top: 19 }}
                    size="small"
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ p: 0, pl: 2, pr: 2 }}>
                    {groupRFQData?.map((rfq, index) => (
                        <Accordion key={index} defaultExpanded={index === 0}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel-${index}-content`}
                                id={`panel-${index}-header`}
                            >
                                <Typography variant="caption" sx={{ display: "block" }} color={"grey.500"}>
                                    RFQ NO
                                </Typography>
                                <Typography variant="body2" color={"grey.0"}>
                                    {rfq.inquiry_no}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box
                                    sx={{
                                        background: "var(--grey-100)",
                                        borderBottom: "1px solid var(--bluegrey-500)",
                                        padding: "8px 16px"
                                    }}
                                >
                                    <Typography variant="body2" color={"grey.500"}>VENDOR NAME</Typography>
                                </Box>
                                <List
                                    sx={{
                                        "&.MuiList-root ": {
                                            maxHeight: "158px",
                                            overflow: "auto",
                                            p: 0,
                                            ".MuiListItemText-root": {
                                                m: 0,
                                                lineHeight: "normal"
                                            },
                                            ".MuiListItemAvatar-root": {
                                                minWidth: "40px"
                                            }
                                        }
                                    }}
                                >
                                    {groupRFQData[index]?.vendors?.map((vendor, vendorIndex) => (
                                        <React.Fragment key={vendorIndex}>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        {...stringAvatar(vendor.name)}
                                                        sx={{
                                                            fontSize: "12px",
                                                            bgcolor: "var(--color-primary)",
                                                            width: 32,
                                                            height: 32
                                                        }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            color={"text.primary"}
                                                            fontWeight={500}
                                                            lineHeight={"normal"}
                                                        >
                                                            {vendor.name}
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <Typography
                                                            variant="caption"
                                                            color={"grey.500"}
                                                            lineHeight={"normal"}
                                                        >
                                                            {vendor.code}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider component="li" />
                                        </React.Fragment>
                                    ))}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button onClick={handleCloseGroupRFQModal} variant="contained">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Snackbar */}
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={3000}
                open={snackbar}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={severity}
                    variant="filled"
                >
                    {SnackMessage}
                </Alert>
            </Snackbar>

            {/* Auto exoire RFQ */}
            <PermissionRegretAlert
                open={autoExpireValidityDialogOpen}
                handleClose={handleAutoExpireValidityDialogClose}
                title={"Permission Restricted"}
                msg={validationResult.errorMsg}
            />
        </>
    );
}