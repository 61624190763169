import axios from "axios";
import React, { Component } from "react";
import {
  Select,
  FormControl,
  MenuItem,
  Button,
  Checkbox,
  Typography,
  TextField,
  Tooltip,
  InputAdornment,
  Link,
  FormControlLabel,
  Badge,
  FormGroup,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  getArtworkDescription,
  getMaterialSpecificationFile,
  get_material_specifications,
  getmatspecghclfile,
} from "../../config/apiUrl";
import "../../pages/style.css";
import { openSnackbar } from "../../redux/actions/snackbarAction";
import EstimatedValue from "../Dialogs/EstimatedValue";
import DeliveryScheduleDetails from "../Dialogs/DeliveryScheduleDetails";
import moment from "moment";
import { ErrorOutline, Update } from "@mui/icons-material";
import { OutlineTextfieldLabel } from "../TextField";
import { connect } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { getSymbol } from "../AccountTemplate/CurrencySymbol";
import MultiplantQty from "../QuotationForm/MultiplantQty/MultiplantQty";
//import prconfig from "../../config/prconfig"
class NegotiationFormField extends Component {
  state = {
    deliveryScheduleOpen: false,
    estimatedValueOpen: false,
    value: "",
    checkDisabled: false,
    readOnlyForSwitch: false,
    count: 0,
    selectedCurrency: "",
    keys: "",
    multiplantQtyOpen: false,
    dateValue: null,
  };
  componentDidMount() {
    this.setState({
      value: this?.props?.FieldState?.value,
      checkDisabled: this?.props?.disabled,
      selectedCurrency: this?.props?.selectedCurrency,
      readOnlyForSwitch: this?.props?.readOnlyForSwitch,
      keys: this?.props?.keys,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.props.FieldState?.value !== prevProps.FieldState?.value) {
    if (
      this.props.FieldState !== prevProps.FieldState ||
      this.props.selectedCurrency !== prevProps.selectedCurrency ||
      this.props.readOnlyForSwitch !== prevProps.readOnlyForSwitch ||
      this.props.disabled !== prevProps.disabled ||
      this.props.keys !== prevProps.keys
    ) {
      this.setState({
        value: this?.props?.FieldState?.value,
        checkDisabled: this?.props?.disabled,
        readOnlyForSwitch: this?.props?.readOnlyForSwitch,
        selectedCurrency: this?.props?.selectedCurrency,
        keys: this?.props?.keys,
      });
    }
    if (
      this.props.FieldState &&
      this.props.FieldState.value &&
      this.props.FieldState.value !== prevProps.FieldState?.value &&
      this.props.cell.ElementType === "DT"
    ) {
      let dateValues = null;
      if (typeof this.props.FieldState.value === "string") {
        let arr = this.props.FieldState.value.split("/");
        if (arr.length > 1) {
          dateValues = `${arr[2]}-${arr[1]}-${arr[0]}`;
        }
      }
      this.setState({
        dateValue: dateValues ? dateValues : this.props.FieldState.value,
      });
    }
  }

  setValue = (evt) => {
    this.setState({
      value: evt.target.value,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.value !== nextState.value) {
      return true;
    }
    if (this.state.estimatedValueOpen !== nextState.estimatedValueOpen) {
      return true;
    }
    if (this.state.deliveryScheduleOpen !== nextState.deliveryScheduleOpen) {
      return true;
    }
    if (this.props.FieldState !== nextProps.FieldState) {
      return true;
    }
    if (this.props.FieldState?.error !== nextProps.FieldState?.error) {
      return true;
    }
    if (this.props.FieldState?.options !== nextProps.FieldState?.options) {
      return true;
    }
    if (this.props.upd_budget_master !== nextProps.upd_budget_master) {
      return true;
    }
    if (this.props.qty !== nextProps.qty) {
      return true;
    }
    if (this.props.rate !== nextProps.rate) {
      return true;
    }
    if (this.state.selectedCurrency !== nextProps.selectedCurrency) {
      return true;
    }
    if (this.state.checkDisabled !== nextProps.checkDisabled) {
      return true;
    }
    if (this.state.readOnlyForSwitch !== nextProps.readOnlyForSwitch) {
      return true;
    }
    if (this.state.keys !== nextProps.keys) {
      return true;
    }
    return false;
  }

  handleMaterialSpecificationClick = () => {
    if (this.props.destination) {
      axios
        .get(
          getMaterialSpecificationFile(
            // this.props.item.id,
            this.props.item.new_item_id,
            // this.props.keys,
            this.props.destination
          )
        )
        .then((response) => {
          if (response.data.path) {
            let a = document.createElement("a");
            a.href = response.data.path;
            a.download = "MaterialSpecificationFile";
            a.target = "_blank";
            a.rel = "noopener noreferrer";
            a.click();
          }
        });
    } else {
      this.props.openSnackbar("error", "please select Destination first!");
    }
  };

  handlematspecghclfileClick = () => {
    //this is the palce for mat description
    let pr_dest_code = "";
    // if (this.props.prlineitems[this.props.item.id]?.length > 0) {
    //   pr_dest_code = this.props.LATEST("Destination", this.props.item);
    // }
    axios
      .get(
        getmatspecghclfile(
          this.props.inquiryId,
          this.props.item.code,
          "Inquiry",
          pr_dest_code
        )
      )
      .then((response) => {
        if (response.data.path) {
          let a = document.createElement("a");
          a.href = response.data.path;
          a.download = "Matspecghclfile";
          a.target = "_blank";
          a.rel = "noopener noreferrer";
          a.click();
        }
      });
  };

  openLink() {
    const link =
      "/cgi-bin/vendx/index.cgi?vendx_action=get_material_specifications&UID=53616c7465645f5f8d8b8aeb500390a0bce740eebae49d0ddc11e83959f70f6d24767952a1b54258";
    window.open(link, "_blank");
  }

  getMaterialSpecifications = (uid) => {
    const form = document.createElement("form");
    form.target = "_blank";
    form.action = get_material_specifications + uid;
    form.method = "POST"; // or "GET" if needed

    const hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    hiddenInput.name = "UID";
    hiddenInput.value = uid;

    form.appendChild(hiddenInput);
    document.body.appendChild(form);
    form.submit();
  };

  ArtworkDescription = () => {
    // let batch_no = this.props.batch_id;
    // if (this.props.prlineitems[this.props.item.id]?.length > 0) {
    //   batch_no = this.props.LATEST("PRBatchNo", this.props.item);
    // }
    // changes made after confirming with devarshi mam for CI7-T327
    let batch_no = "";

    let batch_no_data = Object.values(
      this.props.mainTemplate[this.props.keys]
    ).find(
      (obj) =>
        obj.Identifier === "PRBatchNo" || obj.Identifier === "PRBatchNoForeign"
    );

    batch_no =
      batch_no_data.value !== ""
        ? batch_no_data.value
        : batch_no_data.DefaultValue;

    axios
      .get(
        getArtworkDescription(
          this.props.item.code,
          batch_no !== "" ? batch_no : ""
        )
      )
      .then((response) => {
        if (response.data.path) {
          let a = document.createElement("a");
          a.href = response.data.path;
          a.download = "ArtworkDescription";
          a.target = "_blank";
          a.rel = "noopener noreferrer";
          a.click();
        }
      });
  };
  handleClose = () => {
    this.setState({ multiplantQtyOpen: false });
  };
  renderMultiplantQty = () => {
    const { item } = this.props;

    if (this.state.multiplantQtyOpen) {
      return (
        <MultiplantQty
          open={this.state.multiplantQtyOpen}
          close={this.handleClose}
          itemData={item.plant_qty}
          Unit={item.unit}
          item={item}
        />
      );
    }

    return null;
  };
  render() {
    let {
      item,
      cell,
      qty,
      rate,
      base_currency,
      readOnly,
      SlabTableToggle,
      // selectedCurrency,
    } = this.props;
    let unit;
    let currency;

    if (cell?.UnitsFlag == 1) {
      unit = item?.unit;
    }
    if (cell?.UnitsFlag == 3) {
      unit = "Pack";
    }
    if (cell?.CurrencyFormula === "SelectedCurrency") {
      currency =
        this.state.selectedCurrency === null ||
        this.state.selectedCurrency === undefined ||
        this.state.selectedCurrency === ""
          ? base_currency
          : this.state.selectedCurrency; //`/${item?.unit}`;
      if (unit) unit = `/${unit}`;
    } else if (cell?.CurrencyFormula == "BuyerLocalCurrency") {
      currency = base_currency;
    } else if (
      cell?.CurrencyFormula != "" &&
      cell?.CurrencyFormula.split("||") &&
      cell?.CurrencyFormula.split("||")[0].split(".") &&
      cell.CurrencyFlag != 0
    ) {
      currency = this.state.selectedCurrency;
      if (unit) unit = `/${unit}`;
    }

    let matserCheckbox =
      cell.Identifier === "EstimatedCost" ||
      cell.Identifier === "EstimatedCostForeign" ||
      cell.Identifier === "Budgetedrate" ||
      cell.Identifier === "BudgetedrateForeign";
    let error, ErrorMessage, name, value, DynamicDefaultValue;
    if (this.props.FieldState) {
      error = this.props.FieldState?.error;
      ErrorMessage = this.props.FieldState?.ErrorMessage;
      name = this.props.FieldState.name;
      value = this.props.FieldState.value;
      DynamicDefaultValue = this.props.FieldState?.DynamicDefaultValue;
    }

    if (cell.Identifier === "EstimatedValue") {
      return (
        <React.Fragment>
          <Button
            color="primary"
            aria-controls="fade-menu"
            aria-haspopup="true"
            disabled={SlabTableToggle}
            onClick={() => {
              this.setState({
                estimatedValueOpen: true,
              });
            }}
            variant="contained"
          >
            Details
          </Button>
          <EstimatedValue
            open={this.state.estimatedValueOpen}
            qty={qty}
            rate={rate}
            unit={item?.unit}
            currency={currency}
            handleClose={() => {
              this.setState({
                estimatedValueOpen: false,
              });
            }}
          />
        </React.Fragment>
      );
    } else if (
      cell.Identifier.includes("DeliverySchedule") &&
      cell.ElementType === "TA"
    ) {
      let value = "";
      if (this.props.FieldState) {
        value = this.props.FieldState["value"];
        if (value) {
          value = value.split(";").length;
        } else {
          value = "";
        }
      }
      return (
        <React.Fragment>
          {value ? (
            <Badge color="primary" badgeContent={value}>
              <Button
                startIcon={<CalendarTodayIcon />}
                onClick={() => {
                  this.setState({
                    deliveryScheduleOpen: true,
                  });
                }}
                variant="text"
                disabled={
                  this.state.checkDisabled || this.state.readOnlyForSwitch
                }
              >
                Add Schedule
                {error ? (
                  <Tooltip title={ErrorMessage}>
                    <ErrorOutline sx={{ color: "error.main", ml: 1 }} />
                  </Tooltip>
                ) : null}
              </Button>
            </Badge>
          ) : (
            <Button
              startIcon={<CalendarTodayIcon />}
              onClick={() => {
                this.setState({
                  deliveryScheduleOpen: true,
                });
              }}
              variant="text"
              disabled={
                this.state.checkDisabled || this.state.readOnlyForSwitch
              }
            >
              Add Schedule
              {error ? (
                <Tooltip title={ErrorMessage}>
                  <ErrorOutline sx={{ color: "error.main", ml: 1 }} />
                </Tooltip>
              ) : null}
            </Button>
          )}
          {this.state.deliveryScheduleOpen && (
            <DeliveryScheduleDetails
              open={this.state.deliveryScheduleOpen}
              unit={item?.unit}
              handleClose={() => {
                this.setState({
                  deliveryScheduleOpen: false,
                });
              }}
              value={this.props.FieldState && this.props.FieldState["value"]}
              updateFieldValue={(newValue) => {
                if (this.props.multipleSolutionFlag && this.props.slabFlag) {
                  this.props.updateDeliveryScheduleFieldValueSlab(
                    newValue,
                    this.props.keys,
                    cell.FieldId,
                    this.props.rowIndex
                  );
                } else {
                  this.props.updateDeliveryScheduleFieldValue(
                    newValue,
                    this.props.keys,
                    cell.FieldId,
                    this.props.rowIndex
                  );
                }
              }}
              readonly={readOnly}
            />
          )}
        </React.Fragment>
      );
    } else if (cell.Identifier === "ArtworkDescription") {
      return (
        <Button
          component="Text"
          color="primary"
          onClick={() => this.ArtworkDescription()}
        >
          {cell.FieldLabel}
        </Button>
      );
    } else if (
      cell.Identifier === "BasicHistoricalPrice" ||
      cell.Identifier === "LandedHistoricalPrice" ||
      cell.Identifier === "DateHP"
    ) {
      return <Typography>{DynamicDefaultValue}</Typography>;
    } else if (cell.Identifier === "MaterialSpecificationFile") {
      return (
        <Button
          component="Text"
          color="primary"
          onClick={() => this.handleMaterialSpecificationClick()}
        >
          {cell.FieldLabel}
        </Button>
      );
    } else if (cell.Identifier === "matspecghclfile") {
      return (
        <Button
          component="text"
          color="primary"
          onClick={() => this.handlematspecghclfileClick()}
        >
          {cell.FieldLabel}
        </Button>
      );
    } else if (cell.Identifier === "ItemMasterSPEC") {
      return (
        <React.Fragment>
          <textarea
            style={{
              maxWidth: "300px",
              minWidth: "240px",
              // resize: "vertical",
              minHeight: "36px",
              height: "36px",
              padding: "8.5px 8.5px",
              font: "inherit",
              letterSpacing: "inherit",
              color: "currentColor",
              border: error ? "1px solid #ff5547" : "1px solid #a6c1d3",
            }}
            id={name}
            name={name}
            readOnly={readOnly}
            value={item?.specifications}
            onChange={this.setValue}
            onBlur={(e) => {
              this.props.handleChange(
                e,
                cell.FieldId,
                cell.FieldLabel,
                cell.ElementType
              );
            }}
            disabled={true}
            className="template_textarea"
          />
        </React.Fragment>
      );
    } else if (cell.ElementType === "T") {
      value = cell.defaultValue || this.state.value;

      return (
        <React.Fragment>
          <Tooltip
            title={value ? `${value}${unit ? ` ${unit}` : ""}` : ""}
            disableInteractive
            placement="bottom-start"
          >
            <Typography variant="body2" noWrap>
              {(this.props.vendorType === "International" ||
                this.props.vendorType === "Local Importer") &&
              this.state.selectedCurrency
                ? getSymbol(currency)
                : this.props.vendorType === "Local"
                ? getSymbol(currency)
                : ""}
              {value} {unit ? unit : ""}
            </Typography>
          </Tooltip>
        </React.Fragment>
      );
    } else if (
      cell.Identifier === "ProductQuantity" &&
      item?.plant_qty?.length > 0
    ) {
      return (
        <>
          <FormGroup
            sx={{
              display: "inline-flex",
              "& .MuiTypography-root": {
                color: "var(--primary)",
                textDecoration: "underline",
                fontWeight: "500",
              },
            }}
          >
            {/* <FormControlLabel
          control={""}
          label={ */}
            <Typography
              variant="subtitle1"
              component="a"
              sx={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  multiplantQtyOpen: true,
                });
              }}
            >
              {this.state.value}
            </Typography>
            {/* } */}
            {/* /> */}
          </FormGroup>
          <span style={{ marginLeft: "4px" }}>{unit ? unit : ""}</span>
          {this.renderMultiplantQty()}
        </>
      );
    } else if (cell.ElementType === "TB") {
      return (
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <TextField
            id={name}
            // sx={{ width: "180px" }}
            fullWidth
            error={error}
            variant="outlined"
            size="small"
            placeholder={cell.FieldLabel}
            type="text"
            name={name}
            value={this.state.value}
            onChange={readOnly ? "" : this.setValue}
            disabled={this.state.checkDisabled || this.state.readOnlyForSwitch}
            readOnly={this.state.checkDisabled || this.state.readOnlyForSwitch}
            onBlur={(e) => {
              this.props.handleChange(
                e,
                cell.FieldId,
                cell.Identifier,
                cell.FieldLabel,
                // cell.ElementType,
                this.props.keys,
                this.props.rowIndex
              );
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  title={ErrorMessage}
                  sx={{
                    "&.MuiInputAdornment-root": {
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "text.primary",
                    },
                  }}
                >
                  {unit ? unit : ""}
                  {!this.state.checkDisabled &&
                    !this.state.readOnlyForSwitch &&
                    error && (
                      <Tooltip title={ErrorMessage}>
                        <ErrorOutline sx={{ color: "error.main", ml: 0.5 }} />
                      </Tooltip>
                    )}
                </InputAdornment>
              ),
              startAdornment: cell.Identifier !== "FreightValue" &&
                cell.Identifier !== "FreightChargesForeign" &&
                currency && (
                  <InputAdornment position="start">
                    {this.props.vendorType === "International" ||
                    this.props.vendorType === "Local Importer"
                      ? // this.state.selectedCurrency
                        cell.CurrencyFormula == "BuyerLocalCurrency"
                        ? getSymbol(currency)
                        : this.state.selectedCurrency && getSymbol(currency)
                      : this.props.vendorType === "Local"
                      ? getSymbol(currency)
                      : ""}
                  </InputAdornment>
                ),
            }}
          />
        </div>
      );
    } else if (cell.ElementType === "TA") {
      return (
        <React.Fragment>
          <textarea
            style={{
              width: "100%",
              resize: "vertical",
              minHeight: "36px",
              height: "36px",
              padding: "8.5px 14px",
              font: "inherit",
              letterSpacing: "inherit",
              color: "currentColor",
              borderRadius: "4px",
              border:
                error &&
                !(this.state.checkDisabled || this.state.readOnlyForSwitch)
                  ? "1px solid #ff5547"
                  : "1px solid #a6c1d3",
            }}
            id={name}
            name={name}
            disabled={this.state.checkDisabled || this.state.readOnlyForSwitch}
            readOnly={this.state.checkDisabled || this.state.readOnlyForSwitch}
            value={this.state.value}
            onChange={readOnly ? "" : this.setValue}
            onBlur={(e) => {
              this.props.handleChange(
                e,
                cell.FieldId,
                cell.Identifier,
                cell.FieldLabel,
                // cell.ElementType,
                this.props.keys,
                this.props.rowIndex
              );
            }}
            className="template_textarea"
          />
        </React.Fragment>
      );
    } else if (cell.ElementType === "M") {
      return (
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={!!value}
              name={name}
              onChange={(e) => {
                this.props.handleChange(
                  e,
                  cell.FieldId,
                  cell.FieldLabel,
                  cell.ElementType
                );
              }}
            />
          }
          label={cell.FieldLabel}
        />
      );
    } else if (cell.ElementType === "DT") {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            minDate={new Date()}
            format="dd/MM/yyyy"
            fullWidth
            slotProps={{
              textField: {
                className: "template_Date_picker template_textbox",
                size: "fullWidth",
                title: error
                  ? moment(value).isValid()
                    ? cell.ErrorMessage
                      ? cell.ErrorMessage
                      : "Delivery date should be greater than or equal to current date"
                    : "invalid date"
                  : "",
                placeholder: cell.FieldLabel,
                label: cell.FieldLabel,
                error:
                  (error &&
                    !(
                      this.state.checkDisabled || this.state.readOnlyForSwitch
                    )) ||
                  false,
              },
            }}
            name={name}
            value={this.state.dateValue ? new Date(this.state.dateValue) : null}
            onChange={(newVal) => {
              this.props.handleDateChange(
                newVal ? moment(newVal) : newVal,
                cell.FieldId,
                cell.Validation
              );
            }}
            disabled={this.state.checkDisabled || this.state.readOnlyForSwitch}
          />
        </LocalizationProvider>
      );
    } else if (cell.ElementType === "F") {
      return (
        <OutlineTextfieldLabel
          type="file"
          name={name}
          disabled={this.state.checkDisabled || this.state.readOnlyForSwitch}
          value={value}
          error={error}
          helperText={error && ErrorMessage}
          onChange={(e) => {
            this.props.handleChange(
              e,
              cell.FieldId,
              cell.FieldLabel,
              cell.ElementType
            );
          }}
        />
      );
    } else if (cell.ElementType === "S") {
      if (
        this.props.multiplePlantsFlag === true &&
        cell.Identifier === "GSTType"
      ) {
        return null;
      }
      return (
        <FormControl fullWidth error={error} size="small">
          <Select
            fullWidth
            className="template_dropdown"
            displayEmpty
            name={name}
            disabled={this.state.checkDisabled || this.state.readOnlyForSwitch}
            value={value || ""}
            id="itemizeSelect"
            attr-id={this.props.FieldState?.["HTMLAttributeName"]}
            onChange={(e) => {
              this.props.handleChange(
                e,
                cell.FieldId,
                cell.Identifier,
                cell.FieldLabel,
                // cell.ElementType,
                this.props.keys,
                this.props.rowIndex
              );
            }}
            endAdornment={
              !this.state.checkDisabled &&
              !this.state.readOnlyForSwitch &&
              error && (
                <InputAdornment
                  style={{ marginRight: "10px" }}
                  position="end"
                  title={ErrorMessage}
                >
                  <Tooltip title={ErrorMessage}>
                    <ErrorOutline sx={{ color: "error.main", mr: 0.5 }} />
                  </Tooltip>
                </InputAdornment>
              )
            }
          >
            <MenuItem value="">
              <em>select</em>
            </MenuItem>
            {this.props.FieldState &&
              this.props.FieldState["options"]?.length > 0 &&
              (this.props.FieldState["options"] === "loading" ? (
                <MenuItem value="">Loading...</MenuItem>
              ) : (
                Array.isArray(this.props.FieldState["options"]) &&
                this.props.FieldState["options"]?.map((option, id) => {
                  return (
                    <MenuItem
                      key={id}
                      value={
                        option.dropdown_element_name
                          ? this.props.default_dropdown_type === "10" ||
                            cell.Identifier === "Destination" ||
                            cell.Identifier === "DestinationForeign" ||
                            cell.Identifier === "Currency"
                            ? `${option.dropdown_element_name}-${option.dropdown_element_code}`
                            : `${option.dropdown_element_code}-${option.dropdown_element_name}`
                          : option.code
                      }
                    >
                      {option.dropdown_element_name
                        ? option.dropdown_element_code ===
                          option.dropdown_element_name
                          ? option.dropdown_element_name
                          : `${option.dropdown_element_code}-${option.dropdown_element_name}`
                        : option.name}
                    </MenuItem>
                  );
                })
              ))}
          </Select>
        </FormControl>
      );
    } else if (cell.ElementType === "L") {
      return (
        <Link
          component="button"
          variant="body2"
          target="_blank"
          rel="noopener noreferrer"
        >
          {cell.FieldLabel}
        </Link>
      );
    } else {
      return cell.FieldLabel;
    }
  }
}
const mapStateToProps = (state) => ({
  prlineitems: state.prrfqreducer.setPrLineItemsData,
});
export default connect(mapStateToProps, {
  openSnackbar,
})(NegotiationFormField);
