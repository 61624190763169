import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  GridOverlay,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import commifyWithPrecision from "../../../../common/utility";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { get_Inquiries_data } from "../../../../config/apiUrl";
import CommonDataGridPro from "../../commonDataGridPro/commonDataGridPro";
import axios from "axios";

const useStyles = makeStyles(() => ({
  RFQDrawer: {
    "&.MuiPaper-root": {
      boxShadow: "none",
      borderRadius: "0",
    },
    "& .DataGFullScreen-drawer": {
      height: "calc(100vh - 62px)",
      width: "100%",
    },
  },
}));

const PrGridDrawer = ({
  sessionID,
  setOpenPrDrawer,
  linkUnlinkData,
  selectedId,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbar, setSnackbar] = useState(false);
  const [PRStatusData, setPRStatusData] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const feature_permission_obj = useSelector((state)=>state.permissionsReducer.featurePermission);
  const international_numeric_format = feature_permission_obj?.international_numeric_format?.feature_value;
  const configAxios = {
    headers: { Authorization: `Bearer ${sessionID}` },
  };

  const CustomNoRowsOverlay = () => (
    <GridOverlay>
      <Typography>No data available</Typography>
    </GridOverlay>
  );

  const handleDrawerClose = () => {
    setOpenPrDrawer(false);
  };

  // const handleCloseSnackbar = () => {
  //   setSnackbar(false);
  // };

  const PRStatusDataRow = PRStatusData?.map((row) => ({
    ...row,
    id: uuidv4(), // Generate a UUID for the row
  }));

  function CustomGridToolbar() {
    return (
      <React.Fragment>
        <GridToolbarContainer className="datagrid-toolbar">
          <Grid
            container
            spacing={0}
            sx={{
              width: "100%",
              "&.MuiGrid2-root": {
                paddingTop: { xs: "0", sm: "0", md: "0", alignItems: "center" },
              },
            }}
          >
            <Grid xs={12} sm={12} md={3} xl={4}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <GridToolbarQuickFilter
                  className="DataGrid-SearchBar"
                  sx={{ pb: 0 }}
                />
              </Box>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={9}
              xl={8}
              sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
            >
              <Box className="toolbar-right">
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  &nbsp;
                </Box>
                <Box className="dg-filter">
                  <GridToolbarColumnsButton />
                  <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{
                      fileName: 'PR Details Report',
                    }} />
                  <GridToolbarFilterButton />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </GridToolbarContainer>

        <GridToolbarContainer
          className="datagrid-toolbar"
          sx={{ display: { sm: "flex", md: "none" } }}
        >
          <Grid
            container
            spacing={0}
            sx={{ width: "100%", alignItems: "center" }}
          >
            <Grid xs={2} md={6}>
              <Box>&nbsp;</Box>
            </Grid>
            <Grid
              xs={10}
              md={6}
              sx={{ textAlign: { xs: "right", sm: "right" } }}
            >
              <Box className="dg-filter">
                <GridToolbarColumnsButton />
                <GridToolbarExport
                  printOptions={{ disableToolbarButton: true }}
                  csvOptions={{
                    fileName: 'PR Details Report',
                  }} />
                <GridToolbarFilterButton />
              </Box>
            </Grid>
          </Grid>
        </GridToolbarContainer>
      </React.Fragment>
    );
  }

  // Custom formatting logic
  const rfqStatusCell = (params) => {
    const statuses = [
      { value: "10", label: "Quantity Not distributed", color: "success" },
      { value: "20", label: "Partially Distributed", color: "success" },
      { value: "30", label: "Distributed", color: "success" },
      { value: "40", label: "PR Released", color: "success" },
    ];
    const { value } = params;
    return (
      <React.Fragment>
        {statuses.map(
          (status) =>
            value === status.label && (
              <Tooltip title={status.label}>
                <Chip
                  label={status.label}
                  variant="soft"
                  size="small"
                  color={status.color}
                />
              </Tooltip>
            )
        )}
      </React.Fragment>
    );
  };

  const columns = [
    {
      field: "pr_no",
      headerName: "PR NO",
      width: 135,
      renderCell: (params) => (
        <Typography variant="body2" fontWeight={500}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "line_item",
      headerName: "LINE ITEM",
      width: 80,
    },
    {
      field: "status",
      headerName: "PR STATUS",
      width: 190,
      // filterable: false,
      valueGetter: (params) => {
        const { value } = params;
        const statuses = [
          { value: "10", label: "Quantity Not distributed" },
          { value: "20", label: "Partially Distributed" },
          { value: "30", label: "Distributed" },
          { value: "40", label: "PR Released" },
        ];

        for (const status of statuses) {
          if (value === status.value) {
            return status.label;
          }
        }

        return ""; // Default value if no match is found
      },
      renderCell: rfqStatusCell,
    },
    {
      field: "item.name",
      headerName: "ITEM NAME",
      width: 140,
      renderCell: (params) => (
        <Tooltip title={params.row.inquiry_item.name}>
          <Typography variant="body2" noWrap>
            {params.row.inquiry_item.name}
          </Typography>
        </Tooltip>
      ),
      valueGetter: (params) => params.row.inquiry_item.name,
    },
    {
      field: "item.code",
      headerName: "ITEM CODE",
      width: 120,
      renderCell: (params) => (
        <Typography variant="body2">{params.row.inquiry_item.code}</Typography>
      ),
      valueGetter: (params) => params.row.inquiry_item.code,
    },
    {
      field: "item_total_quantity",
      headerName: "TOTAL QTY",
      width: 100,
      valueGetter: (params) => commifyWithPrecision(params.row.item_total_quantity, international_numeric_format, true),
      renderCell: (params) => (
        <Typography variant="body2">
          {params.value}
        </Typography>
      ),
    },
    {
      field: "item_balance_quanatity",
      headerName: "REMAINING QTY",
      width: 120,
      valueGetter: (params) => commifyWithPrecision(params.row.item_balance_quantity, international_numeric_format, true),
      renderCell: (params) => (
        <Typography variant="body2">
          {params.value}
        </Typography>
      ),
    },
  ];

  const fetchPRDetails = async () => {
    if (selectedId) {
      let url = get_Inquiries_data + selectedId + "/prlis";
      try {
        let response = await axios.get(url, configAxios);
        if (response?.status === 200 && response?.data) {
          setPRStatusData(response?.data?.inquiry_prlis);
          setLoading(false);
        } else {
          // Handle empty data case
          setPRStatusData([]);
          setLoading(false); // Stop the loader here
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // setErrorMessage(error);
        // setSnackbar(true);
        setPRStatusData([]);
        setLoading(false); // Stop the loader here
      }
    }
  };

  React.useEffect(() => {
    fetchPRDetails();
  }, [selectedId]);

  return (
    <Card className={classes.RFQDrawer}>
      <CardHeader
        action={
          <Tooltip title="Close">
            <IconButton
              onClick={handleDrawerClose}
              aria-label="close"
              size="small"
              sx={{ color: "var(--grey-500)" }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        }
        title={
          <Typography
            variant="subtitle1"
            fontWeight={700}
            color={"secondary.main"}
            noWrap
          >
            PR Details
          </Typography>
        }
        subheader={
          <Typography
            variant="subtitle2"
            fontWeight={500}
            color={"text.primary"}
            component="div"
            sx={{ marginTop: "-5px" }}
            noWrap
          >
            {linkUnlinkData[0]?.inquiryNo}
          </Typography>
        }
        sx={{
          borderBottom: "1px solid var(--bluegrey-500)",
          pt: 1,
          pb: 1,
          "& .MuiCardHeader-action": {
            alignSelf: "center",
          },
        }}
      />

      <CardContent sx={{ p: "0 !important" }}>
        <Box className="DataGFullScreen-drawer">
          <CommonDataGridPro
            loading={loading}
            rows={PRStatusDataRow}
            getRowId={(row) => row.id}
            columns={columns}
            columnHeaderHeight={40}
            pagination={true}
            paginationModel={paginationModel}
            NoRowsOverlay={CustomNoRowsOverlay}
            onPaginationModelChange={setPaginationModel}
            Toolbar={CustomGridToolbar}
            className="fullScreenGrid"
            sx={{
              "&.MuiDataGrid-root": {
                border: 0,
              },
              "& .MuiDataGrid-virtualScrollerContent--overflowed .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
              {
                borderBottomColor: "var(--bluegrey-500)",
              },
            }}
          />
        </Box>
      </CardContent>
      {/* <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackbar}
        onClose={handleCloseSnackbar}
        // message="Validate Date Updated Successfully"
      >
        <Alert onClose={handleCloseSnackbar} severity="error" variant="filled">
          {errorMessage.message}
        </Alert>
      </Snackbar> */}
    </Card>
  );
};

export default PrGridDrawer;
