import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { exchangeRates, parentHost } from "../../../config/apiUrl";
import axios from "axios";
import ExchangeRateView from "../ExchangeRateDrawer/ExchangeRateView";

const NegotiationDetailsSectionView = (props) => {
  const [DiffrentValidityDate, setDiffrentValidityDate] = useState({});
  const [negotiationDetails, setNegotiationDetails] = useState({
    Negotiation: {
      negoNo: "",
      NegotiationValidity: null,
      negoDate: null,
    },
  });
  const [IframeVisible, setIframeVisible] = React.useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const [currencyExchangeRates, setCurrencyExchangeRates] = useState({});
  const [openExchangeRate, setOpenExchangeRate] = useState(false);

  const condition = "Negotiation";
  const vendorType = props?.vendorType;

  const CloseExchangeRate = () => {
    setOpenExchangeRate(false);
  };

  const getFieldsByCondition = (condition) => {
    switch (condition) {
      case "Negotiation":
        return [
          {
            id: "1",
            name: "negoNo",
            label: "Negotiation No",
          },
          {
            id: "2",
            name: "negoDate",
            label: "Negotiation Date",
            type: "date",
            isRequired: true,
            isReadOnly: false,
            formId: "datepicker",
          },
          {
            id: "3",
            name: "NegotiationValidity",
            label: "Negotiation Validity",
            type: "date",
            isRequired: true,
            isReadOnly: false,
            formId: "datepicker",
          },
        ];
      default:
        return [];
    }
  };
  const conditionFields = getFieldsByCondition(condition);
  const setValidityDate = (prevDetails, date, type) => ({
    ...prevDetails,
    [`${type}Validity`]: dayjs(date, "YYYY-MM-DD HH:mm:ss").format(
      `DD/MM/YYYY HH:mm:ss [${date.split(" ").pop()}]`
    ),
  });
  useEffect(() => {
    if (props?.CurrentTransaction) {
      const { validity_date, action_date, no } = props?.CurrentTransaction;

      if (props?.PrevTransaction?.validity_date !== validity_date) {
        const date = props?.PrevTransaction?.validity_date;
        const type = "Negotiation";
        setDiffrentValidityDate((prevDetails) =>
          setValidityDate(prevDetails, date, type)
        );
      }
      const actionDate = dayjs(action_date, "YYYY-MM-DD HH:mm:ss").format(
        `DD/MM/YYYY HH:mm:ss [${action_date.split(" ").pop()}]`
      );

      const validityDate = dayjs(validity_date, "YYYY-MM-DD HH:mm:ss").format(
        `DD/MM/YYYY HH:mm:ss [${validity_date.split(" ").pop()}]`
      );
      setNegotiationDetails((prevDetails) => ({
        ...prevDetails,
        Negotiation: {
          ...prevDetails.Negotiation,
          negoNo: no,
          negoDate: actionDate,
          NegotiationValidity: validityDate,
        },
      }));
    }
  }, [props.CurrentTransaction]);

  useEffect(() => {
    // Event listener to handle messages from the iframe
    const handleMessage = (event) => {
      if (event.origin !== parentHost) return;

      if (event.data.excelClose) {
        setIframeVisible(false);
      }
    };
    window.addEventListener("message", handleMessage);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    const exchangeRates = props.currencyOptions.reduce((acc, currency) => {
      acc[currency.Currency] = currency.ExchangeRate;
      return acc;
    }, {});

    // Update the state with the transformed object
    setCurrencyExchangeRates(exchangeRates);
  }, [props.currencyOptions]);

  const renderField = (condition, field) => {
    const value = negotiationDetails[condition][field.name];

    if (!value) {
      return null;
    }
    return (
      <>
        <Typography component="label" variant="body2" color={"grey.500"}>
          {field.label}
        </Typography>

        <Tooltip
          title={
            DiffrentValidityDate?.[field.name]
              ? `Previous Value : ${DiffrentValidityDate?.[field.name]}`
              : value
          }
          placement="bottom-start"
        >
          <Typography
            variant="body2"
            color={
              DiffrentValidityDate?.[field.name] ? "error.main" : "text.primary"
            }
          >
            {value}
          </Typography>
        </Tooltip>
      </>
    );
  };

  useEffect(() => {
    const getExchangeRates = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const sessionID = queryParams.get("q");

      try {
        const URL = exchangeRates(props.inquiryId, props.vendorId);
        const response = await axios.get(URL, {
          headers: {
            Authorization: `Bearer ${sessionID}`,
            "Content-Type": "application/json",
          },
        });
        if (!Array.isArray(response.data)) {
          setCurrencyExchangeRates(response.data);
        }
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    if (!props.isSupplier && vendorType !== "Local") {
      getExchangeRates();
    }
  }, [vendorType, props.isSupplier]);

  return (
    <>
      <Card
        variant="outlined"
        // className="template-card local_recepients_card"
        id="NegotiationDetails"
      >
        <CardHeader
          title={props.title}
          titleTypographyProps={{
            variant: "subtitle2",
            component: "h5",
            color: "grey.900",
            textTransform: "uppercase",
          }}
          sx={{
            minHeight: "50px",
            "& .MuiCardHeader-action": {
              marginRight: 0,
              marginTop: 0,
            },
          }}
          action={
            <>
              {props.isServiceRfq && (
                <Button
                  variant="text"
                  onClick={() => {
                    // PreviewActivity()
                    setIframeVisible(true);
                  }}
                  sx={{
                    textDecoration: "underline",
                    textTransform: "capitalize",
                    p: 0,
                    "&:hover": {
                      backgroundColor: "transparent", // Set background to transparent
                    },
                  }}
                >
                  {`View ${
                    props.AlternativeNames?.service_option || "Activity"
                  } Details`}
                </Button>
              )}
            </>
          }
        />
        <Divider sx={{ marginBottom: "0" }} />
        <CardContent>
          {/* <Grid container spacing={2} className="local_recepients_card_content"> */}
          <Grid container spacing={2}>
            {conditionFields.map((field) => {
              const renderedField = renderField(`${condition}`, field);
              return (
                renderedField && (
                  <Grid item key={field.id} xl={3} lg={4} md={4} sm={6} xs={12}>
                    {renderedField}
                  </Grid>
                )
              );
            })}
            {!props.isSupplier && props?.vendorType !== "Local" && (
              <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
                <>
                  <Typography
                    component="label"
                    variant="body2"
                    color={"grey.500"}
                  >
                    Exchange Rate
                  </Typography>

                  <Typography
                    variant="body2"
                    color={"text.primary"}
                    sx={{
                      cursor: "pointer",
                      color: "var(--color-primary)",
                      textDecoration: "underline",
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenExchangeRate(true);
                    }}
                  >
                    View
                  </Typography>
                </>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <ExchangeRateView
        CloseExchangeRateView={CloseExchangeRate}
        openExchangeRateView={openExchangeRate}
        currencyExchangeRates={currencyExchangeRates}
      />
      <div>
        <Dialog
          open={IframeVisible}
          onClose={() => {
            setIframeVisible(false);
          }}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth={true}
          maxWidth="false"
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              height: "100%",
            },
          }}
          fullScreen
        >
          {/* Iframe content */}
          <iframe
            id="iframePost"
            title="Your Iframe Title"
            src={`${parentHost}/cgi-bin/vendx/iframe_pp.cgi?action=80&TLGUID=${
              props.lastTxnGuid
            }&sessionID=${queryParams.get("q")}`}
            width="100%"
            height="100%"
            style={{ height: "100%", border: "none" }}
          ></iframe>
        </Dialog>
      </div>
    </>
  );
};

export default NegotiationDetailsSectionView;
