import * as React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { DefaultTermsStyle } from "./DefaultTermsStyle";
import { DataGridPro } from "@mui/x-data-grid-pro";
import CloseIcon from "@mui/icons-material/Close";

export default function DefaultTerms(props) {
  const columns = [
    { field: "VendorName", headerName: "VENDOR NAME", width: 200 },
    { field: "element_name", headerName: `${props.title} Name`, width: 150 },
    { field: "element_code", headerName: `${props.title} Code`, width: 150 },
  ];
  const paginationModel = { page: 0, pageSize: 10 };

  return (
    <DefaultTermsStyle
      anchor="right"
      open={props.openDefaultTerms}
      onClose={props.CloseDefaultTerms}
      className="SidebarDrawer"
    >
      <Card>
        <CardHeader
          title={
            <Typography
              variant="h6"
              fontSize="18px"
              fontWeight={500}
              color={"secondary.main"}
            >
              {props.title} Details
            </Typography>
          }
          action={
            <Tooltip title="Close">
              <IconButton
                onClick={props.CloseDefaultTerms}
                aria-label="close"
                size="small"
                sx={{ color: "var(--grey-500)" }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          }
          sx={{
            "& .MuiCardHeader-action": {
              alignSelf: "center",
            },
            "& .MuiCardHeader-avatar": {
              mr: 1,
            },
          }}
        />
        <Divider />
        <CardContent>
          <Paper sx={{ height: "100%", width: "100%" }}>
            <DataGridPro
              rows={props.vendorTermData}
              columns={columns}
              initialState={{ pagination: { paginationModel } }}
              pageSizeOptions={[5, 10]}
              sx={{ border: 0 }}
            />
          </Paper>
        </CardContent>
      </Card>
    </DefaultTermsStyle>
  );
}
