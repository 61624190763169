import React, { Component } from "react";
import {
  Typography,
  IconButton,
  InputAdornment,
  Dialog,
  Avatar,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { VendorRecipSearch } from "./style";
import CloseIcon from "@mui/icons-material/Close";
import {
  openSnackbar,
  closeSnackbar,
} from "../../../redux/actions/snackbarAction";
import { connect } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { setSelectedVendors } from "../../../redux/actions/vendorActions";
import { Box } from "@mui/system";
import ItemizedVendors from "../../MainContent/Vendors/ItemizeVendors";
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro";

const columns = [
  {
    field: "vendor_name",
    headerName: "VENDOR NAME",
    align: "left",
    width: "250",
    flex: 1,
    renderCell: (params) => {
      return (
        <React.Fragment>
          <Avatar
            sx={{ backgroundColor: (theme) => theme.palette.success.main }}>
            {params.row.vendor_name.slice(0, 1)}
          </Avatar>
          <Box sx={{ lineHeight: "normal", ml: 1 }}>
            <Typography color="text.primary" fontWeight={500}>
              {params.row.vendor_name}
            </Typography>
            <Typography variant="body2" component="span" color="grey.500">
              {params.row.is_approved ? params.row.is_approved : " -"}
            </Typography>
          </Box>
        </React.Fragment>
      );
    },
  },
  {
    field: "vendor_code",
    headerName: "VENDOR CODE",
    headerAlign: "left",
    align: "left",
    width: "250",
    flex: 1,
    renderCell: (params) => {
      return (
        <React.Fragment>
          <Typography color="primary.main" fontSize={14} fontWeight={500}>
            {params.row.vendor_code}
          </Typography>
        </React.Fragment>
      );
    },
  },
  {
    field: "email",
    headerName: "EMAIL",
    headerAlign: "left",
    align: "left",
    width: "250",
    flex: 1,
  },
  {
    field: "phone_no",
    headerName: "PHONE NO",
    headerAlign: "left",
    align: "left",
    width: "250",
    flex: 1,
  },
  {
    field: "country",
    headerName: "COUNTRY",
    headerAlign: "left",
    align: "left",
    width: "250",
    flex: 1,
  },
];

function QuickSearchToolbar(props) {
  return (
    <div>
      <VendorRecipSearch
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        }
        endAdornment={
          <IconButton
            className="searchclear-icon"
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{ visibility: props.value ? "visible" : "hidden" }}
            onClick={props.clearSearch}>
            <ClearIcon />
          </IconButton>
        }
      />
    </div>
  );
}

class VendorRecipients extends Component {
  state = {
    searchText: "",
    rows: [],
    mainData: [],
    selectionModel: [],
  };

  // componentDidMount() {
  //   console.log("componentDidMount");

  //   let finalRows;
  //   if (this.props.type === "local") {
  //     finalRows = this.props.selectedVendors.filter(
  //       (vendor) => vendor.country === this.props.accountlevelInfo.country
  //     );
  //   } else {
  //     finalRows = this.props.selectedVendors.filter(
  //       (vendor) => vendor.country !== this.props.accountlevelInfo.country
  //     );
  //   }
  //   console.log(finalRows);
  //   /*
  //   nextProps.selectedVendors.forEach((vendor) => {
  //     if (nextProps.type === "local") {
  //       if (vendor.country === nextProps.accountlevelInfo.country) {
  //       }
  //     } else {
  //     }
  //   }); */
  //   this.setState({
  //     rows: finalRows,
  //   });
  // }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log("UNSAFE_componentWillReceiveProps");
    if (!nextProps.selectedVendors || !nextProps.accountlevelInfo) return;

    // console.log(nextProps.type, "nextProps.type")
    let finalRows;
    if (nextProps.type === "local") {
      finalRows = nextProps.selectedVendors.filter(
        (vendor) => vendor.vendor_location === "L"//nextProps.accountlevelInfo.country
      );
    } else {
      finalRows = nextProps.selectedVendors.filter(
        (vendor) => vendor.vendor_location !== "L"//nextProps.accountlevelInfo.country
      );
    }
    // console.log(finalRows);
    /*
    nextProps.selectedVendors.forEach((vendor) => {
      if (nextProps.type === "local") {
        if (vendor.country === nextProps.accountlevelInfo.country) {
        }
      } else {
      }
    }); */
    this.setState({
      rows: finalRows,
      mainData: finalRows,
    });
  }
  /*static getDerivedStateFromProps(props, state) {
    if (!state.rows.length) {
      if (props.selectedVendors) {
        return {
          rows: props.selectedVendors,
        };
      } else {
        return null;
      }
    } else return { ...state };
  } */
  requestSearch = (searchValue) => {
    this.setState({
      searchText: searchValue,
    });
    let newRows = [];
    if (searchValue.trim()) {
      this.state.mainData.forEach((row) => {
        if (
          row.vendor_name
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase())
        ) {
          newRows.push(row);
        }
      });
    } else {
      newRows = this.state.mainData;
    }
    this.setState({
      rows: newRows,
    });
  };

  removeVendors = () => {
    if (this.state.selectionModel.length) {
      let updatedSelectedVendor = [];
      this.props.selectedVendors.forEach((vendor) => {
        if (
          !this.state.selectionModel.some((element) => {
            return element === vendor.id;
          })
        ) {
          updatedSelectedVendor.push(vendor);
        }
      });
      this.props.setSelectedVendors(updatedSelectedVendor);
      this.setState({
        selectionModel: [],
      });
    }
  };
  render() {
    const { open, master_type } = this.props;
    return (
      <React.Fragment>
        <div>
          <Dialog
            scroll="body"
            fullWidth
            maxWidth="md"
            open={open}
            // onClose={handleClose}
            aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">
              {this.props.type === "local" ? "Local" : "International"}{" "}
              Recipients
              <IconButton
                onClick={this.props.handleClose}
                sx={{
                  ml: "auto",
                  color: (theme) => theme.palette.bluegrey[500],
                }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {master_type === "item" ? (
                <ItemizedVendors isRecipients={this.props.type} />
              ) : (
                <React.Fragment>
                  <QuickSearchToolbar
                    value={this.state.searchText}
                    onChange={(event) => this.requestSearch(event.target.value)}
                    clearSearch={() => this.requestSearch("")}
                  />
                  <div style={{ height: 350, margin: "1rem 0" }}>
                    <DataGridPro
                      columns={columns}
                      rows={this.state.rows}
                      hideFooter
                      slots={{
                        noRowsOverlay: () => {
                          return (
                            <GridOverlay>
                              <Typography>No Vendors found</Typography>
                            </GridOverlay>
                          );
                        },
                      }}
                      hideFooterPagination
                      // checkboxSelection
                      disableRowSelectionOnClick
                      disableColumnReorder
                      // onSelectionModelChange={(newSelectionModel) => {
                      //   this.setState({ selectionModel: newSelectionModel });
                      // }}
                      // selectionModel={this.state.selectionModel}
                      //   filterMode="server"
                    />
                  </div>
                </React.Fragment>
              )}
              {/* <Button
                  startIcon={<RemoveCircleIcon />}
                  onClick={this.removeVendors}
                >
                  REMOVE
                </Button> */}
            </DialogContent>
            {/* <DialogActions>
              <Button variant='outlined' autoFocus onClick={handleClose}>
                Cancel
              </Button>
              <Button variant='contained'>Save</Button>
            </DialogActions> */}
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  selectedVendors: state.vendorReducer.selectedVendors,
  accountlevelInfo: state.permissionsReducer.accountlevelInfo,
});
export default connect(mapStateToProps, {
  openSnackbar,
  closeSnackbar,
  setSelectedVendors,
})(VendorRecipients);
