import React, { useState } from "react";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import Calendar from "@mui/icons-material/Event";
import { Box, Chip, List, ListItem, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import {fetchcompanywisedateDatas} from "../../../src/config/apiUrl";
function CustomRangeShortcuts(props) {
  const { items, onChange, isValid } = props;
  
  if (items == null || items.length === 0) {
    return null;
  }

  const resolvedItems = items.map((item) => {
    const newValue = item.getValue({ isValid });

    return {
      label: item.label,
      onClick: () => {
        onChange(newValue);
      },
      disabled: !isValid(newValue),
    };
  });

  return (
    <Box
      sx={{
        gridRow: 1,
        gridColumn: 2,
      }}
    >
      <List
        dense
        sx={(theme) => ({
          display: "flex",
          px: theme.spacing(4),
          "& .MuiListItem-root": {
            pt: 0,
            pl: 0,
            pr: theme.spacing(1),
          },
        })}
      >
        {resolvedItems.map((item) => {
          return (
            <ListItem key={item.label}>
              <Chip {...item} />
            </ListItem>
          );
        })}
      </List>
      <Divider />
    </Box>
  );
}

CustomRangeShortcuts.propTypes = {
  isValid: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      getValue: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
};

export default function DaterangepickerCustom(props) {
  const userInfo = useSelector((state) => state.userInfoReducer.userInfo);
  const disablePastAndFutureThreeYears = (date) => {
    let company_id=userInfo?.company_id
    let data =props.jsonData;
    const transformedData = {};
    data?.forEach(item => {
        const key = Object.keys(item)[0]; // Get the original key (e.g., "1", "2", etc.)
        const company_id = userInfo.company_id; 
        transformedData[company_id] = item[key]; 

    });

    const configDate = transformedData[userInfo.company_id]?.configurationDate;
    const threeYearsAgo = dayjs().subtract(3, 'year');
    const threeYearsFromNow = dayjs().add(3, 'year');
    return date.isBefore(threeYearsAgo, 'day') || date.isAfter(threeYearsFromNow, 'day');
  };
  
  const shortcutsItems = [
    {
      label: "This Week",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("week"), today.endOf("week")];
      },
    },
    {
      label: "Last Week",
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, "day");
        return [prevWeek.startOf("week"), prevWeek.endOf("week")];
      },
    },
    {
      label: "Last 7 Days",
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, "day"), today];
      },
    },
    {
      label: "Current Month",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("month"), today.endOf("month")];
      },
    },
    // {
    //   label: "Next Month",
    //   getValue: () => {
    //     const today = dayjs();
    //     const startOfNextMonth = today.endOf("month").add(1, "day");
    //     return [startOfNextMonth, startOfNextMonth.endOf("month")];
    //   },
    // },
    {
      label: "Reset",
      getValue: () => {
        const today = dayjs();
        return [today.subtract(366, "day"), today];
      },
    },
  ];

  return (
    <Box
      className="DatePickerBox"
      sx={{
        "&.DatePickerBox": {
          ".MuiOutlinedInput-input": {
            padding: "8px 14px",
            fontSize: "14px",
            letterSpacing: "-1px",
          },
          "& .MuiSvgIcon-root": {
            cursor: "pointer",
          },
        },
      }}
    >
      
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          format="DD/MM/YYYY"
          size="small"
          value={props?.value}
          onChange={(e) => props.setDateRangeValue(e)}
          shouldDisableDate={props?.pastthreeyeardate ? disablePastAndFutureThreeYears : undefined}
          slots={{
            shortcuts: CustomRangeShortcuts,
            field: SingleInputDateRangeField,
          }}
          slotProps={{
            textField: { InputProps: { endAdornment: <Calendar /> } },
            shortcuts: {
              items: shortcutsItems,
            },
            toolbar: {
              hidden: true,
            },
            actionBar: {
              hidden: true,
            },
          }}
          calendars={2}
        />
      </LocalizationProvider>
    </Box>
  );
}
