function commifyInternational(text) {
    text = text.toString().split('').reverse().join('');
    text = text.replace(/(\d{3})(?=\d)(?!\d*\.)/g, '$1,');
    return text.split('').reverse().join('');
}
  
function commifyLocal(text) {
    text = text.toString();
    text = text.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, '$1,');
    return text;
}
  
export default function commifyWithPrecision(numericValue, internationalNumericFormat, quantity) {
    const quantity_decimal = 3;
    const value_decimal = 2;

    const indianNumberFormat = internationalNumericFormat?.toUpperCase() == 'Y' ? false : true;
    numericValue = numericValue.toString().replace(/,/g, '');
    numericValue = parseFloat(numericValue) || 0;
    let numericSystemValue;
    const precision = quantity 
        ? (numericValue).toFixed(quantity_decimal)   // Ensure 3 decimal places for quantity
        : (numericValue).toFixed(value_decimal);  // Ensure 2 decimal places for non-quantity values

    if (indianNumberFormat) {
        numericSystemValue = commifyLocal(precision);
    } else {
        numericSystemValue = commifyInternational(precision);
    }
    return numericSystemValue;
}
