import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  Typography,
  Stack,
  Chip,
  Avatar,
  Tooltip,
  Breadcrumbs,
  IconButton,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import {
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridOverlay,
} from "@mui/x-data-grid-pro";
import { MoreVert } from "@mui/icons-material";
import { VendorStyle } from "./Style";
import {
  HOSTMAP,
  get_Inquiries_data,
  vendor_QPlus,
} from "../../../config/apiUrl";
import { CustomIconButton } from "../../../utils/RFQList/utils";
import axios from "axios";
import moment from "moment";
import JustificationPopOver from "../../../common/RFQList/justificationPopOver";
import VendorChip from "../../Vendors/VendorChip";
import { useSelector } from "react-redux";
import CommonDataGridPro from "../../RFQList/commonDataGridPro/commonDataGridPro";

function VendorList({ sessionID, templateId, selectedItem, ItemWiseVendors }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const companyPermissions = useSelector(
    (state) => state.companyInfoReducer.companyInfo
  );

  const vendorNameCell = (params) => {
    const nameFirstLetter = params?.row?.name.split("", 1);

    return (
      <Fragment>
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          spacing={1}
          sx={{
            width: "100%",
            height: "100%",
            "& .MuiButtonBase-root": {
              visibility: { md: "hidden", sm: "visible" },
              marginLeft: "auto",
            },
            "&:hover .MuiButtonBase-root": {
              visibility: "visible",
            },
          }}
        >
          <Avatar
            sx={{
              color: "white",
              bgcolor: "var(grey[100])",
              width: 32,
              height: 32,
            }}
          >
            {nameFirstLetter}
          </Avatar>
          <Box sx={{ width: "calc(100% - 70px)" }}>
            <>
              <Tooltip title={params?.row?.name}>
                <Typography
                  sx={{ lineHeight: "normal" }}
                  fontWeight={500}
                  noWrap
                >
                  {params?.row?.name}
                </Typography>
              </Tooltip>
            </>

            <Typography
              variant="vendorsubtext"
              color={"secondary.400"}
              display="block"
            >
              {params?.row?.code}
            </Typography>
          </Box>
        </Stack>
      </Fragment>
    );
  };

  function CustomGridToolbar() {
    return (
      <Fragment>
        <GridToolbarContainer className="datagrid-toolbar">
          <Grid
            container
            spacing={0}
            sx={{
              width: "100%",
              pt: 1,
              "&.MuiGrid2-root": {
                paddingTop: { xs: "0", sm: "0", md: "0", alignItems: "center" },
              },
            }}
          >
            <Grid xs={6} sm={6} md={4} xl={4}>
              <Box>
                <GridToolbarQuickFilter
                  className="DataGrid-SearchBar"
                  sx={{ p: 0 }}
                />
              </Box>
            </Grid>
            <Grid xs={6} sm={6} md={8} xl={8} sx={{ textAlign: "right" }}>
              <Box className="toolbar-right">
                <Box className="dg-filter">
                  <GridToolbarColumnsButton />
                  <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{
                      fileName: "Vendor Details Report",
                    }}
                  />
                  <GridToolbarFilterButton />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </GridToolbarContainer>
        {/* <GridToolbarContainer
          className="datagrid-toolbar"
          sx={{ display: { sm: "flex", md: "none" }, right: 0 }}>
          <Grid
            container
            spacing={0}
            sx={{ width: "100%", alignItems: "center" }}>
            <Grid xs={2} md={6}>
              <Box>&nbsp;</Box>
            </Grid>
            <Grid xs={10} md={6} sx={{ textAlign: { xs: "right", sm: "right" } }}>
              <Box className="dg-filter">
                <GridToolbarColumnsButton />
                <GridToolbarExport 
                  printOptions={{ disableToolbarButton: true }}
                  csvOptions={{
                    fileName: "Vendor Details Report",
                  }}
                />
                <GridToolbarFilterButton />
              </Box>
            </Grid>
          </Grid>
        </GridToolbarContainer> 
      <Divider />
      </Box>
      */}
      </Fragment>
    );
  }

  const GroupedIcon = (params) => {
    // const isTB = params.row.inquiry.is_tb;
    return (
      <Stack spacing={1} direction="row">
        <VendorChip
          vendor_data={params.row}
          module_permission={companyPermissions}
        />
      </Stack>
    );
  };

  const columns = [
    {
      field: "name",
      headerName: "VENDOR NAME",
      width: 300,
      renderCell: vendorNameCell,
    },
    {
      field: "inquiry.vendor_status",
      headerName: "VENDOR STATUS",
      width: 140,
      renderCell: GroupedIcon,
      valueGetter: (params) => {
        const {
          is_gst,
          gstin_no,
          pan_no,
          is_approved,
          pan_status,
          msme_status,
          gstin_status,
          industry_type,
          industry_type_value,
        } = params.row;
        const { gst_verification, msme_verification, pan_verification } =
          companyPermissions;

        // Determine GST status based on permissions
        let gstStatus = "";
        if (gst_verification === "Y" && gstin_no && gstin_status) {
          gstStatus = "GSTIN " + gstin_status;
        } else {
          gstStatus = is_gst === true ? "GSTIN Exist" : "GSTIN Not Exists";
        }

        let msmeStatus = "";
        if (
          msme_verification === "Y" &&
          industry_type === "MSME" &&
          industry_type_value &&
          msme_status
        ) {
          msmeStatus = "MSME " + msme_status;
        } else if (
          msme_verification !== "Y" &&
          industry_type === "MSME" &&
          industry_type_value
        ) {
          msmeStatus = "MSME Exists";
        } else {
          msmeStatus = "MSME Not Exists";
        }

        let panStatus = "";
        if (pan_verification === "Y" && pan_no && pan_status) {
          panStatus = "PAN " + pan_status;
        } else if (pan_verification !== "Y" && pan_no) {
          panStatus = "PAN Exists";
        } else {
          panStatus = "PAN Not Exists";
        }

        const approvalStatus =
          is_approved === true ? "Approved" : "Non-Approved";

        // Conditionally push each status to the array if it exists
        const statuses = [];
        if (approvalStatus) statuses.push(approvalStatus);
        if (gstStatus) statuses.push(gstStatus);
        if (msmeStatus) statuses.push(msmeStatus);
        if (panStatus) statuses.push(panStatus);

        return `${statuses.join(", ")}`;
      },
    },
  ];

  const CustomNoRowsOverlay = () => (
    <GridOverlay>
      <Typography>No data available</Typography>
    </GridOverlay>
  );

  return (
    <Box>
      {VendorStyle}
      <Box className="DataGFullScreen-wrap">
        <Box className="Dialog-AppTitle">
          <Typography variant="subtitle2" color="var(--grey-700)">
            {`VENDORS(${ItemWiseVendors.length})`}
          </Typography>
          {selectedItem !== null ? (
            <Breadcrumbs aria-label="breadcrumb" className="Breadcrumb-Vendor">
              <Typography
                sx={{ cursor: "pointer" }}
                underline="hover"
                color="inherit"
                noWrap
              >
                ALL VENDORS
              </Typography>
              {selectedItem !== null && (
                <Tooltip title={selectedItem?.name} placement="bottom-start">
                  <Typography noWrap>{selectedItem?.name}</Typography>
                </Tooltip>
              )}
            </Breadcrumbs>
          ) : (
            ""
          )}
        </Box>
        <CommonDataGridPro
          rows={ItemWiseVendors}
          columns={columns}
          // getRowId={(row) => `${row?.id}-${row?.idx}`}
          headerHeight={40}
          columnHeaderHeight={40}
          getRowHeight={() => "25px"}
          pagination={true}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          Toolbar={CustomGridToolbar}
          NoRowsOverlay={CustomNoRowsOverlay}
          disableRowSelectionOnClick
          className="fullScreenGrid"
          sx={{
            "&.MuiDataGrid-root": {
              border: 0,
            },
            "& .MuiDataGrid-virtualScrollerContent--overflowed .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
              {
                borderBottomColor: "bluegray.500",
              },
          }}
        />
      </Box>
    </Box>
  );
}

export default VendorList;
