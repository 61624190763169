import React, { Component } from "react";
import {
  FormControl,
  Button,
  Checkbox,
  Typography,
  FormHelperText,
  Backdrop,
  CircularProgress,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Stack,
  DialogContent,
  DialogTitle,
  Dialog,
  IconButton,
  Badge,
  DialogContentText,
  TextField,
  DialogActions,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import {
  getDropdownOptions,
  getIdentifiersDynamicValues,
  postFileSpecifications,
  devTemplateURi,
  templateURi,
  vendor_QPlus,
  downloadmaterialDescription,
  updateRegretJustificiation,
  submitRegret,
  // insertPrRfq,
} from "../../../config/apiUrl";
import axios from "axios";
import { styles } from "./style.js";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import { OpenInNew } from "@mui/icons-material";
import { openSnackbar } from "../../../redux/actions/snackbarAction";
import _ from "lodash";
import UploadFileDialog from "../../Dialogs/Upload/Upload";
import moment from "moment";
import ConfirmAlert from "../../Dialogs/ConfirmAlert.js";
import TermsAndConditions from "../../Dialogs/Upload/TermsAndConditions";
import { setTemplateValues } from "../../../redux/actions/prrfqactions";
import NegotiationTemplateSectionTableView from "../NegotiationTemplateSectionTable/NegotiationTemplateSectionTableView";
import NegotiationCardTemplate from "../../Card/NegotiationCardTemplate";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import NegotiationDetailsSectionView from "../NegotiationDetailsSection/NegotiationDetailsSectionView";
import UploadDynamicView from "../../Dialogs/Upload/UploadDynamicView";
import DestinationDetailsDialogView from "../../QuotationForm/DestinationDetailsDialog/DestinationDetailsDialogView";
import dayjs from "dayjs";

let Date_Time_Not_Null = function (value1) {
  //return value1 && value1.isValid() ? true : false;
  //let date1 = new Date(value1);
  return typeof value1 == "object"
    ? value1.isValid()
    : !isNaN(new Date(value1));
};

let Greater_Than_Date_Time = function (value1, value2) {
  let date1 = new Date(value1);
  let date2 = new Date(value2);

  return date1 > date2;
};

let Greater_Than_Current_Date_Time = function (value1) {
  let date1 = new Date(value1);
  let Today = new Date();

  return date1 > Today;
};
let prconfig = {};

let slab = {
  //has to be dynamic
  58: {
    isOpen: false,
  },
};

class NegotiationTemplateView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: "",
      template: "",
      loading: false,
      negotiationInitFlag: true,
      mainTemplate: {},
      mainMSTemplate: {},
      slabMainTemplate: {},
      isLoading: false,
      errorArray: [],
      additionalInfoTemplate: {},
      DifferenceAdditionalFieldId: [],
      DifferenceMainTemplateFieldId: {},
      DifferenceSlabMainTemplateFieldId: {},
      IntlTemplate: {},
      shipment_mark: "",
      MaterialDescription: false,
      specificationsOpen: false,
      qty: "",
      rate: "",
      unit: "",
      loaderArray: [],
      vendorType: "",
      vendorTerms: "",
      vendorTermsOpen: false,
      currentFieldID: "",
      incoTermError: false,
      paymentTermError: false,
      dynamicFileUploadOpen: false,
      currentFieldId: null,
      currentTemplate: null,
      categorySpecificationFiles: [],
      jsonTemplateloading: false,
      prevRFQLoading: false,
      prevFormLoading: false,
      randomId: null,
      usedAttributesId: [],
      confirmDialogOpen: false,
      confirmAlertMsg: "",
      confirmAlertTitle: "",
      tandcDialogOpen: false,
      termsAndConditionsData: {},
      applyToAll: {},
      commonOptions: {},
      localPaymentFields: [],
      localIncoFields: [],
      intlPaymentFields: [],
      intlIncoFields: [],
      submitDisable: false,
      templateValue: {},
      slab: {},
      slabKey: 1,
      applyToAllSlab: false,
      openConfirmBox: false,
      selectedCurrency: "",
      mainSelectedInitiallyN: false,
      attachments: {},
      Loading: true,
      fieldIdentifierMapping: {},
      isDestinationDetailsDialogOpen: false,
      openRegretModal: false,
      justificationRegretModal: this.props.regretJustificationMessage || "",
      regretLoader: false,
    };
    this._isMounted = false;
    this.setIsDestinationDetailsDialogOpen =
      this.setIsDestinationDetailsDialogOpen.bind(this);
  }

  componentDidMount() {
    this.props.JsonTemplate.ITEMIZE[0]?.map((cell, index) => {
      if (cell.VisibilityFlag === "Y") {
        if (cell.Identifier === "matspecghclfile") {
          this.setState({
            MaterialDescription: true,
          });
        }
      }
    });
    this.fetchPrevTemplate();
    // this.props.setClick(this.fetchPrevious);
    // this.initializeLocalRecipientsState();
    // this.initializeState();
    // this.initializeIntlRecipientsState();
  }

  handleCurrencyChange = (event) => {
    this.setState({ selectedCurrency: event.target.value });
  };

  handleConfirmBoxOpen = () => {
    this.setState({
      openConfirmBox: true,
    });
  };

  handleConfirmBoxClose = () => {
    this.setState({
      openConfirmBox: false,
    });
  };

  handleUploadOpen = (value) => {
    // setCurrentFieldId(cell.FieldId);
    this.setState({
      // currentFieldId: cell.FieldId,
      dynamicFileUploadOpen: true,
      currentTemplate: "additionalInfoTemplate",
      // attachments: value,
    });
    // setDynamicFileUploadOpen(true);
    this.getExistingFiles(value);
    // setCurrentTemplate("IntlTemplate");
  };

  getExistingFiles = (file_id) => {
    const queryString = file_id
      .map((file) => `file_ids[]=${file.id}`)
      .join("&");
    const url = `${postFileSpecifications}?${queryString}`;
    axios
      .get(url)
      .then((res) => {
        this.setState({
          // currentFieldId: cell.FieldId,

          attachments: res.data,
          Loading: false,
        });
      })
      .catch((err) => {
        console.log(err, " downloadFile error");
        this.props.openSnackbar(
          "error",
          err.response.data?.message
            ? err.response.data?.message
            : "Something went wrong!"
        );
        this.setState({
          Loading: false,
        });
      });
  };

  downloadTransactionExcel = (format) => {
    // Implement your downloadTransactionExcel function logic here
  };

  validateFileExistance = () => {
    // Implement your validateFileExistance function logic here
  };

  handleImport = () => {
    // Implement your import logic here
    // ...
    this.handleConfirmBoxOpen();
    // Close the dialog after handling the import
    // this.handleConfirmBoxClose();
  };
  isValidJSON = (str) => {
    try {
      JSON.parse(str);
      return true; // If parsing succeeds, it's valid JSON
    } catch (e) {
      return false; // If parsing fails, it's not valid JSON
    }
  };
  //slab functions start
  handleSlab = (evt, item) => {
    this.setState({
      slab: { ...this.state.slab, [item.id]: { isOpen: evt.target.checked } }, //set is open for item
    });
    if (this.state.applyToAllSlab == true) {
      this.setState({
        applyToAllSlab: false,
      });
    }
    //remove value if slab is closed
    if (!evt.target.checked) {
      this.setState((prevState) => ({
        ...prevState,
        mainTemplate1: {
          ...prevState.mainTemplate,
          [item.id]: {
            ...prevState.mainTemplate[item.id],
            slab: [
              { id: 1, start: "1", end: "", error: false },
              { id: 2, start: "More Than", end: "", error: false },
            ],
          },
        },
      }));
    }
  };
  //add row in slab
  slabAddRow = (idx, index) => {
    const re = /((\b|\+|-)(0|([1-9][0-9]*))(\.[0-9]+)?)\b/;
    let allSlab =
      this.state.mainTemplate[this.props.selectedItems[index].id]["slab"];
    if (!re.test(allSlab[allSlab.length - 1].end)) {
      this.props.openSnackbar(
        "error",
        ` Please provide a valid ${this.props?.displayOptions["label"]} for ${this.props.selectedItems[index].code}`
      );
      return;
    } else if (
      parseFloat(allSlab[allSlab.length - 2].start) >=
      parseFloat(allSlab[allSlab.length - 2].end)
    ) {
      this.props.openSnackbar(
        "error",
        `  Please provide a valid ${this.props?.displayOptions["label"]} for ${this.props.selectedItems[index].code}`
      );
      return;
    } else if (allSlab.some((obj) => obj.end == "" || obj.error == true)) {
      this.props.openSnackbar(
        "error",
        `Please provide a valid ${this.props?.displayOptions["label"]} for${this.props.selectedItems[index].code}.`
      );
      return;
    } else if (
      allSlab.length ==
      parseInt(this.props.rfqSettingValues?.allowed_slab_limit)
    ) {
      this.props.openSnackbar(
        "error",
        `Maximum ${this.props.rfqSettingValues.allowed_slab_limit} ${this.props?.displayOptions["label"]} allowed per item`
      );
      return;
    }
    let value = allSlab[idx].end;
    let val = allSlab[idx].end.toString().split(".")[1];
    let isAllZero = val && val.split("").every((char) => char == "0");
    if (val && val.length && !isAllZero) {
      if (val.length == 1) {
        value = parseFloat(value) + 0.1;
        value = value.toFixed(1);
      } else if (val.length == 2) {
        value = parseFloat(value) + 0.01;
        value = value.toFixed(2);
      } else if (val.length == 3) {
        value = parseFloat(value) + 0.001;
        value = value.toFixed(3);
      }
    } else {
      value = parseInt(value) + 1;
    }
    let newSlab = {
      id: Math.random().toString(36).slice(2),
      start: `${parseFloat(value)}`,
      end: "",
      error: false,
    };
    let popped = allSlab.pop(); //remove last element from array
    popped = { ...popped, end: "" };
    let updateSlab = [...allSlab, newSlab];
    updateSlab.push(popped);
    this.setState(
      (prevState) => ({
        ...prevState,
        mainTemplate: {
          ...prevState.mainTemplate,
          [this.props.selectedItems[index].id]: {
            ...prevState.mainTemplate[this.props.selectedItems[index].id],
            slab: updateSlab,
          },
        },
      }),
      () => {
        this.handleKey(index);
      }
    );
  };
  //remove row from slab
  slabRemoveRow = (idx, index) => {
    if (this.state.applyToAllSlab == true) {
      this.setState({
        applyToAllSlab: false,
      });
    }
    let allSlab =
      this.state.mainTemplate[this.props.selectedItems[index].id]["slab"];
    let updateSlab = allSlab.filter((row, i) => i != idx);
    let popped = updateSlab.pop();
    popped = { ...popped, end: updateSlab[updateSlab.length - 1].end };
    if (updateSlab[idx]) {
      updateSlab[idx] = { ...updateSlab[idx], start: allSlab[idx].start };
    }
    updateSlab.push(popped);
    this.setState(
      (prevState) => ({
        ...prevState,
        mainTemplate: {
          ...prevState.mainTemplate,
          [this.props.selectedItems[index].id]: {
            ...prevState.mainTemplate[this.props.selectedItems[index].id],
            slab: updateSlab,
          },
        },
      }),
      () => {
        this.handleKey(index);
      }
    );
  };
  //handle input in row
  handleRowInput = (evt, row, id, index) => {
    if (this.state.applyToAllSlab == true) {
      this.setState({
        applyToAllSlab: false,
      });
    }
    let allSlab =
      this.state.mainTemplate[this.props.selectedItems[index].id]["slab"];
    let popped = allSlab.pop();
    let updateSlab = allSlab.map((obj, i) => {
      let newObj = { ...obj };
      const re = /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/;
      if (i == id) {
        if (
          parseFloat(evt.target.value) <= parseFloat(newObj.start) ||
          !re.test(evt.target.value)
        ) {
          newObj.error = true;
          newObj.end = "";
        } else {
          newObj.end = parseFloat(evt.target.value);
          newObj.error = false;
        }
      } else if (i == id + 1) {
        if (evt.target.value != "" && re.test(evt.target.value)) {
          let value = evt.target.value;
          let val = evt.target.value.toString().split(".")[1];
          let isAllZero = val && val.split("").every((char) => char == "0");
          if (val && val.length && !isAllZero) {
            if (val.length == 1) {
              value = parseFloat(value) + 0.1;
              value = value.toFixed(1);
            } else if (val.length == 2) {
              value = parseFloat(value) + 0.01;
              value = value.toFixed(2);
            } else if (val.length == 3) {
              value = parseFloat(value) + 0.001;
              value = value.toFixed(3);
            }
          } else {
            value = parseInt(value) + 1;
          }
          newObj.start = `${parseFloat(value)}`;
          if (parseFloat(newObj.end) <= parseFloat(newObj.start)) {
            newObj.error = true;
          } else {
            newObj.error = false;
          }
        } else if (evt.target.value == "") {
          newObj.start = "";
        }
      }
      return newObj;
    });
    popped = { ...popped, end: updateSlab[updateSlab.length - 1].end };
    updateSlab.push(popped);
    this.setState((prevState) => ({
      ...prevState,
      mainTemplate: {
        ...prevState.mainTemplate,
        [this.props.selectedItems[index].id]: {
          ...prevState.mainTemplate[this.props.selectedItems[index].id],
          slab: updateSlab,
        },
      },
    }));
  };
  //for apply to all in slab
  handleApplyToAllSlab = (evt, index, itemId) => {
    var found = this.props.selectedItems.find((element) => {
      if (this.state.slab[element.id].isOpen) {
        return element;
      }
    });
    let allSlab = this.state.mainTemplate[found.id]["slab"];
    if (allSlab.some((obj) => obj.error == true || obj.end == "")) {
      this.props.openSnackbar(
        "error",
        `Please provide a valid ${this.props?.displayOptions["label"]} for ${found.code}.`
      );
      return;
    }
    this.setState({
      applyToAllSlab: evt.target.checked,
    });
    this.props.selectedItems.forEach((item) => {
      if (this.state.slab[item.id].isOpen) {
        this.setState((prevState) => ({
          ...prevState,
          mainTemplate: {
            ...prevState.mainTemplate,
            [item.id]: {
              ...prevState.mainTemplate[item.id],
              slab: allSlab.map((obj, i) => {
                return { ...obj, id: Math.random().toString(36).slice(2) };
              }),
            },
          },
        }));
      }
    });
  };
  //change key for render
  handleKey = (index) => {
    let allSlab =
      this.state.mainTemplate[this.props.selectedItems[index].id]["slab"];
    let updateSlab = allSlab.map((obj, i) => {
      return { ...obj, id: Math.random().toString(36).slice(2) };
    });
    this.setState((prevState) => ({
      ...prevState,
      mainTemplate: {
        ...prevState.mainTemplate,
        [this.props.selectedItems[index].id]: {
          ...prevState.mainTemplate[this.props.selectedItems[index].id],
          slab: updateSlab,
        },
      },
    }));
  };
  //slab functions end

  handleChange = (evt, index, FieldId, Identifier, FieldLabel, ElementType) => {
    const { value, type, checked } = evt.target;
    const { mainTemplate } = this?.state;
    const isFieldEditableCondition =
      ElementType === "TA" || ElementType === "TB" || ElementType === "S";

    const dynamicKey = Object.keys(this.props?.selectedQuotedItems?.items)[
      index
    ];
    if (!this.props.quotationFormFlag) {
      if (
        mainTemplate[this.props.selectedItems[index].id][FieldId]["value"] !==
        value
      ) {
        this.setState({
          applyToAll: {
            ...this.state.applyToAll,
            [`${FieldId}_applyToAll`]: false,
            [`${FieldId}_applyToAll`]: false,
            [`${FieldId}_applyToAll`]: false,
          },
        });
      }
      this.setState(
        (prevState) => {
          // Construct the new state object
          return {
            ...prevState,
            mainTemplate: {
              ...prevState.mainTemplate,
            },
          };
        },
        () => {
          // Callback function for actions after state update
          this.validateInput(FieldId, index);
          if (
            Identifier === "Budgetedrate" ||
            Identifier === "ProductQuantity"
          ) {
            const fieldName = Identifier === "Budgetedrate" ? "rate" : "qty";
            this.setState((prevState) => ({
              ...prevState,
              mainTemplate: {
                ...prevState.mainTemplate,
                [this.props.selectedItems[index].id]: {
                  ...prevState.mainTemplate[this.props.selectedItems[index].id],
                  [fieldName]: value,
                },
              },
            }));
          }
        }
      );
      window.localStorage.setItem(
        "maintemplate",
        JSON.stringify(this.state.mainTemplate)
      );
    } else {
      if (
        // mainTemplate[this.props.negotiationJsonData.items[index].id][FieldId]["value"] !==value
        true
      ) {
        this.setState({
          applyToAll: {
            ...this.state.applyToAll,
            [`${FieldId}_applyToAll`]: false,
            [`${FieldId}_applyToAll`]: false,
            [`${FieldId}_applyToAll`]: false,
          },
        });
      }
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            mainTemplate: {
              ...prevState.mainTemplate,
              [dynamicKey]: {
                ...prevState.mainTemplate[dynamicKey],
                [FieldId]: {
                  // ...prevState.mainTemplate[dynamicKey][FieldId],
                  value: type !== "checkbox" ? evt.target.value : checked,
                  FieldLabelName: FieldLabel,
                  isFieldEditable: isFieldEditableCondition ? "Y" : "N",
                },
              },
            },
          };
        },
        () => {
          if (
            Identifier === "Budgetedrate" ||
            Identifier === "ProductQuantity"
          ) {
            //To set the unit value in quotation
            const keyToUpdate = Identifier === "Budgetedrate" ? "rate" : "qty";
            const updatedState = {
              ...this.state,
              mainTemplate: {
                ...this.state.mainTemplate,
                [dynamicKey]: {
                  ...this.state.mainTemplate[dynamicKey],
                  [keyToUpdate]: value,
                },
              },
            };
            this.setState(updatedState);
          }
        }
      );
      window.localStorage.setItem(
        "mainTemplate",
        JSON.stringify(this.state.mainTemplate)
      );
    }
  };

  generateRandom = (min, max) => {
    const timestring = new Date().getTime();
    const numstring = Math.floor(Math.random() * (max - min + 1)) + min;
    return timestring + "." + numstring;
  };

  fetchPrevious = () => {
    const { JsonTemplate, lastTransactionData } = this.props;
    if (JsonTemplate?.ITEMIZE?.length && lastTransactionData) {
      this.setState({
        prevFormLoading: true,
      });

      let mainTemplate = _.cloneDeep(this.state.mainTemplate);
      let slabMainTemplate = _.cloneDeep(this.state.mainTemplate);
      let additionalInfoTemplate;
      if (this.props.vendorType == "Local") {
        additionalInfoTemplate = this.initializeLocalRecipientsState();
      } else {
        additionalInfoTemplate = this.initializeIntlRecipientsState();
      }

      let ItemizeColorChange = {};
      let ItemizeSlabColorChange = {}; // For slab changes
      let mainTemp = {};
      let slabTemp = {};
      if (this.props.multipleSolutionFlag && this.props.slabFlag) {
        for (const key in slabMainTemplate) {
          const currentItem = slabMainTemplate[key];
          for (const key2 in currentItem) {
            const currentField = currentItem[key2];
            // const defaultFormula = currentField["DefaultFormula"];
            let attributeNameWithoutF = currentField?.HTMLAttributeName
              ? currentField.HTMLAttributeName.replace("f", "").split("_")[0]
              : null;
            attributeNameWithoutF = parseInt(attributeNameWithoutF);
            lastTransactionData?.itemize[key]?.solution[0].data.map(
              (solution, index) => {
                const currentIndex = index.toString();
                if (solution.hasOwnProperty(attributeNameWithoutF)) {
                  if (
                    this.state.fieldIdentifierMapping?.[currentField.Identifier]
                  ) {
                    if (
                      solution?.[attributeNameWithoutF] !==
                      this.props.PrevTransaction.template.itemize[key]
                        ?.solution[0]?.data[index][
                        this.state.fieldIdentifierMapping?.[
                          currentField.Identifier
                        ]
                      ]
                    ) {
                      if (!ItemizeSlabColorChange[key]) {
                        ItemizeSlabColorChange[key] = {};
                      }
                      if (!ItemizeSlabColorChange[key][index]) {
                        ItemizeSlabColorChange[key][index] = {};
                      }
                      ItemizeSlabColorChange[key][index][
                        attributeNameWithoutF
                      ] =
                        this.props.PrevTransaction.template.itemize[
                          key
                        ]?.solution[0]?.data[index][
                          this.state.fieldIdentifierMapping?.[
                            currentField.Identifier
                          ]
                        ];
                    }
                  }

                  // Ensure the key for the current index exists

                  // Assign currentField value to mainTemp
                  if (currentField.Identifier === "ProductQuantity") {
                    currentItem["qty"] = solution?.[attributeNameWithoutF];
                  }

                  // if (currentItem[key2]["Identifier"] === "ProductQuantity") {
                  //   currentItem["qty"] = finalValue;
                  // }

                  if (
                    currentItem[key2]["Identifier"] === "EstimatedCost" ||
                    currentItem[key2]["Identifier"] ===
                      "EstimatedCostForeign" ||
                    currentItem[key2]["Identifier"] === "Budgetedrate" ||
                    currentItem[key2]["Identifier"] === "Rate" ||
                    currentItem[key2]["Identifier"] === "CIFRate" ||
                    currentItem[key2]["Identifier"] === "rate"
                  ) {
                    currentItem["rate"] = solution?.[attributeNameWithoutF];
                  }
                  if (currentField.Identifier === "GSTType") {
                    let gstTypeValue = currentField.DefaultValue.find(
                      (item) => item.code === solution?.[attributeNameWithoutF]
                    )?.name;
                    currentField.value = gstTypeValue;
                  } else {
                    currentField.value = solution?.[attributeNameWithoutF]; // Assigning currentField value
                  }
                }
                if (!slabTemp[key]) {
                  slabTemp[key] = [];
                }

                if (lastTransactionData?.itemize[key]?.selected) {
                  slabTemp[key]["selected"] =
                    lastTransactionData?.itemize[key]?.selected;
                }

                if (!slabTemp[key][currentIndex]) {
                  slabTemp[key][currentIndex] = {};
                }
                if (attributeNameWithoutF) {
                  if (currentField.Identifier === "ProductQuantity") {
                    slabTemp[key][currentIndex]["qty"] =
                      solution?.[attributeNameWithoutF];
                  }
                  if (
                    [
                      "EstimatedCost",
                      "EstimatedCostForeign",
                      "Budgetedrate",
                      "Rate",
                      "CIFRate",
                      "rate",
                    ].includes(currentField.Identifier)
                  ) {
                    currentItem["rate"] = solution?.[attributeNameWithoutF];
                  }

                  if (solution?._range) {
                    slabTemp[key][currentIndex]["_range"] = solution?._range;
                  }
                  if (solution?.select_slab) {
                    slabTemp[key][currentIndex]["select_slab"] =
                      solution?.select_slab;
                  }
                  slabTemp[key][currentIndex][attributeNameWithoutF] = {
                    ...currentField,
                  };
                }
              }
            );
          }
        }
      } else {
        for (const key in mainTemplate) {
          const currentItem = mainTemplate[key];
          for (const key2 in currentItem) {
            const currentField = currentItem[key2];
            // const defaultFormula = currentField["DefaultFormula"];
            let attributeNameWithoutF = currentField?.HTMLAttributeName
              ? currentField.HTMLAttributeName.replace("f", "").split("_")[0]
              : null;
            attributeNameWithoutF = parseInt(attributeNameWithoutF);
            lastTransactionData?.itemize[key]?.solution.map(
              (solution, index) => {
                const currentIndex = index.toString();

                if (solution?.data[0][attributeNameWithoutF]) {
                  if (
                    this.state.fieldIdentifierMapping?.[currentField.Identifier]
                  ) {
                    if (
                      solution?.data?.[0][attributeNameWithoutF] !==
                      this.props.PrevTransaction.template.itemize[key]
                        ?.solution[index]?.data[0][
                        this.state.fieldIdentifierMapping?.[
                          currentField.Identifier
                        ]
                      ]
                    ) {
                      if (!ItemizeColorChange[key]) {
                        ItemizeColorChange[key] = {};
                      }
                      if (!ItemizeColorChange[key][index]) {
                        ItemizeColorChange[key][index] = {};
                      }
                      ItemizeColorChange[key][index][attributeNameWithoutF] =
                        this.props.PrevTransaction.template.itemize[
                          key
                        ]?.solution[index]?.data[0][
                          this.state.fieldIdentifierMapping?.[
                            currentField.Identifier
                          ]
                        ];
                    }
                  }

                  // Ensure the key for the current index exists

                  // Assign currentField value to mainTemp
                  if (currentField.Identifier === "ProductQuantity") {
                    currentItem["qty"] =
                      solution?.data[0][attributeNameWithoutF];
                  }

                  // if (currentItem[key2]["Identifier"] === "ProductQuantity") {
                  //   currentItem["qty"] = finalValue;
                  // }

                  if (
                    currentItem[key2]["Identifier"] === "EstimatedCost" ||
                    currentItem[key2]["Identifier"] ===
                      "EstimatedCostForeign" ||
                    currentItem[key2]["Identifier"] === "Budgetedrate" ||
                    currentItem[key2]["Identifier"] === "Rate" ||
                    currentItem[key2]["Identifier"] === "CIFRate" ||
                    currentItem[key2]["Identifier"] === "rate"
                  ) {
                    currentItem["rate"] =
                      solution?.data[0][attributeNameWithoutF];
                  }
                  if (currentField.Identifier === "GSTType") {
                    let gstTypeValue = currentField.DefaultValue.find(
                      (item) =>
                        item.code === solution?.data[0][attributeNameWithoutF]
                    )?.name;
                    currentField.value = gstTypeValue;
                  } else {
                    currentField.value =
                      solution?.data[0][attributeNameWithoutF]; // Assigning currentField value
                  }
                }
                if (!mainTemp[key]) {
                  mainTemp[key] = {};
                }

                if (!mainTemp[key][currentIndex]) {
                  mainTemp[key][currentIndex] = {};
                }
                if (attributeNameWithoutF) {
                  if (currentField.Identifier === "ProductQuantity") {
                    mainTemp[key][currentIndex]["qty"] =
                      solution?.data[0][attributeNameWithoutF];
                  }
                  if (
                    currentField.Identifier === "EstimatedCost" ||
                    currentField.Identifier === "EstimatedCostForeign" ||
                    currentField.Identifier === "Budgetedrate" ||
                    currentField.Identifier === "Rate" ||
                    currentField.Identifier === "CIFRate" ||
                    currentField.Identifier === "rate"
                  ) {
                    mainTemp[key][currentIndex]["rate"] =
                      solution?.data[0][attributeNameWithoutF];
                  }
                  if (solution?.name) {
                    mainTemp[key][currentIndex]["name"] = solution?.name;
                  }
                  mainTemp[key][currentIndex][attributeNameWithoutF] = {
                    ...currentField,
                  };
                }
              }
            );
          }
        }
      }
      let ColorChange = {};
      Object.entries(additionalInfoTemplate).forEach(([key, currentField]) => {
        const attributeNameWithoutF = currentField.HTMLAttributeName
          ? currentField.HTMLAttributeName.replace("f", "")
          : null;

        if (
          attributeNameWithoutF &&
          lastTransactionData.common.hasOwnProperty(attributeNameWithoutF)
        ) {
          // Set the value to the currentField
          if (this.state.fieldIdentifierMapping?.[currentField.Identifier]) {
            // if (this.props.PrevTransaction.template.common[this.state.fieldIdentifierMapping?.[currentField.Identifier]]) {
            if (
              lastTransactionData.common[attributeNameWithoutF] !==
              this.props.PrevTransaction.template.common[
                this.state.fieldIdentifierMapping?.[currentField.Identifier]
              ]
            ) {
              ColorChange[attributeNameWithoutF] =
                this.props.PrevTransaction.template.common[
                  this.state.fieldIdentifierMapping?.[currentField.Identifier]
                ];
            }
          }
          currentField.value =
            lastTransactionData.common[attributeNameWithoutF];
          currentField.error = false;

          // Remove field from errorArray if present
          const index1 = this.state.errorArray.indexOf(attributeNameWithoutF);
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }

          // Set value to corresponding HTML element if it exists
          const element = document.getElementsByName(attributeNameWithoutF)[0];
          if (element) {
            element.value = currentField?.value;
          }
        } else if (
          currentField.DefaultFormula &&
          currentField.DefaultFormula.trim() !== ""
        ) {
          // Extract attribute codes from DefaultFormula
          const attributeCodes =
            currentField.DefaultFormula.match(/f\d+/g) || [];
          // Iterate over each attribute code
          attributeCodes.forEach((attributeCode) => {
            // Extract attribute key from attributeCode
            const attributeKey = attributeCode.substring(1);
            // Check if the attribute key exists in lastTransactionData.common
            if (lastTransactionData.common.hasOwnProperty(attributeKey)) {
              // Set the value to the currentField
              currentField.value = lastTransactionData.common[attributeKey];
              currentField.error = false;

              // Remove field from errorArray if present
              const index2 = this.state.errorArray.indexOf(
                currentField.HTMLAttributeName
              );
              if (index2 !== -1) {
                this.state.errorArray.splice(index2, 1);
              }

              // Set value to corresponding HTML element if it exists
              const element = document.getElementsByName(
                currentField.HTMLAttributeName
              )[0];
              if (element) {
                element.value = currentField.value;
              }
            }
          });
        }
      });
      if (this.props.itemSpecific === true) {
        const filteredMainTemplate = Object.fromEntries(
          Object.entries(mainTemplate).filter(([key]) =>
            this.props.inquiryItemsId.includes(parseInt(key))
          )
        );
        mainTemplate = filteredMainTemplate;
        if (this.props.multipleSolutionFlag === true && this.props.slabFlag) {
          const filteredSlabmainTemplate = Object.fromEntries(
            Object.entries(slabTemp).filter(([key]) =>
              this.props.inquiryItemsId.includes(parseInt(key))
            )
          );
          slabTemp = filteredSlabmainTemplate;
        } else {
          const filteredMSmainTemplate = Object.fromEntries(
            Object.entries(mainTemp).filter(([key]) =>
              this.props.inquiryItemsId.includes(parseInt(key))
            )
          );
          mainTemp = filteredMSmainTemplate;
        }
      }

      this.setState({
        mainTemplate,
        additionalInfoTemplate,
        DifferenceAdditionalFieldId: ColorChange,
        DifferenceMainTemplateFieldId: ItemizeColorChange,
        DifferenceSlabMainTemplateFieldId: ItemizeSlabColorChange,
        mainMSTemplate: mainTemp,
        slabMainTemplate: slabTemp,
        prevFormLoading: false, // Assuming loading is finished after updating the state
      });
    }
  };

  handleCheckboxChange = (event, key) => {
    // Ensure that mainTemplate and lastTransactionTypeInThread exist before accessing their properties
    if (
      this.state.mainTemplate &&
      this.props.lastTransactionInThread &&
      this.state.mainTemplate[key] &&
      this.props.lastTransactionInThread[key]
    ) {
      const isNegotiation =
        this.props.lastTransactionTypeInThread === "Negotiation";
      const lastSelected =
        this.props.lastTransactionInThread[key].selected === "Y";
      const mainSelected = this.state.mainTemplate[key].selected === "Y";

      // Check if the transaction is negotiation and the last transaction selected the item
      if (isNegotiation && lastSelected) {
        // Disable input fields
        const updatedQuotation = {
          ...this.state.mainTemplate,
          [key]: {
            ...this.state.mainTemplate[key],
            selected: "Y",
            readOnly: true, // Set readOnly to true to disable input fields
          },
        };
        this.setState({ mainTemplate: updatedQuotation });
      } else if (!mainSelected) {
        // Allow toggling between 'Y' and 'N' when mainSelected is 'N'
        const updatedQuotation = {
          ...this.state.mainTemplate,
          [key]: {
            ...this.state.mainTemplate[key],
            selected: event.target.checked ? "Y" : "N",
            // readOnly: false, // Reset readOnly to false to enable input fields
          },
        };
        this.setState({ mainTemplate: updatedQuotation });
      } else {
        // Check if filteredSelectedNotData contains the key and its selected value is 'N'
        const filteredSelectedNotDataContainsKey =
          this.props.filteredSelectedNotData &&
          this.props.filteredSelectedNotData[key] &&
          this.props.filteredSelectedNotData[key].selected === "N";

        // If filteredSelectedNotData contains the key and its selected value is 'N', set selected to 'N'
        const selectedStatus = filteredSelectedNotDataContainsKey ? "N" : "Y";

        const updatedQuotation = {
          ...this.state.mainTemplate,
          [key]: {
            ...this.state.mainTemplate[key],
            selected: selectedStatus,
            readOnly: false, // Reset readOnly to false to enable input fields
          },
        };
        this.setState({ mainTemplate: updatedQuotation });
      }
    } else {
      console.error(
        `Template with key ${key} does not exist in either mainTemplate or lastTransactionInThread.`
      );
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the negotiationInitFlag has changed
    if (this.state.negotiationInitFlag !== prevState.negotiationInitFlag) {
      // If it has changed, conditionally initialize the state
      if (this.state.negotiationInitFlag) {
        this.initializeState();
      }
    }
    if (
      this.props.regretJustificationMessage !=
      prevProps.regretJustificationMessage
    ) {
      this.setState({
        justificationRegretModal: this.props.regretJustificationMessage,
      });
    }
  }

  toggleLoaderArray = (arg) => {
    let temp = this.state.loaderArray;
    let index = temp.indexOf(arg);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(arg);
    }
    this.setState({
      loaderArray: temp,
    });
  };

  SUM = (field, item) => {
    let sum = 0;
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = 0;
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}
      sum += Number(expression_val);
    });
    return sum;
  };

  PRLongText = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    //let replacedStr= pritems[pritems.length-1][prconfig[field].response_key].replace(/\^/,"\n");
    return pritems[pritems?.length - 1][prconfig[field].response_key];
  };

  COMMA = (field, item) => {
    let str = "";
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = "";
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}
      str += expression_val + ",";
    });
    return str.slice(0, -1);
  };

  COMMA_WITH_SPACE = (field, item) => {
    let str = "";
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = "";
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}
      str += expression_val + ", ";
    });
    return str.slice(0, -2);
  };

  EstimatedCost = (field, item) => {
    let value = item.estimated_cost;
    if (
      this.props.featurePermission
        ?.Fetch_Budget_Value_in_RFQ_Form_from_Item_Master?.feature_value === "N"
    ) {
      value = this.LATEST(field, item);
    }
    return value;
  };

  MIN = (field, item) => {
    let value = 0;
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = 0;
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}

      if (expression_val < value) {
        value = expression_val;
      }
    });

    return value;
  };

  MIN_DATE = (field, item) => {
    let value = 0;

    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = 0;
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
        if (!value || moment(value).isAfter(moment(expression_val))) {
          value = expression_val;
        }
      } catch (e) {}

      if (expression_val < value) {
        value = expression_val;
      }
    });

    return value;
  };

  LATEST_Remove_Circumflex = (field, item) => {
    let value = this.LATEST(field, item);
    return value ? value.replace(/\^\^\^/g, "\n") : "";
  };

  PRLongText = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    return (
      this.LATEST_Remove_Circumflex(field, item) ||
      pritems[pritems.length - 1]["material_short_text"] ||
      ""
    );
  };

  DeliverySchedule = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    let pr_delivery_schedule = "";
    pritems.forEach(function (item, index) {
      let quantity = item.quantity;
      let delivery_date = item.delivery_date;
      let date_arr = delivery_date.split("-");
      date_arr = date_arr.reverse();
      //delivery_date = delivery_date.replace(/-/g, "\/");
      delivery_date = date_arr.join("-");
      let line_item = item.pr_line_item;
      let pr_number = item.pr.number;
      pr_delivery_schedule +=
        delivery_date +
        "," +
        quantity +
        "," +
        pr_number +
        "(" +
        line_item +
        ");";
    });
    pr_delivery_schedule = pr_delivery_schedule.slice(0, -1);
    return pr_delivery_schedule;
  };

  PRNoLineItem = (field, item) => {
    let str = "";
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      str += prlineitem.pr.number + "(" + prlineitem.pr_line_item + "), ";
    });
    return str.slice(0, -2);
  };

  ReqDeliveryDate = (field, item) => {};

  LATEST = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    //return pritems[pritems?.length-1][prconfig[field].response_key] || '';
    let pr_line_item_object = pritems[pritems.length - 1];
    let expression = "pr_line_item_object" + "." + prconfig[field].response_key;
    let result = "";
    try {
      result = eval(expression);
    } catch (e) {}

    return result;
  };

  LATEST_PO_DATE = (field, item) => {
    let value = "";
    value = this.LATEST(field, item);
    if (value) {
      let pattern = /\b(\d{4})(\d{2})(\d{2})\b/;
      let result = value.match(pattern);
      if (result) {
        result.shift();
        value = result.join("-");
      }
    }
    return value;
  };

  QTY_Check = (qty) => {
    let $moq_permission =
      this.props.accountModulePermission[
        "Allow more Quantity in PR Qty Distribution"
      ]?.module_activated;
    let $service_rfq_permission =
      this.props.accountModulePermission["Service PR"]?.module_activated;
    let readOnly = true;
    if ($moq_permission === "Y" || $service_rfq_permission === "Y") {
      readOnly = false;
    }
    return readOnly;
  };

  COMPANY_CODE_Check = (company_code) => {
    return company_code ? true : false;
  };

  initializeState = async () => {
    let initialSlab = {};
    let finalState = {};
    if (this.props.JsonTemplate?.ITEMIZE?.length) {
      for (
        let index = 0;
        index < Object.keys(this.props.negotiationJsonData.items).length;
        index++
      ) {
        let obj = {};
        this.state.usedAttributesId.push(
          this.props.JsonTemplate?.ITEMIZE[index][1]?.Child?.AttributeId
        );
        this.setState({
          usedAttributesId: this.state.usedAttributesId,
        });
        for (
          let fieldIndex = 0;
          fieldIndex < this.props.JsonTemplate?.ITEMIZE[index].length;
          fieldIndex++
        ) {
          const row = this.props.JsonTemplate?.ITEMIZE[index][fieldIndex];
          if (row.VisibilityFlag === "Y") {
            if (
              row.Identifier === "Product" ||
              row.Identifier === "ProductForeign"
            ) {
              obj[row.FieldId] = {
                HTMLAttributeName: row.HTMLAttributeName,
                value: Object.keys(this.props.negotiationJsonData.items)[index],
                Validation: "",
                FieldLabel: row.FieldLabel,
                error: false,
                Identifier: row.Identifier,
                ErrorMessage: "",
                DefaultFormula: row.DefaultFormula,
                DefaultValue: row.DefaultValue,
              };
              continue;
            }
            if (row.Identifier === "ProductCode") {
              obj[row.FieldId] = {
                HTMLAttributeName: row.HTMLAttributeName,
                value: Object.values(this.props.negotiationJsonData.items)[
                  index
                ].code,
                Validation: "",
                FieldLabel: row.FieldLabel,
                error: false,
                Identifier: row.Identifier,
                ErrorMessage: "",
                DefaultFormula: row.DefaultFormula,
                DefaultValue: row.DefaultValue,
              };
              continue;
            }

            let value = "";
            if (row.Identifier === "MasterSpecification") {
              value =
                this.props.negotiationJsonData.items[index]?.specifications;
            } else if (row.Identifier === "MasterHSNCode") {
              value = this.props.negotiationJsonData.items[index]?.hsn_code;
            }

            let readOnly = false;
            if (
              Object.keys(this.props.prlineitems).length &&
              prconfig &&
              prconfig[row.Identifier] !== undefined
            ) {
              value =
                eval(this[prconfig[row.Identifier].function_name])(
                  row.Identifier,
                  this.props.items.selectedQuotedItems[index]
                ) || "";
              if (
                prconfig[row.Identifier]?.non_editable_function &&
                prconfig[row.Identifier]?.non_editable_function !== "0"
              ) {
                if (prconfig[row.Identifier]?.non_editable_function == "1") {
                  readOnly = true;
                } else {
                  readOnly = eval(
                    this[prconfig[row.Identifier]?.non_editable_function]
                  )(value);
                }
              }
            }
            obj[row.FieldId] = {
              HTMLAttributeName: row.HTMLAttributeName,
              value: value,
              Validation: row.Validation,
              FieldLabel: row.FieldLabel,
              error: false,
              ErrorMessage: row.ErrorMessage,
              Identifier: row.Identifier,
              ElementType: row.ElementType,
              readOnly: readOnly,
              DefaultFormula: row.DefaultFormula,
              DefaultValue: row.DefaultValue,
            };
            if (
              row.Identifier === "EstimatedCost" ||
              row.Identifier === "EstimatedCostForeign" ||
              row.Identifier === "Budgetedrate" ||
              row.Identifier === "BudgetedrateForeign"
            ) {
              obj[row.FieldId][
                `upd_budget_master_${this.props.negotiationJsonData.items[index]?.id}`
              ] = false;

              if (
                this.props.featurePermission
                  ?.Fetch_Budget_Value_in_RFQ_Form_from_Item_Master
                  ?.feature_value === "Y" &&
                this.props.negotiationJsonData.items[index]?.budgeted_rate
              ) {
                if (
                  parseFloat(
                    this.props.negotiationJsonData.items[index]?.budgeted_rate
                  )
                ) {
                  obj[row.FieldId]["value"] =
                    this.props.negotiationJsonData.items[index].budgeted_rate;
                  obj["rate"] =
                    this.props.negotiationJsonData.items[index].budgeted_rate;
                } else {
                  obj["rate"] = "";
                }
              } else {
                obj["rate"] = value;
              }
            }

            if (row.ElementType === "S") {
              if (row.DropdownEdit === "Y") {
                obj[row.FieldId]["options"] = [];
                //getting options data from redux if it is available else calling api
                let optionsData = this.getOptionsFromRedux(row, "itemize");
                if (optionsData) {
                  obj[row.FieldId]["options"] = optionsData;
                  this.setState({
                    commonOptions: {
                      ...this.state.commonOptions,
                      [row.Identifier]: optionsData,
                    },
                  });
                } else {
                  // obj[row.FieldId]["options"] = await this.getItemizeOptions(
                  //   row,
                  //   index
                  // );
                }
              } else {
                obj[row.FieldId]["options"] = row.DefaultValue;
              }
            }
            if (
              row.Identifier === "BasicHistoricalPrice" ||
              row.Identifier === "LandedHistoricalPrice" ||
              row.Identifier === "DateHP"
            ) {
              obj[row.FieldId]["DynamicDefaultValue"] = "loading";
              this.getDynamicDefaultValues(row, index, row.DefaultValue);
            }
          }
          if (row.Identifier === "EstimatedValue") {
            obj[row.FieldId] = {
              HTMLAttributeName: row.HTMLAttributeName,
              value: "",
              Validation: row.Validation,
              FieldLabel: row.FieldLabel,
              error: false,
              ErrorMessage: row.ErrorMessage,
              Identifier: row.Identifier,
              DefaultFormula: row.DefaultFormula,
              DefaultValue: row.DefaultValue,
            };
          }
          // if ( row.Identifier === "ProductQuantity") obj.qty =  obj[row.FieldId].value
          if (row.Identifier === "ProductQuantity" && obj[row.FieldId])
            obj.qty = obj[row.FieldId].value;
        }
        obj.specification = "";
        const itemize = this.props.lastTransactionData.itemize;
        const keys = Object.keys(itemize);
        const key = keys[index]; // Get the key at the specified index
        obj.added_after_rfq_created =
          this.props.items[key]?.added_after_rfq_created;
        obj.selected = Object.values(this.props.lastTransactionData.itemize)[
          index
        ]?.selected;
        if (!obj.hasOwnProperty("qty")) {
          obj.qty = "";
        }
        if (!obj.hasOwnProperty("slab")) {
          obj["slab"] = [
            { id: 1, start: "1", end: "", error: false },
            { id: 2, start: "More Than", end: "", error: false },
          ];
        }
        finalState[Object.keys(this.props.negotiationJsonData.items)[index]] =
          obj;
        if (this.props.rfqSettingValues.includeSlab) {
          initialSlab[
            Object.keys(this.props.negotiationJsonData.items)[index]
          ] = {
            isOpen: true,
          };
        } else {
          initialSlab[
            Object.keys(this.props.negotiationJsonData.items)[index]
          ] = {
            isOpen: false,
          };
        }
      }
      this.setState(
        {
          mainTemplate: finalState,
          slab: initialSlab,
        },
        () => {
          if (
            this.props.featurePermission?.prev_tran_value?.feature_value ===
              "Y" &&
            this.props.prplants.length === 0
          ) {
            this.fetchPrevious();
          } else {
            this.fetchPrevious();
          }
        }
      );
    }
  };

  fetchPrevTemplate = async () => {
    let templateURL = "";
    let transaction_id;
    if (this.props.PrevTransaction?.type == "Inquiry") {
      transaction_id = 1;
    } else if (this.props.PrevTransaction?.type === "Quotation") {
      transaction_id = 3;
    } else if (this.props.PrevTransaction?.type === "Negotiation") {
      transaction_id = 4;
    } else if (this.props.PrevTransaction?.type === "Requote") {
      transaction_id = 5;
    }
    if (process.env.NODE_ENV === "production") {
      templateURL = templateURi(
        this.props.userInfo.division_id,
        this.props.companyInfo.display_name,
        this.props.negotiationJsonData.template.id,
        this.props.negotiationJsonData.template.batch_id,
        transaction_id == 1
          ? transaction_id
          : transaction_id + (this.props.vendorType === "Local" ? "_L" : "_I")
      );
    } else {
      templateURL = devTemplateURi(
        this.props.userInfo.division_id,
        this.props.companyInfo.display_name,
        this.props.negotiationJsonData.template.id,
        this.props.negotiationJsonData.template.batch_id,
        transaction_id == 1
          ? transaction_id
          : transaction_id + (this.props.vendorType === "Local" ? "_L" : "_I")
      );
    }
    await axios
      .get(templateURL)
      .then((response) => {
        let FieldIDIdentifierMapping = this.convertToFieldIdIdentifierPairs(
          response.data
        );
        this.setState(
          {
            fieldIdentifierMapping: FieldIDIdentifierMapping,
          },
          () => {
            this.initializeState();
          }
        );
      })
      .catch((err) => {
        console.log(err, "main json err");
      });
  };
  convertToFieldIdIdentifierPairs = (jsonData) => {
    const fieldIdIdentifierPairs = {};

    // Loop through FOREIGN section
    if (jsonData.FOREIGN) {
      jsonData.FOREIGN.forEach((obj) => {
        fieldIdIdentifierPairs[obj.Identifier] = obj.FieldId;
      });
    }

    // Consider only the first array inside ITEMIZE section if present
    if (jsonData.ITEMIZE && jsonData.ITEMIZE.length > 0) {
      jsonData.ITEMIZE[0].forEach((obj) => {
        fieldIdIdentifierPairs[obj.Identifier] = obj.FieldId;
      });
    }

    // Loop through LOCAL section if present
    if (jsonData.LOCAL) {
      jsonData.LOCAL.forEach((obj) => {
        fieldIdIdentifierPairs[obj.Identifier] = obj.FieldId;
      });
    }

    // Loop through COMMON section if present
    if (jsonData.COMMON) {
      jsonData.COMMON.forEach((obj) => {
        fieldIdIdentifierPairs[obj.Identifier] = obj.FieldId;
      });
    }

    return fieldIdIdentifierPairs;
  };

  initializeLocalRecipientsState = () => {
    let row = {};
    const combinedTemplate = this.props.JsonTemplate?.COMMON.concat(
      this.props.JsonTemplate?.LOCAL
    );
    combinedTemplate.forEach((cell) => {
      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: "",
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        Identifier: cell.Identifier,
        ElementType: cell.ElementType,
        DefaultFormula: cell.DefaultFormula,
        DefaultValue: cell.DefaultValue,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          //getting options data from redux if it is available else calling api
          let optionsData = this.getOptionsFromRedux(cell, "additionalInfo");
          if (optionsData) {
            row[cell.FieldId]["options"] = optionsData;
          } else {
            // this.getOptionsFromApi(cell, "additionalInfo");
          }
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue;
        }
      } else {
        row[cell.FieldId]["value"] = cell.DefaultValue || "";
      }
    });
    this.setState({
      additionalInfoTemplate: row,
    });
    return row;
  };

  initializeIntlRecipientsState = () => {
    let row = {};

    const combinedTemplate = this.props.JsonTemplate?.COMMON.concat(
      this.props.JsonTemplate?.FOREIGN
    );

    combinedTemplate.forEach((cell) => {
      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: "",
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        Identifier: cell.Identifier,
        ElementType: cell.ElementType,
        DefaultFormula: cell.DefaultFormula,
        DefaultValue: cell.DefaultValue,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          //getting options data from redux if it is available else calling api
          let optionsData = this.getOptionsFromRedux(cell, "intl");
          if (optionsData) {
            row[cell.FieldId]["options"] = optionsData;
          } else {
            // this.getOptionsFromApi(cell, "intl");
          }
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue || "";
        }
      }
    });

    this.setState({
      additionalInfoTemplate: row,
    });
    return row;
  };

  getItemizeOptions = async (cell, index) => {
    let templateId = this.props.JsonTemplate?.PARAM[0]?.template_id;
    if (index !== 0) {
      return this.state.commonOptions[cell.Identifier];
    }
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
  };

  getOptionsFromApi = async (cell, template) => {
    try {
      let templateId = this.props.JsonTemplate?.PARAM[0]?.template_id;
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.sessionId}`;
      const url = getDropdownOptions(
        cell.Identifier,
        this.props.JsonTemplate?.PARAM[0]?.template_id
      );
      this.toggleLoaderArray(cell.Identifier);
      const res = await axios.get(url);
      if (template === "additionalInfo") {
        if (res.data.dropdown_mapping_status !== false) {
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [cell.FieldId]: {
                ...prevState.additionalInfoTemplate[cell.FieldId],
                options: res.data?.data,
              },
            },
          }));
          this.setOptionsInRedux(
            templateId,
            cell.Identifier,
            res.data.data,
            template
          );
        } else {
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [cell.FieldId]: {
                ...prevState.additionalInfoTemplate[cell.FieldId],
                options: cell.DefaultValue,
              },
            },
          }));
        }
      }
      this.toggleLoaderArray(cell.Identifier);
    } catch (error) {
      console.log(error, "options error");
      if (template === "additionalInfo") {
        this.setState((prevState) => ({
          ...prevState,
          additionalInfoTemplate: {
            ...prevState.additionalInfoTemplate,
            [cell.FieldId]: {
              ...prevState.additionalInfoTemplate[cell.FieldId],
              options: [],
            },
          },
        }));
      }
      this.toggleLoaderArray(cell.Identifier);
    }
  };

  updateAdditionalInfoFromCurrencyOptions = (cell, template) => {
    let templateId = this.props.JsonTemplate?.PARAM[0]?.template_id;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = "http://192.168.1.161:8888/v1/dropdowns-currency-list";
    this.toggleLoaderArray(cell.Identifier);

    axios
      .get(url)
      .then((res) => {
        if (cell.Identifier === "Currency" && template === "additionalInfo") {
          // Assuming the response is an array of currency objects
          const options = res.data.map((currency) => ({
            value: currency.CurrencyID,
            label: currency.Currency,
          }));

          // Update state with the options
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [cell.FieldId]: {
                ...prevState.additionalInfoTemplate[cell.FieldId],
                options: res.data,
              },
            },
          }));

          // Assuming setOptionsInRedux function is defined
          this.setOptionsInRedux(
            templateId,
            cell.Identifier,
            options,
            template
          );
        }
        this.toggleLoaderArray(cell.Identifier);
      })
      .catch((err) => {
        console.log(err, "options error");
        if (cell.Identifier === "Currency" && template === "additionalInfo") {
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [cell.FieldId]: {
                ...prevState.additionalInfoTemplate[cell.FieldId],
                options: [],
              },
            },
          }));
        }
        this.toggleLoaderArray(cell.Identifier);
      });
  };

  setOptionsInRedux = (templateId, name, data, template) => {
    let tempObject = {
      data,
      template,
    };
    let updateTemplateValues = this.props.templateValues;
    if (Object.keys(updateTemplateValues).length !== 0) {
      if (Object.keys(updateTemplateValues).some((val) => val == templateId)) {
        if (
          !Object.keys(updateTemplateValues[templateId]).some(
            (item) => item == name
          )
        ) {
          let newTemplateValues = {
            ...updateTemplateValues[templateId],
            [name]: tempObject,
          };
          this.props.setTemplateValues({ [templateId]: newTemplateValues });
        }
      } else {
        this.props.setTemplateValues({ [templateId]: { [name]: tempObject } });
      }
    } else {
      this.props.setTemplateValues({ [templateId]: { [name]: tempObject } });
    }
  };
  //getting options data from redux
  getOptionsFromRedux = (cell, template) => {
    let templateId = this.props.JsonTemplate?.PARAM[0]?.template_id;
    let updateTemplateValues = this.props.templateValues;
    if (Object.keys(updateTemplateValues).length !== 0) {
      if (updateTemplateValues[templateId]) {
        let cellData = updateTemplateValues[templateId][cell.Identifier];
        if (cellData && cellData.template == template) return cellData.data;
      }
    }
  };

  getDynamicDefaultValues = (cell, index, DefaultValue) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = getIdentifiersDynamicValues(
      cell.Identifier,
      this.props.selectedItems[index].id,
      this.props.selectedCategory.id
    );
    this.toggleLoaderArray(
      `${cell.Identifier}_getDynamicDefaultValues_${index}`
    );
    axios
      .get(url)
      .then((res) => {
        if (res.data) {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplate: {
              ...prevState.mainTemplate,
              // [this.props.selectedItems[index]?.id]: {
              //   ...prevState.mainTemplate[this.props.selectedItems[index]?.id],
              //   [cell.FieldId]: {
              //     ...prevState.mainTemplate[
              //       this.props.selectedItems[index]?.id
              //     ][cell.FieldId],
              //     DynamicDefaultValue:
              //       res.data.path[0][0][
              //         `${
              //           cell.Identifier === "BasicHistoricalPrice"
              //             ? "last_basic_rate"
              //             : cell.Identifier === "LandedHistoricalPrice"
              //             ? "landed_cost"
              //             : cell.Identifier === "DateHP" && "update_timestamp"
              //         }`
              //       ],
              //   },
              // },
            },
          }));
        } else {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplate: {
              ...prevState.mainTemplate,
              [this.props.selectedItems[index]?.id]: {
                ...prevState.mainTemplate[this.props.selectedItems[index]?.id],
                [cell.FieldId]: {
                  ...prevState.mainTemplate[
                    this.props.selectedItems[index]?.id
                  ][cell.FieldId],
                  DynamicDefaultValue: DefaultValue,
                },
              },
            },
          }));
        }

        this.toggleLoaderArray(
          `${cell.Identifier}_getDynamicDefaultValues_${index}`
        );
      })
      .catch((err) => {
        console.log(err, "getDynamicDefaultValues");
        if (this.props.selectedItems) {
          if (this.props.selectedItems[index]) {
            this.setState((prevState) => ({
              ...prevState,
              mainTemplate: {
                ...prevState.mainTemplate,
                [this.props.selectedItems[index]?.id]: {
                  ...prevState.mainTemplate[
                    this.props.selectedItems[index]?.id
                  ],
                  [cell.FieldId]: {
                    ...prevState.mainTemplate[
                      this.props.selectedItems[index]?.id
                    ][cell.FieldId],
                    options: [],
                  },
                },
              },
            }));
          }
        }

        this.toggleLoaderArray(
          `${cell.Identifier}_getDynamicDefaultValues_${index}`
        );
      });
  };

  checkIsLinkLocal = (cell) => {
    //first check if the vendor master check is present or not
    if (
      this.props.rfqSettingValues?.vendorMasterPaymentTerm &&
      this.props.rfqSettingValues?.vendorMasterIncoTerm
    ) {
      // check from both
      let str = this.props.companyInfo?.inco_term_identifier;
      let str1 = this.props.companyInfo?.payment_term_identifier;
      if (str) {
        let arr = str.split(";");
        if (arr.includes(cell.Identifier)) {
          return "inco";
        }
      }
      if (str1) {
        let arr1 = str1.split(";");
        if (arr1.includes(cell.Identifier)) {
          return "payment";
        }
      }
      return false;
    } else if (this.props.rfqSettingValues?.vendorMasterPaymentTerm) {
      // paymentterm array
      if (!this.props.companyInfo?.payment_term_identifier) return false;
      let str = this.props.companyInfo?.payment_term_identifier;
      let arr = str.split(";");
      // let arr = ["PaymentType", "PaymentTypeForeign"];
      if (arr.includes(cell.Identifier)) {
        return "payment";
      }
      return false;
    } else if (this.props.rfqSettingValues?.vendorMasterIncoTerm) {
      // incoterm array
      if (!this.props.companyInfo?.inco_term_identifier) return false;
      let str = this.props.companyInfo?.inco_term_identifier;
      let arr = str.split(";");
      // let arr = ["DeliveryBasis", "DeliveryRemarks"];
      if (arr.includes(cell.Identifier)) {
        return "inco";
      }
      return false;
    }
    return false;
  };

  download = () => {
    // setisLoading(true);
    this.setState({
      isLoading: true,
    });
    let destination = this.getDestinationValue();
    const dest = destination?.split("-");
    const code = Object.values(this.props.items).map((item) => item.code);

    // this.props.items.map((item) => {
    //   code.push(item.code);
    // });
    if (destination == "") {
      // dispatch({
      //   type: OPEN_SNACKBAR,
      //   payload: {
      //     type: "error",
      //     msg: "Please select destination first",
      //   },
      // });
      this.setState({
        isLoading: false,
      });
      // setisLoading(false);

      return false;
    }
    axios
      .post(
        downloadmaterialDescription,
        {
          buyerID: this.props.userInfo.division_id,
          pr_dest_code: dest[dest.length - 1],
          items: code,
        },
        {
          responseType: "blob", // Move responseType option inside the configuration object
        }
      )
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        const href = window.webkitURL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Material Description.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        // dispatch({
        //   type: OPEN_SNACKBAR,
        //   payload: {
        //     type: "error",
        //     msg: err?.response?.data.message
        //       ? err?.response?.data.message
        //       : err?.response?.statusText
        //       ? err?.response?.statusText
        //       : "Something went wrong!",
        //   },
        // });
      });
  };

  getFieldLocal = (cell) => {
    let error =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["error"];
    let ErrorMessage =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["ErrorMessage"];
    let name =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["HTMLAttributeName"];
    let value =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["value"];
    let isLink = this.checkIsLinkLocal(cell);
    if (isLink) {
      let index1 = this.state.errorArray.indexOf(name);
      if (index1 !== -1) {
        this.state.errorArray.splice(index1, 1);
        this.setState({ errorArray: this.state.errorArray });
      }
      return (
        <React.Fragment>
          <Button
            className="local_template_textfield"
            fullWidth
            variant="outlined"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              border: "1x solid",
              borderColor: (theme) => theme.palette.bluegrey[500],
              padding: "16.5px 14px",
              color: "text.primary",
              fontWeight: 400,
              fontSize:
                "calc(14px - (14 - 16) * ((100vw - 300px) / (1920 - 300)))",
              lineHeight: 1,
              textTransform: "capitalize",
            }}
            onClick={() => {
              this.handleLocalVendorTermsOpen(isLink, cell.FieldId);
            }}
            endIcon={
              <OpenInNew
                sx={{ color: (theme) => theme.palette.primary.main }}
              />
            }
          >
            {cell.FieldLabel}
          </Button>
        </React.Fragment>
      );
    } else if (
      (cell.ElementType === "S") & this.props.multiplePlantsFlag &&
      (cell.Identifier === "Destination" ||
        (cell.Identifier === "DestinationForeign" &&
          value &&
          value.includes("###")))
    ) {
      return null;
    } else if (cell?.ElementType === "F") {
      return (
        <FormControl error={error}>
          <Typography
            component="label"
            variant="body2"
            color={"grey.500"}
            mb={1}
          >
            {`View ${
              cell.FieldLabel +
              (cell.InstructionText ? ` (${cell.InstructionText})` : "")
            }`}
          </Typography>
          <Box>
            <Badge
              badgeContent={`${value?.length ? value?.length : "0"}`}
              color="error"
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  value?.length > 0 && this.handleUploadOpen(value);
                  // this.setState({
                  //   currentFieldId: cell.FieldId,
                  //   dynamicFileUploadOpen: true,
                  //   currentTemplate: "additionalInfoTemplate",
                  //   attachments: value,
                  // });
                }}
                startIcon={
                  <VisibilityIcon sx={{ fontSize: "22px !important" }} />
                }
              >
                {`View ${cell.FieldLabel}`}
              </Button>
            </Badge>
          </Box>
          {/* <Typography mt={1} variant="body2" color={"text.primary"}>Number Of Files {value?.length}</Typography> */}
          <Typography>
            {this.state.additionalInfoTemplate[cell.HTMLAttributeName]}
          </Typography>
          {/* <FormHelperText sx={{ ml: "auto" }}>
                Number Of Files {value?.length}
              </FormHelperText>
            <Typography variant="caption">
              {this.state.additionalInfoTemplate[cell.HTMLAttributeName]}
            </Typography> */}
          {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
        </FormControl>
      );
    } else if (cell.ElementType === "T" && Array.isArray(value)) {
      return (
        <FormControl error={error}>
          <Typography
            component="label"
            variant="body2"
            color={"grey.500"}
            mb={1}
          >
            View Attachment
          </Typography>
          <Box>
            <Badge
              badgeContent={`${value?.length ? value?.length : "0"}`}
              color="error"
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  value?.length > 0 && this.handleUploadOpen(value);
                  // this.setState({
                  //   currentFieldId: cell.FieldId,
                  //   dynamicFileUploadOpen: true,
                  //   currentTemplate: "additionalInfoTemplate",
                  //   attachments: value,
                  // });
                }}
                startIcon={
                  <VisibilityIcon sx={{ fontSize: "22px !important" }} />
                }
              >
                {`View ${cell.FieldLabel}`}
              </Button>
            </Badge>
          </Box>
          {/* <Typography mt={1} variant="body2" color={"text.primary"}>Number Of Files {value?.length}</Typography> */}
          <Typography>
            {this.state.additionalInfoTemplate[cell.HTMLAttributeName]}
          </Typography>
          {/* <FormHelperText sx={{ ml: "auto" }}>
                Number Of Files {value?.length}
              </FormHelperText>
            <Typography variant="caption">
              {this.state.additionalInfoTemplate[cell.HTMLAttributeName]}
            </Typography> */}
          {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
        </FormControl>
      );
    } else if (cell?.ElementType === "DT") {
      return (
        <>
          <Typography component="label" variant="body2" color={"grey.500"}>
            {cell.FieldLabel +
              (cell.ElementType == "T" && cell.InstructionText
                ? ` (${cell.InstructionText})`
                : "")}
          </Typography>
          <Tooltip
            title={
              this.state.DifferenceAdditionalFieldId.hasOwnProperty(
                cell.FieldId
              ) && value
                ? this.state.DifferenceAdditionalFieldId[cell.FieldId]
                  ? `Previous Value : ${dayjs(
                      this.state.DifferenceAdditionalFieldId[cell.FieldId],
                      "YYYY-MM-DD"
                    ).format(`DD/MM/YYYY [IST]`)}`
                  : "Previous Value : N/A"
                : dayjs(value, "YYYY-MM-DD").format(`DD/MM/YYYY [IST]`)
                ? dayjs(value, "YYYY-MM-DD").format(`DD/MM/YYYY [IST]`)
                : "N/A"
            }
            placement="bottom-start"
          >
            <Typography
              variant="body2"
              color={
                this.state.DifferenceAdditionalFieldId.hasOwnProperty(
                  cell.FieldId
                ) && value
                  ? "error.main"
                  : "text.primary"
              }
            >
              {value
                ? dayjs(value, "YYYY-MM-DD").format(`DD/MM/YYYY [IST]`)
                : "N/A"}
            </Typography>
          </Tooltip>
        </>
      );
    } else if (
      (this.props.PermissionInquiry?.is_payment_term === "Y" &&
        this.props.companyInfo.payment_term_identifier
          .split(";")
          .includes(cell.Identifier)) ||
      (this.props.PermissionInquiry?.is_inco_term === "Y" &&
        this.props.companyInfo.inco_term_identifier
          .split(";")
          .includes(cell.Identifier))
    ) {
      if (value && this.isValidJSON(value)) {
        const parsedValue = JSON?.parse(value);
        if (parsedValue[this.props.vendorId]) {
          let vendorWiseValue = parsedValue[this.props.vendorId];
          value =
            this.props.companyInfo?.default_dropdown_type === "10"
              ? `${vendorWiseValue.element_name}-${vendorWiseValue.element_code}`
              : `${vendorWiseValue.element_code}-${vendorWiseValue.element_name}`;
        }
      }
      return (
        <>
          <Typography component="label" variant="body2" color={"grey.500"}>
            {/* {cell.FieldLabel +
                (cell.ElementType == "T" && cell.InstructionText
                  ? ` (${cell.InstructionText})`
                  : "")} */}
            {cell.FieldLabel +
              (cell.InstructionText ? ` (${cell.InstructionText})` : "")}
          </Typography>
          <Tooltip title={value ? value : "N/A"} placement="bottom-start">
            <Typography variant="body2" color={"text.primary"}>
              {value ? value : "N/A"}
            </Typography>
          </Tooltip>
        </>
      );
    } else if (cell?.ElementType === "S") {
      if (this.props.multiplePlantsFlag && cell.Identifier === "GSTType") {
        // GSTType will not shown in common in case of multiple plants
        return null;
      }
      let prevValue = this.state.DifferenceAdditionalFieldId[cell.FieldId];
      if (cell?.DefaultValue?.length > 0) {
        let dropdownName = cell?.DefaultValue.filter((option) => {
          return option.code === value;
        });
        if (dropdownName.length > 0) {
          value = dropdownName[0]?.name;
        }
        let dropdownPrevName = cell?.DefaultValue.filter((option) => {
          return option.code === prevValue;
        });
        if (dropdownPrevName.length > 0) {
          prevValue = dropdownPrevName[0]?.name;
        }
      }
      let sameCodeNameValue = "";
      if (value) {
        // console.log('value', (value?.split("-").length > 2 && (value?.split("-").length % 2) == 0) ?  : 'false', value?.split("-"))
        if (value?.split("-").length > 2 && value?.split("-").length % 2 == 0) {
          let chunkVal = _.chunk(
            value?.split("-"),
            value?.split("-").length / 2
          );
          sameCodeNameValue =
            JSON.stringify(chunkVal[0]) === JSON.stringify(chunkVal[1])
              ? chunkVal[0].join("-")
              : value;
        } else if (
          value &&
          value.split(/-(.+)/)[0] == value.split(/-(.+)/)[1]
        ) {
          sameCodeNameValue = value.split(/-(.+)/)[0];
        } else {
          sameCodeNameValue = value;
        }
      } else {
        sameCodeNameValue = "N/A";
      }
      return (
        <>
          <Typography component="label" variant="body2" color={"grey.500"}>
            {cell.FieldLabel +
              (cell.InstructionText ? ` (${cell.InstructionText})` : "")}
          </Typography>

          <Tooltip
            title={
              this.state.DifferenceAdditionalFieldId.hasOwnProperty(
                cell.FieldId
              ) && value
                ? this.state.DifferenceAdditionalFieldId[cell.FieldId]
                  ? `Previous Value : ${
                      prevValue ||
                      this.state.DifferenceAdditionalFieldId[cell.FieldId]
                    }`
                  : "Previous Value : N/A"
                : sameCodeNameValue
            }
            placement="bottom-start"
          >
            <Typography
              variant="body2"
              color={
                this.state.DifferenceAdditionalFieldId.hasOwnProperty(
                  cell.FieldId
                ) && value
                  ? "error.main"
                  : "text.primary"
              }
            >
              {sameCodeNameValue}
            </Typography>
          </Tooltip>
        </>
      );
    } else {
      if (
        (cell.ElementType === "S") & this.props.multiplePlantsFlag &&
        (cell.Identifier === "Destination" ||
          cell.Identifier === "DestinationForeign") &&
        value &&
        value?.includes("###")
      ) {
        return null;
      } else {
        return (
          <>
            <Typography component="label" variant="body2" color={"grey.500"}>
              {/* {cell.FieldLabel +
                (cell.ElementType == "T" && cell.InstructionText
                  ? ` (${cell.InstructionText})`
                  : "")} */}
              {cell.FieldLabel +
                (cell.InstructionText ? ` (${cell.InstructionText})` : "")}
            </Typography>
            <Tooltip
              title={
                this.state.DifferenceAdditionalFieldId.hasOwnProperty(
                  cell.FieldId
                ) && value
                  ? this.state.DifferenceAdditionalFieldId[cell.FieldId]
                    ? `Previous Value : ${
                        this.state.DifferenceAdditionalFieldId[cell.FieldId]
                      }`
                    : "Previous Value : N/A"
                  : value
                  ? value
                  : "N/A"
              }
              placement="bottom-start"
            >
              <Typography
                variant="body2"
                color={
                  this.state.DifferenceAdditionalFieldId.hasOwnProperty(
                    cell.FieldId
                  ) && value
                    ? "error.main"
                    : "text.primary"
                }
              >
                {value ? value : "N/A"}
              </Typography>
            </Tooltip>
          </>
        );
      }
      // return cell?.FieldLabel;
    }
  };

  finddependacy = (FieldId, validation) => {
    let field_ids = validation.match(/f(\d)*/g);
    let is_dependacy = 0;
    for (const field_id of field_ids) {
      if ("f" + FieldId != field_id) {
        is_dependacy = 1;
        break;
      }
    }
    return is_dependacy;
  };

  validateInput = (FieldId, index, submitting) => {
    if (submitting) {
      if (
        this.state.mainTemplate[this.props.selectedItems[index].id][FieldId] &&
        this.state.mainTemplate[this.props.selectedItems[index].id][FieldId][
          "error"
        ] &&
        this.state.mainTemplate[this.props.selectedItems[index].id][FieldId][
          "Validation"
        ] &&
        !this.finddependacy(
          FieldId,
          this.state.mainTemplate[this.props.selectedItems[index].id][FieldId][
            "Validation"
          ]
        )
      ) {
        if (
          !this.state.errorArray.includes(
            this.state.mainTemplate[this.props.selectedItems[index].id][
              FieldId
            ]["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(
            this.state.mainTemplate[this.props.selectedItems[index].id][
              FieldId
            ]["HTMLAttributeName"]
          );
          this.setState({
            errorArray: this.state.errorArray,
          });
        }
        return;
      }
    }
    if (
      this.state.mainTemplate[this.props.selectedItems[index].id][FieldId] &&
      this.state.mainTemplate[this.props.selectedItems[index].id][FieldId][
        "Validation"
      ]
    ) {
      let temp = this.state.mainTemplate[this.props.selectedItems[index].id][
        FieldId
      ]["Validation"].replace(/f(\d)+/g, (match) => {
        if (
          this.state.mainTemplate[this.props.selectedItems[index].id][
            match.replace("f", "")
          ]
        ) {
          return `this.state.mainTemplate[${
            this.props.selectedItems[index].id
          }][${match.replace("f", "")}]["value"]`;
        } else if (this.state.additionalInfoTemplate[match.replace("f", "")]) {
          return `this.state.additionalInfoTemplate[${match.replace(
            "f",
            ""
          )}]["value"]`;
        } else if (this.state.IntlTemplate[match.replace("f", "")]) {
          return `this.state.IntlTemplate[${match.replace("f", "")}]["value"]`;
        } else {
          return null;
        }
      });
      if (temp !== null) {
        if (eval(temp)) {
          // this.state.mainTemplate[this.props.selectedItems[index].id][FieldId][
          //   "error"
          // ] = false;

          this.setState((prevState) => ({
            ...prevState,
            mainTemplate: {
              ...prevState.mainTemplate,
              // [this.props.selectedItems[index]?.id]: {
              //   ...prevState.mainTemplate[this.props.selectedItems[index]?.id],
              //   [FieldId]: {
              //     ...prevState.mainTemplate[
              //       this.props.selectedItems[index]?.id
              //     ][FieldId],
              //     error: false,
              //   },
              // },
            },
          }));

          let index1 = this.state.errorArray.indexOf(
            this.state.mainTemplate[this.props.selectedItems[index].id][
              FieldId
            ]["HTMLAttributeName"]
          );
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }
        } else {
          // this.state.mainTemplate[this.props.selectedItems[index].id][FieldId][
          //   "error"
          // ] = true;

          this.setState((prevState) => ({
            ...prevState,
            mainTemplate: {
              ...prevState.mainTemplate,
              [this.props.selectedItems[index]?.id]: {
                ...prevState.mainTemplate[this.props.selectedItems[index]?.id],
                [FieldId]: {
                  ...prevState.mainTemplate[
                    this.props.selectedItems[index]?.id
                  ][FieldId],
                  error: true,
                },
              },
            },
          }));

          if (
            !this.state.errorArray.includes(
              this.state.mainTemplate[this.props.selectedItems[index].id][
                FieldId
              ]["HTMLAttributeName"]
            )
          ) {
            this.state.errorArray.push(
              this.state.mainTemplate[this.props.selectedItems[index].id][
                FieldId
              ]["HTMLAttributeName"]
            );
          }
        }
        this.setState({
          // mainTemplate: this.state.mainTemplate,
          errorArray: this.state.errorArray,
        });
      } else {
        console.log("dependent field not found in template");
      }
    }
  };

  handleLocalChange = (e, FieldId) => {
    const { value, type, checked } = e.target;

    const { additionalInfoTemplate } = this.state;
    // additionalInfoTemplate[FieldId]["value"] = type !== "checkbox" ? value : checked;
    // e.target.blur();
    this.setState({
      additionalInfoTemplate,
    });
  };

  setTermValue = (value, vendorType, FieldId) => {
    const { additionalInfoTemplate, IntlTemplate } = this.state;
    if (vendorType === true) {
      additionalInfoTemplate[FieldId]["value"] = "Hello";
      this.setState({
        additionalInfoTemplate,
      });
    } else {
      IntlTemplate[FieldId]["value"] = value;
      this.setState({
        IntlTemplate,
      });
    }
  };

  setTermError = () =>
    this.state.vendorTerms === "inco"
      ? this.setState({ incoTermError: true })
      : this.setState({ paymentTermError: true });

  setAttachmentsValue = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      [this.state.currentTemplate]: {
        ...prevState[this.state.currentTemplate],
        [this.state.currentFieldId]: {
          ...prevState[this.state.currentTemplate][this.state.currentFieldId],
          value: this.state[this.state.currentTemplate][
            this.state.currentFieldId
          ]["value"]
            ? [
                ...this.state[this.state.currentTemplate][
                  this.state.currentFieldId
                ]["value"],
                ...value,
              ]
            : value,
        },
      },
    }));
  };

  handleIntlChange = (e, FieldId) => {
    const { value, type, checked } = e.target;
    const { IntlTemplate } = this.state;
    IntlTemplate[FieldId]["value"] = type !== "checkbox" ? value : checked;
    // e.target.blur();
    this.setState({
      IntlTemplate,
    });
  };
  //added val for autocomplete multiplant

  handleLocalVendorTermsOpen = (term, currentFieldID) => {
    this.setState({
      vendorType: "additionalInfo",
      vendorTerms: term,
      vendorTermsOpen: true,
      currentFieldID,
    });
  };

  handleUplaod = (index, res) => {
    const { mainTemplate } = this.state;
    mainTemplate[this.props.selectedItems[index].id]["specification"] = res;
    this.setState({
      mainTemplate,
    });
  };

  getDestinationValue = () => {
    for (const key in this.state.additionalInfoTemplate) {
      const element = this.state.additionalInfoTemplate[key];
      if (
        element.Identifier === "Destination" ||
        element.Identifier === "DestinationForeign"
      ) {
        return element.value;
      }
    }
  };

  handleOpenActions = (currentTransactionGUID, quoteType) => {
    if (
      this.props.featurePermission?.is_mass_negotiation?.feature_value ===
        "Y" &&
      quoteType === "NEGOTIATION"
    ) {
      this.props.openSnackbar(
        "error",
        "Please submit the negotiation by using “mass negotiation” functionality"
      );
      return;
    }
    let url = vendor_QPlus;

    url +=
      "?call_from=qcs_plus&vendx_action=compose&ReverseAuction=n&perform=" +
      (quoteType === "NEGOTIATION" ? "negotiation" : "offline_quote") +
      "&inquiry_guid=" +
      currentTransactionGUID +
      "&qcs_plus=1&vendor_id=" +
      this.props.vendorId;
    let obj = {
      message: "update url",
      url: url,
    };
    window.parent.postMessage(obj, "*");
  };

  handleCloseDestinationDialog() {
    this.setState({ isDestinationDetailsDialogOpen: false });
  }

  handleOpenDestinationDialog() {
    this.setState({ isDestinationDetailsDialogOpen: true });
  }

  setIsDestinationDetailsDialogOpen(isOpen) {
    this.setState({ isDestinationDetailsDialogOpen: isOpen });
  }

  handleOpenRegretModal = () => {
    this.setState({ openRegretModal: true });
    this.props.getRegret();
  };

  handleCloseRegretModal = () => {
    this.setState({
      openRegretModal: false,
      justificationRegretModal: this.props.regretJustificationMessage || "", // Reset to the initial value from props
    });
  };
  handleChangeRegretModal = (event) => {
    this.setState({ justificationRegretModal: event.target.value });
    // this.props.handleRegretMessage(event.target.value);
  };

  handleSubmitRegretModal = () => {
    this.setState({
      regretLoader: true,
    });
    axios
      .post(
        submitRegret,
        {
          justification: this.state.justificationRegretModal,
          tran_status: this.props.isSupplier ? 10 : 20,
          tl_guid: this.props.tlGuid,
          inquiry_id: this.props.inquiryId,
          vendor_id: this.props.vendorId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.SessionID}`,
          },
        }
      )
      .then((response) => {
        this.setState({
          regretLoader: false,
          openRegretModal: false,
        });
        this.props.openSnackbar("success", "Transaction Regret Successfully.");
        this.props.ViewJustificationFlag(true);
      })
      .catch((error) => {
        if (error.response.status == 422) {
          this.props.openSnackbar(
            "error",
            error?.response?.data[0]?.error
              ? error?.response?.data[0]?.error
              : "Something went wrong!"
          );
          this.setState({
            openRegretModal: false,
          });
        }
        console.log("There was a problem with your axios operation:", error);
        this.setState({
          regretLoader: false,
        });
      });
  };

  handleUpdateRegret = () => {
    if (
      this.props.regretJustificationMessage ===
      this.state.justificationRegretModal
    ) {
      this.props.openSnackbar(
        "error",
        "You Have Already Updated Justification For This Transaction or Your Justification Text Matches The Previous Justification Text"
      );
      return false;
    }
    this.setState({
      regretLoader: true,
    });
    axios
      .patch(
        updateRegretJustificiation(
          this.props.inquiryId,
          this.props.vendorId,
          this.props.tlGuid
        ),
        {
          justification: this.state.justificationRegretModal,
          tran_status: this.props.isSupplier ? 10 : 20,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.SessionID}`,
          },
        }
      )
      .then((response) => {
        this.setState({
          regretLoader: false,
          openRegretModal: false,
        });
        this.props.openSnackbar(
          "success",
          "Justification updated scuccessfully."
        );
      })
      .catch((error) => {
        this.setState({
          regretLoader: false,
        });
        console.error("There was a problem with your axios operation:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Backdrop
          style={{ color: "#fff", zIndex: "10000000000" }}
          open={
            this.state.loaderArray.length > 0 ||
            this.state.prevRFQLoading ||
            this.props.jsonTemplateloading ||
            this.state.prevFormLoading ||
            Object.keys(this.state.mainTemplate).length == 0
          }
        >
          <CircularProgress style={{ color: "#fff" }} />
        </Backdrop>
        <React.Fragment>
          <form
            id="novalidatedform"
            noValidate
            autoComplete="off"
            onSubmit={(event) => {
              if (
                this.props.location?.pathname.indexOf("/prcombination") > -1
              ) {
                event.preventDefault();
              }
            }}
          >
            {/* -----------------------nego Details----------------------- */}

            <Box mb={2} id="NegotiationDetails">
              <NegotiationDetailsSectionView
                title="Negotiation Details"
                data={this.props.negotiationJsonData}
                Alreadyexistingfiles={this.props.categorySpecFiles}
                getField={this.getFieldLocal}
                foreignData={this?.props?.JsonTemplate?.COMMON?.concat(
                  this?.props?.JsonTemplate?.FOREIGN
                )}
                localData={this?.props?.JsonTemplate?.COMMON.concat(
                  this?.props?.JsonTemplate?.LOCAL
                )}
                currencyOptions={this.props.currencyOptions}
                currencyDetails={this.props.currencyDetails}
                fileSpecificationData={this.props.fileSpecificationData}
                handleCurrencyChange={this.handleCurrencyChange}
                CurrentTransaction={this.props.CurrentTransaction}
                PrevTransaction={this.props.PrevTransaction}
                isServiceRfq={this.props.isServiceRfq}
                AlternativeNames={this.props.AlternativeNames}
                vendorId={this.props.vendorId}
                inquiryId={this.props.inquiryId}
                vendorType={this.props.vendorType}
                isSupplier={this.props.isSupplier}
                lastTxnGuid={this.props.lastTxnGuid}
              />
            </Box>

            {/* -----------------------Template Section----------------------- */}

            <Box mb={2} id="Template">
              <NegotiationTemplateSectionTableView
                inquiryId={this.props.inquiryId}
                randomId={this.state.randomId}
                categoryId={this.props.categoryId}
                batch_id={
                  this.props.JsonTemplate?.PARAM &&
                  this.props.JsonTemplate?.PARAM[0]?.batch_id
                }
                data={this.props.JsonTemplate?.ITEMIZE || []}
                mainTemplate={this.state.mainTemplate}
                diffrentiatedFields={this.state.DifferenceMainTemplateFieldId}
                diffrentiatedSlabFields={
                  this.state.DifferenceSlabMainTemplateFieldId
                }
                handleOpenDestinationDialog={this.handleOpenDestinationDialog}
                setIsDestinationDetailsDialogOpen={
                  this.setIsDestinationDetailsDialogOpen
                }
                negotiationJsonData={this.props.negotiationJsonData.items}
                base_currency={this.props.currencyDetails}
                handleUplaod={this.handleUplaod}
                applyToAll={this.state.applyToAll}
                handleChange={this.handleChange}
                handleCheckboxChange={this.handleCheckboxChange}
                default_dropdown_type={
                  this.props.companyInfo?.default_dropdown_type
                }
                destination={this.getDestinationValue()}
                itemSpecificFiles={this.props.itemSpecificFiles}
                itemSpecific={this.props.itemSpecific}
                LATEST={this.LATEST}
                handleSlab={this.handleSlab}
                // slab={this.state.slab}
                slab={slab}
                mainMSTemplate={this.state.mainMSTemplate}
                slabMainTemplate={this.state.slabMainTemplate}
                // rfqSettingValues={this.props.rfqSettingValues}
                // rfqSettingValues={rfqSettingValues}
                // slabRows={this.state.slabRows}
                slabAddRow={this.slabAddRow}
                slabRemoveRow={this.slabRemoveRow}
                handleRowInput={this.handleRowInput}
                handleApplyToAllSlab={this.handleApplyToAllSlab}
                handleKey={this.handleKey}
                setItemsTemplate={this.setItemsTemplate}
                slabKey={this.state.slabKey}
                additionalInfoTemplate={this.state.additionalInfoTemplate}
                applyToAllSlab={this.state.applyToAllSlab}
                firstActiveSlab={this.props.selectedItems?.find((element) => {
                  if (
                    this.state?.slab[element?.id] &&
                    this.state?.slab[element?.id].isOpen
                  ) {
                    return element;
                  }
                })}
                displayOptions={this.props.displayOptions}
                selectedCurrency={this.state.selectedCurrency}
                lastTransactionInThread={this.props.lastTransactionInThread}
                lastTransactionTypeInThread={
                  this.props.lastTransactionTypeInThread
                }
                filteredSelectedNotData={this.props.filteredSelectedNotData}
                CurrentTransaction={this.props.CurrentTransaction}
                items={this.props.items}
                vendorType={this.props.vendorType}
                currencyDetails={this.props.currencyDetails}
                multiplePlantsFlag={this.props.multiplePlantsFlag}
                transactionItemSequence={this.props.transactionItemSequence}
                template={this.props.JsonTemplate}
                quotedCurrency={this.props.quotedCurrency}
                multipleSolutionFlag={this.props.multipleSolutionFlag}
                slabFlag={this.props.slabFlag}
                inquiryItemsId={this.props.inquiryItemsId}
                isMultipleCurrency={this.props.isMultipleCurrency}
                isSupplier={this.props.isSupplier}
                userInfo={this.props.userInfo.type}
              />
            </Box>
            {/*----------------------- Additional Info -----------------------*/}
            <>
              <Box mb={2} id="AdditionalInfo">
                <NegotiationCardTemplate
                  title="Additional Info"
                  from="View"
                  state={this?.state?.lastTransactionData?.common}
                  data={this?.props?.JsonTemplate?.COMMON.concat(
                    this?.props?.JsonTemplate?.LOCAL
                  )}
                  foreignData={this?.props?.JsonTemplate?.COMMON.concat(
                    this?.props?.JsonTemplate?.FOREIGN
                  )}
                  localData={this?.props?.JsonTemplate?.COMMON?.concat(
                    this?.props?.JsonTemplate?.LOCAL
                  )}
                  getField={this.getFieldLocal}
                  handleChange={this.handleLocalChange}
                  vendorType={this.props.vendorType}
                  categoryId={this.props.categoryId}
                  fileSpecificationData={this.props.fileSpecificationData}
                  contract={this.props.negotiationJsonData.filter_data.contract}
                  cc_user_email={
                    this.props.negotiationJsonData.cc_user_email_id
                  }
                  MaterialDescription={this.state.MaterialDescription}
                  isLoading={this.state.isLoading}
                  materialDescriptionAll={this.download}
                  CurrentTransaction={this.props.CurrentTransaction}
                  multiplePlantsFlag={this.props.multiplePlantsFlag}
                  category={this.props.negotiationJsonData.categories[0]}
                />
              </Box>

              {/* -----------------------Submit Quotattion Form Button----------------------- */}
              <Box className="Footer-QuotedForm" sx={{ mb: 2 }}>
                <FormGroup
                  sx={{
                    display: "inline-flex",
                    "& .MuiFormControlLabel-root": {
                      m: 0,
                    },
                    "& .MuiTypography-root": {
                      color: "var(--primary)",
                      textDecoration: "underline",
                      fontWeight: "500",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.quotationChecked}
                        onChange={(event) =>
                          this.handleQuotationCheckboxChange(event)
                        }
                        color="primary"
                        sx={{ display: "none" }}
                      />
                    }
                    label={
                      <Typography
                        variant="subtitle1"
                        component="a"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            tandcDialogOpen: true,
                          });
                        }}
                      >
                        View Terms and Conditions
                      </Typography>
                    }
                  />
                </FormGroup>
                {/* <div style={{ display: "flex", alignItems: "center" }}> */}
                {/* <Checkbox
                      // checked={checked}
                      // onChange={handleCheckboxChange}
                      color="primary"
                    /> */}
                {/* <Typography
                      onClick={() => {
                        this.setState({
                          tandcDialogOpen: true,
                        });
                      }}
                      variant="body1"
                      sx={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      color={"primary.500"}
                      fontWeight={500}
                      mt={2}
                    >
                      View Terms and Conditions
                    </Typography>
                  </div> */}

                {this.props.IsLastTxn &&
                  (this.props.userInfo.type === "buyer" ? (
                    <Stack direction="row" spacing={2} mt={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="Upload_file_btn"
                        onClick={() =>
                          this.handleOpenActions(
                            this.props.inquiryTransaction?.TranTypeGUID,
                            "OFF. REQUOTE"
                          )
                        }
                      >
                        OFF. REQUOTE
                      </Button>
                      {this.props.regretJustificationMessage === "" && (
                        <Button
                          variant="contained"
                          onClick={() => this.handleOpenRegretModal()}
                          color="error"
                        >
                          REGRET
                        </Button>
                      )}
                    </Stack>
                  ) : (
                    this.props.userInfo.type === "vendor" && (
                      <Stack direction="row" spacing={2} mt={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            this.handleOpenActions(
                              this.props.inquiryTransaction?.TranTypeGUID,
                              "REQUOTE"
                            )
                          }
                        >
                          REQUOTE
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => this.handleOpenRegretModal()}
                          color="error"
                        >
                          REGRET
                        </Button>
                      </Stack>
                    )
                  ))}
              </Box>
              {/* <Box className="Footer-QuotedForm" sx={{ mb: 7 }}>
                {this.props.negotiationJsonData._internal.current_user_id && (
                  <FormGroup
                    sx={{
                      "& .MuiTypography-root": {
                        color: "var(--primary)",
                        textDecoration: "underline",
                        fontWeight: "500",
                      },
                    }}
                  >
                    <FormControlLabel
                      // control={<Checkbox />}
                      label="Accept Terms and Contions"
                    />
                  </FormGroup>
                )}
              </Box> */}
            </>
          </form>
        </React.Fragment>
        {/* Modals */}

        {this.state.specificationsOpen && (
          <UploadFileDialog
            product_id={this.props.selectedCategory?.id}
            randomId={this.state.randomId}
            master_type="category"
            handleUplaod={this.handleUplaod}
            open={this.state.specificationsOpen}
            Alreadyexistingfiles={this.props.categorySpecFiles}
            updateCategorySpecificationFiles={(newFiles) => {
              this.setState({
                categorySpecificationFiles: newFiles,
              });
            }}
            handleClose={() => {
              this.setState({
                specificationsOpen: false,
              });
            }}
          />
        )}

        {this.state.dynamicFileUploadOpen && (
          <UploadDynamicView
            sessionID={this.props.sessionId}
            Loading={this.state.Loading}
            uploadedFiles={this.state.attachments.data}
            open={this.state.dynamicFileUploadOpen}
            handleClose={() => {
              this.setState({
                dynamicFileUploadOpen: false,
                attachments: [],
                Loading: true,
              });
            }}
          />
        )}

        {this.state.tandcDialogOpen && (
          <TermsAndConditions
            category_id={this.props.categoryId}
            open={this.state.tandcDialogOpen}
            termsAndConditionsData={this.props.termsAndConditionsDetails}
            handleClose={() => {
              this.setState({
                tandcDialogOpen: false,
              });
            }}
          />
        )}

        <ConfirmAlert
          open={this.state.confirmDialogOpen}
          handleClose={() => {
            this.setState({
              confirmDialogOpen: false,
            });
          }}
          handleSubmit={() => {
            this.setState({
              confirmDialogOpen: false,
            });
            this.rfqsubmitAPI();
          }}
          msg={this.state.confirmAlertMsg}
          title={this.state.confirmAlertTitle}
        />
        <Dialog
          open={this.state.openConfirmBox}
          onClose={this.handleConfirmBoxClose}
        >
          {/* <DialogTitle>Import {tranType} Form via Excel</DialogTitle> */}
          <DialogTitle>Import Form via Excel</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={this.handleConfirmBoxClose}
            sx={{ position: "absolute", right: 8, top: 13 }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Typography variant="h6">Download File Format:</Typography>
            {/* {masterType === 'category' ? ( */}
            {true ? (
              <>
                <Button onClick={() => this.downloadTransactionExcel(1)}>
                  Vertical
                </Button>
                <Button onClick={() => this.downloadTransactionExcel(2)}>
                  Horizontal
                </Button>
              </>
            ) : (
              <Button onClick={() => this.downloadTransactionExcel(1)}>
                Excel
              </Button>
            )}
            <br />
            <br />
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              {/* Upload file */}
              <input type="file" />
            </Button>
          </DialogContent>
        </Dialog>

        <DestinationDetailsDialogView
          handleClose={this.handleCloseDestinationDialog}
          open={this.state.isDestinationDetailsDialogOpen}
          lastTransactionInThreadCommon={
            this.props.lastTransactionInThreadCommon
          }
          lastTransactionInThreadItemize={
            this.props.lastTransactionData?.itemize
          }
          diffrentiatedFields={this.state.DifferenceMainTemplateFieldId}
          diffrentiatedSlabFields={this.state.DifferenceSlabMainTemplateFieldId}
          setIsDestinationDetailsDialogOpen={
            this.setIsDestinationDetailsDialogOpen
          }
          itemizeData={this?.props?.JsonTemplate?.ITEMIZE[0]}
          foreignData={this?.props?.JsonTemplate?.COMMON?.concat(
            this?.props?.JsonTemplate?.FOREIGN
          )}
          localData={this?.props?.JsonTemplate?.COMMON?.concat(
            this?.props?.JsonTemplate?.LOCAL
          )}
          vendorType={this.props.vendorType}
          handleSave={this.handleSave}
        />
        <Dialog
          open={this.state.openRegretModal}
          onClose={this.handleCloseRegretModal}
          fullWidth
          maxWidth="sm"
          aria-labelledby="edit-regret-dialog-title"
        >
          <DialogTitle id="edit-regret-dialog-title">
            {this.props.regretJustificationMessage === "" ||
            !this.props.isSupplier
              ? "Submit Regret"
              : "Edit Regret"}
            <IconButton
              aria-label="close"
              onClick={this.handleCloseRegretModal}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ marginBottom: "5px" }}>
              {this.props.regretJustificationMessage === "" ||
              !this.props.isSupplier
                ? "Add Justification"
                : "Edit Justification"}
            </DialogContentText>
            <br></br>
            <TextField
              id="justificationRegretModal"
              label="Justification"
              multiline
              rows={4}
              value={this.state.justificationRegretModal}
              onChange={this.handleChangeRegretModal}
              fullWidth
              disabled={this.props.OfflineRegretFlag}
              helperText={
                this.props.OfflineRegretFlag &&
                "Note : Negotiation regretted offline by buyer,So can't change Justification."
              }
              error={this.props.OfflineRegretFlag}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseRegretModal} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={
                this.props.regretJustificationMessage === "" ||
                !this.props.isSupplier
                  ? this.handleSubmitRegretModal
                  : this.handleUpdateRegret
              }
              color="primary"
              variant="contained"
              disabled={
                this.state.justificationRegretModal.trim() === "" ||
                this.props.OfflineRegretFlag
              }
              startIcon={
                this.state.regretLoader && (
                  <CircularProgress color="inherit" size="1rem" />
                )
              }
            >
              {this.props.regretJustificationMessage === "" ||
              !this.props.isSupplier
                ? "Submit"
                : "Change"}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  sessionId: state.sessionReducer.sessionId,
  companyInfo: state.companyInfoReducer.companyInfo,
  rfqSettingValues: state.drawerValuesReducer.rfqSettingValues,
  accountModulePermission: state.permissionsReducer.accountModulePermission,
  featurePermission: state.permissionsReducer.featurePermission,
  categorySpecFiles: state.categoryReducer.fileSpecifications,
  itemSpecificFiles: state.itemReducer.itemSpecifications,
  prlineitems: state.prrfqreducer.setPrLineItemsData,
  prplants: state.prrfqreducer.plantcodes,
  userInfo: state.userInfoReducer.userInfo,
  templateValues: state.prrfqreducer.templateValues,
  selectedItems: state.itemReducer.selectedItems,
  displayOptions: state.prrfqreducer.displayOptions,
});

const mapDispatchToProps = {
  openSnackbar,
  setTemplateValues,
};

const ConnectedNegotiationTemplateView = connect(
  mapStateToProps,
  mapDispatchToProps
)(NegotiationTemplateView);

const StyledAccountTemplate = withStyles(styles)(
  ConnectedNegotiationTemplateView
);

export default StyledAccountTemplate;
