import * as React from "react";
import {
  Alert,
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputLabel,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { SendMailDrawer } from "./SendMailStyle";
import CloseIcon from "@mui/icons-material/Close";
import { sendMailUriViewForm, sendMailUri } from "../../config/apiUrl";
import dayjs from "dayjs";
import axios from "axios";
import { openSnackbarfunc } from "../../redux/actions/snackbarAction";
import { useDispatch } from "react-redux";

function stringAvatar(name) {
  const initials = name
    ? name
        .split(" ")
        .map((word) => word[0])
        .join("") // Take the first letter of each word
    : "NA"; // Default initials if name is undefined or empty

  return {
    sx: {
      bgcolor: "var(--primary-500)",
      fontSize: "12px",
      width: "32px",
      height: "32px",
    },
    children: initials.toUpperCase(), // Ensure initials are uppercase
  };
}

export default function SendMail(props) {
  const currentDate = dayjs();
  const formattedCurrentDate = currentDate.format("DD/MM/YYYY");
  const [mailSubject, setMailSubject] = React.useState("");
  const [mailDetails, setMailDetails] = React.useState("");
  const [mailNumber, setMailNumber] = React.useState(null);
  const [loading, setLoading] = React.useState(false); // State for loader
  const [modalOpen, setModalOpen] = React.useState(false); // State to control modal visibility
  const [modalContent, setModalContent] = React.useState(""); // State to store modal HTML content
  const dispatch = useDispatch();

  const handleMailSubject = (event) => {
    setMailSubject(event.target.value);
  };

  const handleMailDetails = (event) => {
    setMailDetails(event.target.value);
  };

  const handleMailNumber = (event) => {
    setMailNumber(event.target.value);
  };

  const handleCloseSendMailDrawer = () => {
    setMailSubject("");
    setMailDetails("");
    setMailNumber(null);
    props.CloseSendMailDrawer();
  };

  const sendMail = async () => {
    setLoading(true); // Show loader
    try {
      // Extract session ID from the URL
      const queryParams = new URLSearchParams(window.location.search);
      const sessionID = queryParams.get("q");
      if (!sessionID) {
        console.error(
          "Session ID is missing. Cannot proceed with the sendMail operation."
        );
        return;
      }

      // Extract necessary data
      const { transactionData, buyerInfo, userInfo } = props;
      const vendor = transactionData?.vendors?.[0];
      const filterData = transactionData?.filter_data;

      // Prepare payload using URLSearchParams
      const payload = new URLSearchParams();

      payload.append("NewUISendMail", "1");
      payload.append("productid", transactionData?.categories?.[0]?.id || "");
      payload.append("vendx_action", "transact");
      payload.append(
        "referer",
        `${sendMailUri}?call_from=qcs_plus&vendx_action=tran_details&TLGUID=s1:${vendor?.transaction_thread?.[0]?.guid}&TType=${vendor?.transaction_thread?.[0]?.type}&session_id=${props.newSessionID}&buyerid=${buyerInfo.id}&bid=${buyerInfo.id}&postmessage=0`
      );
      payload.append("TemplateMasterID", "");
      payload.append("CallingFile", "compose");
      payload.append("from_vendx_admin", "Mail");
      payload.append("userid", userInfo.user_id || "");
      payload.append(
        "multi_solution_flag",
        filterData?.multiple_solution ? "Y" : "N"
      );
      payload.append("buyerid", buyerInfo.id || "");
      payload.append("newtranstype", "Mail");
      payload.append("LinkedBusinessGrID", "4");
      payload.append("NewTranNo", mailNumber || "");
      payload.append("NewId", "1");
      payload.append("CurrentDate", formattedCurrentDate || "");
      payload.append("MailSubject", mailSubject || "");
      payload.append("MailDetails", mailDetails || "");
      payload.append("q", sessionID);
      payload.append("sessionID", sessionID);

      // Append vendor-specific details
      if (vendor) {
        payload.append("RecepientIdList", vendor?.id || "");
        payload.append("RecepientName", vendor?.name || "");
        payload.append("RecepientCode", vendor?.code || "");
        payload.append("RecepientCountry", vendor?.country || "");
        payload.append("RecepientAddress", vendor?.address || "");
        payload.append("RecepientTel1", vendor?.phone || "");
        payload.append("RecepientEmail", vendor?.email || "");
        payload.append("RecepientUserName", vendor?.sender || "");
      }

      // Add session-specific flags
      payload.append("master_type", "category");

      // Make the POST request
      const response = await axios.post(sendMailUriViewForm, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${sessionID}`,
        },
      });

      if (response.status === 200) {
        dispatch(openSnackbarfunc("success", "Mail sent successfully."));
        handleCloseSendMailDrawer();
      }
    } catch (error) {
      // Handle errors
      console.error(
        "Error sending mail:",
        error.response?.data || error.message
      );
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalContent("");
    props.CloseSendMailDrawer();
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading} // Show backdrop when loading is true
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SendMailDrawer
        anchor="right"
        open={props.openSendMailDrawer}
        onClose={props.CloseSendMailDrawer}
        className="SidebarDrawer"
      >
        <Card>
          <CardHeader
            // avatar={<InvoiceTrailIcon />}
            action={
              <Tooltip title="Close">
                <IconButton
                  onClick={props.CloseSendMailDrawer}
                  aria-label="close"
                  size="small"
                  sx={{ color: "var(--grey-500)" }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            }
            title={
              <Typography
                variant="subtitle1"
                fontWeight={500}
                component="h6"
                color={"secondary.main"}
                lineHeight={"normal"}
              >
                Send Mail
              </Typography>
            }
            subheader={
              <React.Fragment>
                <Typography
                  variant="body2"
                  color={"text.primary"}
                  component="span"
                  whiteSpace={"nowrap"}
                >
                  RFQ No:
                </Typography>
                <Tooltip
                  title={props.transactionData.ref_no}
                  placement="bottom-start"
                >
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    color={"text.primary"}
                    component="span"
                    noWrap
                  >
                    {props.transactionData.ref_no}
                  </Typography>
                </Tooltip>
              </React.Fragment>
            }
            sx={{
              pt: 1,
              pb: 1,
              "& .MuiCardHeader-action": {
                alignSelf: "center",
              },
              "& .MuiCardHeader-content": {
                width: "calc(100% - 75px)",
              },
              "& .MuiCardHeader-subheader": {
                display: "flex",
                gap: "4px",
              },
              "& .MuiCardHeader-avatar": {
                mr: 1,
              },
            }}
          />
          <Divider />
          <CardContent>
            <Box p={2}>
              <Typography variant="subtitle2" color={"text.primary"}>
                Vendor Details
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center" mt={1}>
                <Avatar
                  {...stringAvatar(props.transactionData?.vendors?.[0]?.name)}
                />
                <Box>
                  <Tooltip
                    title={props.transactionData?.vendors?.[0]?.name}
                    placement="bottom-start"
                  >
                    <Typography variant="body2" color={"text.primary"} noWrap>
                      {props.transactionData?.vendors?.[0]?.name}
                    </Typography>
                  </Tooltip>
                  <Typography
                    variant="caption"
                    display="block"
                    color={"grey.500"}
                  >
                    {props.transactionData?.vendors?.[0]?.code}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Divider />
            <Box p={2}>
              <Typography variant="body2" component="label" color={"grey.900"}>
                E-Mail
              </Typography>
              <Tooltip
                title={props.transactionData?.vendors?.[0]?.email}
                placement="bottom-start"
              >
                <Typography
                  variant="body2"
                  component="p"
                  color={"text.primary"}
                  noWrap
                >
                  To : {props.transactionData?.vendors?.[0]?.email}
                </Typography>
              </Tooltip>
            </Box>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { mb: 2 },
                ".MuiFormLabel-root": {
                  fontSize: "14px",
                  color: "var(--grey-900)",
                  mb: 0.5,
                },
                pl: 2,
                pr: 2,
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <InputLabel>
                  Mail No <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  onChange={handleMailNumber}
                  value={mailNumber}
                />
              </div>
              <div>
                <InputLabel>
                  Subject <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  onChange={handleMailSubject}
                  value={mailSubject}
                />
              </div>

              <div>
                <InputLabel htmlFor="my-input">
                  Details <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <TextField
                  multiline
                  value={mailDetails}
                  rows={4}
                  defaultValue=""
                  fullWidth
                  onChange={handleMailDetails}
                />
              </div>
            </Box>
          </CardContent>
          <Divider />
          <CardActions sx={{ p: 2, justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              size="small"
              onClick={handleCloseSendMailDrawer}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={!mailDetails || !mailSubject || !mailNumber}
              onClick={() => {
                if (mailDetails && mailSubject && mailNumber) {
                  sendMail();
                }
              }}
            >
              Send
            </Button>
          </CardActions>
        </Card>
      </SendMailDrawer>

      {/* Modal to display response content */}
      <Dialog open={modalOpen} onClose={handleCloseModal} maxWidth="md">
        <DialogTitle>
          Mail Sent Successfully
          {/* <IconButton
                        aria-label="close"
                        onClick={handleCloseModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton> */}
        </DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: modalContent }} />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Box display="flex" justifyContent="center" width="100%">
            <Button
              onClick={handleCloseModal}
              variant="contained"
              color="primary"
            >
              OK
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
