import GlobalStyles from "@mui/material/GlobalStyles";
import * as React from 'react';

export const RFQItemStyle = (
    <GlobalStyles
    styles={(theme) => ({
        '.RFQ-VendorDialog .Dialog-AppTitle': {
          height: '50px',
          padding:'5px 20px',
          borderBottom:'solid 1px var(--bluegrey-500)',
          display: 'flex',
          justifyContent: 'center',
          flexDirection:'column',
          oveflow:'hidden',
          [theme.breakpoints.down("md")]: {
            display:'none'
          }
        },
        '.RFQ-VendorDialog .RFQ-ItemsList-Left':{
            width:'450px',
            borderRight:'1px solid var(--bluegrey-500)',
            position:'relative',
            [theme.breakpoints.down("lg")]: {
                // width:'375px',
                width:'365px',
            },
            [theme.breakpoints.down("md")]: {
                width:'100%',
                borderRight:'none',
            }
        },
        '.RFQ-VendorDialog .RFQ-ItemsList-Left .RFQ-ItemList':{
            height:'calc(100vh - 181px)',
            width: "100%",
            maxWidth: "100%",
            overflowY: 'auto',
            overflowX: 'hidden',       
            padding: 0 ,
            [theme.breakpoints.down("md")]: {
                height:'calc(100vh - 160px)',
            }
        },
        '.RFQ-ItemsList-Left .MuiFormControl-root .MuiInputBase-root': {
            borderRadius: '0',
            backgroundColor: 'var(--grey-100)',        
            height:'39px',
            '& .MuiInputBase-input':{
                paddingTop:'8px'
            },
            '&.MuiInputBase-root:before':{
                borderColor: 'var(--bluegrey-500)',
            }
        },
        '.RFQ-ItemList li.MuiListItem-root':{
            display: 'block',
            cursor: 'inherit'
        },
        '.RFQ-ItemList .MuiListItemButton-root:hover' :{
            background:'transparent',
        },
        '.RFQ-ItemList .selected:before': {
            content: '""',
            width: '3px',
            background: 'var(--color-primary)',
            height: '100%',
            position: 'absolute',
            left: '0',
            [theme.breakpoints.down("md")]: {
                display:'none'
            },
        },
        '.RFQ-ItemList .MuiListItemButton-root[aria-expanded="true"] span.MuiTypography-root:not(.ItemQtyText span.MuiTypography-root)':{
            color: 'var(--color-primary)',
        },
        '.RFQ-ItemList .MuiListItemText-root': {
            margin: '0 16px 0 0'
        },
        '.RFQ-ItemList span.MuiTypography-root': {
            fontSize: '14px',
            fontWeight: '500',
            color:'var(--text-color)',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        '.RFQ-ItemList .ItemQtyText span.MuiTypography-root': {
            fontSize: '12px',
        },
        '.RFQ-ItemList p.MuiTypography-root': {
            fontSize: '12px',
            color: 'var(--grey-500)',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        '.RFQ-ItemList .MuiGrid-item': {
            paddingLeft:'10px',
            '& svg.MuiSvgIcon-root':{
                fontSize: '30px'
            }
        },
        '.RFQ-VendorDialog .ItemizeVendor-Tab .DataGFullScreen-wrap':{
            height: '195px'
            // height:'calc(100vh - 280px)'
        },
        '.ItemizeVendor-Tab' : {
            '.datagrid-toolbar, .MuiDataGrid-footerContainer':{
                display:'none',            
            },
            '> .MuiTabs-root, .MuiTab-root': {
                minHeight: '38px'
            }
        },
        '& .no-search': {
            minWidth:'100%',
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',  
        },
        '& .no-search p': {
            color: theme.palette.text.primary,
        }
    })}
    />
)




