import React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {  Box, IconButton, Button, Dialog, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
export default function PermissionRegretAlert({
  open,
  handleClose,
  handleSubmit,
  msg,
  title,
}) {
  return (
    <div>
      <Dialog open={open}
          scroll="body"
          maxWidth={"sm"}> 
        <DialogTitle>
          {title}
          <Box className="dialog-title-action">
            {/* <IconButton size="small" onClick={handleClose} sx={{ color: (theme) => theme.palette.bluegrey[500], }}>
              <CloseIcon />
            </IconButton> */}
          </Box>         
        </DialogTitle>
        <DialogContent>          
            {typeof(msg) === "object"
              ? msg?.map((line, index) => <Typography key={index}>{line}</Typography>)
              : <Typography>{msg}</Typography>          
            }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} variant="outlined" autoFocus color="primary">
            OK
          </Button>   
        </DialogActions>
      </Dialog>
    </div>
  );
}
