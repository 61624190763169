import React, { Component } from "react";
import { VendorRecipSearch } from "./style";
import { InputAdornment, IconButton, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { debounce } from "lodash";
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro";

class VendorGrid extends Component {
  state = {
    searchText: "",
    rows: [],
    searchedRows: [],
    filteredRows: [],
    loading: false,
    currentPage: 1,
    searchedDataCurrentPage: 1,
    filterDataCurrentPage: 1,
    lastPage: 1,
    searchedDataLastPage: 1,
    filterDataLastPage: 1,
    total: 0,
    searchededTotal: 0,
    vendorSearchDebounceDelay: false,
    isFilterActive: false,
    filterModel: {
      items: [],
    },
    vendor_name: "",
    vendor_name_operator: "",
    vendor_code: "",
    vendor_code_operator: "",
    mobile_no: "",
    mobile_no_operator: "",
    country: "",
    country_operator: "",
    email: "",
    email_operator: "",
  };

  componentDidMount() {
    this.fetchVendors(1);
  }

  requestSearch = (searchValue) => {
    this.setState({
      searchText: searchValue,
    });

    if (searchValue.trim() !== "") {
      this.setState(
        {
          isFilterActive: false,
          vendorSearchDebounceDelay: true,
          loading: true,
          searchedRows: [],
          searchedDataCurrentPage: 1,
          searchedDataLastPage: 1,
          searchededTotal: 0,
          filterModel: {
            items: [],
          },
        },
        () => {
          this.getSearchApi();
        }
      );
    }
  };

  getSearchApi = debounce(() => {
    this.setState({
      vendorSearchDebounceDelay: false,
    });
    this.searchVendors();
  }, 1000);

  searchVendors = () => {
    const url = this.props.getURi(
      this.state.searchedDataCurrentPage,
      this.state.searchText.trim()
    );
    this.setState({
      loading: true,
    });

    let items = this.props.selectedItems.map((item) => item.id);
    axios
      .get(url, {
        params: {
          items,
        },
      })
      .then((res) => {
        if (this.state.searchedDataCurrentPage === 1) {
          this.setState({
            loading: false,
            searchedRows: res.data.data,
            searchedDataCurrentPage: res.data.meta.current_page,
            searchedDataLastPage: res.data.meta.last_page,
            searchededTotal: res.data.meta.total,
          });
        } else {
          let finalRows = [...this.state.searchedRows, ...res.data.data];
          let jsonObject = finalRows.map(JSON.stringify);
          let uniqueSet = new Set(jsonObject);
          let uniqueRows = Array.from(uniqueSet).map(JSON.parse);
          this.setState({
            loading: false,
            searchedRows: uniqueRows,
            searchedDataCurrentPage: res.data.meta.current_page,
            searchedDataLastPage: res.data.meta.last_page,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log("searched vendors err", err);
      });
  };

  fetchVendors = (page) => {
    const url = this.props.getURi(page);
    let items = this.props.selectedItems.map((item) => item.id);
    let vendor_classification = JSON.stringify(this.props.vendorClassification) || {};
    this.setState({
      loading: true,
    });
    axios
      .get(url, {
        params: {
          items,
          vendor_classification,
        },
      })
      .then((res) => {
        let finalRows = [...this.state.rows, ...res.data.data];
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueRows = Array.from(uniqueSet).map(JSON.parse);

        this.setState({
          loading: false,
          // mainDataWithoutPaginaion: uniqueRows,
          rows: uniqueRows,
          currentPage: res.data.meta?.current_page,
          lastPage: res.data.meta?.last_page,
          ...(!this.state.total && {
            total: res.data.meta.total,
          }),
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log("fetch vendors err", err);
      });
  };

  fetchFilterVendors = (page) => {
    let url = this.props.getURi(page);

    const {
      vendor_name,
      vendor_name_operator,
      vendor_code,
      vendor_code_operator,
      country,
      country_operator,
      mobile_no,
      mobile_no_operator,
      email,
      email_operator,
    } = this.state;

    url =
      url +
      `${
        vendor_name &&
        `&${
          vendor_name_operator === "contains"
            ? `vendor_name[like]=%25${encodeURIComponent(vendor_name)}%25`
            : vendor_name_operator === "equals"
            ? `vendor_name=${encodeURIComponent(vendor_name)}`
            : vendor_name_operator === "startsWith"
            ? `vendor_name[like]=${encodeURIComponent(vendor_name)}%25`
            : vendor_name_operator === "endsWith"
            ? `vendor_name[like]=%25${encodeURIComponent(vendor_name)}`
            : `vendor_name[like]=%25${encodeURIComponent(vendor_name)}%25`
        }`
      }${
        vendor_code &&
        `&${
          vendor_code_operator === "contains"
            ? `vendor_code[like]=%25${encodeURIComponent(vendor_code)}%25`
            : vendor_code_operator === "equals"
            ? `vendor_code=${encodeURIComponent(vendor_code)}`
            : vendor_code_operator === "startsWith"
            ? `vendor_code[like]=${encodeURIComponent(vendor_code)}%25`
            : vendor_code_operator === "endsWith"
            ? `vendor_code[like]=%25${encodeURIComponent(vendor_code)}`
            : `vendor_code[like]=%25${encodeURIComponent(vendor_code)}%25`
        }`
      }${
        country &&
        `&${
          country_operator === "contains"
            ? `country[like]=%25${country}%25`
            : country_operator === "equals"
            ? `country=${country}`
            : country_operator === "startsWith"
            ? `country[like]=${country}%25`
            : country_operator === "endsWith"
            ? `country[like]=%25${country}`
            : `country[like]=%25${country}%25`
        }`
      }${
        mobile_no &&
        `&${
          mobile_no_operator === "contains"
            ? `mobile_no[like]=%25${mobile_no}%25`
            : mobile_no_operator === "equals"
            ? `mobile_no=${mobile_no}`
            : mobile_no_operator === "startsWith"
            ? `mobile_no[like]=${mobile_no}%25`
            : mobile_no_operator === "endsWith"
            ? `mobile_no[like]=%25${mobile_no}`
            : `mobile_no[like]=%25${mobile_no}%25`
        }`
      }${
        email &&
        `&${
          email_operator === "contains"
            ? `email[like]=%25${email}%25`
            : email_operator === "equals"
            ? `email=${email}`
            : email_operator === "startsWith"
            ? `email[like]=${email}%25`
            : email_operator === "endsWith"
            ? `email[like]=%25${email}`
            : `email[like]=%25${email}%25`
        }`
      }`;

    let items = this.props.selectedItems.map((item) => item.id);

    this.setState({
      loading: true,
    });
    axios
      .get(url, {
        params: {
          items,
        },
      })
      .then((res) => {
        if (page === 1) {
          this.setState({
            loading: false,
            filteredRows: res.data.data,
            filterDataCurrentPage: res.data.meta.current_page,
            filterDataLastPage: res.data.meta.last_page,
            filteredTotal: res.data.meta.total,
          });
        } else {
          let finalRows = [...this.state.filteredRows, ...res.data.data];
          let jsonObject = finalRows.map(JSON.stringify);
          let uniqueSet = new Set(jsonObject);
          let uniqueRows = Array.from(uniqueSet).map(JSON.parse);
          this.setState({
            loading: false,
            filteredRows: uniqueRows,
            filterDataCurrentPage: res.data.meta.current_page,
            filterDataLastPage: res.data.meta.last_page,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log("fetch vendors err", err);
      });
  };
  handleScrollEnd = () => {
    if (this.state.isFilterActive) {
      if (this.state.filterDataCurrentPage !== this.state.filterDataLastPage) {
        this.setState(
          {
            filterDataCurrentPage: this.state.filterDataCurrentPage + 1,
          },
          () => {
            this.fetchFilterVendors(this.state.filterDataCurrentPage);
          }
        );
      }
    } else {
      if (this.state.searchText.trim() === "") {
        if (this.state.currentPage !== this.state.lastPage) {
          this.setState(
            {
              currentPage: this.state.currentPage + 1,
            },
            () => {
              this.fetchVendors(this.state.currentPage);
            }
          );
        }
      } else {
        if (
          this.state.searchedDataCurrentPage !== this.state.searchedDataLastPage
        ) {
          this.setState(
            {
              searchedDataCurrentPage: this.state.searchedDataCurrentPage + 1,
            },
            () => {
              this.searchVendors();
              // this.fetchVendors(this.state.currentPage);
            }
          );
        }
      }
    }
  };

  handleFilterModelChange = (filterModel) => {
    let vendor_name = "";
    let vendor_name_operator = "";
    let vendor_code = "";
    let vendor_code_operator = "";
    let country = "";
    let country_operator = "";
    let mobile_no = "";
    let mobile_no_operator = "";
    let email = "";
    let email_operator = "";

    this.setState({
      filterModel: filterModel,
    });
    filterModel.items.forEach((filter) => {
      if (filter.value) {
        if (filter.field === "vendor_name") {
          vendor_name = filter.value;
          vendor_name_operator = filter.operator;
        } else if (filter.field === "vendor_code") {
          vendor_code = filter.value;
          vendor_code_operator = filter.operator;
        } else if (filter.field === "country") {
          country = filter.value;
          country_operator = filter.operator;
        } else if (filter.field === "mobile_no") {
          mobile_no = filter.value;
          mobile_no_operator = filter.operator;
        } else if (filter.field === "email") {
          email = filter.value;
          email_operator = filter.operator;
        }
      }
    });
    //api call
    if (vendor_name || vendor_code || country || mobile_no || email) {
      this.setState(
        {
          isFilterActive: true,
          vendor_name,
          vendor_name_operator,
          vendor_code,
          vendor_code_operator,
          country,
          country_operator,
          mobile_no,
          mobile_no_operator,
          email,
          email_operator,
          filterDataCurrentPage: 1,
          filteredRows: [],
          filterDataLastPage: 1,
          filteredTotal: 0,
          searchText: "",
        },
        () => {
          this.fetchFilterVendors(1);
        }
      );
    } else {
      this.setState({
        isFilterActive: false,
        vendor_name: "",
        vendor_code: "",
        country: "",
        mobile_no: "",
        email: "",
        filterDataCurrentPage: 1,
        filteredRows: [],
        filterDataLastPage: 1,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        {!this.props.isTerms && (
          <VendorRecipSearch
            variant="standard"
            value={this.state.searchText}
            onChange={(event) => this.requestSearch(event.target.value)}
            placeholder="Search…"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            }
            endAdornment={
              <IconButton
                className="searchclear-icon"
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{
                  visibility: this.state.searchText ? "visible" : "hidden",
                }}
                onClick={() => this.requestSearch("")}>
                <ClearIcon />
              </IconButton>
            }
          />
        )}
        <div
          style={{ height: this.props.isTerms ? 400 : 350, margin: "1rem 0" }}>
          <DataGridPro
            columns={this.props.columns}
            loading={this.state.loading}
            rows={
              this.state.isFilterActive
                ? this.state.filteredRows
                : this.state.searchText.trim() === ""
                ? this.state.rows
                : this.state.searchedRows
            }
            hideFooter
            slots={{
              noRowsOverlay: () => {
                return (
                  <GridOverlay>
                    <Typography>No Vendors found</Typography>
                  </GridOverlay>
                );
              },
            }}
            hideFooterPagination
            disableRowSelectionOnClick
            disableColumnReorder
            onRowsScrollEnd={() => {
              this.handleScrollEnd();
            }}
            disableColumnFilter={this.state.searchText.trim() !== ""}
            filterModel={this.state.filterModel}
            onFilterModelChange={this.handleFilterModelChange}
            disableColumnSelector
            filterMode="server"
            disableColumnPinning
          />
        </div>
      </React.Fragment>
    );
  }
}
export default VendorGrid;
