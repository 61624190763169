import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  Divider,
  FilledInput,
  FormControl,
  InputAdornment,
  ListItem,
  Stack,
  Typography,
  Tab,
  Tabs,
  IconButton,
  Tooltip,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { RFQItemStyle } from "./Style";
import { makeStyles } from "@mui/styles";
import { CustomIconButton } from "../../../utils/RFQList/utils";
import { get_Inquiries_data } from "../../../config/apiUrl";
import VendorList from "../vendorList/vendorList";
import noSearchimg from "../../../assets/images/no-search.svg";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { BrowserView, MobileView } from "react-device-detect";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  // SearchInput: {
  // "& .css-1vbc0rj-MuiInputBase-input-MuiFilledInput-input": {
  //   padding: 0,
  // },
  // },
}));

const ItemLists = ({
  sessionID,
  selectedId,
  handleSelectItem,
  selectedItem,
  TransactionFilteredItems,
}) => {
  const [value, setValue] = React.useState(0);
  const [searchText, setSearchText] = useState("");
  const [itemName, setItemName] = useState(null);
  const [openItemsId, setOpenItemId] = useState(null); // Track the ID of the open item
  const [itemListData, setItemListData] = useState([]);
  const classes = useStyles();

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    handleSelectItem(TransactionFilteredItems[0]);
  }, []);

  const filteredData = TransactionFilteredItems?.filter((item) => {
    return Object.values(item.name)
      .join("")
      .toLowerCase()
      .includes(searchText.toLowerCase());
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseSearch = () => {
    setSearchText("");
  };

  return (
    <Box>
      {RFQItemStyle}
      <Box className="Dialog-AppTitle">
        <Typography variant="subtitle2" color="var(--text-color)">
          {`ITEMS LIST(${TransactionFilteredItems.length})`}
        </Typography>
      </Box>

      <FormControl sx={{ width: "100%", p: 0 }}>
        <FilledInput
          className={classes.SearchInput}
          value={searchText}
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <SearchOutlinedIcon />
            </InputAdornment>
          }
          endAdornment={
            searchText !== "" && (
              <IconButton
                className="search-clear-btn"
                size="small"
                onClick={handleCloseSearch}
              >
                <ClearIcon fontSize="inherit" />
              </IconButton>
            )
          }
          onChange={handleSearchChange}
        />
      </FormControl>

      {filteredData.length === 0 ? (
        <Box className="no-search">
          <img src={noSearchimg} />
          <Typography component="p">
            {searchText !== "" ? (
              <>
                There are no item list like <b>{searchText}</b>
              </>
            ) : (
              "There are no item list"
            )}
          </Typography>
        </Box>
      ) : (
        <List className="RFQ-ItemList">
          {filteredData?.map((item) => (
            <ListItem
              className={
                item?.item_id === selectedItem?.item_id
                  ? "selected"
                  : "notSelected"
              }
              key={item.name}
              disablePadding
            >
              <ListItemButton disableRipple={true} sx={{ pr: 0 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ width: "100%" }}
                >
                  <ListItemText
                    primary={
                      <Tooltip title={item.name} placement="bottom-start">
                        {item.name}
                      </Tooltip>
                    }
                    secondary={
                      <Tooltip title={item.name} placement="bottom-start">
                        {item.code}
                      </Tooltip>
                    }
                    onClick={() => {
                      handleSelectItem(item);
                      setItemName(item);
                    }}
                  />
                </Stack>
              </ListItemButton>

              <Divider />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default ItemLists;
