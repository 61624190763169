import {
  Typography,
  IconButton,
  Dialog,
  Avatar,
  DialogActions,
  Button,
} from "@mui/material";
import React, { Component } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import {
  openSnackbar,
  closeSnackbar,
} from "../../../redux/actions/snackbarAction";
import { connect } from "react-redux";
import { setSelectedVendors } from "../../../redux/actions/vendorActions";
import { Box } from "@mui/system";
import VendorGrid from "../VendorRecipints/VendorGrid";
import { getMultipleItemsVendors } from "../../../config/apiUrl";
import { getFilterOperators } from "../../Utils/datagridHelper";

class TermDetailsItemize extends Component {
  render() {
    const { open, handleClose, vendorTerms } = this.props;
    const columns = [
      {
        field: "vendor_name",
        headerName: "VENDOR NAME",
        width: 300,
        // flex: 1,
        editable: false,
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return (
            <React.Fragment>
              <Avatar
                sx={{
                  backgroundColor: (theme) => theme.palette.success.main,
                  width: "34px",
                  height: "34px",
                }}>
                {params.row.vendor_name.slice(0, 1)}
              </Avatar>
              <Box sx={{ lineHeight: "12px", ml: 1 }}>
                <Typography color="text.primary" fontWeight={500}>
                  {params.row.vendor_name}
                </Typography>
                <Typography variant="span" color="grey.500">
                  {params.row.is_approved ? params.row.is_approved : " -"}
                </Typography>
              </Box>
            </React.Fragment>
          );
        },
      },
      {
        field: vendorTerms === "inco" ? "inco_term_name" : "payment_term_name",
        headerName:
          vendorTerms === "inco" ? "Inco Term Name" : "Payment Term Name",
        headerAlign: "left",
        align: "left",
        width: 300,
        filterable: false,
        // flex: 1,
      },
      {
        field: vendorTerms === "inco" ? "inco_term_code" : "payment_term_code",
        headerName:
          vendorTerms === "inco" ? "Inco Term Code" : "Payment Term Code",
        headerAlign: "left",
        align: "left",
        width: 300,
        filterable: false,

        // flex: 1,
      },
    ];

    return (
      <React.Fragment>
        <Dialog
          fullWidth
          scroll="body"
          maxWidth={"md"}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">
            {vendorTerms === "inco" ? "Inco" : "Payment"} Term Details
            <IconButton
              onClick={handleClose}
              sx={{
                ml: "auto",
                color: (theme) => theme.palette.bluegrey[500],
              }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {/* <div style={{ height: 400, margin: "1rem 0" }}> */}
            <VendorGrid
              selectedItems={this.props.selectedItems}
              isTerms
              columns={columns}
              getURi={(page) => {
                return getMultipleItemsVendors(
                  this.props.selectedCategory?.id,
                  this.props.vendorType,
                  page,
                  this.props.vendorTerms
                );
              }}
              vendorClassification={this.props.getVendorLocationFlag}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              autoFocus
              onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  selectedItems: state.itemReducer.selectedItems,
  selectedVendors: state.vendorReducer.selectedVendors,
  accountlevelInfo: state.permissionsReducer.accountlevelInfo,
  getVendorLocationFlag: state.itemReducer.vendorLocationFlag,
});
export default connect(mapStateToProps, {
  openSnackbar,
  closeSnackbar,
  setSelectedVendors,
})(TermDetailsItemize);
