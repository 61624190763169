import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardContent,
  Grid,
  Divider,
  CardHeader,
  Button,
  Box,
  InputLabel,
  FormHelperText,
  FormControl,
  Typography,
  Tooltip,
  Badge,
  Popover,
  IconButton,
  ListItemText,
  ListItem,
  List,
  Paper,
  TextField,
} from "@mui/material";
import FileSpecification from "../Dialogs/Upload/FileSpecification";
import UploadIcon from "@mui/icons-material/CloudUploadRounded";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import axios from "axios";
import { postFileSpecifications } from "../../config/apiUrl";
import UploadDynamicView from "../Dialogs/Upload/UploadDynamicView";
import { CloudDownloadOutlined } from "@mui/icons-material";
import ExpandLess from "@mui/icons-material/ArrowDropDown";
import ExpandMore from "@mui/icons-material/ArrowDropUp";

const NegotiationCardTemplate = ({
  title,
  localData,
  getField,
  foreignData,
  vendorType,
  categoryId,
  fileSpecificationData,
  from,
  contract,
  cc_user_email,
  MaterialDescription,
  materialDescriptionAll,
  CurrentTransaction,
  category,
  shipmentMark,
  handleShipmentChange,
}) => {
  const [additionalInfoData, setAdditionalInfoData] = useState([]);
  const [fileSpecificationsOpen, setFileSpecificationsOpen] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [specification, setSpecification] = useState([]);
  useEffect(() => {
    if (localData?.length > 0 || foreignData?.length > 0) {
      if (vendorType === "Local") {
        setAdditionalInfoData(localData);
      } else {
        setAdditionalInfoData(foreignData);
      }
    }
  }, [localData, foreignData]);

  const handleFileSpecificationsOpen = (value) => {
    setFileSpecificationsOpen(true);
    getExistingFiles(value);
  };
  const handleUploadClose = () => {
    setFileSpecificationsOpen(false);
    setSpecification([]);
    setLoading(true);
  };

  const getExistingFiles = (file_id) => {
    const queryString = file_id
      .map((file) => `file_ids[]=${file.id}`)
      .join("&");
    const url = `${postFileSpecifications}?${queryString}`;
    axios
      .get(url)
      .then((res) => {
        setSpecification(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, " downloadFile error");
        this.props.openSnackbar(
          "error",
          err.response.data?.message
            ? err.response.data?.message
            : "Something went wrong!"
        );
        setLoading(false);
      });
  };

  // Start CC Users' mails script
  const openEmailListRef = useRef(null);

  const [openEmailList, setOpenEmailList] = React.useState(null);

  const EmailListClick = (event) => {
    setOpenEmailList(event.currentTarget);
  };

  const EmailListClose = () => {
    setOpenEmailList(null);
  };

  const openEmailPopover = Boolean(openEmailList);
  // End CC Users' mails script

  return (
    <Card variant="outlined" id="CardAdditional">
      <CardHeader
        title={title}
        titleTypographyProps={{
          variant: "subtitle2",
          component: "h5",
          color: "grey.900",
          textTransform: "uppercase",
        }}
        sx={{
          minHeight: "50px",
          "& .MuiCardHeader-action": {
            marginRight: 0,
            marginTop: 0,
          },
        }}
      />
      <Divider sx={{ marginBottom: "0" }} />
      <CardContent>
        <Grid container spacing={2}>
          {additionalInfoData.map((cell, i) => {
            const field = getField(cell);
            if (
              field &&
              cell.VisibilityFlag === "Y" &&
              cell.Identifier !== "Currency"
            ) {
              return (
                <Grid item xl={3} lg={4} md={6} sm={6} xs={12} key={i}>
                  {field}
                </Grid>
              );
            }
          })}
          {MaterialDescription && (
            <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
              <Button
                component="text"
                color="primary"
                onClick={materialDescriptionAll}
              >
                Material Description (All Items)
              </Button>
            </Grid>
          )}
          {vendorType !== "Local" &&
            (from == "View" ? (
              <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                <Typography
                  component="label"
                  variant="body2"
                  color={"grey.500"}
                >
                  {"Shipment Mark"}
                </Typography>
                <Tooltip
                  title={
                    CurrentTransaction?.shipment_mark
                      ? CurrentTransaction?.shipment_mark
                      : "N/A"
                  }
                  placement="bottom-start"
                >
                  <Typography variant="body2" color={"text.primary"}>
                    {CurrentTransaction?.shipment_mark
                      ? CurrentTransaction?.shipment_mark
                      : "N/A"}
                  </Typography>
                </Tooltip>
              </Grid>
            ) : (
              <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                <TextField
                  className="local_template_textfield"
                  fullWidth
                  variant="outlined"
                  label="Shipment Mark"
                  type="text"
                  value={shipmentMark}
                  onChange={(e) => {
                    handleShipmentChange(e.target.value);
                  }}
                />
              </Grid>
            ))}
          {from == "View" && (
            <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
              <Typography component="label" variant="body2" color={"grey.500"}>
                {"Contract RFQ"}
              </Typography>
              <Tooltip title={contract ? "Yes" : "No"} placement="bottom-start">
                <Typography variant="body2" color={"text.primary"}>
                  {contract ? "Yes" : "No"}
                </Typography>
              </Tooltip>
            </Grid>
          )}
          {from == "View" && (
            <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
              <Typography component="label" variant="body2" color={"grey.500"}>
                {"CC Users' mails"}
              </Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap="8px"
                className="EmailId-Info"
                ref={openEmailListRef}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  gap="8px"
                  sx={{ width: "calc(100% - 45px)" }}
                >
                  <Tooltip
                    title={cc_user_email ? cc_user_email?.split(",")[0] : "N/A"}
                    placement="bottom-start"
                  >
                    <Typography variant="body2" color={"text.primary"} noWrap>
                      {cc_user_email ? cc_user_email?.split(",")[0] : "N/A"}
                    </Typography>
                  </Tooltip>
                </Box>
                {cc_user_email?.split(",").length > 1 && (
                  <IconButton variant="contained" onClick={EmailListClick}>
                    {openEmailPopover ? (
                      <ExpandMore fontSize="small" />
                    ) : (
                      <ExpandLess fontSize="small" />
                    )}
                  </IconButton>
                )}
              </Box>
              <Popover
                open={openEmailPopover}
                anchorEl={openEmailList}
                onClose={EmailListClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <Paper
                  style={{
                    width: openEmailListRef.current
                      ? openEmailListRef.current.clientWidth
                      : "100%",
                  }}
                >
                  <List
                    sx={{
                      position: "relative",
                      overflow: "auto",
                      maxHeight: 130,
                    }}
                  >
                    {cc_user_email
                      ?.split(",")
                      .slice(1)
                      .map((email) => (
                        <ListItem alignItems="flex-start" sx={{ pb: 0, pt: 0 }}>
                          <ListItemText
                            primary={
                              <Box
                                display="flex"
                                gap="8px"
                                justifyContent="space-between"
                                className="EmailId-Info"
                              >
                                <Tooltip title={email} placement="bottom-start">
                                  <Typography
                                    variant="body2"
                                    color={"text.primary"}
                                    noWrap
                                  >
                                    {email}
                                  </Typography>
                                </Tooltip>
                              </Box>
                            }
                          />
                        </ListItem>
                      ))}
                  </List>
                </Paper>
              </Popover>
            </Grid>
          )}

          {from == "View" ? (
            <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
              <FormControl>
                <Typography
                  component="label"
                  variant="body2"
                  color={"grey.500"}
                  mb={1}
                >
                  View Specification Attachment
                </Typography>
                <Box>
                  <Badge
                    badgeContent={`${fileSpecificationData?.length}`}
                    color="error"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        fileSpecificationData?.length > 0 &&
                          handleFileSpecificationsOpen(fileSpecificationData);
                      }}
                      startIcon={
                        <VisibilityIcon sx={{ fontSize: "22px !important" }} />
                      }
                    >
                      View Attachment
                    </Button>
                  </Badge>
                </Box>
                {/* <Typography mt={1} variant="body2" color={"text.primary"}>
                  Number Of Files {fileSpecificationData?.length}
                </Typography> */}
              </FormControl>
            </Grid>
          ) : (
            <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
              <Box
                sx={{
                  border: "solid 1px",
                  borderColor: (theme) => theme.palette.bluegrey[500],
                  borderRadius: "4px",
                  minWidth: "100%",
                  height: "56px",
                  display: "flex",
                  alignItems: "center",
                  padding: "0 10px",
                  position: "relative",
                }}
              >
                <InputLabel
                  sx={{
                    color: "text.secondary",
                    backgroundColor: (theme) => theme.palette.grey[0],
                    position: "absolute",
                    left: 0,
                    top: 0,
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    transformOrigin: "top left",
                    webkitTransform: " translate(14px, -9px) scale(0.75)",
                    transform: "translate(14px, -9px) scale(0.75)",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "calc(133% - 24px)",
                    zIndex: 1,
                  }}
                >
                  Specification
                </InputLabel>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ fontSize: "12px" }}
                  onClick={() => {
                    fileSpecificationData?.length > 0 &&
                      handleFileSpecificationsOpen(fileSpecificationData);
                  }}
                  startIcon={
                    <VisibilityIcon sx={{ fontSize: "22px !important" }} />
                  }
                >
                  View Specification
                </Button>
                <FormHelperText sx={{ ml: "auto" }}>
                  Number Of Files {fileSpecificationData?.length}
                </FormHelperText>
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
      {fileSpecificationsOpen && (
        <UploadDynamicView
          Loading={Loading}
          title="View Transaction Specification"
          uploadedFiles={specification?.data}
          open={fileSpecificationsOpen}
          handleClose={handleUploadClose}
          SpecificationText={category?.specification_text}
        />
      )}
    </Card>
  );
};

export default NegotiationCardTemplate;
