import * as React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ExchangeRateDrawer } from "./ExchangeRateStyle";
import CloseIcon from "@mui/icons-material/Close";
import { getSymbol } from "../../AccountTemplate/CurrencySymbol";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../../redux/actions/snackbarAction";

export default function ExchangeRate(props) {
  const [tempExchangeRate, setTempExchangeRate] = React.useState({});
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!props.openExchangeRate) {
      setTempExchangeRate(props.currencyExchangeRates); // Reset the rate to initial value when drawer closes
    }
  }, [props.openExchangeRate, props.currencyExchangeRates]);

  const hasBlankValue = (obj) => {
    return Object.values(obj).some((value) => !value); // Checks if there's any falsy value (empty string, null, undefined, 0, etc.)
  };
  const handleSaveExchangeRates = () => {
    // Round the rates to 4 decimals before saving
    const result = hasBlankValue(tempExchangeRate);
    if (!result) {
      props.handleChangeExchangeRates(tempExchangeRate);
      props.CloseExchangeRate();
    } else {
      dispatch(openSnackbar("error", "Please fill exchange rate first"));
      return;
    }
    // Call the function passed via props to save the exchange rates (e.g., to parent component or API)
    // Close the exchange rate drawer after saving
  };
  return (
    <ExchangeRateDrawer
      anchor="right"
      open={props.openExchangeRate}
      onClose={props.CloseExchangeRate}
      className="SidebarDrawer"
    >
      <Card>
        <CardHeader
          title={
            <Typography
              variant="h6"
              fontSize="18px"
              fontWeight={500}
              color={"secondary.main"}
            >
              Exchange Rate
            </Typography>
          }
          action={
            <Tooltip title="Close">
              <IconButton
                onClick={props.CloseExchangeRate}
                aria-label="close"
                size="small"
                sx={{ color: "var(--grey-500)" }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          }
          sx={{
            "& .MuiCardHeader-action": {
              alignSelf: "center",
            },
            "& .MuiCardHeader-avatar": {
              mr: 1,
            },
          }}
        />
        <Divider />
        <CardContent>
          <Table
            size="small"
            sx={{
              "& .MuiTableCell-root .MuiInputBase-input": {
                textAlign: "right",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell width={260}>Exchange rates for inr (₹) 1</TableCell>
                <TableCell width={160} align="right">
                  RATES
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(tempExchangeRate).map(([currency, rate]) => {
                if (currency !== "INR") {
                  return (
                    <TableRow>
                      <TableCell width={260}>{`${currency} (${getSymbol(
                        currency
                      )})`}</TableCell>
                      <TableCell width={160} align="right">
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={rate} // Apply toFixed here
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setTempExchangeRate((prev) => ({
                                ...prev,
                                [currency]: e.target.value,
                              }));
                            } else {
                              console.log("Invalid rate value");
                            }
                            //   props.handleChangeExchangeRates(e, currency);
                          }}
                          error={!rate}
                          helperText={!rate && `Enter currecy rate ${currency}`}
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </CardContent>
        <Divider />
        <CardActions sx={{ p: 2, justifyContent: "flex-end" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={props.CloseExchangeRate}
          >
            Close
          </Button>

          <Button
            variant="contained"
            size="small"
            onClick={handleSaveExchangeRates} // Save changes and close
          >
            Save
          </Button>
        </CardActions>
      </Card>
    </ExchangeRateDrawer>
  );
}
