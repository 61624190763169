import React, { Component } from "react";
import {
  Avatar,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
  InputBase,
  Tooltip,
  Checkbox,
  Badge,
  Button,
  styled,
  Skeleton,
  LinearProgress,
  FormControl,
  Select,
} from "@mui/material";
import { connect } from "react-redux";
import {
  DataGridPro,
  GridActionsCellItem,
  gridColumnPositionsSelector,
  gridColumnsTotalWidthSelector,
  GridOverlay,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import {
  getVendors,
  filterVendors,
  setSelectedVendors,
  // setSelectAllVendors
} from "../../../redux/actions/vendorActions";
import SearchIcon from "@mui/icons-material/Search";
import { MoreVert } from "@mui/icons-material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import RefreshIcon from "@mui/icons-material/Refresh";
import "../../../pages/style.css";
import vendoremptyicon from "../../../assets/vendxAdminPanelAsstes/assetsnew/vendor_blank.svg";
import LinkVendorToCategory from "../../Dialogs/LinkVendorToCategory/Index";
import { resetVendors } from "../../../redux/actions/vendorActions";
import {
  getAllLinkedVendors,
  getFilteredVendors,
  searchCategoryVendorsByNameOrCode,
} from "../../../config/apiUrl";
import axios from "axios";
import { debounce } from "lodash";
import LoopIcon from "@mui/icons-material/Loop";
import ClearIcon from "@mui/icons-material/Clear";
import { getFilterOperators } from "../../Utils/datagridHelper";
import { Box } from "@mui/system";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import { tooltipClasses } from "@mui/material/Tooltip";
import { getVendorGMPFlag } from "../../Vendors/VendorChip";
import { setVendorLocationFlag } from "../../../redux/actions/itemActions";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const randomBetween = (seed, min, max) => {
  const random = mulberry32(seed);
  return () => min + (max - min) * random();
};

const mulberry32 = (a) => {
  return () => {
    /* eslint-disable */
    let t = (a += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    /* eslint-enable */
  };
};
const SkeletonCell = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));


class CategoryVendors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      total: 0,
      currentPage: 1,
      filteredTotal: 0,
      filteredRows: [],
      selectionModel: [],
      lastPage: 1,
      isFilterActive: false,
      filterDataLastPage: 1,
      filterDataCurrentPage: 1,
      vendor_name: "",
      vendor_name_operator: "",
      vendor_code: "",
      vendor_code_operator: "",
      industry_type: "",
      industry_type_operator: "",
      country: "",
      country_operator: "",
      email: "",
      email_operator: "",
      state: "",
      state_operator: "",
      contact_person: "",
      contact_person_operator: "",
      phone_no: "",
      phone_no_operator: "",
      address: "",
      address_operator: "",
      anchorEl: null,
      linkVendorToCategoryOpen: false,
      loading: false,
      VendorSearchInput: "",
      vendorSearchDebounceDelay: false,
      searchedRows: [],
      searchedDataCurrentPage: 1,
      searchedDataLastPage: 1,
      searchededTotal: 0,
      filterModel: {
        items: [],
      },
      pagination: true,
      mainDataWithoutPaginaion: [],
      mainSelectionModel: [],
      showSelected: false,
      selectallvendors: false,
      selectallarea: false,
      isAllVendorSelected: false,
      vendorClassifications: {},
    };
  }
  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  componentDidMount() {
    this.setState(
      {
        pagination:
          this.props.featurePermission
            .restrict_users_to_deselect_linked_vendors_while_floating_rfq
            ?.feature_value !== "Y",
      },
      () => {
        if (
          Object.keys(this.props.defaultValues).length !== 0 &&
          this.props.defaultValues.selectAllVendors === true
        ) {
          this.setState({ selectallvendors: true }, () => this.fetchVendors(1));
        } else {
          this.fetchVendors(1);
        }
      }
    );
  }

  componentDidUpdate() {
    if (this.state.showSelected && !this.state.mainSelectionModel.length) {
      this.setState({
        showSelected: false,
      });
    }   
    if (!this.state.mainSelectionModel.length && this.props.selectedVendors.length && Object.keys(this.props.ImportExcelRfq).length !== 0) {
      this.setState({
        mainSelectionModel: this.props.selectedVendors,
      });
    }
   
  }
  //for vendors pagination
  handleScrollEnd = () => {
    if (this.state.isFilterActive) {
      if (this.state.filterDataCurrentPage !== this.state.filterDataLastPage) {
        this.setState(
          {
            filterDataCurrentPage: this.state.filterDataCurrentPage + 1,
          },
          () => {
            this.fetchFilterVendors(this.state.filterDataCurrentPage);
          }
        );
      }
    } else {
      if (this.state.VendorSearchInput.trim() === "") {
        if (this.state.currentPage !== this.state.lastPage) {
          this.setState(
            {
              currentPage: this.state.currentPage + 1,
            },
            () => {
              this.fetchVendors(this.state.currentPage);
            }
          );
        }
      } else {
        if (
          this.state.searchedDataCurrentPage !== this.state.searchedDataLastPage
        ) {
          this.setState(
            {
              searchedDataCurrentPage: this.state.searchedDataCurrentPage + 1,
            },
            () => {
              this.searchVendors();
              // this.fetchVendors(this.state.currentPage);
            }
          );
        }
      }
    }
  };

  handleFilterModelChange = (filterModel) => {
    let vendor_name = "";
    let vendor_name_operator = "";
    let vendor_code = "";
    let vendor_code_operator = "";
    let country = "";
    let country_operator = "";
    let industry_type = "";
    let industry_type_operator = "";
    let email = "";
    let email_operator = "";
    let state = "";
    let state_operator = "";
    let contact_person = "";
    let contact_person_operator = "";
    let phone_no = "";
    let phone_no_operator = "";
    let address = "";
    let address_operator = "";
    this.setState({
      filterModel: filterModel,
    });
    if (this.state.pagination) {
      filterModel.items.forEach((filter) => {
        if (filter.value) {
          if (filter.field === "vendor_name") {
            vendor_name = filter.value;
            vendor_name_operator = filter.operator;
          } else if (filter.field === "vendor_code") {
            vendor_code = filter.value;
            vendor_code_operator = filter.operator;
          } else if (filter.field === "country") {
            country = filter.value;
            country_operator = filter.operator;
          } else if (filter.field === "industry_type") {
            industry_type = filter.value;
            industry_type_operator = filter.operator;
          } else if (filter.field === "email") {
            email = filter.value;
            email_operator = filter.operator;
          } else if (filter.field === "state") {
            state = filter.value;
            state_operator = filter.operator;
          } else if (filter.field === "contact_person") {
            contact_person = filter.value;
            contact_person_operator = filter.operator;
          } else if (filter.field === "phone_no") {
            phone_no = filter.value;
            phone_no_operator = filter.operator;
          } else if (filter.field === "address") {
            address = filter.value;
            address_operator = filter.operator;
          }
        }
      });
      //api call
      if (
        vendor_name ||
        vendor_code ||
        country ||
        industry_type ||
        email ||
        state ||
        contact_person ||
        phone_no ||
        address
      ) {
        this.setState(
          {
            isFilterActive: true,
            vendor_name,
            vendor_name_operator,
            vendor_code,
            vendor_code_operator,
            country,
            country_operator,
            industry_type,
            industry_type_operator,
            email,
            email_operator,
            state,
            state_operator,
            contact_person,
            contact_person_operator,
            phone_no,
            phone_no_operator,
            address,
            address_operator,
            filterDataCurrentPage: 1,
            filteredRows: [],
            filterDataLastPage: 1,
            filteredTotal: 0,
            VendorSearchInput: "",
            selectionModel: [],
          },
          () => {
            this.fetchFilterVendors(1);
          }
        );
      } else {
        this.setState({
          isFilterActive: false,
          vendor_name: "",
          vendor_code: "",
          country: "",
          industry_type: "",
          filterDataCurrentPage: 1,
          filteredRows: [],
          filterDataLastPage: 1,
        });
      }
    }
  };

  updateSelectedVendors = (newSelectionModel, finalData) => {
    let updatedVendors;
    
    if (finalData) {
      updatedVendors = finalData.map((vendor) => ({
        ...vendor,
        vendor_location: this.state.vendorClassifications[vendor.id] || ''
      }));
    } else {
      updatedVendors = this.state.mainSelectionModel.filter((vendor) =>
        newSelectionModel.includes(vendor.id)
      ).map((vendor) => ({
        ...vendor,
        vendor_location: this.state.vendorClassifications[vendor.id] || ''
      }));
    }
      this.setState({
      selectionModel: newSelectionModel,
      mainSelectionModel: updatedVendors
    });
  
    this.props.setSelectedVendors(updatedVendors);
    };

  handleReload = () => {
    this.props.handleCategoryVendorsReload();
  };

  getVendorLocation = (country) => {
    if (this.props.accountlevelInfo?.country === country) {
      return "L";
    } else return "I";
  };

  handleClassificationChange = (vendorId, newClassification) => {
    this.setState((prevState) => {
      const updatedVendorClassifications = {
        ...prevState.vendorClassifications,
        [vendorId]: newClassification,
      };
      const updatedSelectedVendors = prevState.mainSelectionModel.map(vendor => {
          return { ...vendor, vendor_location: updatedVendorClassifications[vendor.id] }; 
      });

      return {
        vendorClassifications: updatedVendorClassifications, 
        mainSelectionModel: updatedSelectedVendors, 
      };
    }, () => {
        this.props.setSelectedVendors(this.state.mainSelectionModel);
    }
    );
  };

  fetchVendors = (page) => {
    const url = getAllLinkedVendors(
      this.props.selectedCategory?.id,
      page,
      this.state.selectallvendors ? false : this.state.pagination
    );
    this.setState({
      loading: true,
    });
    axios
      .get(url)
      .then((res) => {
        let finalRows = [...this.state.rows, ...res.data.data];
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueRows = Array.from(uniqueSet).map(JSON.parse);
        const updatedVendorClassifications = { ...this.state.vendorClassifications };
        uniqueRows.forEach((vendor) => {
          if (!updatedVendorClassifications[vendor.id]) {
            updatedVendorClassifications[vendor.id] = this.getVendorLocation(vendor.country);
          }
          return {
            ...vendor,
            vendor_location: updatedVendorClassifications[vendor.id],
          };
        });
        this.setState(
          {
            loading: false,
            mainDataWithoutPaginaion: uniqueRows,
            rows: uniqueRows,
            currentPage: res.data.meta?.current_page,
            lastPage: res.data.meta?.last_page,
            ...(!this.state.total && {
              total:
                this.state.pagination && !this.state.selectallvendors
                  ? res.data.meta.total
                  : uniqueRows.length,
            }),
            vendorClassifications: updatedVendorClassifications,
          },
          () => {
            if (this.state.selectallvendors) {
              const value = this.getSelectableRows(uniqueRows);
              this.setState({ mainSelectionModel: value });
              this.updateSelectedVendors(value.map((vendor) => vendor.id));
            }
            if (!this.state.pagination) {
              this.updateSelectedVendors(
                this.getSelectableRows(uniqueRows).map((vendor) => vendor.id)
              );
            }
          }
        );
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log("fetch vendors err", err);
      });
  };
  fetchFilterVendors = (page) => {
    const url = getFilteredVendors(
      this.props.selectedCategory?.id,
      this.state.vendor_name.trim(),
      this.state.vendor_name_operator,
      this.state.vendor_code.trim(),
      this.state.vendor_code_operator,
      this.state.country.trim(),
      this.state.country_operator,
      this.state.industry_type.trim(),
      this.state.industry_type_operator,
      this.state.email.trim(),
      this.state.email_operator,
      this.state.state.trim(),
      this.state.state_operator,
      this.state.contact_person.trim(),
      this.state.contact_person_operator,
      this.state.phone_no.trim(),
      this.state.phone_no_operator,
      this.state.address.trim(),
      this.state.address_operator,

      page
    );
    this.setState({
      loading: true,
    });
    axios
      .get(url)
      .then((res) => {
        if (page === 1) {
          this.setState({
            loading: false,
            filteredRows: res.data.data,
            filterDataCurrentPage: res.data.meta.current_page,
            filterDataLastPage: res.data.meta.last_page,
            filteredTotal: res.data.meta.total,
          });
        } else {
          let finalRows = [...this.state.filteredRows, ...res.data.data];
          let jsonObject = finalRows.map(JSON.stringify);
          let uniqueSet = new Set(jsonObject);
          let uniqueRows = Array.from(uniqueSet).map(JSON.parse);
          const updatedVendorClassifications = { ...this.state.vendorClassifications };
          uniqueRows.forEach((vendor) => {
            if (!updatedVendorClassifications[vendor.id]) {
              updatedVendorClassifications[vendor.id] = this.getVendorLocation(vendor.country);
            }
            return {
              ...vendor,
              vendor_location: updatedVendorClassifications[vendor.id],
            };
          });
          this.setState({
            loading: false,
            filteredRows: uniqueRows,
            filterDataCurrentPage: res.data.meta.current_page,
            filterDataLastPage: res.data.meta.last_page,
            vendorClassifications: updatedVendorClassifications,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log("fetch vendors err", err);
      });
  };

  searchVendors = () => {
    const url = searchCategoryVendorsByNameOrCode(
      this.props.selectedCategory?.id,
      this.state.VendorSearchInput.trim(),
      this.state.searchedDataCurrentPage
    );

    this.setState({
      loading: true,
    });

    axios
      .get(url)
      .then((res) => {
        if (this.state.searchedDataCurrentPage === 1) {
          let jsonObject = res.data.data.map(JSON.stringify);
          let uniqueRows = Array.from(jsonObject).map(JSON.parse);
          const updatedVendorClassifications = { ...this.state.vendorClassifications };
          uniqueRows.forEach((vendor) => {
            if (!updatedVendorClassifications[vendor.id]) {
              updatedVendorClassifications[vendor.id] = this.getVendorLocation(vendor.country);
            }
            return {
              ...vendor,
              vendor_location: updatedVendorClassifications[vendor.id],
            };
          });
          this.setState({
            loading: false,
            searchedRows: uniqueRows,
            searchedDataCurrentPage: res.data.meta.current_page,
            searchedDataLastPage: res.data.meta.last_page,
            searchededTotal: res.data.meta.total,
            vendorClassifications: updatedVendorClassifications,
          });
        } else {
          let finalRows = [...this.state.searchedRows, ...res.data.data];
          let jsonObject = finalRows.map(JSON.stringify);
          let uniqueSet = new Set(jsonObject);
          let uniqueRows = Array.from(uniqueSet).map(JSON.parse);
          const updatedVendorClassifications = { ...this.state.vendorClassifications };
          uniqueRows.forEach((vendor) => {
            if (!updatedVendorClassifications[vendor.id]) {
              updatedVendorClassifications[vendor.id] = this.getVendorLocation(vendor.country);
            }
            return {
              ...vendor,
              vendor_location: updatedVendorClassifications[vendor.id],
            };
          });
          this.setState({
            loading: false,
            searchedRows: uniqueRows,
            searchedDataCurrentPage: res.data.meta.current_page,
            searchedDataLastPage: res.data.meta.last_page,
            vendorClassifications: updatedVendorClassifications,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log("searched vendors err", err);
      });
  };

  handleSearchChange = (evt) => {
    this.setState({
      VendorSearchInput: evt.target.value,
    });
    if (evt.target.value.trim() !== "") {
      this.setState(
        {
          isFilterActive: false,
          vendorSearchDebounceDelay: true,
          loading: this.state.pagination,
          searchedRows: [],
          selectionModel: [],
          searchedDataCurrentPage: 1,
          searchedDataLastPage: 1,
          searchededTotal: 0,
          filterModel: {
            items: [],
          },
        },
        () => {
          this.getSearchApi();
        }
      );
    } else {
      this.setState(
        {
          isFilterActive: false,
          vendor_name: "",
          vendor_code: "",
          country: "",
          industry_type: "",
          filterDataCurrentPage: 1,
          filteredRows: [],
          filterDataLastPage: 1,
        },
        () => this.getSearchApi()
      );
    }
  };

  getApi = debounce(() => {
    this.setState({
      isFilterActive: true,
      vendorSearchDebounceDelay: false,
    });
    this.fetchFilterVendors(1);
  }, 1000);

  escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  getSearchApi = debounce(() => {
    this.setState({
      vendorSearchDebounceDelay: false,
    });

    if (this.state.pagination) {
      this.searchVendors();
    } else {
      if (this.state.VendorSearchInput.trim() === "") {
        this.setState(
          {
            selectionModel: [],
          },
          () => {
            this.setState(
              {
                rows: this.state.mainDataWithoutPaginaion,
              },
              () => {
                this.setState({
                  selectionModel: this.state.mainDataWithoutPaginaion.map(
                    (vendor) => vendor.id
                  ),
                });
              }
            );
          }
        );
        return;
      }

      const searchRegex = new RegExp(
        this.escapeRegExp(this.state.VendorSearchInput.trim()),
        "i"
      );
      const filteredRows = this.state.mainDataWithoutPaginaion.filter((row) => {
        return searchRegex.test(row["vendor_name"].toString());
      });
      this.setState(
        {
          rows: filteredRows,
          // selectionModel:this.state.mainDataWithoutPaginaion.map(vendor =>vendor.id),
        },
        () => {
          this.setState({
            selectionModel: this.state.rows.map((vendor) => vendor.id),
          });
        }
      );

      // setRows(filteredRows);
    }
  }, 1000);

  isVendorSelected = (row) => {
    return this.state.mainSelectionModel.some((e) => e.id === row.id);
  };

  handleVendorClick = (vendor) => {
    const selectedIndex = this.state.mainSelectionModel
      .map((ob) => ob.id)
      .indexOf(vendor.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.mainSelectionModel, vendor);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.mainSelectionModel.slice(1));
    } else if (selectedIndex === this.state.mainSelectionModel.length - 1) {
      newSelected = newSelected.concat(
        this.state.mainSelectionModel.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.mainSelectionModel.slice(0, selectedIndex),
        this.state.mainSelectionModel.slice(selectedIndex + 1)
      );
    }
    this.setState(
      {
        mainSelectionModel: newSelected,
      },
      () => {
        this.updateSelectedVendors(
          this.state.mainSelectionModel.map((cv) => cv.id),
          this.state.mainSelectionModel
        );
      }
    );
  };

  getSelectableRows = (data) => {
    return data.filter((params) => {
      if (!params.email?.length) {
        return false;
      }
      // if inco is enable and vendor has no inco
      if (this.props.rfqSettingValues?.vendorMasterPaymentTerm) {
        if (!params.payment_term_info.payment_term_code) return false;
      }
      // if payment is enable and vendor has no payment
      if (this.props.rfqSettingValues?.vendorMasterIncoTerm) {
        if (!params.inco_term_info.inco_term_code) return false;
      }
      return true;
    });
  };
  handleSelectAllClick = (event) => {
    if (this.state.rows.length !== this.state.total && event.target.checked) {
      this.setState({ selectallarea: event.target.checked });
    } else {
      this.setState({ selectallarea: false });
    }

    if (!this.state.pagination) {
      return;
    }
    if (this.state.showSelected) {
      this.setState(
        {
          mainSelectionModel: [],
        },
        () => {
          this.updateSelectedVendors([]);
        }
      );
      return;
    }
    let newSelecteds = [];
    if (this.state.isFilterActive) {
      let newRows = [
        ...this.getSelectableRows(this.state.filteredRows),
        ...this.state.mainSelectionModel,
      ];
      let finalRows = newRows;
      let jsonObject = finalRows.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      newSelecteds = Array.from(uniqueSet).map(JSON.parse);
    } else {
      if (this.state.VendorSearchInput.trim() === "") {
        // let newRows = [...this.state.rows, ...this.state.mainSelectionModel];
        let newRows = [
          ...this.getSelectableRows(this.state.rows),
          ...this.state.mainSelectionModel,
        ];
        let finalRows = newRows;
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        newSelecteds = Array.from(uniqueSet).map(JSON.parse);
      } else {
        let newRows = [
          ...this.getSelectableRows(this.state.searchedRows),
          ...this.state.mainSelectionModel,
        ];
        let finalRows = newRows;
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        newSelecteds = Array.from(uniqueSet).map(JSON.parse);
      }
    }

    if (event.target.checked) {
      this.setState(
        {
          mainSelectionModel: newSelecteds,
        },
        () => {
          this.updateSelectedVendors(newSelecteds.map((vendor) => vendor.id));
        }
      );
      return;
    }

    let update = [];
    if (this.state.isFilterActive) {
      update = this.state.mainSelectionModel.filter(
        (el) => !this.state.filteredRows.map((ob) => ob.id).includes(el.id)
      );
    } else {
      if (this.state.VendorSearchInput.trim() === "") {
        //removing the rows from the main selection model
        update = this.state.mainSelectionModel.filter(
          (el) => !this.state.rows.map((ob) => ob.id).includes(el.id)
        );
      } else {
        //removing the searched:inkedItems from the checked linked items
        update = this.state.mainSelectionModel.filter(
          (el) => !this.state.searchedRows.map((ob) => ob.id).includes(el.id)
        );
      }
    }
    this.setState(
      {
        mainSelectionModel: update,
      },
      () => {
        this.updateSelectedVendors(
          update.map((vendor) => vendor.id),
          this.state.mainSelectionModel
        );
      }
    );
  };

  commonItems = (arr1, arr2) => {
    return arr1.filter(function (o1) {
      return arr2.some(function (o2) {
        return o1.id === o2.id; // return the ones with equal id
      });
    });
  };

  getFirstColumn = () => {
    if (this.state.showSelected) {
      return {
        field: "id",
        width: 20,
        disableColumnMenu: true,
        align: "left",
        headerAlign: "left",
        editable: false,
        sortable: false,
        filterable: false,
        renderHeader: () => (
          <Tooltip title="remove all vendors">
            <IconButton
              onClick={(event) => this.handleSelectAllClick(event)}
              color="error">
              <RemoveCircleIcon />
            </IconButton>
          </Tooltip>
        ),
        renderCell: (params) => {
          return (
            <Tooltip title="remove vendor">
              <IconButton
                onClick={() => this.handleVendorClick(params.row)}
                color="error">
                <RemoveCircleIcon />
              </IconButton>
            </Tooltip>
          );
        },
      };
    } else {
      return {
        field: "id",
        width: 42,
        disableColumnMenu: true,
        align: "left",
        headerAlign: "left",
        editable: false,
        sortable: false,
        filterable: false,
        renderHeader: () => (
          <Checkbox
            indeterminate={
              this.state.isFilterActive
                ? this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.filteredRows)
                  )?.length > 0 &&
                  this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.filteredRows)
                  )?.length <
                    this.getSelectableRows(this.state.filteredRows).length
                : this.state.VendorSearchInput.trim() === ""
                ? this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.rows)
                  )?.length > 0 &&
                  this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.rows)
                  )?.length < this.getSelectableRows(this.state.rows).length
                : this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.searchedRows)
                  )?.length > 0 &&
                  this.commonItems(
                    this.state.mainSelectionModel,
                    this.getSelectableRows(this.state.searchedRows)
                  )?.length <
                    this.getSelectableRows(this.state.searchedRows).length
            }
            checked={
              this.state.pagination
                ? this.state.isFilterActive
                  ? this.commonItems(
                      this.state.mainSelectionModel,
                      this.getSelectableRows(this.state.filteredRows)
                    )?.length > 0 &&
                    this.commonItems(
                      this.state.mainSelectionModel,
                      this.getSelectableRows(this.state.filteredRows)
                    )?.length ===
                      this.getSelectableRows(this.state.filteredRows).length
                  : this.state.VendorSearchInput.trim() === ""
                  ? this.commonItems(
                      this.state.mainSelectionModel,
                      this.getSelectableRows(this.state.rows)
                    )?.length > 0 &&
                    this.commonItems(
                      this.state.mainSelectionModel,
                      this.getSelectableRows(this.state.rows)
                    )?.length === this.getSelectableRows(this.state.rows).length
                  : this.commonItems(
                      this.state.mainSelectionModel,
                      this.getSelectableRows(this.state.searchedRows)
                    )?.length > 0 &&
                    this.commonItems(
                      this.state.mainSelectionModel,
                      this.getSelectableRows(this.state.searchedRows)
                    )?.length ===
                      this.getSelectableRows(this.state.searchedRows).length
                : true
            }
            onChange={(event) => this.handleSelectAllClick(event)}
          />
        ),
        renderCell: (params) => {
          if (!params.row.email?.length) {
            return "";
          }
          // if inco is enable and vendor has no inco
          if (this.props.rfqSettingValues?.vendorMasterPaymentTerm) {
            if (!params.row.payment_term_info.payment_term_code) return "";
          }
          // if payment is enable and vendor has no payment
          if (this.props.rfqSettingValues?.vendorMasterIncoTerm) {
            if (!params.row.inco_term_info.inco_term_code) return "";
          }
          return (
            <Checkbox
              checked={
                this.state.pagination ? this.isVendorSelected(params.row) : true
              }
            />
          );
        },
      };
    }
  };

  selectallbuttonclick = () => {
    const { total, rows } = this.state;
    if (rows.length === total) {
      const mainrows = this.getSelectableRows(rows);
      this.setState({ mainSelectionModel: mainrows });
      this.updateSelectedVendors(mainrows.map((vendor) => vendor.id));
    } else {
      this.setState({ selectallvendors: true }, () => this.fetchVendors(1));
      // this.props.setSelectAllVendors(true)
    }
  };
  render() {
    const columns = [
      this.getFirstColumn(),
      {
        field: "actions",
        type: "actions",
        width: 30,
        align: "left",
        resizable: false,
        hide: !(
          this.props.userInfo?.is_super_user === "Y" ||
          this.props.masterPermission.vendor_master === "Y"
        ),
        getActions: (params) => [
          <GridActionsCellItem
            className="dropdown_menu_with_icon"
            //icon={<MoreVert />}
            label="Edit Vendor"
            showInMenu
            onClick={() => {
              // deleteUser(params.id);
              this.props.handleAddVendorDialogOpen(
                params.id,
                params.row.vendor_name
              );
            }}
          />,
        ],
      },
      {
        field: "vendor_name",
        headerName: "VENDOR NAME",
        width: 320,
        editable: false,
        filterable: this.state.VendorSearchInput.trim() === "",
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return (
            <React.Fragment>
              <Avatar
                sx={{ backgroundColor: (theme) => theme.palette.success.main }}>
                {params.row.vendor_name.slice(0, 1)}
              </Avatar>
              <Box sx={{ lineHeight: "12px", ml: 1 }}>
                <Tooltip
                  title={params.row.vendor_name}
                  disableInteractive
                  placement="bottom-start">
                  <Typography color="text.primary" fontWeight={500} noWrap>
                    {params.row.vendor_name}
                  </Typography>
                </Tooltip>
                <Typography variant="body2" component="span" color="grey.500">
                  {params.row.vendor_code}
                </Typography>
              </Box>
            </React.Fragment>
          );
        },
      },
      {
        field: "vendor_status",
        headerName: "VENDOR STATUS",
        width: 150,
        editable: false,
        filterable: this.state.VendorSearchInput.trim() === "",
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return (
            <React.Fragment>
              <Tooltip
                title=""
                disableInteractive
                placement="bottom-start">
                <Typography fontWeight={500}>
                  {params.row.is_approved === "approved" ? 
                    getVendorGMPFlag("Y", "A", "Approved", 'A', "") : 
                    getVendorGMPFlag("Y", "N", "Non-Approved", 'N', "")
                  }
                  {getVendorGMPFlag(this.props.companyInfo.gst_verification, params.row.gstin_no, params.row.gstin_status, 'G', "GSTIN")}
                  {params.row.industry_type === "MSME" ? 
                    getVendorGMPFlag(this.props.companyInfo.msme_verification, params.row.industry_type_value, params.row.msme_status, 'M', "MSME") : 
                    getVendorGMPFlag(this.props.companyInfo.msme_verification, "", params.row.msme_status, 'M', "MSME")
                  }
                  {getVendorGMPFlag(this.props.companyInfo.pan_verification, params.row.pan_no, params.row.pan_status, 'P', "PAN")}
                </Typography>
              </Tooltip>
            </React.Fragment>
          );
        },
        // flex:1,
        // sortable: false,
      },
      {
        field: "vendor_classification",
        headerName: "VENDOR CLASSIFICATION",
        width: 160,
        editable: false,
        filterable: this.state.VendorSearchInput.trim() === "",
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          const vendorClassification = this.state.vendorClassifications[params.row.id];
          return (
            <React.Fragment>
              <FormControl sx={{ minWidth: 120 }} size="small" >
                <Select
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  readOnly = {this.props.masterPermission.allow_vendor_type_modification_for_rfq !== 'Y'}
                  value={vendorClassification}
                  onChange={(event) => this.handleClassificationChange(params.row.id, event.target.value)}
                  
                >
                  <MenuItem value={'L'}>Local</MenuItem>
                  <MenuItem value={'I'}>International</MenuItem>
                </Select>
              </FormControl>
            </React.Fragment>
          );
        },
      },
      {
        field: "country",
        headerName: "COUNTRY",
        filterable: this.state.VendorSearchInput.trim() === "",
        // flex:1,
        width: 150,
        editable: false,
        sortable: false,
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return <Typography>{params.row.country}</Typography>;
        },
      },
      {
        field: "email",
        headerName: "Email",
        filterable: this.state.VendorSearchInput.trim() === "",
        width: 180,
        editable: false,
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return (
            <React.Fragment>
              <Tooltip
                title={params.row.email}
                disableInteractive
                placement="bottom-start">
                <Typography>{params.row.email}</Typography>
              </Tooltip>
            </React.Fragment>
          );
        },
      },
     
      {
        field: "state",
        headerName: "State",
        filterable: this.state.VendorSearchInput.trim() === "",
        width: 150,
        editable: false,
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return <Typography>{params.row.state}</Typography>;
        },
      },
      {
        field: "industry_type",
        headerName: "INDUSTRY TYPE",
        filterable: this.state.VendorSearchInput.trim() === "",
        // flex: 1,
        width: 200,
        editable: false,
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return <Typography>{params.row.industry_type}</Typography>;
        },
        // sortable: false,
      },
      {
        field: "contact_person",
        headerName: "Contact Person",
        filterable: this.state.VendorSearchInput.trim() === "",
        width: 150,
        editable: false,
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return <Typography>{params.row.contact_person}</Typography>;
        },
      },
      {
        field: "phone_no",
        headerName: "Contact Number",
        filterable: this.state.VendorSearchInput.trim() === "",
        width: 150,
        editable: false,
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return <Typography>{params.row.phone_no}</Typography>;
        },
      },
      {
        field: "address",
        headerName: "Address",
        filterable: this.state.VendorSearchInput.trim() === "",
        width: 150,
        editable: false,
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return <Typography>{params.row.address}</Typography>;
        },
      },
    ];
    function SkeletonLoadingOverlay() {
      const apiRef = useGridApiContext();
  
      const dimensions = apiRef.current?.getRootDimensions();
      const viewportHeight = dimensions?.viewportInnerSize.height ?? 0;
  
      const rowHeight = apiRef.current.unstable_getRowHeight();
      const skeletonRowsCount = Math.ceil(viewportHeight / rowHeight);
  
      const totalWidth = gridColumnsTotalWidthSelector(apiRef);
      const positions = gridColumnPositionsSelector(apiRef);
      const inViewportCount = React.useMemo(
        () => positions.filter((value) => value <= totalWidth).length,
        [totalWidth, positions]
      );
      const columns = apiRef.current.getVisibleColumns().slice(0, inViewportCount);
  
      const children = React.useMemo(() => {
        // reseed random number generator to create stable lines betwen renders
        const random = randomBetween(12345, 25, 75);
        const array= [];
  
        for (let i = 0; i < skeletonRowsCount; i += 1) {
          for (const column of columns) {
            const width = Math.round(random());
            array.push(
              <SkeletonCell
                key={`column-${i}-${column.field}`}
                sx={{ justifyContent: column.align }}
              >
                <Skeleton sx={{ mx: 1 }} width={`${width}%`} />
              </SkeletonCell>
            );
          }
          array.push(<SkeletonCell key={`fill-${i}`} />);
        }
        return array;
      }, [skeletonRowsCount, columns]);
  
      const rowsCount = apiRef.current.getRowsCount();
      return rowsCount > 0 ? (
        <LinearProgress />
        
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: `${columns
              .map(({ computedWidth }) => `${computedWidth}px`)
              .join(" ")} 1fr`,
            gridAutoRows: rowHeight
          }}
        >
          {children}
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="card_top_bg">
          <Grid container>
            <Grid item lg={6} md={6} sm={10} xs={10} className="card_top_bg" sx={{borderBottom:"none"}}>
              <InputBase
                sx={{ width: "100%" }}
                placeholder="Search Vendor Name"
                value={this.state.VendorSearchInput}
                onChange={this.handleSearchChange}
                startAdornment={
                  <InputAdornment position="start">
                    {this.state.vendorSearchDebounceDelay ? (
                      <IconButton sx={{ color: "grey.600" }}>
                        <LoopIcon />
                      </IconButton>
                    ) : this.state.VendorSearchInput.trim() !== "" ? (
                      <IconButton
                        style={{ color: `grey.600` }}
                        onClick={() => {
                          this.setState({
                            VendorSearchInput: "",
                            isFilterActive: false,
                            vendor_name: "",
                            vendor_code: "",
                            country: "",
                            industry_type: "",
                            filterDataCurrentPage: 1,
                            filteredRows: [],
                            filterDataLastPage: 1,
                          });

                          if (!this.state.pagination) {
                            this.setState(
                              {
                                selectionModel: [],
                              },
                              () => {
                                this.setState(
                                  {
                                    rows: this.state.mainDataWithoutPaginaion,
                                  },
                                  () => {
                                    this.setState({
                                      selectionModel:
                                        this.state.mainDataWithoutPaginaion.map(
                                          (vendor) => vendor.id
                                        ),
                                    });
                                  }
                                );
                              }
                            );
                            return;
                          }
                        }}>
                        <ClearIcon />
                      </IconButton>
                    ) : (
                      <IconButton style={{ color: `grey.600` }}>
                        <SearchIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={2}
              xs={2}
              className="top_right_content">
              {this.state.pagination && (
                <Tooltip
                  title={
                    this.state.mainSelectionModel.length &&
                    "View Selected vendors"
                  }>
                  <IconButton
                    sx={{
                      ml: "auto",
                      color: (theme) =>
                        this.state.showSelected
                          ? theme.palette.primary.main
                          : theme.palette.grey[600],
                    }}
                    disabled={!this.state.mainSelectionModel.length}
                    onClick={() =>
                      this.setState({
                        showSelected: !this.state.showSelected,
                      })
                    }>
                    <Badge
                      badgeContent={this.state.mainSelectionModel.length}
                      color="error">
                      <ListAltOutlinedIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="More">
                <IconButton onClick={this.handleClick} size="small">
                  <MoreVert />
                </IconButton>
              </Tooltip>
              <Menu
                id="long-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}>
                <MenuItem
                  onClick={() => {
                    this.handleReload();
                  }}>
                  <RefreshIcon />
                  Reload
                </MenuItem>
                {(this.props.userInfo?.is_super_user === "Y" ||
                  this.props.masterPermission.vendor_master === "Y") && (
                  <MenuItem
                    onClick={() => {
                      if (this.props.companyInfo?.common_vendor_login === "Y") {
                        this.props.handleCompanyToAccountOpen();
                      } else {
                        this.props.handleAddVendorDialogOpen();
                      }
                      this.handleClose();
                    }}>
                    <AddCircleOutlinedIcon />
                    Add Vendor
                  </MenuItem>
                )}
                {(this.props.userInfo?.is_super_user === "Y" ||
                  this.props.masterPermission.linking === "Y") && (
                  <MenuItem
                    onClick={() => {
                      this.props.handlelinkVendorToCategoryOpen();
                      // this.setState({
                      //   linkVendorToCategoryOpen: true,
                      // });
                      this.handleClose();
                    }}>
                    <InsertLinkIcon />
                    Link Unlink Vendor(s)
                  </MenuItem>
                )}
              </Menu>
            </Grid>
          </Grid>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <div className="item_listing_card category_vendor_items">
              {this.state.selectallarea && (
                <Box sx={{ pt: 1, pb: 1, textAlign: "center" }}>
                  <Typography component="span">
                    {this.state.mainSelectionModel.length} vendor on this page
                    are selected.
                  </Typography>
                  <Button onClick={this.selectallbuttonclick} variant="text">
                    Select all {this.state.total} Vendors
                  </Button>
                </Box>
              )}
              <div
                style={{ height: 420, width: "100%" }}
                className="position-relative">
                {/* <DataGridVendor */}
                {this.state.loading || this.state.rows.length > 0 ? (
                  <React.Fragment>
                    {this.state.showSelected ? (
                      <DataGridPro
                        sx={{
                          zIndex: "0",
                          border: "none",
                          "& .MuiDataGrid-columnHeaders": {
                            border: 0,
                          },
                          "& .Mui-disabled": {
                            visibility: "hidden",
                          },
                          ".MuiDataGrid-columnHeaderTitleContainer": {
                            padding: 0,
                          },
                        }}
                        rows={this.state.mainSelectionModel}
                        rowSelectionModel={this.state.mainSelectionModel.map(
                          (ob) => ob.id
                        )}
                        columns={columns}
                        disableColumnSelector
                        disableColumnReorder
                        hideFooterPagination
                        slots={{
                          noRowsOverlay: () => {
                            return (
                              <GridOverlay>
                                <Typography>
                                  {this.props.error
                                    ? this.props.error
                                    : "Vendors not available"}
                                </Typography>
                              </GridOverlay>
                            );
                          },
                          footer: () => {
                            return (
                              <div
                                style={{
                                  textAlign: "right",
                                  margin: "15px 10px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}>
                                <Typography>
                                  {this.state.mainSelectionModel?.length > 0
                                    ? this.state.mainSelectionModel.length +
                                      " Selected"
                                    : ""}
                                </Typography>
                              </div>
                            );
                          },
                        }}
                        isRowSelectable={(params) => {
                          if (!params.row.email?.length) {
                            return false;
                          }
                          // if inco is enable and vendor has no inco
                          if (
                            this.props.rfqSettingValues?.vendorMasterPaymentTerm
                          ) {
                            if (!params.row.payment_term_info.payment_term_code)
                              return false;
                          }
                          // if payment is enable and vendor has no payment
                          if (
                            this.props.rfqSettingValues?.vendorMasterIncoTerm
                          ) {
                            if (!params.row.inco_term_info.inco_term_code)
                              return false;
                          }
                          return true;
                        }}
                        disableColumnPinning
                      />
                    ) : (
                      <DataGridPro
                        sx={{
                          zIndex: "0",
                          border: "none",
                          "& .MuiDataGrid-columnHeaders": {
                            border: 0,
                          },
                          "& .Mui-disabled": {
                            visibility: "hidden",
                          },
                          ".MuiDataGrid-columnHeaderTitleContainer": {
                            padding: 0,
                          },
                        }}
                        rows={
                          this.state.pagination
                            ? this.state.isFilterActive
                              ? this.state.filteredRows
                              : this.state.VendorSearchInput.trim() === ""
                              ? this.state.rows
                              : this.state.searchedRows
                            : this.state.rows
                        }
                        loading={this.state.loading}
                        rowSelectionModel={
                          this.state.pagination
                            ? this.state.isFilterActive
                              ? this.state.mainSelectionModel
                                  .filter((el) =>
                                    this.state.filteredRows
                                      .map((ob) => ob.id)
                                      .includes(el.id)
                                  )
                                  .map((row) => row.id)
                              : this.state.VendorSearchInput.trim() === ""
                              ? this.state.mainSelectionModel
                                  .filter((el) =>
                                    this.state.rows
                                      .map((ob) => ob.id)
                                      .includes(el.id)
                                  )
                                  .map((row) => row.id)
                              : this.state.mainSelectionModel
                                  .filter((el) =>
                                    this.state.searchedRows
                                      .map((ob) => ob.id)
                                      .includes(el.id)
                                  )
                                  .map((row) => row.id)
                            : this.state.selectionModel
                        }
                      
                        onRowsScrollEnd={() => {
                          if (this.state.pagination) {
                            this.handleScrollEnd();
                          }
                        }}
                        onRowClick={(params) => {
                          if (this.state.pagination) {
                            if (!params.row.email?.length) {
                              return;
                            }
                            // if inco is enable and vendor has no inco
                            if (
                              this.props.rfqSettingValues
                                ?.vendorMasterPaymentTerm
                            ) {
                              if (
                                !params.row.payment_term_info.payment_term_code
                              )
                                return;
                            }
                            // if payment is enable and vendor has no payment
                            if (
                              this.props.rfqSettingValues?.vendorMasterIncoTerm
                            ) {
                              if (!params.row.inco_term_info.inco_term_code)
                                return;
                            }
                            this.handleVendorClick(params.row);
                          }
                        }}
                        filterModel={this.state.filterModel}
                      
                        onFilterModelChange={this.handleFilterModelChange}
                        columns={columns}
                        disableColumnSelector
                        disableColumnReorder
                        hideFooterPagination
                        filterMode={this.state.pagination ? "server" : "client"}
                        slots={{
                          // noRowsOverlay: () => {
                          //   console.log("NoRowsOverlay")
                          //   return (
                          //     <GridOverlay>
                          //       <Typography>
                          //         {this.props.error
                          //           ? this.props.error
                          //           : "Vendors not available 2"}
                          //       </Typography>
                          //     </GridOverlay>
                          //   );
                          // },
                          //showing Skeleton while data is loading

                          loadingOverlay: () => SkeletonLoadingOverlay(),
                          footer: () => {
                            return (
                              <div
                                style={{
                                  textAlign: "right",
                                  margin: "15px 10px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}>
                                <Typography>
                                  {this.state.mainSelectionModel?.length > 0
                                    ? this.state.mainSelectionModel.length +
                                      " Selected"
                                    : ""}
                                </Typography>
                                <Typography className="grid_footer_text">
                                  {this.state.isFilterActive
                                    ? `Showing ${this.state.filteredRows.length} / ${this.state.filteredTotal}`
                                    : this.state.VendorSearchInput.trim() === ""
                                    ? `Showing ${this.state.rows.length} / ${this.state.total}`
                                    : `Showing ${this.state.searchedRows.length} / ${this.state.searchededTotal}`}
                                </Typography>
                              </div>
                            );
                          },
                        }}
                        isRowSelectable={(params) => {
                          if (!params.row.email?.length) {
                            return false;
                          }
                          // if inco is enable and vendor has no inco
                          if (
                            this.props.rfqSettingValues?.vendorMasterPaymentTerm
                          ) {
                            if (!params.row.payment_term_info.payment_term_code)
                              return false;
                          }
                          // if payment is enable and vendor has no payment
                          if (
                            this.props.rfqSettingValues?.vendorMasterIncoTerm
                          ) {
                            if (!params.row.inco_term_info.inco_term_code)
                              return false;
                          }
                          return true;
                        }}
                        disableColumnPinning
                      />
                    )}
                  </React.Fragment>
                ) : (
                  <Grid>
                    {this.props.error ? (
                      <Typography>{this.props.error}</Typography>
                    ) : (
                      <div className="no-vendor vendor_not_available_main">
                        <img src={vendoremptyicon} alt="Vendor Empty Icon" />
                        <Typography variant="h5" className="mt-15">
                          Vendors not available
                        </Typography>
                        <Typography variant="caption">
                          {(this.props.userInfo?.is_super_user === "Y" ||
                            this.props.masterPermission.linking === "Y") && (
                            <React.Fragment>
                              <span
                                onClick={() => {
                                  this.props.handlelinkVendorToCategoryOpen();

                                  // this.setState({
                                  //   linkVendorToCategoryOpen: true,
                                  // });
                                }}>
                                Link vendors
                              </span>
                              with category
                            </React.Fragment>
                          )}
                        </Typography>
                      </div>
                    )}
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
        {this.state.linkVendorToCategoryOpen && (
          <LinkVendorToCategory
            open={this.state.linkVendorToCategoryOpen}
            handleCategoryVendorsReload={this.handleReload}
            handleClose={() => {
              this.setState({
                linkVendorToCategoryOpen: false,
              });
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedCategory: state.categoryReducer.selectedCategory,
    selectedItems: state.itemReducer.selectedItems,
    sessionId: state.sessionReducer.sessionId,
    vendors: state.vendorReducer.vendors,
    vendorsLoading: state.vendorReducer.loading,
    vendorsError: state.vendorReducer.error,
    meta: state.vendorReducer.meta,
    filteredVendors: state.vendorReducer.filteredVendors,
    filteredVendorsLoading: state.vendorReducer.filteredVendorsLoading,
    filteredVendorsError: state.vendorReducer.filteredVendorsError,
    filteredVendorsMeta: state.vendorReducer.filteredVendorsMeta,
    selectedVendors: state.vendorReducer.selectedVendors || [],
    userInfo: state.userInfoReducer.userInfo,
    masterPermission: state.permissionsReducer.masterPermission,
    accountlevelInfo: state.permissionsReducer.accountlevelInfo,
    rfqSettingValues: state.drawerValuesReducer.rfqSettingValues,
    featurePermission: state.permissionsReducer.featurePermission,
    companyInfo: state.companyInfoReducer.companyInfo,
    defaultValues: state.prrfqreducer.defaultValues,
    ImportExcelRfq: state.ImportExcelReducer.excelResponse,
    getVendorLocationFlag: state.itemReducer.vendorLocationFlag
  };
};

export default connect(mapStateToProps, {
  getVendors,
  filterVendors,
  setSelectedVendors,
  resetVendors,
  setVendorLocationFlag,
  // setSelectAllVendors
})(CategoryVendors);
