import React, { useEffect, useState } from "react";
//import { styles } from "./style";
import {
  Grid,
  Drawer,
  Toolbar,
  Typography,
  Backdrop,
  CircularProgress,
  Badge,
  IconButton,
  InputBase,
  InputAdornment,
  Chip,
  Tooltip,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { styles } from "./PrLineItemStyle";
import { setPrDialog } from "../../redux/actions/prrfqactions";

import { useDispatch, useSelector } from "react-redux";
import "./prgroup.css";
import LoopIcon from "@mui/icons-material/Loop";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { getFilterOperators } from "../Utils/datagridHelper";
import { DataGridPro } from "@mui/x-data-grid-pro";
import commifyWithPrecision from "../../common/utility";

const PrLineItems = (props) => {
  // const prOpen = useSelector((state)=>state.prrfqreducer.selectedPrItems)
  const prlineItems = useSelector(
    (state) => state.prrfqreducer.setPrLineItemsData
  );
  const pr_dropdown_values = useSelector(
    (state) => state.prrfqreducer.pr_dropdown_values
  );
  const prItem = useSelector((state) => state.prrfqreducer.selectedPrItems);
  const [prOpen, setPrOpen] = useState(props.prOpen);
  const [data, setData] = useState(prlineItems[prItem.id] || []);
  const [datalength, setdatalength] = useState(data.length);
  const [pagination, setPagination] = useState(false);
  const [prSearchInput, setPrSearchInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [combinationLength, setCombinationLength] = useState();
  const [prSearchInputeDelay, setPrSearchInputeDelay] = useState() || [];
  const [filterModel, setFilterModel] = useState({ items: [] });

  // let sum = 0;
  // prlineItems[prItem.id].forEach((prlineitem,i) => {
  //   sum += Number(prlineitem.quantity);
  // });
  const feature_permission_obj = useSelector((state)=>state.permissionsReducer.featurePermission);
  const international_numeric_format = feature_permission_obj?.international_numeric_format?.feature_value
  const dispatch = useDispatch();
  useEffect(() => {
    setPrOpen(props.prOpen);
  }, [props]);

  const columns = [
    {
      field: "prnumber",
      headerName: "PR NO",
      filterable: prSearchInput.trim() === "",
      // flex:1,
      width: 150,
      editable: false,
      valueGetter: (params) => {
        return params.row.pr.number;
      },
      filterOperators: getFilterOperators(),
    },
    {
      field: "pr_line_item",
      headerName: "LINE ITEM",
      filterable: prSearchInput.trim() === "",
      // flex:1,
      width: 140,
      editable: false,
      filterOperators: getFilterOperators(),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      filterable: prSearchInput.trim() === "",
      // flex:1,
      width: 130,
      editable: false,
      filterOperators: getFilterOperators(),
      // type:'number'
    },
    {
      field: "uom",
      headerName: "UNIT",
      filterable: prSearchInput.trim() === "",
      // flex:1,
      width: 130,
      editable: false,
      filterOperators: getFilterOperators(),
    },
    {
      field: "pgr",
      headerName: "PGRP",
      filterable: prSearchInput.trim() === "",
      // flex:1,
      width: 150,
      editable: false,
      filterOperators: getFilterOperators(),
      renderCell: (params) => {
        return (
          <Tooltip
            title={params.value ? params.value : ""}
            disableInteractive
            placement="bottom-start">
            <Typography noWrap>{params.value ? params.value : ""}</Typography>
          </Tooltip>
        );
      },
      valueGetter: (params) => {
        let ab =
          pr_dropdown_values &&
          pr_dropdown_values?.pgr &&
          pr_dropdown_values?.pgr[params.row["pgr"]]
            ? pr_dropdown_values?.pgr[params.row["pgr"]][
                "Dropdown_Element_Name"
              ]
            : "";
        return ab;
        //return pr_dropdown_values.pgr[params.row['pgr']] ? pr_dropdown_values.pgr[params.row['pgr']]['Dropdown_Element_Name'] : "";
      },
      // sortable: false,
    },
    {
      field: "plantName",
      headerName: "DESTINATION NAME",
      filterable: prSearchInput.trim() === "",
      // flex:1,
      width: 150,
      editable: false,
      filterOperators: getFilterOperators(),
      valueGetter : (params) => pr_dropdown_values?.plant?.[params.row.plant]?.Dropdown_Element_Name,
      renderCell: (params) => {
        return (
          <Tooltip
            title={params.value ? params.value : ""}
            disableInteractive
            placement="bottom-start">
            <Typography noWrap>{params.value ? params.value : ""}</Typography>
          </Tooltip>
        );
      }
    },
    {
      field: "plantCode",
      headerName: "DESTINATION CODE",
      filterable: prSearchInput.trim() === "",
      // flex:1,
      width: 150,
      editable: false,
      filterOperators: getFilterOperators(),
      valueGetter : (params) => params.row.plant,
      renderCell: (params) => {
        return (
          <Tooltip
            title={params.row.plant ? params.row.plant : ""}
            disableInteractive
            placement="bottom-start">
            <Typography noWrap>{params.row.plant ? params.row.plant : ""}</Typography>
          </Tooltip>
        );
      }
    },
  ];

  const handleClose = () => {
    dispatch(setPrDialog(false));
  };

  useEffect(() => {
    prlineItems[prItem.id].forEach((item, index) => {
      let quantity = parseFloat(item['quantity']);
      let roundedQuantity = commifyWithPrecision(quantity, international_numeric_format, true);
      prlineItems[prItem.id][index]['quantity'] = roundedQuantity;
    });
    setData(prlineItems[prItem.id]);
  }, []);

  useEffect(() => {
    setdatalength(data.length);
  }, [data]);

  const handleSearchChange = (value) => {
    setPrSearchInput(value);
    let newRows = [];
    //alert("prSearchInput" + prSearchInput + "value" + value);

    if (value.trim()) {
      prlineItems[prItem.id].forEach((row) => {
        if (row.pr.number.toLowerCase().includes(value.trim().toLowerCase())) {
          newRows.push(row);
        }
      });
    } else {
      newRows = prlineItems[prItem.id];
    }
    setData(newRows);
  };

  return (
    <React.Fragment>
      <Drawer
        className={props.classes.RFQSettingSlide}
        anchor={"right"}
        open={prOpen}>
        <Backdrop
          style={{ color: "#fff", zIndex: "10000000000" }}
          open={loading}>
          <CircularProgress style={{ color: "#fff" }} />
        </Backdrop>
        <Toolbar className="drawer-toolbar">
          <Typography className="drawer-title">
            {prItem.name} &nbsp;
            <Tooltip title={prItem.code}>
              <Chip
                size="small"
                color="primary"
                sx={{ borderRadius: "4px", fontWeight: 500, maxWidth: "160px" }}
                label={prItem.code}
                noWrap
              />
            </Tooltip>
          </Typography>
          <Badge badgeContent={combinationLength} color="primary"></Badge>

          <IconButton sx={{ ml: "auto" }} onClick={handleClose}>
            <Close />
          </IconButton>
        </Toolbar>
        <Grid container className="prdrawerinner_content">
          <Grid item lg={10} md={10} sm={10} xs={10} className="card_top_bg">
            <InputBase
              sx={{ width: "100%" }}
              placeholder="Search PR NO"
              value={prSearchInput}
              onChange={(event) => handleSearchChange(event.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  {prSearchInputeDelay ? (
                    <IconButton sx={{ color: "grey.600" }}>
                      <LoopIcon />
                    </IconButton>
                  ) : prSearchInput.trim() !== "" ? (
                    <IconButton
                      style={{ color: `grey.600` }}
                      onClick={() => {
                        handleSearchChange("");
                        if (!pagination) {
                          return;
                        }
                      }}>
                      <ClearIcon />
                    </IconButton>
                  ) : (
                    <IconButton style={{ color: `grey.600` }}>
                      <SearchIcon />{" "}
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </Grid>
          <div style={{ height: 560, margin: "1rem 0" }}>
            <DataGridPro
              columns={columns}
              rows={data}
              disableColumnSelector
              disableColumnReorder
              hideFooterPagination
              disableColumnPinning
              filterModel={filterModel}
              onFilterModelChange={(filterModel) => setFilterModel(filterModel)}
              filterMode={"client"}
            />
          </div>
        </Grid>
      </Drawer>
    </React.Fragment>
  );
};

export default withStyles(styles)(PrLineItems);
