import { Box, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, daDK } from '@mui/x-data-grid-pro';
import React, { useCallback, useEffect, useState } from 'react';
import Grid from "@mui/material/Unstable_Grid2";
import commifyWithPrecision from '../../common/utility';
import { useDispatch, useSelector } from "react-redux";



function R2aDutchAuctionTable(props) {
    //Dutch Auctions
    const feature_permission_obj = useSelector((state) => state.permissionsReducer.featurePermission);
    const international_numeric_format = feature_permission_obj?.international_numeric_format?.feature_value
    const [dutchItemData, setDutchItemData] = React.useState([]);
    const [dutchVendorData, setDutchVendorData] = React.useState([]);

    React.useEffect(() => {
        const auctionDutchItems = props.auctionDetail?.items || [];
        const dutchItemData = auctionDutchItems.map(item => ({
            id: item.id,
            name: item.name,
            code: item.code,
            quantity_uom: item.quantity + '/' + item.uom
        }));

        setDutchItemData(dutchItemData);
        const dutchR2aData = Array.from(
            auctionDutchItems
                .flatMap(item => item.r2a_data || [])
                .reduce((map, { vendor_id, inquiry_rate, auction_rate, vendor_name }) => {
                    if (!map.has(vendor_id)) {
                        map.set(vendor_id, {
                            vendor_id,
                            vendor_name,
                            auction_rate: auction_rate ?? 0,
                            inquiry_rate: inquiry_rate || 0,
                            totalInquiryRate: 0
                        });
                    }
                    const vendorData = map.get(vendor_id);
                    vendorData.totalInquiryRate += inquiry_rate || 0;
                    return map;
                }, new Map())
                .values()
        );

        const transformedData = dutchR2aData.map(data => {
            const totalSavingsAuction = (data.totalInquiryRate || 0) - (data.auction_rate || 0);
            const savingsInAuction = data.totalInquiryRate
                ? (totalSavingsAuction * 100) / data.totalInquiryRate
                : 0;
            return {
                id: data.vendor_id,
                vendorName: data.vendor_name,
                rfqRate: data.totalInquiryRate || '-',
                auctionRate: data.auction_rate,
                totalSavingAuction: totalSavingsAuction,
                totalSavingAuctionPer: savingsInAuction.toFixed(2)
            };
        });
        setDutchVendorData(transformedData);
    }, [props.auctionDetail])


    // Toolbar Component
    function CustomGridToolbarR2A() {
        return (
            <>
                <GridToolbarContainer className="datagrid-toolbar">
                    <Grid container spacing={0} sx={{
                        width: '100%',
                        '&.MuiGrid2-root': {
                            paddingTop: { xs: '0', sm: '0', md: '0', alignItems: 'center' },
                        }
                    }}>
                        <Grid xs={12} sm={12} md={3} xl={4}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, }}>
                                <GridToolbarQuickFilter className="DataGrid-SearchBar" sx={{ pb: 0 }} />
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={12} md={9} xl={8} sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                            <Box className="toolbar-right">
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, }}>&nbsp;</Box>
                                <Box className='dg-filter' >
                                    <GridToolbarColumnsButton />
                                    <GridToolbarExport />
                                    <GridToolbarFilterButton />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </GridToolbarContainer>

                <GridToolbarContainer className="datagrid-toolbar" sx={{ display: { sm: 'flex', md: 'none' } }}>
                    <Grid container spacing={0} sx={{ width: '100%', alignItems: 'center' }}>
                        <Grid xs={2} md={6}>
                            <Box>&nbsp;</Box>
                        </Grid>
                        <Grid xs={10} md={6} sx={{ textAlign: { xs: 'right', sm: 'right' } }}>
                            <Box className='dg-filter' >
                                <GridToolbarColumnsButton />
                                <GridToolbarExport />
                                <GridToolbarFilterButton />
                            </Box>
                        </Grid>
                    </Grid>
                </GridToolbarContainer>
            </>
        );
    }

    // Detail Panel Content
    function DetailPanelContent({ row, data }) {
        const columns = [
            { field: 'itemName', headerName: 'Item Name', width: 200 },
            { field: 'quantity_uom', headerName: 'quantity/uom', width: 150, editable: true }
        ];

        const rows = data.map(item => ({
            id: item.id,
            itemName: item.name,
            quantity_uom: item.quantity_uom
        }));

        return (
            <Stack
                sx={{ pt: 2, boxSizing: 'border-box', borderBottom: '1px solid var(--bluegrey-500)' }}
                direction="column"
            >
                <Paper sx={{ flex: 1, mx: 'auto', width: 'calc(100% - 32px)', borderRadius: '0', boxShadow: 'none', mb: 2 }}>
                    <DataGridPro
                        rows={rows}
                        columns={columns}
                        columnHeaderHeight={40}
                        disableRowSelectionOnClick
                        density="compact"
                        sx={{
                            maxHeight: '200px',
                            '&.MuiDataGrid-root': {
                                borderRadius: "0",
                                borderWidth: "1px",
                                borderColor: "var(--bluegrey-500)"
                            }
                        }}
                        hideFooter
                    />
                </Paper>
            </Stack>
        );
    }

    const getDetailPanelContent = useCallback(
        ({ row }) => <DetailPanelContent row={row} data={dutchItemData} />,
        [dutchItemData]
    );
    const getDetailPanelHeight = useCallback(() => 'auto', []);
    const rows = dutchVendorData.map(data => ({
        id: data.id,
        auctionVendorName: data.vendorName,
        rfqLandedValue: data.rfqRate,
        auctionLandedValue: data.auctionRate,
        totalSavingAuction: data.totalSavingAuction,
        totalSavingAuctionPer: data.totalSavingAuctionPer,
    }));



    const columnsR2A = [
        { field: 'auctionVendorName', headerName: 'Auction Vendor Name', width: 200 },
        {
            field: 'rfqLandedValue', headerName: 'RFQ LANDED VALUE', width: 200, headerAlign: "right", align: "right",
            renderCell: (params) => {
                return (
                    <Typography variant="body2" fontWeight={500}>
                        {
                            params.value == 0 || params.value == '-'
                                ? '-' : international_numeric_format && commifyWithPrecision(params.value, international_numeric_format, false)
                        }
                    </Typography>
                );
            },
        },
        {
            field: 'auctionLandedValue', headerName: 'AUCTION LANDED VALUE', width: 200, headerAlign: "right", align: "right",
            renderCell: (params) => {
                return (
                    <Typography variant="body2" fontWeight={500}>
                        {
                            params.value == 0 || params.value == '-'
                                ? '-' : commifyWithPrecision(params.value, international_numeric_format, false)
                        }
                    </Typography>
                );
            },
        },
        {
            field: 'totalSavingAuction', headerName: 'TOTAL SAVINGS IN AUCTION', width: 200, headerAlign: "right", align: "right",
            renderCell: (params) => {
                return (
                    <Typography variant="body2" fontWeight={500}>
                        {
                            params.value == 0 || params.value == '-'
                                ? '-' : commifyWithPrecision(params.value, international_numeric_format, false)
                        }</Typography>
                );
            },
        },
        {
            field: 'totalSavingAuctionPer', headerName: 'TOTAL SAVINGS IN AUCTION (%)', width: 200, headerAlign: "right", align: "right",
            renderCell: (params) => {
                return (
                    <Typography variant="body2" fontWeight={500}>
                        {
                            params.value == 0 || params.value == '-'
                                ? '-' : `${commifyWithPrecision(params.value, international_numeric_format, false)
                                }%`                                 
                        }</Typography>
                );
            },
        },
    ];

    return (
        <Box sx={{ height: "400", width: "100%" }}>
            <DataGridPro
                rows={rows}
                columns={columnsR2A}
                headerHeight={40}
                columnHeaderHeight={40}
                checkboxSelection
                disableSelectionOnClick
                disableRowSelectionOnClick
                pagination
                pageSizeOptions={[25, 50, 100]}
                slots={{
                    toolbar: CustomGridToolbarR2A,
                }}
                density="compact"
                className="fullScreenGrid"
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={getDetailPanelContent}
                sx={{
                    '&.MuiDataGrid-root': {
                        borderRadius: "0",
                        borderWidth: "1px 0 0 0"
                    },
                    '& .MuiDataGrid-virtualScrollerContent--overflowed .MuiDataGrid-row--lastVisible .MuiDataGrid-cell': {
                        borderBottomColor: '#a6c1d3'
                    }
                }}
                hideFooter
            />
        </Box>
    );
}

export default R2aDutchAuctionTable;
